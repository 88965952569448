import { useEffect, useState } from "react"

import scrollDown from "../../assets/images/scrollDownIcon.svg"
import "../../assets/css/reportResults/videoAndMap.css"
import Map from "../map/map.jsx"
import videoSource from "../../assets/videos/animacionInicial.mp4"

export default function VideoAndMap(props) {
  const [hide, setHide] = useState(false)                           //control for "report is ready" container visibility
  const [hideImg, setHideImg] = useState(false)                     //control for the scroll img visibility
  const [finish, setFinish] = useState(false)                       //control when animation of "report is ready" finish
  const [videoStart, setVideoStart] = useState(false)               //control for when video start
  const [videoFinish, setVideoFinish] = useState(false);            //control for when video finish
  const [animate, setAnimate] = useState(false)                     //control for start flashing of report is ready

  useEffect(() => {
    // Add an event listener to the video's "ended" event
    const videoElement = document.querySelector("#videoElement");
    if (videoElement) {
      videoElement.addEventListener("play", () => setVideoStart(true));
      videoElement.addEventListener("ended", () => { setVideoFinish(true), setAnimate(true) });
    }

    window.addEventListener("scroll", handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)

      if (videoElement) {
        videoElement.removeEventListener("play", () => setVideoStart(true));
        videoElement.removeEventListener("ended", () => { setVideoFinish(true), setAnimate(true) });
      }
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [videoFinish])

  function handleClick() {                        //move scrollbar to the bottom
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
  }

  function handleScroll() {                       //adjust scale for "report is ready" when scrollbar position
    const scrollPosition = window.scrollY
    const scrollHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight;
    const position = (scrollPosition + windowHeight) - scrollHeight

    const scaleFactor = 1 + ((scrollPosition / 100) * (scrollPosition / 150));
    const matrixValue = `matrix(${scaleFactor}, 0, 0, ${scaleFactor}, 0, 0)`

    const animatedTitle = document.querySelector(".animatedTitle")

    setHideImg(scrollPosition > 1 ? true : false)
    setAnimate(scrollPosition > 1 ? false : true)

    if (position >= 0) {
      window.removeEventListener("scroll", handleScroll)
      increaseScrollPosition(scrollPosition, animatedTitle)
    }

    if (animatedTitle) {
      animatedTitle.style.transform = matrixValue
    }
  }

  function increaseScrollPosition(scrollPosition, animatedTitle) {    //take control for "report is ready" animation
    let updatedScrollPosition = scrollPosition;
    let index = 0;

    function increaseWithDelay() {
      setTimeout(() => {
        if (index < 120) {
          updatedScrollPosition += 15;

          if (updatedScrollPosition > 1600) {
            setHide(true)
          }

          if (updatedScrollPosition > 2200) {
            setFinish(true)
          }

          const scaleFactor = 1 + ((updatedScrollPosition / 100) * (updatedScrollPosition / 150));
          const matrixValue = `matrix(${scaleFactor}, 0, 0, ${scaleFactor}, 0, 0)`
          animatedTitle.style.transform = matrixValue

          index++;
          increaseWithDelay();
        }
      }, 10); // Adjust the delay in milliseconds as needed
    }

    if (index < 150) {
      increaseWithDelay();
    }
  }

  return (
    <div className="videoMapScrollContainer">
      <section className={`titleContainer ${hide || props.typeOfResult === "preview" ? "hide" : ""} ${finish || props.typeOfResult === "preview" ? "finish" : ""} ${videoFinish || props.typeOfResult === "preview" ? "ready" : ""}`}>
        <div className="animatedContainer">
          <div className={`containerAnimation ${videoStart ? "show" : ""}`}>
            <h1 className={`animatedTitle ${animate ? "animate" : ""}`}>
              <span className="visuallyHidden">Your&nbsp;report<br />is&nbsp;ready</span>
              <div className="reportReady"></div>
            </h1>
            <span alt="Prodensa Automation" onClick={handleClick} className={`scrollImg ${hideImg || props.typeOfResult === "preview" ? "hide" : ""} scrollText`}>Scroll down to continue</span>
            <img src={scrollDown} alt="Prodensa Automation" onClick={handleClick} className={`scrollImg ${hideImg || props.typeOfResult === "preview" ? "hide" : ""}`} />
          </div>
        </div>
      </section>

      <Map
        finish={finish}
        videoFinish={videoFinish}
        typeOfResult={props.typeOfResult}
        activebutton={props.activebutton}
        flyAnimationInProgress={props.flyAnimationInProgress}
        setFlyAnimationInProgress={props.setFlyAnimationInProgress}
        streetviewInfo={props.streetviewInfo}
        setStreetviewInfo={props.setStreetviewInfo}
        assumptions={props.assumptions}
        allAssumptions={props.allAssumptions}
        representationLetter={props.representationLetter}
        representationLetterActive={props.representationLetterActive}
        musts={props.musts}
        client={props.client}
        setMobilePopupOpen={props.setMobilePopupOpen}
        borderDistances={props.borderDistances}
      />

      <section id="videoContainer" className={`videoContainer ${videoFinish || props.typeOfResult === "preview" ? "hide" : ""}`}>
        <video id="videoElement" className="video" autoPlay muted>
          <source src={videoSource} type="video/mp4" />
        </video>
      </section>
    </div>
  );
}