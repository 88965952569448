import { useCookies } from "react-cookie";
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

function AuthProvider(props) {
    const [user, setUser] = useState(null);
    const [cookies] = useCookies(['token']);

    useEffect(() => {
        if (!cookies.token) {
            setUser(null);
        }
    }, [cookies.token])

    function updateUser(newUser) {
        setUser(newUser);
    }
    
    const value = {
        user,
        updateUser
    }

    return (
        <AuthContext.Provider value={value} {...props} />
    );
}

function useAuth() {
    return useContext(AuthContext);
}

export { AuthProvider, useAuth };