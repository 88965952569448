export class DocumentData {
  constructor(filename?, path?, updatedAt?, createdAt?) {
    this.filename = filename;
    this.path = path;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  public filename: string = "";
  public path: string = "";
  public updatedAt: string = "";
  public createdAt: string = "";
}
