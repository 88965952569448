import {useEffect, useRef, useState} from "react";

function PinButton({inProgressReports, openDraft}) {

    const [pinActive, setPinActive] = useState(false)

    useEffect(() => {
        document.body.addEventListener('click', closePinnedButton);

        return function cleanup() {
            document.body.removeEventListener('click', closePinnedButton);
        }
    }, []);

    const pinButtonClick = useRef(null);

    const closePinnedButton = (e) => {
        if (pinButtonClick.current && pinButtonClick.current.contains(e.target)) {
            e.stopPropagation();
        } else {
            setPinActive(false)
        }
    }

    return (
        <div className="pinButtonContainer">
            <button ref={pinButtonClick} className={`pinButton ${pinActive ? "active" : ""}`}
                    onClick={() => setPinActive(!pinActive)}></button>
            <div className={`pinnedReports ${pinActive ? "show" : ""}`}>
                {inProgressReports.map((inProgressReport, index) => (
                    <button key={index} className="reportPin" onClick={() => openDraft(inProgressReport.draftID)}>
                        {inProgressReport.reportName.charAt(0)}
                        <div className="reportName">{inProgressReport.reportName}</div>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default PinButton
