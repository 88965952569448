import "../../../assets/css/siteSelection/assumption.css";

import Axios from "axios";
import Swal from "sweetalert2";
import { useEffect, useState, useRef } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";

import deleteIcon from "../../../assets/images/delete.svg";
import imgSettings from "../../../assets/images/settings.svg";
import addCircle from "../../../assets/images/add_circle.svg";
import close from "../../../assets/images/close.svg";
import checkGreen from "../../../assets/images/check_green.svg";
import loadingGif from "../../../assets/images/loading.gif";

//Sned number to format with commas for thousands
import { formatCommas } from "../../../utils/formatCommas";
import { max, set } from "date-fns";
import { customAlert } from "../../../utils/customAlert";
// import { HeadcountOptions } from "../../../models/assumption.ts";

const MobileMenuShowing = [
    {
        section: "Building",
    },
    {
        section: "Land",
    },
    {
        section: "Utilities",
    },
    {
        section: "Logistics",
    },
    {
        section: "Enviromental",
    }
]

const MenuShowing = {
    BUILDING: "Building",
    LAND: "Land",
    UTILITIES: "Utilities",
    LOGISTICS: "Logistics",
    // HEADCOUNT: "Headcount",
    ENVIROMENTAL: "Enviromental",
}

const PlacesContainers = {
    OriginPort: Symbol("OriginPort"),
    DestinationPort: Symbol("DestinationPort"),
    BorderCity: Symbol("BorderCity"),
    FinalDestination: Symbol("FinalDestination"),
    None: Symbol("None"),
}

function Assumptions(props) {
    function settingsModal(item, length, itemIndex, itemGroupIndex = null) {

        const handleInput = (value, input, maxValue) => {
            let numericValue
            let numericMaxValue
            let saveValue
            if (item.unit && item.unit !== "Express courier") {
                numericValue = +value.replace(/[^0-9.]/g, '');
                numericMaxValue = +maxValue.replace(/[^0-9.]/g, '');
                saveValue = value === "" ? "" : numericValue < 0 ? 0 : numericValue > numericMaxValue ? numericMaxValue : numericValue
            } else {
                saveValue = value === "" ? "" : +formatCommas(value).numericValue
            }

            if (itemGroupIndex !== null) {
                props.setDraft(prevState => {
                    const updatedDraft = { ...prevState }

                    updatedDraft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups[itemGroupIndex].origins[itemIndex].selectedUnit.measures[input] = saveValue

                    return updatedDraft
                })
            } else {
                props.setDraft(prevState => {
                    const updatedDraft = { ...prevState }

                    updatedDraft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations[itemIndex].selectedUnit.measures[input] = saveValue

                    return updatedDraft
                })
            }
        }

        const selectUnit = (option) => {
            const selectedUnit = units.find(x => x.unit === item.unit)?.options.find(x => x.description === option)
            if (itemGroupIndex !== null) {
                props.setDraft(prevState => {
                    const updatedDraft = { ...prevState }

                    updatedDraft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups[itemGroupIndex].origins[itemIndex].selectedUnit = selectedUnit

                    return updatedDraft
                })
            } else {
                props.setDraft(prevState => {
                    const updatedDraft = { ...prevState }

                    updatedDraft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations[itemIndex].selectedUnit = selectedUnit

                    return updatedDraft
                })
            }
        }

        const units = [
            {
                unit: "Truck",
                // inputs: [
                //     "length",
                //     "width",
                //     "height",
                //     "cubic space"
                // ],
                options: [
                    {
                        description: "28 Ft Trailer",
                        measures: {
                            length: "328 in",
                            width: "99 in",
                            height: "108 in",
                            "cubic space": "2,029.5 ft3"
                        }
                    },
                    {
                        description: "48 Ft Trailer",
                        measures: {
                            length: "568 in",
                            width: "99 in",
                            height: "110 in",
                            "cubic space": "3,579.6 ft3"
                        }
                    },
                    {
                        description: "53 Ft Trailer",
                        measures: {
                            length: "630 in",
                            width: "99 in",
                            height: "111 in",
                            "cubic space": "4,006.4 ft3"
                        }
                    },
                ]
            },
            {
                unit: "Pallet",
                options: [
                    {
                        description: "EUR Pallet",
                        measures: {
                            dimension: "800 x 1,200 mm",
                            "load-bearing capacity": "1,500 kg",
                            "extra load": "6,000 kg",
                            "weight": ""
                        }
                    },
                    {
                        description: "Industrial Pallet",
                        measures: {
                            dimension: "1000 x 1,200 mm",
                            "load-bearing capacity": "1,500 kg",
                            "extra load": "6,000 kg",
                            "weight": ""
                        }
                    },
                    {
                        description: "Asia Pallet",
                        measures: {
                            dimension: "1100 x 1,100 mm",
                            "load-bearing capacity": "1,300 kg",
                            "extra load": "5,200 kg",
                            "weight": ""
                        }
                    },
                ]
            },
            {
                unit: "Container",
                options: [
                    {
                        description: "20' Dry Freight Container",
                        measures: {
                            length: "19' 5''",
                            width: "7' 8 3/8''",
                            height: "7' 9 5/8''",
                            "cubic capacity": "1,164 cu. ft.",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "40' High Cube Container",
                        measures: {
                            length: "39' 3/8''",
                            width: "7' 8 3/8''",
                            height: "8' 8''",
                            "cubic capacity": "2,700 cu. ft.",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "20' Open Top Container",
                        measures: {
                            length: "19' 5''",
                            width: "7' 8 1/8''",
                            height: "7' 9 5/8''",
                            "cubic capacity": "1,126 cu. ft.",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "40' Open Top Container",
                        measures: {
                            length: "39' 6 1/8''",
                            width: "7' 8 3/4''",
                            height: "7' 5 7/16''",
                            "cubic capacity": "2,295 cu. ft.",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "20' Collapsable Flat Rack",
                        measures: {
                            length: "19' 6''",
                            width: "7' 5''",
                            height: "6' 9 3/4''",
                            "cubic capacity": "N/A",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "40' Collapsable Flat Rack",
                        measures: {
                            length: "39' 7''",
                            width: "8' 0''",
                            height: "6' 9 3/4''",
                            "cubic capacity": "N/A",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "20' Reefer Container",
                        measures: {
                            length: "17' 10''",
                            width: "7' 4 1/16''",
                            height: "7' 3 1/2''",
                            "cubic capacity": "950 cu. ft.",
                            // "express courier": false,
                        }
                    },
                    {
                        description: "40' High Cube Reefer Container",
                        measures: {
                            length: "38' 0''",
                            width: "7' 6''",
                            height: "8' 4''",
                            "cubic capacity": "1,942 cu. ft.",
                            // "express courier": false,
                        }
                    },
                ]
            },
            {
                unit: "Express courier",
                options: [
                    {
                        description: "express courier",
                        measures: {
                            length: "",
                            width: "",
                            height: "",
                            weight: ""
                        }
                    },
                ]
            },
        ]

        return (
            <div className="containerSettings" style={{ right: length > 1 ? "0" : "-26px" }} ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                <div className="titleAndType">
                    <h5>{item.unit}</h5>
                </div>
                {item.unit !== "Express courier" &&
                    <Dropdown>
                        <Dropdown.Toggle>
                            {item.selectedUnit && item.selectedUnit.description !== "" ? item.selectedUnit.description : "Select an option"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {item.unit !== "" && units.find(x => x.unit === item.unit)?.options?.map((option, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={(e) => selectUnit(e.target.innerText)}>
                                        {option.description}
                                    </Dropdown.Item>
                                )
                            })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <div className="informativeInputs">
                    {item.unit && item.unit !== "" && Object.keys(units.find(x => x.unit === item.unit)?.options[0]?.measures)?.map(key => {
                        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                        const value = units.find(x => x.unit === item.unit)?.options.find(option => option.description === item.selectedUnit?.description)?.measures[key];
                        const maxValue = units.find(x => x.unit === item.unit)?.options.find(option => option.description === item.selectedUnit?.description)?.measures["extra load"]
                        return (
                            <div key={`${item.unit}-${key}`} className="metrics">
                                <label htmlFor={key}>{capitalizedKey}</label>
                                <input type="text" id={key}
                                    value={item.unit && item.unit === "Express courier" ?
                                        (item?.selectedUnit?.measures?.[key] === "" ? "" : formatCommas(item?.selectedUnit?.measures?.[key]).formattedValue) :
                                        key === "weight" ? (item?.selectedUnit?.measures?.weight === "" ? "" : formatCommas(item?.selectedUnit?.measures?.weight).formattedValue) :
                                            item.selectedUnit?.description !== "" ? value : ""}
                                    disabled={(key !== "weight" && item.unit && item.unit !== "Express courier") ||
                                        ((item.selectedUnit && item.selectedUnit.description === "") && item.unit && item.unit !== "Express courier")}
                                    onChange={(e) => handleInput(e.target.value, key, maxValue)} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }


    const menuSettingsRef = useRef();
    const menuInformationRef = useRef()
    const [menuShowing, setMenuShowing] = useState(MenuShowing.BUILDING)
    const [citySearch, setCitySearch] = useState("")
    const [placesFound, setPlacesFound] = useState([])
    const [showPlacesContainer, setShowPlacesContainer] = useState(PlacesContainers.None)
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [downloading, setDownloading] = useState(false);

    //Search industry variables
    const [searchIndustry, setSearchIndustry] = useState("");
    const [industriesFound, setIndustriesFound] = useState([]);
    const [industryResults, setIndustryResults] = useState([]);
    const [industriesLookupActive, setIndustriesLookupActive] = useState(false);
    // const [headcountsFile, setHeadcountsFile] = useState(null);
    // const [isFileError, setIsFileError] = useState(false);
    // const [fileMessage, setFileMessage] = useState("")

    /* STATES FOR NUMERIC COMMA VALUES */
    const [inputValue, setInputValue] = useState({
        buildingSize: props.draft.siteSelectionReport.assumption.building.sizeValue === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.building.sizeValue).formattedValue,
        landSize: props.draft.siteSelectionReport.assumption.land.sizeValue === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.land.sizeValue).formattedValue,
        electricityInstalled: props.draft.siteSelectionReport.assumption.utilities.electricityInstalled === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.utilities.electricityInstalled).formattedValue,
        electricityConsumed: props.draft.siteSelectionReport.assumption.utilities.electricityConsumed === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.utilities.electricityConsumed).formattedValue,
        gasGj: props.draft.siteSelectionReport.assumption.utilities.gas === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.utilities.gas).formattedValue,
        waterMeters: props.draft.siteSelectionReport.assumption.utilities.water === "" ? "" : formatCommas(props.draft.siteSelectionReport.assumption.utilities.water).formattedValue,
    })

    //Function for selecting representation letter file

    const selectFile = async (e) => {
        const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        const selectedFile = e.target.files[0]

        if (selectFile && allowTypes !== selectedFile?.type) {
            await Swal.fire({
                icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
            return;
        }
        props.setRepresentationLetter(selectedFile)
    }

    //Save file function for representation letter

    const saveFile = async () => {
        const data = new FormData()
        data.append('cities', JSON.stringify(props.draft.siteSelectionReport.must.cities))
        data.append('reportId', props.draft._id)
        data.append('representation', props.representationLetter)
        const updatedDraft = { ...props.draft }

        try {
            const response = await Axios.post(process.env.REACT_APP_UPDATE_REPRESENTATION, data)
            updatedDraft.siteSelectionReport.assumption.representationDocument.filename = response.data.filename
            updatedDraft.siteSelectionReport.assumption.representationDocument.representationFileId = response.data.fileUpdated._id

            const draftRes = await Axios.patch(process.env.REACT_APP_UPDATE_REPORT + props.draft._id, updatedDraft)
            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            representationDocument: {
                                filename: response.data.filename,
                                representationFileId: response.data.fileUpdated._id
                            }
                        }
                    }
                }
            })
            props.setSavedRepresentationLetter(true)
            setDownloading(false)
            setIsDownloaded(true)
        } catch (err) {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
            // if (err.response.status && err.response.status === 400) {
            //     setLoading(false)
            // }
        }
    }

    //Function to look in the industry array for the selected industry

    const closeMenus = (e) => {
        const menusInformation = document.querySelectorAll(".informationContainer");
        const menusSettings = document.querySelectorAll(".containerSettings");

        if (menuInformationRef.current && menuInformationRef.current.contains(e.target)) {
            menusInformation.forEach(element => {
                if (!menuInformationRef.current.nextElementSibling.contains(element)) {
                    element.classList.remove("show");
                }
            });
            menuInformationRef.current.nextElementSibling.classList.toggle("show");
        } else {
            menusInformation.forEach(element => {
            element.classList.remove("show");
            });
        }

        if (menuSettingsRef.current && menuSettingsRef.current.contains(e.target)) {
            menusSettings.forEach(element => {
                if (!menuSettingsRef.current.nextElementSibling.contains(element)) {
                    element.classList.remove("show");
                }
            });
            menuSettingsRef.current.nextElementSibling.classList.toggle("show");
        } else {
            menusSettings.forEach(element => {
            element.classList.remove("show");
        });
        }


    }
    
	const industryLookup = (searchTerm) => {
		setSearchIndustry(searchTerm); // Set the search term to the state
		const matchingIndustries = industriesFound.length > 0 ? industriesFound.filter(item => {
			const normalizedIndustryName = item.nationalIndustryName?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			
			return normalizedIndustryName?.toLowerCase().includes(searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
		}) : []

		if (matchingIndustries.length > 0) {
            let resultsWithoutSelected = props.draft.siteSelectionReport.assumption.selectedIndustries.length > 0 ? props.draft.siteSelectionReport.assumption.selectedIndustries.map(item => {
                return matchingIndustries.filter(industry => industry.nationalIndustryId !== item.nationalIndustryId)
            }).flat() : matchingIndustries
			setIndustryResults(resultsWithoutSelected); // Set a message if no matches found
		} else {
			setIndustryResults([{nationalIndustryName: "No matching industries found"}]); // Set a message if no matches found
		}
	}

    const selectIndustry = (industry) => {
        let findIndustry = props.draft.siteSelectionReport.assumption.selectedIndustries.find(item => item.nationalIndustryId === industry.nationalIndustryId);

        if (findIndustry) {
            let filteredArray = props.draft.siteSelectionReport.assumption.selectedIndustries.filter(item => item.nationalIndustryId !== industry.nationalIndustryId)
            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            selectedIndustries: filteredArray
                        }
                    }
                }
            })
            if (industryResults.length > 0)  {
                setIndustryResults([industry,...industryResults])
                setIndustriesFound([industry,...industriesFound])
            }

        } else {
            let industryObject = {
                nationalIndustryId: industry.nationalIndustryId,
                nationalIndustryName: industry.nationalIndustryName,
                numberOfCompaniesPerIndustry: industry.numberOfCompaniesPerIndustry,
                }
            props.draft.siteSelectionReport.assumption.selectedIndustries.push(industryObject)
            if (industryResults.length > 0) {
                setIndustryResults(industryResults.filter(item => item.nationalIndustryId !== industry.nationalIndustryId))
            }
            setIndustriesFound(industriesFound.filter(item => item.nationalIndustryId !== industry.nationalIndustryId))
        }
        setIndustriesLookupActive(false)
    }

    //Collects all industry data from the database collection of economic activities

    useEffect(() => {
        Axios.get(process.env.REACT_APP_GET_ALL_INDUSTRIES).then((res) => {
            setIndustriesFound(res.data)
        }).catch((err) => {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }, [])


    useEffect(() => {
        const assumptionNavigationContainer = document.getElementById("assumptionNavigationContainer");
        window.addEventListener("scroll", () => {
            if (window.scrollY <= 400)
                assumptionNavigationContainer.style.top = `${400 - window.scrollY}px`;
            else
                assumptionNavigationContainer.style.top = "0";

            if (window.scrollY >= 0 && window.scrollY <= 780)
                setMenuShowing(MenuShowing.BUILDING);
            else if (window.scrollY > 780 && window.scrollY <= 1080)
                setMenuShowing(MenuShowing.LAND);
            else if (window.scrollY > 1080 && window.scrollY <= 1380)
                setMenuShowing(MenuShowing.UTILITIES);
            else if (window.scrollY > 1380 && window.scrollY <= 2280)
                setMenuShowing(MenuShowing.LOGISTICS);
            // else if (window.scrollY > 3000 && window.scrollY <= 3500)
            //     setMenuShowing(MenuShowing.HEADCOUNT);
            else
                setMenuShowing(MenuShowing.ENVIROMENTAL);
        });
    }, [])

    useEffect(() => {
        document.body.addEventListener("click", closeMenus)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenus)
        }
    }, [])

    useEffect(() => {
        props.setFunctionActionButton(() => next);
    }, [props.draft.activeMenu])

    useEffect(() => {
        fetchCoordenates();
    }, [citySearch])

    const fetchCoordenates = async () => {
        const { data } = await Axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${citySearch}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
        );

        setPlacesFound(data.features)
    };


    const handleBuildingCheckbox = (target) => {
        const { value, name, id } = target

        if (id === "otherComments") {
            props.setDraft(prevState => {
                const state = prevState.siteSelectionReport.assumption.building.requirements.otherChecked;

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            building: {
                                ...prevState.siteSelectionReport.assumption.building,
                                requirements: {
                                    ...prevState.siteSelectionReport.assumption.building.requirements,
                                    otherValue: "",
                                    otherChecked: !state,
                                }
                            },
                        },
                    }
                }
            });
            return
        }

        if (name === "other options") {
            props.setDraft(prevState => {

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            building: {
                                ...prevState.siteSelectionReport.assumption.building,
                                requirements: {
                                    ...prevState.siteSelectionReport.assumption.building.requirements,
                                    otherValue: value,
                                }
                            },
                        },
                    }
                }
            });
        } else {
            props.setDraft(prevState => {
                const buildingRequirements = [...prevState.siteSelectionReport.assumption.building.requirements.options];

                const valueExist = buildingRequirements.includes(value);

                const updateBuiReq = valueExist ?
                    buildingRequirements.filter(req => req !== value)
                    : [...buildingRequirements, value]

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            building: {
                                ...prevState.siteSelectionReport.assumption.building,
                                requirements: {
                                    ...prevState.siteSelectionReport.assumption.building.requirements,
                                    options: updateBuiReq,
                                }
                            },
                        },
                    }
                }
            });
        }
    }

    const handleEnvironmentalCheckbox = (target) => {
        const { name, value, id } = target

        if (id === "otherEnvironmental") {
            props.setDraft(prevState => {
                const state = prevState.siteSelectionReport.assumption.environmentalImpacts.otherChecked;

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            environmentalImpacts: {
                                ...prevState.siteSelectionReport.assumption.environmentalImpacts,
                                otherValue: "",
                                otherChecked: !state,
                            },
                        },
                    }
                }
            });
            return
        }

        if (name === "other environmental") {
            props.setDraft(prevState => {

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            environmentalImpacts: {
                                ...prevState.siteSelectionReport.assumption.environmentalImpacts,
                                otherValue: value,
                            },
                        },
                    }
                }
            });
        } else {
            props.setDraft(prevState => {
                const environmentalImpacts = [...prevState.siteSelectionReport.assumption.environmentalImpacts.options];

                const valueExist = environmentalImpacts.includes(value);

                const updateEnvImp = valueExist ? environmentalImpacts.filter(item => item !== value) : [...environmentalImpacts, value]

                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            environmentalImpacts: {
                                ...prevState.siteSelectionReport.assumption.environmentalImpacts,
                                options: updateEnvImp,
                            }
                        },
                    }
                }
            });
        }

    }

    const next = () => {
        props.setActiveMenu(2);
    }

    const handleChangeOrigin = (position, value, criteria) => {
        if (criteria === "Inbound") {
            const inboundGroups = JSON.parse(JSON.stringify(props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups))
            const { indexGroup, ...restOfValue } = value

            inboundGroups[indexGroup].origins[position] = restOfValue

            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            logistics: {
                                ...prevState.siteSelectionReport.assumption.logistics,
                                logisticsInbound: {
                                    ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                    inboundGroups: inboundGroups
                                }
                            }
                        },
                    }
                }
            })
        } else {
            const destinations = JSON.parse(JSON.stringify(props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations))

            destinations[position] = value

            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        assumption: {
                            ...prevState.siteSelectionReport.assumption,
                            logistics: {
                                ...prevState.siteSelectionReport.assumption.logistics,
                                logisticsOutbound: {
                                    ...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
                                    destinations: destinations
                                }
                            }
                        },
                    }
                }
            })
        }
    }


    return (
        <section className="assumptionsSection">
            <Row>
                <Col className="px-0" xs={{ span: 12}}>
                    <div className="mobile-navigation d-lg-none">
                        {MobileMenuShowing.map(item => {
                            return (
                                <div key={item.section} className={`optionContainer ${menuShowing === MenuShowing[item.section.toUpperCase()] ? "active" : ""}`}>
                                    <div className="dot"></div>
                                    <a className="menuTitle" href={`#${item.section.toLowerCase()}Section`}>{item.section}</a>
                                </div>
                            )
                        })
                        }
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col lg={{ span: 6, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                    <div className="assumptionTitle" id="buildingSection">Building</div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Building</span>
                        <Dropdown className={props.draft.siteSelectionReport.assumption.building.buildingContract ? "active" : ""}>
                            <Dropdown.Toggle>
                                {
                                    props.draft.siteSelectionReport.assumption.building.buildingContract ?
                                        props.draft.siteSelectionReport.assumption.building.buildingContract :
                                        "Lease/Purchase"
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                building: {
                                                    ...prevState.siteSelectionReport.assumption.building,
                                                    buildingContract: "Lease",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                    Lease
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                building: {
                                                    ...prevState.siteSelectionReport.assumption.building,
                                                    buildingContract: "Purchase",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                    Purchase</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="inputContainerFifty">
                        <div className="assumptionRadioSizeTypeContainer">
                            <span>Size</span>
                            <button
                                className={`secondaryButton ${props.draft.siteSelectionReport.assumption.building.sizeType !== "sqft" ? "radioDisabled" : ""}`}
                                type="submit"
                                onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                building: {
                                                    ...prevState.siteSelectionReport.assumption.building,
                                                    sizeType: "sqft",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                <span>sqft</span>
                            </button>
                            <button
                                className={`secondaryButton ${props.draft.siteSelectionReport.assumption.building.sizeType !== "m2" ? "radioDisabled" : ""}`}
                                type="submit"
                                onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                building: {
                                                    ...prevState.siteSelectionReport.assumption.building,
                                                    sizeType: "m2",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                <span>m²</span>
                            </button>
                        </div>

                        <input type="text" placeholder="Size"
                            truevalue={props.draft.siteSelectionReport.assumption.building.sizeValue}
                            value={inputValue.buildingSize}
                            onChange={
                                (e) => {
                                    /* Send value of input to format with commas */
                                    const commaFormat = formatCommas(e.target.value)
                                    /* Update state for input value */
                                    setInputValue((prevInputValue) => ({
                                        ...prevInputValue,
                                        buildingSize: e.target.value === "" ? "" : commaFormat.formattedValue,
                                    }));

                                    /* Update state for report */
                                    props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    building: {
                                                        ...prevState.siteSelectionReport.assumption.building,
                                                        sizeValue: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                    }
                                                },
                                            }
                                        }

                                    })
                                }} />
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Requirements/Comments</span>
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="ac" type="checkbox" value="A/C"
                                    checked={props.draft.siteSelectionReport.assumption.building.requirements.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "A/C".toLowerCase())}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                                <label htmlFor="ac">A/C</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="crane" type="checkbox" value="Overhead Crane"
                                    checked={props.draft.siteSelectionReport.assumption.building.requirements.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "Overhead Crane".toLowerCase())}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                                <label htmlFor="crane">Overhead Crane</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="wasteWater" type="checkbox" value="Waste water"
                                    checked={props.draft.siteSelectionReport.assumption.building.requirements.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "Waste water".toLowerCase())}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                                <label htmlFor="wasteWater">Waste water</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="warehouseSpace" type="checkbox" value="Warehouse space"
                                    checked={props.draft.siteSelectionReport.assumption.building.requirements.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "Warehouse space".toLowerCase())}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                                <label htmlFor="warehouseSpace">Warehouse space</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="otherComments" type="checkbox" value="other"
                                    checked={props.draft.siteSelectionReport.assumption.building.requirements.otherChecked}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                                <label htmlFor="otherComments">Other</label>
                                <input
                                    className={props.draft.siteSelectionReport.assumption.building.requirements.otherChecked ? "active" : ""}
                                    type="text" placeholder="Other" name="other options"
                                    value={props.draft.siteSelectionReport.assumption.building.requirements.otherValue}
                                    onChange={(e) => handleBuildingCheckbox(e.target)} />
                            </div>
                        </div>
                    </div>
                    <div className="assumptionTitle" id="landSection">Land</div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Lease/Purchase</span>
                        <Dropdown className={props.draft.siteSelectionReport.assumption.land.landContract ? "active" : ""}>
                            <Dropdown.Toggle>
                                {
                                    props.draft.siteSelectionReport.assumption.land.landContract ?
                                        props.draft.siteSelectionReport.assumption.land.landContract :
                                        "Lease/Purchase"
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                land: {
                                                    ...prevState.siteSelectionReport.assumption.land,
                                                    landContract: "Lease",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                    Lease
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                land: {
                                                    ...prevState.siteSelectionReport.assumption.land,
                                                    landContract: "Purchase",
                                                }
                                            },
                                        }
                                    }
                                })}>Purchase</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="inputContainerFifty">
                        <div className="assumptionRadioSizeTypeContainer">
                            <span>Size</span>
                            <button
                                className={`secondaryButton ${props.draft.siteSelectionReport.assumption.land.sizeType !== "sqft" ? "radioDisabled" : ""}`}
                                type="submit"
                                onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                land: {
                                                    ...prevState.siteSelectionReport.assumption.land,
                                                    sizeType: "sqft",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                <span>sqft</span>
                            </button>
                            <button
                                className={`secondaryButton ${props.draft.siteSelectionReport.assumption.land.sizeType !== "m2" ? "radioDisabled" : ""}`}
                                type="submit"
                                onClick={() => props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            assumption: {
                                                ...prevState.siteSelectionReport.assumption,
                                                land: {
                                                    ...prevState.siteSelectionReport.assumption.land,
                                                    sizeType: "m2",
                                                }
                                            },
                                        }
                                    }
                                })}>
                                <span>m²</span>
                            </button>
                        </div>
                        <input type="text" placeholder="Size"
                            value={inputValue.landSize}
                            onChange={
                                (e) => {
                                    /* Send value of input to format with commas */
                                    const commaFormat = formatCommas(e.target.value)
                                    /* Update state for input value */
                                    setInputValue((prevInputValue) => ({
                                        ...prevInputValue,
                                        landSize: e.target.value === "" ? "" : commaFormat.formattedValue,
                                    }));

                                    /* Update state for report */
                                    props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    land: {
                                                        ...prevState.siteSelectionReport.assumption.land,
                                                        sizeValue: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                    }
                                                },
                                            }
                                        }
                                    })
                                }} />
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Requirements/Comments</span>
                        <textarea type="text" placeholder="Requirements/Comments"
                            value={props.draft.siteSelectionReport.assumption.land.comment}
                            onChange={(e) => props.setDraft(prevState => {
                                return {
                                    ...prevState,
                                    siteSelectionReport: {
                                        ...prevState.siteSelectionReport,
                                        assumption: {
                                            ...prevState.siteSelectionReport.assumption,
                                            land: {
                                                ...prevState.siteSelectionReport.assumption.land,
                                                comment: e.target.value,
                                            }
                                        },
                                    }
                                }
                            })} ></textarea>
                    </div>
                    <div className="assumptionTitle" id="utilitiesSection">Utilities</div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Electricity installed <label>(KVA)</label></span>
                        <div className="d-flex">
                            <input type="text" placeholder="Electricity installed"
                                value={inputValue.electricityInstalled}
                                onChange={
                                    (e) => {
                                        /* Send value of input to format with commas */
                                        const commaFormat = formatCommas(e.target.value)
                                        /* Update state for input value */
                                        setInputValue((prevInputValue) => ({
                                            ...prevInputValue,
                                            electricityInstalled: e.target.value === "" ? "" : commaFormat.formattedValue,
                                        }));

                                        /* Update state for report */
                                        props.setDraft(prevState => {
                                            return {
                                                ...prevState,
                                                siteSelectionReport: {
                                                    ...prevState.siteSelectionReport,
                                                    assumption: {
                                                        ...prevState.siteSelectionReport.assumption,
                                                        utilities: {
                                                            ...prevState.siteSelectionReport.assumption.utilities,
                                                            electricityInstalled: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                        }
                                                    },
                                                }
                                            }
                                        })
                                    }} />
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {props.draft.siteSelectionReport.assumption.utilities.electricityInstalledType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        electricityInstalledType: "monthly",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        monthly
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        electricityInstalledType: "annual",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        annual
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Electricity consumed <label>(KWH)</label></span>
                        <div className="d-flex">
                            <input type="text" placeholder="Electricity consumed"
                                value={inputValue.electricityConsumed}
                                onChange={
                                    (e) => {
                                        /* Send value of input to format with commas */
                                        const commaFormat = formatCommas(e.target.value)
                                        /* Update state for input value */
                                        setInputValue((prevInputValue) => ({
                                            ...prevInputValue,
                                            electricityConsumed: e.target.value === "" ? "" : commaFormat.formattedValue,
                                        }));

                                        /* Update state for report */
                                        props.setDraft(prevState => {
                                            return {
                                                ...prevState,
                                                siteSelectionReport: {
                                                    ...prevState.siteSelectionReport,
                                                    assumption: {
                                                        ...prevState.siteSelectionReport.assumption,
                                                        utilities: {
                                                            ...prevState.siteSelectionReport.assumption.utilities,
                                                            electricityConsumed: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                        }
                                                    },
                                                }
                                            }
                                        })
                                    }} />
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {props.draft.siteSelectionReport.assumption.utilities.electricityConsumedType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        electricityConsumedType: "monthly",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        monthly
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        electricityConsumedType: "annual",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        annual
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Gas <label>(Monthly total gigajoules)</label></span>
                        <div className="d-flex">
                            <input type="text" placeholder="Gas"
                                value={inputValue.gasGj}
                                onChange={
                                    (e) => {
                                        /* Send value of input to format with commas */
                                        const commaFormat = formatCommas(e.target.value)
                                        /* Update state for input value */
                                        setInputValue((prevInputValue) => ({
                                            ...prevInputValue,
                                            gasGj: e.target.value === "" ? "" : commaFormat.formattedValue,
                                        }));

                                        /* Update state for report */
                                        props.setDraft(prevState => {
                                            return {
                                                ...prevState,
                                                siteSelectionReport: {
                                                    ...prevState.siteSelectionReport,
                                                    assumption: {
                                                        ...prevState.siteSelectionReport.assumption,
                                                        utilities: {
                                                            ...prevState.siteSelectionReport.assumption.utilities,
                                                            gas: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                        }
                                                    },
                                                }
                                            }
                                        })
                                    }} />
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {props.draft.siteSelectionReport.assumption.utilities.gasType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        gasType: "monthly",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        monthly
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        gasType: "annual",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        annual
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="inputContainerFifty">
                        <span className="inputTitle">Water <label>(Cubic meters per month)</label></span>
                        <div className="d-flex">
                            <input type="text" placeholder="Water"
                                value={inputValue.waterMeters}
                                onChange={
                                    (e) => {
                                        /* Send value of input to format with commas */
                                        const commaFormat = formatCommas(e.target.value)
                                        /* Update state for input value */
                                        setInputValue((prevInputValue) => ({
                                            ...prevInputValue,
                                            waterMeters: e.target.value === "" ? "" : commaFormat.formattedValue,
                                        }));

                                        /* Update state for report */
                                        props.setDraft(prevState => {
                                            return {
                                                ...prevState,
                                                siteSelectionReport: {
                                                    ...prevState.siteSelectionReport,
                                                    assumption: {
                                                        ...prevState.siteSelectionReport.assumption,
                                                        utilities: {
                                                            ...prevState.siteSelectionReport.assumption.utilities,
                                                            water: e.target.value === "" ? "" : +commaFormat.numericValue,
                                                        }
                                                    },
                                                }
                                            }
                                        })
                                    }} />
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {props.draft.siteSelectionReport.assumption.utilities.waterType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        waterType: "monthly",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        monthly
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        waterType: "annual",
                                                    }
                                                },
                                            }
                                        }
                                    })}>
                                        annual
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Water availability</span>
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="water_availability_yes" type="radio" name="water_availability" value="water_availability_yes"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        waterAvailable: true
                                                    }
                                                },
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.utilities.waterAvailable === true} />
                                <label htmlFor="water_availability_yes">Yes</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="water_availability_no" type="radio" name="water_availability" value="water_availability_no"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    utilities: {
                                                        ...prevState.siteSelectionReport.assumption.utilities,
                                                        waterAvailable: false
                                                    }
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.utilities.waterAvailable === false} />
                                <label htmlFor="water_availability_no">Not specified</label>
                            </div>
                        </div>
                    </div>


                    <div className="assumptionTitle" id="logisticsSection">Logistics</div>
                    <div className="inputContainer">
                        <span className="inputTitle">Other logistics considerations</span>
                        <input type="text" placeholder="Other logistics considerations"
                            value={props.draft.siteSelectionReport.assumption.logistics.otherLogisticConsiderations}
                            onChange={(e) => props.setDraft(prevState => {
                                return {
                                    ...prevState,
                                    siteSelectionReport: {
                                        ...prevState.siteSelectionReport,
                                        assumption: {
                                            ...prevState.siteSelectionReport.assumption,
                                            logistics: {
                                                ...prevState.siteSelectionReport.assumption.logistics,
                                                otherLogisticConsiderations: e.target.value,
                                            }
                                        },
                                    }
                                }
                            })} />
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Handling equipment</span>
                        <input type="text" placeholder="Handling equipement"
                            value={props.draft.siteSelectionReport.assumption.logistics.handlingEquipment}
                            onChange={(e) => props.setDraft(prevState => {
                                return {
                                    ...prevState,
                                    siteSelectionReport: {
                                        ...prevState.siteSelectionReport,
                                        assumption: {
                                            ...prevState.siteSelectionReport.assumption,
                                            logistics: {
                                                ...prevState.siteSelectionReport.assumption.logistics,
                                                handlingEquipment: e.target.value,
                                            }
                                        },
                                    }
                                }
                            })} />
                    </div>

                    <div className="assumptionSubtitle">Logistics inbound</div>
                    {props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map((group, indexGroup) => (
                        <div key={indexGroup} className="groupContainer">
                            <div className="groupNameContainer">
                                <input type="text" className="groupName" placeholder="Group name" value={group.groupName}
                                    onChange={(e) => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        logisticsInbound: {
                                                            ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                            inboundGroups: prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map((group, index) => {
                                                                if (index === indexGroup)
                                                                    group.groupName = e.target.value;

                                                                return group;
                                                            })
                                                        }
                                                    }
                                                },
                                            }
                                        }
                                    })} />
                                {props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.length > 1 &&
                                    <img className="deleteInboundGroup" src={deleteIcon} alt="Prodensa automation"
                                        onClick={() => props.setDraft(prevState => {
                                            const inboundGroups = [...prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups];
                                            inboundGroups.splice(indexGroup, 1);

                                            return {
                                                ...prevState,
                                                siteSelectionReport: {
                                                    ...prevState.siteSelectionReport,
                                                    assumption: {
                                                        ...prevState.siteSelectionReport.assumption,
                                                        logistics: {
                                                            ...prevState.siteSelectionReport.assumption.logistics,
                                                            logisticsInbound: {
                                                                ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                                inboundGroups: inboundGroups
                                                            }
                                                        }
                                                    },
                                                }
                                            }
                                        })}
                                    />}
                            </div>
                            <div className="originsGroupContainer">
                                {group.origins.map((origin, indexOrigin) => (
                                    <div key={indexOrigin} className="destinationContainer">
                                        <div className="inputContainer destinationPoints">
                                            <span className="inputTitle">Origin</span>
                                            <div>
                                                <input
                                                    value={origin.fullName}
                                                    type="text"
                                                    placeholder="City"
                                                    onBlur={() => setShowPlacesContainer("None")}
                                                    onChange={(e) => {
                                                        if (e.target.value !== "")
                                                            setShowPlacesContainer(`OriginPort_${indexGroup}_${indexOrigin}`)
                                                        else
                                                            setShowPlacesContainer("None")

                                                        handleChangeOrigin(indexOrigin, {
                                                            indexGroup,
                                                            fullName: e.target.value,
                                                            city: "",
                                                            state: "",
                                                            country: "",
                                                            latitude: undefined,
                                                            longitude: undefined,
                                                            quantity: "",
                                                            unit: "",
                                                            selectedUnit: { description: "", measures: {} }
                                                        }, "Inbound")

                                                        setCitySearch(e.target.value);
                                                    }}
                                                />
                                                <div className={`placesFoundContainer ${showPlacesContainer === `OriginPort_${indexGroup}_${indexOrigin}` ? "show" : ""}`}>
                                                    {placesFound.map((place, index) => {
                                                        return (
                                                            <span key={`place_${index}`} className="placeFound"
                                                                onClick={async() => {
                                                                    const state = place.context?.find(x => x.id.includes("region"))?.text;
                                                                    const country = place.context?.find(x => x.id.includes("country"))?.text;

                                                                    const exist = group.origins.some(origin => origin.fullName === place.place_name);


                                                                    if (exist) {
                                                                        await Swal.fire({
                                                                            icon: 'error',
                                                                            title: 'This origin already exist in this group',
                                                                            showConfirmButton: false,
                                                                            showCancelButton: false,
                                                                            timer: 2000,
                                                                            timerProgressBar: true,
                                                                        })
                                                                        
                                                                        props.setDraft(prevState => {
                                                                            const inboundGroups = [...prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups];
                                                                            inboundGroups[indexGroup].origins.splice(indexOrigin, 1);

                                                                            return {
                                                                                ...prevState,
                                                                                siteSelectionReport: {
                                                                                    ...prevState.siteSelectionReport,
                                                                                    assumption: {
                                                                                        ...prevState.siteSelectionReport.assumption,
                                                                                        logistics: {
                                                                                            ...prevState.siteSelectionReport.assumption.logistics,
                                                                                            logisticsInbound: {
                                                                                                ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                                                                inboundGroups: inboundGroups
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                }
                                                                            }
                                                                        })
                                                                        return;
                                                                    }
                                                                    
                                                                    handleChangeOrigin(indexOrigin, {
                                                                        indexGroup,
                                                                        fullName: place.place_name,
                                                                        city: place.text,
                                                                        state: state ?? "",
                                                                        country: country ?? "",
                                                                        latitude: place.geometry.coordinates[1],
                                                                        longitude: place.geometry.coordinates[0],
                                                                        unit: origin.unit,
                                                                        selectedUnit: origin.selectedUnit,
                                                                        quantity: origin.quantity,
                                                                    }, "Inbound")

                                                                    setShowPlacesContainer("None");
                                                                }}>
                                                                {place.place_name}
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inputContainer qty">
                                            <span className="inputTitle">Qty.</span>
                                            <input type="number" placeholder="Qty."
                                                value={origin.quantity}
                                                onChange={(e) => handleChangeOrigin(indexOrigin, {
                                                    indexGroup,
                                                    fullName: origin.fullName,
                                                    city: origin.city,
                                                    state: origin.state,
                                                    country: origin.country,
                                                    latitude: origin.latitude,
                                                    longitude: origin.longitude,
                                                    quantity: e.target.value === "" ? "" : +e.target.value > 0 ? (Math.ceil(+e.target.value)).toString() : "0",
                                                    unit: origin.unit,
                                                    selectedUnit: origin.selectedUnit
                                                }, "Inbound")}
                                            />
                                        </div>
                                        <div className="inputContainer unit">
                                            <span className="inputTitle">Unit (per month)</span>
                                            <Dropdown className={origin.unit ? "active" : ""}>
                                                <Dropdown.Toggle>
                                                    {origin.unit ? origin.unit : "Select an option"}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={(e) => handleChangeOrigin(indexOrigin, {
                                                        indexGroup,
                                                        fullName: origin.fullName,
                                                        city: origin.city,
                                                        state: origin.state,
                                                        country: origin.country,
                                                        latitude: origin.latitude,
                                                        longitude: origin.longitude,
                                                        quantity: origin.quantity,
                                                        unit: e.target.innerText,
                                                        selectedUnit: { description: "", measures: {} }
                                                    }, "Inbound")}>
                                                        Truck
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => handleChangeOrigin(indexOrigin, {
                                                        indexGroup,
                                                        fullName: origin.fullName,
                                                        city: origin.city,
                                                        state: origin.state,
                                                        country: origin.country,
                                                        latitude: origin.latitude,
                                                        longitude: origin.longitude,
                                                        quantity: origin.quantity,
                                                        unit: e.target.innerText,
                                                        selectedUnit: { description: "", measures: {} }
                                                    }, "Inbound")}>
                                                        Container
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => handleChangeOrigin(indexOrigin, {
                                                        indexGroup,
                                                        fullName: origin.fullName,
                                                        city: origin.city,
                                                        state: origin.state,
                                                        country: origin.country,
                                                        latitude: origin.latitude,
                                                        longitude: origin.longitude,
                                                        quantity: origin.quantity,
                                                        unit: e.target.innerText,
                                                        selectedUnit: {
                                                            description: "", measures: {
                                                                weight: ""
                                                            }
                                                        }
                                                    }, "Inbound")}>
                                                        Pallet
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => handleChangeOrigin(indexOrigin, {
                                                        indexGroup,
                                                        fullName: origin.fullName,
                                                        city: origin.city,
                                                        state: origin.state,
                                                        country: origin.country,
                                                        latitude: origin.latitude,
                                                        longitude: origin.longitude,
                                                        quantity: origin.quantity,
                                                        unit: e.target.innerText,
                                                        selectedUnit: {
                                                            description: "express courier", measures: {
                                                                length: "",
                                                                width: "",
                                                                height: "",
                                                                weight: "",
                                                            }
                                                        }
                                                    }, "Inbound")}>
                                                        Express courier
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        {origin.unit && <img className="deleteOrigin" ref={menuSettingsRef} src={imgSettings} alt="Prodensa automation" onClick={(e) => menuSettingsRef.current = e.target} />}
                                        {settingsModal(origin, group.origins.length, indexOrigin, indexGroup)}
                                        {group.origins.length > 1 &&
                                            <img className="deleteOrigin" src={deleteIcon} alt="Prodensa automation"
                                                onClick={() => props.setDraft(prevState => {
                                                    const inboundGroups = [...prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups];
                                                    inboundGroups[indexGroup].origins.splice(indexOrigin, 1);

                                                    return {
                                                        ...prevState,
                                                        siteSelectionReport: {
                                                            ...prevState.siteSelectionReport,
                                                            assumption: {
                                                                ...prevState.siteSelectionReport.assumption,
                                                                logistics: {
                                                                    ...prevState.siteSelectionReport.assumption.logistics,
                                                                    logisticsInbound: {
                                                                        ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                                        inboundGroups: inboundGroups
                                                                    }
                                                                }
                                                            },
                                                        }
                                                    }
                                                })}
                                            />}
                                    </div>
                                ))}
                                <button className="secondaryButton addOrigin" type="submit"
                                    onClick={() => props.setDraft(prevState => {
                                        const inboundGroups = [...prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups];
                                        inboundGroups[indexGroup].origins.push({
                                            fullName: "",
                                            city: "",
                                            state: "",
                                            country: "",
                                            latitude: undefined,
                                            longitude: undefined,
                                            quantity: "",
                                            unit: "",
                                            selectedUnit: { description: "" }
                                        })

                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        logisticsInbound: {
                                                            ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                            inboundGroups: inboundGroups
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    })}>
                                    <img src={addCircle} alt="Prodensa automation" /> Add origin
                                </button>
                            </div>
                            <div className="originGroupSeparator"></div>
                        </div>
                    ))}
                    <button className="secondaryButton addOrigin" type="submit"
                        onClick={() => props.setDraft(prevState => {
                            return {
                                ...prevState,
                                siteSelectionReport: {
                                    ...prevState.siteSelectionReport,
                                    assumption: {
                                        ...prevState.siteSelectionReport.assumption,
                                        logistics: {
                                            ...prevState.siteSelectionReport.assumption.logistics,
                                            logisticsInbound: {
                                                ...prevState.siteSelectionReport.assumption.logistics.logisticsInbound,
                                                inboundGroups: [...prevState.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups, {
                                                    groupName: "",
                                                    origins: [{
                                                        fullName: "",
                                                        city: "",
                                                        state: "",
                                                        country: "",
                                                        latitude: undefined,
                                                        longitude: undefined,
                                                        quantity: "",
                                                        unit: "",
                                                        selectedUnit: {
                                                            description: "", measures: {
                                                                length: "",
                                                                width: "",
                                                                height: "",
                                                                weight: "",
                                                            }
                                                        }
                                                    }]
                                                }]
                                            }
                                        }
                                    }
                                }
                            }
                        })}>
                        <img src={addCircle} alt="Prodensa automation" /> Add inbound group
                    </button>

                    <div className="assumptionSubtitle">Logistics outbound</div>
                    {props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map((destiny, indexDestiny) => (
                        <div key={indexDestiny} className="destinationContainer">
                            <div className="inputContainer destinationPoints">
                                <span className="inputTitle">Destination</span>
                                <div>
                                    <input
                                        value={destiny.fullName}
                                        type="text"
                                        placeholder="City"
                                        onBlur={() => setShowPlacesContainer("None")}
                                        onChange={(e) => {
                                            if (e.target.value !== "")
                                                setShowPlacesContainer(`DestinationPort_${indexDestiny}`)
                                            else
                                                setShowPlacesContainer("None")

                                            handleChangeOrigin(indexDestiny, {
                                                fullName: e.target.value,
                                                city: "",
                                                state: "",
                                                country: "",
                                                latitude: undefined,
                                                longitude: undefined,
                                                quantity: "",
                                                unit: "",
                                                selectedUnit: { description: "", measures: {} }
                                            }, "Outbound")

                                            setCitySearch(e.target.value);
                                        }}
                                    />
                                    <div className={`placesFoundContainer ${showPlacesContainer === `DestinationPort_${indexDestiny}` ? "show" : ""}`}>
                                        {placesFound.map((place, index) => {
                                            return (
                                                <span key={`place_${index}`} className="placeFound"
                                                    onClick={async() => {
                                                        const state = place.context?.find(x => x.id.includes("region"))?.text;
                                                        const country = place.context?.find(x => x.id.includes("country"))?.text;

                                                        // check if the destiny already exist
                                                        const exist = props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.some(destiny => destiny.fullName === place.place_name);

                                                        if (exist) {
                                                            await Swal.fire({
                                                                icon: 'error',
                                                                title: 'This destination already exist',
                                                                showConfirmButton: false,
                                                                showCancelButton: false,
                                                                timer: 2000,
                                                                timerProgressBar: true,
                                                            })

                                                            props.setDraft(prevState => {
                                                                const destinations = [...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations];
                                                                destinations.splice(indexDestiny, 1);
                                                                
                                                                return {
                                                                    ...prevState,
                                                                    siteSelectionReport: {
                                                                        ...prevState.siteSelectionReport,
                                                                        assumption: {
                                                                            ...prevState.siteSelectionReport.assumption,
                                                                            logistics: {
                                                                                ...prevState.siteSelectionReport.assumption.logistics,
                                                                                logisticsOutbound: {
                                                                                    ...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
                                                                                    destinations: destinations
                                                                                }
                                                                            }
                                                                        },
                                                                    }
                                                                }
                                                            })

                                                            return;
                                                        }


                                                        // add the destiny
                                                        handleChangeOrigin(indexDestiny, {
                                                            fullName: place.place_name,
                                                            city: place.text,
                                                            state: state ?? "",
                                                            country: country ?? "",
                                                            latitude: place.geometry.coordinates[1],
                                                            longitude: place.geometry.coordinates[0],
                                                            unit: destiny.unit,
                                                            selectedUnit: destiny.selectedUnit,
                                                            quantity: destiny.quantity,
                                                        }, "Outbound")

                                                        setShowPlacesContainer("None");
                                                    }}>
                                                    {place.place_name}
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="inputContainer qty">
                                <span className="inputTitle">Qty.</span>
                                <input type="number" placeholder="Qty."
                                    value={destiny.quantity}
                                    onChange={(e) => handleChangeOrigin(indexDestiny, {
                                        fullName: destiny.fullName,
                                        city: destiny.city,
                                        state: destiny.state,
                                        country: destiny.country,
                                        latitude: destiny.latitude,
                                        longitude: destiny.longitude,
                                        quantity: e.target.value === "" ? "" : +e.target.value > 0 ? (Math.ceil(+e.target.value)).toString() : "0",
                                        unit: destiny.unit,
                                        selectedUnit: destiny.selectedUnit,
                                    }, "Outbound")}
                                />
                            </div>
                            <div className="inputContainer unit">
                                <span className="inputTitle">Unit (per month)</span>
                                <Dropdown className={destiny.unit ? "active" : ""}>
                                    <Dropdown.Toggle>
                                        {destiny.unit ? destiny.unit : "Select an option"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={(e) => handleChangeOrigin(indexDestiny, {
                                            fullName: destiny.fullName,
                                            city: destiny.city,
                                            state: destiny.state,
                                            country: destiny.country,
                                            latitude: destiny.latitude,
                                            longitude: destiny.longitude,
                                            quantity: destiny.quantity,
                                            unit: e.target.innerText,
                                            selectedUnit: { description: "", measures: {} }
                                        }, "Outbound")}>
                                            Truck
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => handleChangeOrigin(indexDestiny, {
                                            fullName: destiny.fullName,
                                            city: destiny.city,
                                            state: destiny.state,
                                            country: destiny.country,
                                            latitude: destiny.latitude,
                                            longitude: destiny.longitude,
                                            quantity: destiny.quantity,
                                            unit: e.target.innerText,
                                            selectedUnit: { description: "", measures: {} }
                                        }, "Outbound")}>
                                            Container
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => handleChangeOrigin(indexDestiny, {
                                            fullName: destiny.fullName,
                                            city: destiny.city,
                                            state: destiny.state,
                                            country: destiny.country,
                                            latitude: destiny.latitude,
                                            longitude: destiny.longitude,
                                            quantity: destiny.quantity,
                                            unit: e.target.innerText,
                                            selectedUnit: {
                                                description: "", measures: {
                                                    weight: ""
                                                }
                                            }
                                        }, "Outbound")}>
                                            Pallet
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => handleChangeOrigin(indexDestiny, {
                                            fullName: destiny.fullName,
                                            city: destiny.city,
                                            state: destiny.state,
                                            country: destiny.country,
                                            latitude: destiny.latitude,
                                            longitude: destiny.longitude,
                                            quantity: destiny.quantity,
                                            unit: e.target.innerText,
                                            selectedUnit: {
                                                description: "express courier", measures: {
                                                    length: "",
                                                    width: "",
                                                    height: "",
                                                    weight: "",
                                                }
                                            }
                                        }, "Outbound")}>
                                            Express courier
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {destiny.unit && <img className="deleteOrigin" ref={menuSettingsRef} src={imgSettings} alt="Prodensa automation" onClick={(e) => menuSettingsRef.current = e.target} />}
                            {settingsModal(destiny, props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.length, indexDestiny)}
                            {props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.length > 1 &&
                                <img className="deleteOrigin" src={deleteIcon} alt="Prodensa automation"
                                    onClick={() => props.setDraft(prevState => {
                                        const destinations = [...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations];
                                        destinations.splice(indexDestiny, 1);

                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        logisticsOutbound: {
                                                            ...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
                                                            destinations: destinations
                                                        }
                                                    }
                                                },
                                            }
                                        }
                                    })}
                                />}
                        </div>
                    ))}
                    <button className="secondaryButton addOrigin" type="submit"
                        onClick={() => props.setDraft(prevState => {
                            return {
                                ...prevState,
                                siteSelectionReport: {
                                    ...prevState.siteSelectionReport,
                                    assumption: {
                                        ...prevState.siteSelectionReport.assumption,
                                        logistics: {
                                            ...prevState.siteSelectionReport.assumption.logistics,
                                            logisticsOutbound: {
                                                ...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound,
                                                destinations: [...prevState.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations, {
                                                    fullName: "",
                                                    city: "",
                                                    state: "",
                                                    country: "",
                                                    latitude: undefined,
                                                    longitude: undefined,
                                                    quantity: "",
                                                    unit: "",
                                                    selectedUnit: { description: "" }
                                                }]
                                            }
                                        }
                                    },
                                }
                            }
                        })}>
                        <img src={addCircle} alt="Prodensa automation" /> Add destination
                    </button>
                    <div className="inputContainer" style={{ marginTop: '30px' }}>
                        <span className="inputTitle">Do you require 3/4PL Logistics?</span>
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="yes_3logistics" type="radio" name="3plLogistics" value="yes_3logistics"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        plLogistics: true
                                                    }
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.logistics.plLogistics === true} />
                                <label htmlFor="yes_3logistics">Yes</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="no_3logistics" type="radio" name="3plLogistics" value="no_3logistics"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        plLogistics: false
                                                    }
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.logistics.plLogistics === false} />
                                <label htmlFor="no_3logistics">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Would you require a 3/4PL warehouse solution?</span>
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="yes_logisticsWarehouse" type="radio" name="logisticsWarehouse" value="yes_logisticsWarehouse"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        logisticsWarehouse: true
                                                    }
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.logistics.logisticsWarehouse === true} />
                                <label htmlFor="yes_logisticsWarehouse">Yes</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="no_logisticsWarehouse" type="radio" name="logisticsWarehouse" value="no_logisticsWarehouse"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    logistics: {
                                                        ...prevState.siteSelectionReport.assumption.logistics,
                                                        logisticsWarehouse: false
                                                    }
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.logistics.logisticsWarehouse === false} />
                                <label htmlFor="no_logisticsWarehouse">No</label>
                            </div>
                        </div>
                    </div>

                    <div className="assumptionTitle" id="environmentalSection">Environmental impact and special
                        certifications
                    </div>
                    <div className="inputContainer">
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="iso9001" type="checkbox" value="ISO-9001"
                                    checked={props.draft.siteSelectionReport.assumption.environmentalImpacts.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "ISO-9001".toLowerCase())}
                                    onChange={(e) => handleEnvironmentalCheckbox(e.target)} />
                                <label htmlFor="iso9001">ISO-9001</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="iso140012015" type="checkbox" value="ISO 14001-2015"
                                    checked={props.draft.siteSelectionReport.assumption.environmentalImpacts.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "ISO 14001-2015".toLowerCase())}
                                    onChange={(e) =>
                                        handleEnvironmentalCheckbox(e.target)} />
                                <label htmlFor="iso140012015">ISO 14001-2015</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="iatf" type="checkbox" value="IATF"
                                    checked={props.draft.siteSelectionReport.assumption.environmentalImpacts.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "IATF".toLowerCase())}
                                    onChange={(e) =>
                                        handleEnvironmentalCheckbox(e.target)} />
                                <label htmlFor="iatf">IATF</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="ctpatoea" type="checkbox" value="CTPAT-OEA"
                                    checked={props.draft.siteSelectionReport.assumption.environmentalImpacts.options.some(item =>
                                        typeof item === 'string' && item.toLowerCase() === "CTPAT-OEA".toLowerCase())}
                                    onChange={(e) =>
                                        handleEnvironmentalCheckbox(e.target)} />
                                <label htmlFor="ctpatoea">CTPAT-OEA</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="otherEnvironmental" type="checkbox"
                                    checked={props.draft.siteSelectionReport.assumption.environmentalImpacts.otherChecked}
                                    onChange={(e) => handleEnvironmentalCheckbox(e.target)} />
                                <label htmlFor="otherEnvironmental">Other</label>
                                <input
                                    className={props.draft.siteSelectionReport.assumption.environmentalImpacts.otherChecked ? "active" : ""}
                                    type="text" placeholder="Other" name="other environmental"
                                    value={props.draft.siteSelectionReport.assumption.environmentalImpacts.otherValue}
                                    onChange={(e) => handleEnvironmentalCheckbox(e.target)} />
                            </div>
                        </div>
                    </div>
                    <div className="inputContainer">
                        <span className="inputTitle">Additional comments</span>
                        <textarea placeholder="Additional comments" rows={5}
                            value={props.draft.siteSelectionReport.assumption.additionalComments}
                            onChange={(e) => props.setDraft(prevState => {
                                return {
                                    ...prevState,
                                    siteSelectionReport: {
                                        ...prevState.siteSelectionReport,
                                        assumption: {
                                            ...prevState.siteSelectionReport.assumption,
                                            additionalComments: e.target.value,
                                        },
                                    }
                                }
                            })}>
                            {props.draft.siteSelectionReport.assumption.additionalComments}
                        </textarea>
                    </div>
                    <div className="inputContainer file">
                        <span className="inputTitle">Representation Letter</span>
                        <div className="assumptionRequirementsCommentsContainer">
                            <div className="assumptionCheckboxContainer">
                                <input id="yes" type="radio" name="representation" value="yes"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    representationLetter: true
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.representationLetter === true} />
                                <label htmlFor="yes">Yes</label>
                            </div>
                            <div className="assumptionCheckboxContainer">
                                <input id="no" type="radio" name="representation" value="no"
                                    onChange={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    representationLetter: false
                                                }
                                            }
                                        }
                                    })} checked={props.draft.siteSelectionReport.assumption.representationLetter === false} />
                                <label htmlFor="no">No</label>
                            </div>
                        </div>
                        {/*Code to show the file input field if Yes is selected in representation letter */}
                        {props.draft.siteSelectionReport.assumption.representationLetter && (
                            props.representationLetter.filename !== "" && props.savedRepresentationLetter ? (
                                <div className="representationFile">
                                    <div className="inputFileContainer">
                                        <div className="selectFileContainer">
                                            <div className="savingDraftContainer my-auto ms-2">
                                                {isDownloaded &&
                                                    <img className="loading show"
                                                        src={checkGreen}
                                                        alt="ProdensaAutomation" />
                                                    || !isDownloaded &&
                                                    <img className={`loading ${downloading ? "show" : ""}`}
                                                        src={loadingGif}
                                                        alt="ProdensaAutomation" />
                                                }
                                            </div>
                                            <button className="secondaryButton" onClick={() => console.log(props.draft.siteSelectionReport.assumption.representationDocument)}><span>View attached file</span></button>
                                            <span>{props.draft.siteSelectionReport.assumption.representationDocument.filename}</span>
                                        </div>
                                        <img src={close} alt="Prodensa automation" onClick={() => {
                                            props.setRepresentationLetter({
                                                ...props.representationLetter,
                                                filename: ""
                                            })
                                            props.setDraft(prevState => {
                                                return {
                                                    ...prevState,
                                                    siteSelectionReport: {
                                                        ...prevState.siteSelectionReport,
                                                        assumption: {
                                                            ...prevState.siteSelectionReport.assumption,
                                                            representationDocument: {
                                                                ...prevState.siteSelectionReport.assumption.representationDocument,
                                                                filename: ""
                                                            }
                                                        }
                                                    }
                                                }
                                            })
                                            props.setSavedRepresentationLetter(false)
                                        }} />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="representationFile">
                                        <input
                                            type="file"
                                            accept=".xlsx"
                                            name="representationLetter"
                                            placeholder="representationLetter"
                                            onChange={(e) => selectFile(e)} />
                                    </div>
                                    <div className="actions payrollActions">
                                        <button className="btnBgBlueTextGradient" onClick={() => window.open(process.env.REACT_APP_DOWNLOAD_REPRESENTATION_TEMPLATE, "_blank")}>Download Template</button>
                                        <button className="btnBgBlueTextGradient" disabled={!props.representationLetter.name} onClick={() => saveFile()}>Save</button>
                                    </div>
                                </>
                            ))}
                    </div>

                    {/*Filter for selection of industry */}
                    <div className="inputContainer industrySearch">
                        <span className="inputTitle">Select industry categories</span>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <h5 className="textContainer">
                                {props.draft.siteSelectionReport.assumption?.selectedIndustries.length > 0 ? `${props.draft.siteSelectionReport.assumption.selectedIndustries.map(industry => industry.nationalIndustryName).join(", ")}` : "Select an option"}
                                </h5> 
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <div className={`lookupWrapper ${industriesLookupActive ? "active" : ""}`}>
                            <input
                                value={searchIndustry}
                                type="text"
                                className=""
                                placeholder="Type to search..."
                                onChange={(e) => industryLookup(e.target.value)}
                            />
                            <div className="industriesFoundContainer show">
                            {props.draft.siteSelectionReport.assumption.selectedIndustries.length > 0 && props.draft.siteSelectionReport.assumption.selectedIndustries.map((industry, index) => {
                                    return (
                                        <div className="option-row" key={`option-${index}`}>
                                            <input
                                                id={industry.nationalIndustryId}
                                                type="checkbox"
                                                key={`industry_${index}`}
                                                value={industry.nationalIndustryId}
                                                className=""
                                                onChange={() => selectIndustry(industry)}
                                                checked={true}
                                            />
                                            <label htmlFor={industry.nationalIndustryId} style={{cursor: "pointer"}}>{industry.nationalIndustryName}</label>
                                        </div>
                                    )
                                })}
                                {searchIndustry === "" ? (industriesFound.length > 0 && industriesFound.map((industry, index) => {
                                    return (
                                        <div className="option-row" key={`option-industry1-${index}`}>
                                            <input
                                                id={industry.nationalIndustryId}
                                                type="checkbox"
                                                key={`industry_${index}`}
                                                value={industry.nationalIndustryId}
                                                className=""
                                                onChange={() => selectIndustry(industry)}
                                                checked={false}
                                            />
                                            <label htmlFor={industry.nationalIndustryId} style={{cursor: "pointer"}}>{industry.nationalIndustryName}</label>
                                        </div>
                                    )
                                })):(
                                    industryResults.length > 0 && industryResults.map((industry, index) => {
                                        if(industry.nationalIndustryName === "No matching industries found"){
                                            return (
                                                <div className="option-row" key={`option-industry2-${index}`}>
                                                    <label>{industry.nationalIndustryName}</label>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div className="option-row">
                                                <input
                                                    id={industry.nationalIndustryId}
                                                    type="checkbox"
                                                    key={`industry_${index}`}
                                                    value={industry.nationalIndustryId}
                                                    className=""
                                                    onChange={() => selectIndustry(industriesFound.find(x => x.nationalIndustryName === industry.nationalIndustryName))}
                                                    checked={false}
                                                />
                                                <label htmlFor={industry.nationalIndustryId} style={{cursor: "pointer"}}>{industry.nationalIndustryName}</label>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        
                    </div>

                    <div className="inputContainer">
                        <span className="inputTitle">Exchange rate</span>
                        <input type="number" placeholder="Exchange rate"
                            value={props.draft.siteSelectionReport.assumption.exchangeRate}
                            onChange={(e) => props.setDraft(prevState => {
                                return {
                                    ...prevState,
                                    siteSelectionReport: {
                                        ...prevState.siteSelectionReport,
                                        assumption: {
                                            ...prevState.siteSelectionReport.assumption,
                                            exchangeRate: e.target.value === "" ? "" : e.target.value > 0 ? e.target.value : 0,
                                        },
                                    }
                                }
                            })} />
                        {/* <div className="d-flex"> */}
                        {/* <Dropdown>
                                <Dropdown.Toggle>
                                    {props.draft.siteSelectionReport.assumption.exchangeRateType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    exchangeRateType: "USD",
                                                },
                                            }
                                        }
                                    })}>
                                        USD
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.setDraft(prevState => {
                                        return {
                                            ...prevState,
                                            siteSelectionReport: {
                                                ...prevState.siteSelectionReport,
                                                assumption: {
                                                    ...prevState.siteSelectionReport.assumption,
                                                    exchangeRateType: "MXN",
                                                },
                                            }
                                        }
                                    })}>
                                        MXN
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        {/* </div> */}
                    </div>
                </Col>
                <Col className="d-none d-lg-block" lg={{ span: 4, offset: 1 }}>
                    <div id="assumptionNavigationContainer"
                        className="assumptionNavigationContainer">
                        <div className={`menuContainer ${menuShowing === MenuShowing.BUILDING ? "active" : ""}`}>
                            <div className="dot"></div>
                            <a className="menuTitle" href="#buildingSection">Building</a>
                        </div>
                        <div className="miniDot"></div>
                        <div className="miniDot"></div>
                        <div className={`menuContainer ${menuShowing === MenuShowing.LAND ? "active" : ""}`}>
                            <div className="dot"></div>
                            <a className="menuTitle" href="#landSection">Land</a>
                        </div>
                        <div className="miniDot"></div>
                        <div className="miniDot"></div>
                        <div className={`menuContainer ${menuShowing === MenuShowing.UTILITIES ? "active" : ""}`}>
                            <div className="dot"></div>
                            <a className="menuTitle" href="#utilitiesSection">Utilities</a>
                        </div>
                        <div className="miniDot"></div>
                        <div className="miniDot"></div>
                        <div className={`menuContainer ${menuShowing === MenuShowing.LOGISTICS ? "active" : ""}`}>
                            <div className="dot"></div>
                            <a className="menuTitle" href="#logisticsSection">Logistics</a>
                        </div>
                        <div className="miniDot"></div>
                        <div className="miniDot"></div>
                        <div
                            className={`menuContainer ${menuShowing === MenuShowing.ENVIROMENTAL ? "active" : ""}`}>
                            <div className="dot"></div>
                            <a className="menuTitle" href="#environmentalSection">
                                Environmental impact and special certifications
                            </a>
                        </div>
                    </div>

                </Col>
            </Row >
        </section >
    )
}

export default Assumptions;
