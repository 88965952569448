import "../../../assets/css/siteSelection/wants.css"

import Axios from "axios"
import Swal from "sweetalert2"
import { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownButton, DropdownToggle, Modal, DropdownMenu } from "react-bootstrap";
import { formatCommas } from "../../../utils/formatCommas";

import imgOperatingCost from "../../../assets/images/operatingCost.svg"
import imgCompetitionLabor from "../../../assets/images/competitionLabor.svg"
import imgEnviromentLabor from "../../../assets/images/enviromentLabor.svg"
import imgLogistics from "../../../assets/images/logistics.svg"
import imgInfrastructure from "../../../assets/images/infrastructure.svg"
import imgGovermentIncetives from "../../../assets/images/govermentIncentives.svg"
import imgSecurity from "../../../assets/images/security.svg"
import imgQualityLife from "../../../assets/images/qualityLife.svg"

import close from "../../../assets/images/close.svg"
import attachment from "../../../assets/images/attachment.svg"
import imgDelete from "../../../assets/images/delete.svg"
import infoIcon from "../../../assets/images/infoBlue.svg"
import imgMetric from "../../../assets/images/metricIcon.svg"
import imgSource from "../../../assets/images/sourceIcon.svg"
import imgSettings from "../../../assets/images/settings.svg"
import addCircle from "../../../assets/images/add_circle.svg"
import infoIconDanger from "../../../assets/images/infoDanger.svg"
import imgDefinition from "../../../assets/images/definitionIcon.svg"
import { customAlert } from "../../../utils/customAlert";
import WantsMobile from "./wantsMobile";


//  JSX FOR THE CRITERIA MODAL FOR EVERY VARIABLE
function PayrollFileModel(props) {
    const filePayrollRef = useRef(null);

    return (
        <Modal
            className="wantsCriteriaModal payrollModal"
            size="xl"
            centered
            show={props.showPayrollModal}
            onHide={props.onHide}
        >
            <Modal.Body>
                <section className="payrollColumn">
                    <div className="columnArea">
                        <span className="columnNumber">1</span>
                    </div>
                    <div className="columnArea">
                        <h5>Download template</h5>
                        <p>Download the Excel template provided below.</p>
                    </div>
                    <div className="columnArea">
                        <button className="applyChanges" id="download" onClick={() => window.open(process.env.REACT_APP_DOWNLOAD_PAYROLL_TEMPLATE, "_blank")}>Download template</button>
                    </div>
                </section>
                <section className="payrollColumn">
                    <div className="columnArea">
                        <span className="columnNumber">2</span>
                    </div>
                    <div className="columnArea">
                        <h5>Configurate template</h5>
                        <p>Make sure that the number of tabs in your Excel is equivalent to the number of cities selected in the "must" step, also make sure that the tab names are exactly the name of the selected city (without the state).</p>
                    </div>
                </section>
                <section className="payrollColumn">
                    <div className="columnArea">
                        <span className="columnNumber">3</span>
                    </div>
                    <div className="columnArea">
                        <h5>Upload your file</h5>
                        <p>Select the file you want to upload</p>
                    </div>
                    <div className="columnArea">
                        <input type="file" accept=".xlsx" ref={filePayrollRef} style={{display: "none"}} name="payroll" placeholder="payroll" 
                        onChange={(e) => props.selectFile(e)} />
                        <button className="applyChanges" id="upload" onClick={() => filePayrollRef.current && filePayrollRef.current.click()}>Upload file</button>
                        <div className="filenameContainer">
                            <img src={attachment} alt="prodensa automation" />
                            <span className={`filename ${(props.payrollFile.filename !== "" && props.savePayroll) || 
                            props.payrollFile.name ? "" : "hide"}`}>
                                {props.draft.siteSelectionReport.want.TPCDocument.filename !== "" ? props.draft.siteSelectionReport.want.TPCDocument.filename : 
                                props.payrollFile.name}
                            </span>
                            <img className={`deleteFile ${(props.payrollFile.filename !== "" && props.savePayroll) || 
                            props.payrollFile.name ? "" : "hide"}`} src={close} alt="prodensa automation" onClick={() => {
                                props.setPayrollFile({
                                    ...props.payrollFile,
                                    filename: ""
                                })

                                props.setDraft(prevState => {
                                    return {
                                        ...prevState,
                                        siteSelectionReport: {
                                            ...prevState.siteSelectionReport,
                                            want: {
                                                ...prevState.siteSelectionReport.want,
                                                TPCDocument: {
                                                    ...prevState.siteSelectionReport.want.TPCDocument,
                                                    filename: ""
                                                }
                                            }
                                        }
                                    }
                                })

                                props.setSavePayroll(false)
                            }}/>
                        </div>
                    </div>
                    <div className="actions payrollActions">
                        <button className="btnBgBlueTextGradient" id="cancelFile" onClick={props.onHide}>Close</button>
                        <button className="btnBgBlueTextGradient" disabled={!props.payrollFile.name} onClick={() => props.saveFile()}>Save</button>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}

//  JSX FOR THE CRITERIA MODAL FOR EVERY VARIABLE
function CriteriaModel(props) {
    return (
        <Modal
            className="wantsCriteriaModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="titleAndType">
                    <span>{props.modalVarRef?.title}</span>
                    <DropdownButton title={props.data?.criterias?.typeOfValue} align={"end"}>
                        <Dropdown.Item as="button"
                            onClick={(e) => props.changeVariableCriteriaTypeOfValue(e.target.innerText)}>Percentage</Dropdown.Item>
                        <Dropdown.Item as="button"
                            onClick={(e) => props.changeVariableCriteriaTypeOfValue(e.target.innerText)}>Number</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className="criteriaPoints">
                    <div className="criterias">
                        <span>Criteria</span>
                        <div className="inputContainer">
                            <span className="label">Option</span>
                            <Dropdown>
                                <DropdownToggle>
                                    {props.data?.criterias?.options?.[0]?.criteria}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Lowest value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Highest value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Higher than</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Lower than</Dropdown.Item>
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className={`inputContainer ${props.data.criterias.options[0].criteria === "Lowest value" || props.data.criterias.options[0].criteria === "Highest value" ? "hide" : ""}`}>
                            <span className="label">Value</span>
                            <input type="text" value={formatCommas(props.data?.criterias?.options?.[0]?.value)?.formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(0, "value", formatCommas(e.target.value)?.numericValue)} />
                            <span className="absoluteIcon">{props.data?.criterias?.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>

                        <div className="inputContainer">
                            <span className="label">From</span>
                            <input type="text" placeholder="From" value={formatCommas(props.data?.criterias?.options?.[1]?.firstRange)?.formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "firstRange", formatCommas(e.target.value)?.numericValue)} />
                            <span className="absoluteIcon">{props.data?.criterias?.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">To</span>
                            <input type="text" placeholder="To" value={formatCommas(props.data?.criterias?.options?.[1]?.secondRange)?.formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "secondRange", formatCommas(e.target.value)?.numericValue)} />
                            <span className="absoluteIcon">{props.data?.criterias?.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">From</span>
                            <input type="text" placeholder="From" value={formatCommas(props.data?.criterias?.options?.[2]?.firstRange)?.formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "firstRange", formatCommas(e.target.value)?.numericValue)} />
                            <span className="absoluteIcon">{props.data?.criterias?.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">To</span>
                            <input type="text" placeholder="To" value={formatCommas(props.data?.criterias?.options?.[2]?.secondRange)?.formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "secondRange", formatCommas(e.target.value)?.numericValue)} />
                            <span className="absoluteIcon">{props.data?.criterias?.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>

                        <div className="inputContainer">
                            <span className="label">Option</span>
                            <DropdownButton title={props.data.criterias.options[3].criteria}>
                                <Dropdown.Item as="button"
                                    onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Lowest
                                    value</Dropdown.Item>
                                <Dropdown.Item as="button"
                                    onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Highest
                                    value</Dropdown.Item>
                                <Dropdown.Item as="button"
                                    onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Higher
                                    than</Dropdown.Item>
                                <Dropdown.Item as="button"
                                    onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Lower
                                    than</Dropdown.Item>
                            </DropdownButton>
                        </div>

                        <div className={`inputContainer ${props.data.criterias.options[3].criteria === "Lowest value" || props.data.criterias.options[3].criteria === "Highest value" ? "hide" : ""}`}>
                            <span className="label">Value</span>
                            <input type="text" value={formatCommas(props.data.criterias.options[3].value).formattedValue}
                                onChange={(e) => props.changeVariableCriteriaValue(3, "value", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                    </div>
                    <div className="points">
                        <span className="title text-center">Points</span>
                        <span className="value">0</span>
                        <span className="value">1</span>
                        <span className="value">2</span>
                        <span className="value">3</span>
                    </div>
                </div>
                <button className="applyChanges" onClick={props.onHide}>Apply</button>
            </Modal.Body>
        </Modal>
    );
}

//  JSX FOR THE MANUAL CRITERIA MODAL FOR VARIABLE 5.1, 6.1 and 6.2
function ManualCriteriaModel(props) {
    return (
        <Modal
            className="wantsCriteriaModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="titleAndType">
                    <span>{props.varRef?.title}</span>
                </div>
                <div className="criteriaPoints">
                    <div className="criterias">
                        <span>Criteria</span>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data?.criterias?.options?.[0]?.value}
                                onChange={(e) => props.changeVariableCriteriaValue(0, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data?.criterias?.options?.[1]?.value}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data?.criterias?.options?.[2]?.value}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data?.criterias?.options?.[3]?.value}
                                onChange={(e) => props.changeVariableCriteriaValue(3, "value", e.target.value)} />
                        </div>
                    </div>
                    <div className="points">
                        <span className="title text-center">Points</span>
                        <span className="value">0</span>
                        <span className="value">1</span>
                        <span className="value">2</span>
                        <span className="value">3</span>
                    </div>
                </div>
                <button className="applyChanges" onClick={props.onHide}>Apply</button>
            </Modal.Body>
        </Modal>
    );
}

function Wants(props) {
    function settingsModal(varRef) { //* add varRef to props
        const PlacesContainers = {
            OriginPort: Symbol("OriginPort"),
            DestinationPort: Symbol("DestinationPort"),
            BorderCity: Symbol("BorderCity"),
            FinalDestination: Symbol("FinalDestination"),
            None: Symbol("None"),
        }

        const [citySearch, setCitySearch] = useState("")
        const [placesFound, setPlacesFound] = useState([])
        const [showPlacesContainer, setShowPlacesContainer] = useState(PlacesContainers.None)

        useEffect(() => {
            fetchCoordenates();
        }, [citySearch])

        const fetchCoordenates = async () => {
            const { data } = await Axios.get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${citySearch}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
            );

            setPlacesFound(data.features)
        };

        switch (varRef._id) {
            // water cost variable popup
            case "65cd41b0f955fa725381e7ea":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Cost per cubic meter (MXN)</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost?.cities?.filter(city => props.draft.siteSelectionReport.must?.cities?.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        let costPerCubicMeterFormat = props.variableCost.cities[index].dataVariables.waterCost.costPerCubicMeter === "" ?
                                            "" : formatCommas(props.variableCost.cities[index].dataVariables.waterCost.costPerCubicMeter)

                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]

                                                            citiesCopy[index].dataVariables.waterCost.costPerCubicMeter = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={ costPerCubicMeterFormat === "" ? "": costPerCubicMeterFormat.formattedValue }
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // average land cost variable popup
            case "65cd41c4f955fa725381e7ec":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>Average {(props.draft.siteSelectionReport.assumption.land.landContract).toLowerCase()} cost</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Low cost (sqft)</th>
                                        <th>High cost (sqft)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input name='lowCost' style={{ background: "#F5F5F5", }} type="number" value={props.variableCost.cities[index].dataVariables.landCost.lowCost}
                                                        onChange={(e) => props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.landCost.lowCost = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy,
                                                            }
                                                        })}
                                                    />
                                                </td>
                                                <td>
                                                    <input name='highCost' style={{ background: "#F5F5F5", }} type="number" value={props.variableCost.cities[index].dataVariables.landCost.highCost}
                                                        onChange={(e) => props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.landCost.highCost = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy,
                                                            }
                                                        })}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>

                )
            // turnover rate variable popup
            case "65cd4263f955fa725381e7f2":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Average monthly turnover rate</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.turnoverRate.avgMonthlyTOR = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.turnoverRate.avgMonthlyTOR}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // absenteeism variable popup
            case "65cd42a0f955fa725381e7f6":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Absenteeism rate</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.absenteeism.absenteeismRate = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.absenteeism.absenteeismRate}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // union environment variable popup
            case "65cd429af955fa725381e7f5":
                return (
                    <div className="containerSettings unionSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <div className="tablePopDataContainer">
                                <div className="tableTheadContainer">
                                    <span>Strategic Driver</span>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <span key={index}>{city.cityName}</span>
                                        )
                                    })}
                                </div>
                                <div className="tableTotalContainer">
                                    <span>Total</span>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <span key={index}>{props.variableCost.cities[index].dataVariables.unionEnvironment.total}</span>
                                        )
                                    })}
                                </div>
                                <div className="tableBodyContainer">
                                    <div className="tableRowContainer">
                                        <span>Union pro business culture</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.unionProBusinessCulture === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.unionProBusinessCulture
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.unionProBusinessCulture = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.unionProBusinessCulture}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Radical union/new union takeover risks</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.radicalTakeoverRisks === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.radicalTakeoverRisks
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.radicalTakeoverRisks = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.radicalTakeoverRisks}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Labor knowledge of the labor law</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.laborKnowledgeLaborLaw === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.laborKnowledgeLaborLaw
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.laborKnowledgeLaborLaw = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.laborKnowledgeLaborLaw}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Employers leadership perception</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.employersLeadershipPerception === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.employersLeadershipPerception
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.employersLeadershipPerception = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.employersLeadershipPerception}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Productivity oriented culture</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.productivityOrientedCulture === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.productivityOrientedCulture
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.productivityOrientedCulture = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.productivityOrientedCulture}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>HR Executives labor experience / readiness to face new challenges</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.HRExecutivesFaceChallenges === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.HRExecutivesFaceChallenges
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.HRExecutivesFaceChallenges = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.HRExecutivesFaceChallenges}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Management orientation to people culture</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.managementOrientation === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.managementOrientation
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.managementOrientation = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.managementOrientation}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Pro-business social environment</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="number" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        const prevValue = citiesCopy[index].dataVariables.unionEnvironment.proBusinessSocialEnvironment === "" ? 0 : citiesCopy[index].dataVariables.unionEnvironment.proBusinessSocialEnvironment
                                                        const newValue = e.target.value === "" ? 0 : +e.target.value
                                                        const prevTotalValue = citiesCopy[index].dataVariables.unionEnvironment.total
                                                        citiesCopy[index].dataVariables.unionEnvironment.total = prevTotalValue - prevValue + newValue
                                                        citiesCopy[index].dataVariables.unionEnvironment.proBusinessSocialEnvironment = e.target.value === "" ? "" : +e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.proBusinessSocialEnvironment}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="tableRowContainer">
                                        <span>Common unions</span>
                                        {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                            return (
                                                <input key={index} style={{ background: "#F5F5F5", }} type="text" onWheel={(e) => e.target.blur()} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        citiesCopy[index].dataVariables.unionEnvironment.commonUnions = e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.unionEnvironment.commonUnions}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // distance to port variable popup
            case "65cd42c0f955fa725381e7f9":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>

                        <div className="popData distance">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Destination</th>
                                        <th>Quantity (trucks/month)</th>
                                        {props.variableCost.distanceToClient.length > 1 && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.distanceToClient.map((clientDestiny, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        <input
                                                            style={{ background: "#F5F5F5", }}
                                                            value={clientDestiny.destiny?.fullName ?? ""}
                                                            type="text"
                                                            placeholder="City"
                                                            onBlur={() => setShowPlacesContainer("None")}
                                                            onChange={(e) => {
                                                                if (e.target.value !== "")
                                                                    setShowPlacesContainer(`destinyClient_${index}`)
                                                                else
                                                                    setShowPlacesContainer("None")

                                                                props.setVariableCost(prevState => {
                                                                    const distanceToClientCopy = [...prevState.distanceToClient]
                                                                    distanceToClientCopy[index].destiny = {
                                                                        fullName: e.target.value,
                                                                        city: "",
                                                                        state: "",
                                                                        country: "",
                                                                        latitude: undefined,
                                                                        longitude: undefined,
                                                                    }
                                                                    return {
                                                                        ...prevState,
                                                                        distanceToClient: distanceToClientCopy
                                                                    }
                                                                })

                                                                setCitySearch(e.target.value);
                                                            }}
                                                        />
                                                        <div className={`placesFoundContainer ${showPlacesContainer === `destinyClient_${index}` ? "show" : ""}`}>
                                                            {placesFound.map((place, indexPlace) => {
                                                                return (
                                                                    <span key={`place_${indexPlace}`} className="placeFound"
                                                                        onClick={() => {
                                                                            const state = place.context?.find(x => x.id.includes("region"))?.text;
                                                                            const country = place.context?.find(x => x.id.includes("country"))?.text;

                                                                            props.setVariableCost(prevState => {
                                                                                const distanceToClientCopy = [...prevState.distanceToClient]
                                                                                distanceToClientCopy[index].destiny = {
                                                                                    fullName: place.place_name,
                                                                                    city: place.text,
                                                                                    state: state ?? "",
                                                                                    country: country ?? "",
                                                                                    longitude: place.geometry.coordinates[0],
                                                                                    latitude: place.geometry.coordinates[1],
                                                                                }
                                                                                return {
                                                                                    ...prevState,
                                                                                    distanceToClient: distanceToClientCopy
                                                                                }
                                                                            })

                                                                            setShowPlacesContainer("None");
                                                                        }}>
                                                                        {place.place_name}
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" placeholder="Qty."
                                                        onChange={(e) =>
                                                            props.setVariableCost(prevState => {
                                                                const distanceToClientCopy = [...prevState.distanceToClient]
                                                                distanceToClientCopy[index].quantity = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                                return {
                                                                    ...prevState,
                                                                    distanceToClient: distanceToClientCopy
                                                                }
                                                            })
                                                        }
                                                        value={clientDestiny.quantity === "" ? "" : formatCommas(clientDestiny.quantity).formattedValue}
                                                    />
                                                </td>
                                                {props.variableCost.distanceToClient.length > 1 &&
                                                    <td>
                                                        <img className="deleteOrigin" src={imgDelete} alt="Prodensa automation"
                                                            onClick={() => props.setVariableCost(prevState => {
                                                                const distanceToClientCopy = [...prevState.distanceToClient];
                                                                distanceToClientCopy.splice(index, 1);

                                                                return {
                                                                    ...prevState,
                                                                    distanceToClient: distanceToClientCopy
                                                                }
                                                            })}
                                                        />
                                                    </td>}
                                            </tr>

                                        )
                                    })
                                    }
                                </tbody>
                                <button className="secondaryButton addOrigin" type="submit"
                                    onClick={() => props.setVariableCost(prevState => {
                                        return {
                                            ...prevState,
                                            distanceToClient: [...prevState.distanceToClient, {
                                                destiny: {
                                                    fullName: "",
                                                    city: "",
                                                    state: "",
                                                    country: "",
                                                    latitude: undefined,
                                                    longitude: undefined,
                                                },
                                                quantity: "",
                                                duration: 0,
                                            }],
                                        }
                                    })}>
                                    <img src={addCircle} alt="Prodensa automation" /> Add destination
                                </button>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // distance to seaport variable popup
            case "65cd42baf955fa725381e7f8":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>

                        <div className="popData distance">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Destination</th>
                                        <th>Quantity (trucks/month)</th>
                                        {props.variableCost.distanceToSeaport.length > 1 && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.distanceToSeaport.map((seaportDestiny, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        <input
                                                            style={{ background: "#F5F5F5", }}
                                                            value={seaportDestiny.destiny?.fullName ?? ""}
                                                            type="text"
                                                            placeholder="City"
                                                            onBlur={() => setShowPlacesContainer("None")}
                                                            onChange={(e) => {
                                                                if (e.target.value !== "")
                                                                    setShowPlacesContainer(`destinyClient_${index}`)
                                                                else
                                                                    setShowPlacesContainer("None")

                                                                props.setVariableCost(prevState => {
                                                                    const distanceToSeaportCopy = [...prevState.distanceToSeaport]
                                                                    distanceToSeaportCopy[index].destiny = {
                                                                        fullName: e.target.value,
                                                                        city: "",
                                                                        state: "",
                                                                        country: "",
                                                                        latitude: undefined,
                                                                        longitude: undefined,
                                                                    }
                                                                    return {
                                                                        ...prevState,
                                                                        distanceToSeaport: distanceToSeaportCopy
                                                                    }
                                                                })

                                                                setCitySearch(e.target.value);
                                                            }}
                                                        />
                                                        <div className={`placesFoundContainer ${showPlacesContainer === `destinyClient_${index}` ? "show" : ""}`}>
                                                            {placesFound.map((place, indexPlace) => {
                                                                return (
                                                                    <span key={`place_${indexPlace}`} className="placeFound"
                                                                        onClick={() => {
                                                                            const state = place.context?.find(x => x.id.includes("region"))?.text;
                                                                            const country = place.context?.find(x => x.id.includes("country"))?.text;

                                                                            props.setVariableCost(prevState => {
                                                                                const distanceToSeaportCopy = [...prevState.distanceToSeaport]
                                                                                distanceToSeaportCopy[index].destiny = {
                                                                                    fullName: place.place_name,
                                                                                    city: place.text,
                                                                                    state: state ?? "",
                                                                                    country: country ?? "",
                                                                                    longitude: place.geometry.coordinates[0],
                                                                                    latitude: place.geometry.coordinates[1],
                                                                                }
                                                                                return {
                                                                                    ...prevState,
                                                                                    distanceToSeaport: distanceToSeaportCopy
                                                                                }
                                                                            })

                                                                            setShowPlacesContainer("None");
                                                                        }}>
                                                                        {place.place_name}
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" placeholder="Qty."
                                                        onChange={(e) =>
                                                            props.setVariableCost(prevState => {
                                                                const distanceToSeaportCopy = [...prevState.distanceToSeaport]
                                                                distanceToSeaportCopy[index].quantity = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                                return {
                                                                    ...prevState,
                                                                    distanceToSeaport: distanceToSeaportCopy
                                                                }
                                                            })
                                                        }
                                                        value={seaportDestiny.quantity === "" ? "" : formatCommas(seaportDestiny.quantity).formattedValue}
                                                    />
                                                </td>
                                                {props.variableCost.distanceToSeaport.length > 1 &&
                                                    <td>
                                                        <img className="deleteOrigin" src={imgDelete} alt="Prodensa automation"
                                                            onClick={() => props.setVariableCost(prevState => {
                                                                const distanceToSeaportCopy = [...prevState.distanceToSeaport];
                                                                distanceToSeaportCopy.splice(index, 1);

                                                                return {
                                                                    ...prevState,
                                                                    distanceToSeaport: distanceToSeaportCopy
                                                                }
                                                            })}
                                                        />
                                                    </td>}
                                            </tr>

                                        )
                                    })
                                    }
                                </tbody>
                                <button className="secondaryButton addOrigin" type="submit"
                                    onClick={() => props.setVariableCost(prevState => {
                                        return {
                                            ...prevState,
                                            distanceToSeaport: [...prevState.distanceToSeaport, {
                                                destiny: {
                                                    fullName: "",
                                                    city: "",
                                                    state: "",
                                                    country: "",
                                                    latitude: undefined,
                                                    longitude: undefined,
                                                },
                                                quantity: "",
                                                duration: 0,
                                            }],
                                        }
                                    })}>
                                    <img src={addCircle} alt="Prodensa automation" /> Add destination
                                </button>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // distance to supplier variable popup
            case "65cd42b5f955fa725381e7f7":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>

                        <div className="popData distance">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Destination</th>
                                        <th>Quantity (trucks/month)</th>
                                        {props.variableCost.distanceToSupplier.length > 1 && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.distanceToSupplier.map((supplierDestiny, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        <input
                                                            style={{ background: "#F5F5F5", }}
                                                            value={supplierDestiny.destiny?.fullName ?? ""}
                                                            type="text"
                                                            placeholder="City"
                                                            onBlur={() => setShowPlacesContainer("None")}
                                                            onChange={(e) => {
                                                                if (e.target.value !== "")
                                                                    setShowPlacesContainer(`destinyClient_${index}`)
                                                                else
                                                                    setShowPlacesContainer("None")

                                                                props.setVariableCost(prevState => {
                                                                    const distanceToSupplierCopy = [...prevState.distanceToSupplier]
                                                                    distanceToSupplierCopy[index].destiny = {
                                                                        fullName: e.target.value,
                                                                        city: "",
                                                                        state: "",
                                                                        country: "",
                                                                        latitude: undefined,
                                                                        longitude: undefined,
                                                                    }
                                                                    return {
                                                                        ...prevState,
                                                                        distanceToSupplier: distanceToSupplierCopy
                                                                    }
                                                                })

                                                                setCitySearch(e.target.value);
                                                            }}
                                                        />
                                                        <div className={`placesFoundContainer ${showPlacesContainer === `destinyClient_${index}` ? "show" : ""}`}>
                                                            {placesFound.map((place, indexPlace) => {
                                                                return (
                                                                    <span key={`place_${indexPlace}`} className="placeFound"
                                                                        onClick={() => {
                                                                            const state = place.context?.find(x => x.id.includes("region"))?.text;
                                                                            const country = place.context?.find(x => x.id.includes("country"))?.text;

                                                                            props.setVariableCost(prevState => {
                                                                                const distanceToSupplierCopy = [...prevState.distanceToSupplier]
                                                                                distanceToSupplierCopy[index].destiny = {
                                                                                    fullName: place.place_name,
                                                                                    city: place.text,
                                                                                    state: state ?? "",
                                                                                    country: country ?? "",
                                                                                    longitude: place.geometry.coordinates[0],
                                                                                    latitude: place.geometry.coordinates[1],
                                                                                }
                                                                                return {
                                                                                    ...prevState,
                                                                                    distanceToSupplier: distanceToSupplierCopy
                                                                                }
                                                                            })

                                                                            setShowPlacesContainer("None");
                                                                        }}>
                                                                        {place.place_name}
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" placeholder="Qty."
                                                        onChange={(e) =>
                                                            props.setVariableCost(prevState => {
                                                                const distanceToSupplierCopy = [...prevState.distanceToSupplier]
                                                                distanceToSupplierCopy[index].quantity = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                                return {
                                                                    ...prevState,
                                                                    distanceToSupplier: distanceToSupplierCopy
                                                                }
                                                            })
                                                        }
                                                        value={supplierDestiny.quantity === "" ? "" : formatCommas(supplierDestiny.quantity).formattedValue}
                                                    />
                                                </td>
                                                {props.variableCost.distanceToSupplier.length > 1 &&
                                                    <td>
                                                        <img className="deleteOrigin" src={imgDelete} alt="Prodensa automation"
                                                            onClick={() => props.setVariableCost(prevState => {
                                                                const distanceToSupplierCopy = [...prevState.distanceToSupplier];
                                                                distanceToSupplierCopy.splice(index, 1);

                                                                return {
                                                                    ...prevState,
                                                                    distanceToSupplier: distanceToSupplierCopy
                                                                }
                                                            })}
                                                        />
                                                    </td>}
                                            </tr>

                                        )
                                    })
                                    }
                                </tbody>
                                <button className="secondaryButton addOrigin" type="submit"
                                    onClick={() => props.setVariableCost(prevState => {
                                        return {
                                            ...prevState,
                                            distanceToSupplier: [...prevState.distanceToSupplier, {
                                                destiny: {
                                                    fullName: "",
                                                    city: "",
                                                    state: "",
                                                    country: "",
                                                    latitude: undefined,
                                                    longitude: undefined,
                                                },
                                                quantity: "",
                                                duration: 0,
                                            }],
                                        }
                                    })}>
                                    <img src={addCircle} alt="Prodensa automation" /> Add destination
                                </button>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // connectivity travel variable popup
            case "65cd42daf955fa725381e7fc":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Airport</th>
                                        <th>Information</th>
                                        <th>Miles to nearest International Airport</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.connectivityTravel.airport = e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.connectivityTravel.airport}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.connectivityTravel.information = e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.connectivityTravel.information}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.connectivityTravel.milesToAirport = e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.connectivityTravel.milesToAirport === "" ? "" : formatCommas(props.variableCost.cities[index].dataVariables.connectivityTravel.milesToAirport).formattedValue}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            const value = e.target.value === "" ? "" : +e.target.value
                                                            citiesCopy[index].dataVariables.connectivityTravel.points = value === "" ? "" : value < 0 ? 0 : value > 3 ? 3 : value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.connectivityTravel.points}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // government incentives variable popup
            case "65cd42f3f955fa725381e7fe":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Incentives</th>
                                        <th>Description</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td><input type="text" style={{ background: "#F5F5F5" }} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        citiesCopy[index].dataVariables.governmentIncentives.incentives = e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.governmentIncentives.incentives}
                                                /></td>
                                                <td><input type="text" style={{ background: "#F5F5F5" }} onChange={(e) =>
                                                    props.setVariableCost(prevState => {
                                                        const citiesCopy = [...prevState.cities]
                                                        citiesCopy[index].dataVariables.governmentIncentives.description = e.target.value
                                                        return {
                                                            ...prevState,
                                                            cities: citiesCopy
                                                        }
                                                    })
                                                }
                                                    value={props.variableCost.cities[index].dataVariables.governmentIncentives.description}
                                                /></td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            const value = e.target.value === "" ? "" : +e.target.value
                                                            citiesCopy[index].dataVariables.governmentIncentives.points = value === "" ? "" : value < 0 ? 0 : value > 3 ? 3 : value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentIncentives.points}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // government landscape variable popup
            case "65cd42ecf955fa725381e7fd":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Year State Elections</th>
                                        <th>Party in Power</th>
                                        <th>Party Elected</th>
                                        <th>Federal Government Party</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", opacity: 0.5, cursor: "not-allowed" }} type="number" disabled onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            const value = e.target.value === "" ? "" : +e.target.value
                                                            citiesCopy[index].dataVariables.governmentLandscape.yearStateElection = value > 0 ? value : 0
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentLandscape.yearStateElection}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.governmentLandscape.partyPower = e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentLandscape.partyPower}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.governmentLandscape.partyElected = e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentLandscape.partyElected}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.governmentLandscape.federalGovernment = e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentLandscape.federalGovernment}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            const value = e.target.value === "" ? "" : +e.target.value
                                                            citiesCopy[index].dataVariables.governmentLandscape.points = value === "" ? "" : value < 0 ? 0 : value > 3 ? 3 : value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.governmentLandscape.points}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // availability of potential land variable popup
            case "65cd42d4f955fa725381e7fb":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Number of Potencial Buildings</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.availabilityOfPotentialLand.numberOfPotencialBuildings = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.availabilityOfPotentialLand.numberOfPotencialBuildings}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            // cost of living variable popup
            case "65cd4355f955fa725381e808":
                return (
                    <div className="containerSettings payrollSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title} (MXN)</h5>
                        </div>
                        <div className="popData">
                            <table>
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Cost of housing (1 Mth)</th>
                                        <th>Gas</th>
                                        <th>Utilities</th>
                                        <th>Food</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.variableCost.cities.filter(city => props.draft.siteSelectionReport.must.cities.some(selectedCity => selectedCity.municipality_name === city.cityName)).map((city, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{city.cityName}</td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="text" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            
                                                            citiesCopy[index].dataVariables.costOfLiving.costOfHousing =  e.target.value === "" ? "" : formatCommas(e.target.value).numericValue
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.costOfLiving.costOfHousing === "" ? "": formatCommas(props.variableCost.cities[index].dataVariables.costOfLiving.costOfHousing).formattedValue}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.costOfLiving.costOfGas = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.costOfLiving.costOfGas}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.costOfLiving.costOfUtilities = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.costOfLiving.costOfUtilities}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ background: "#F5F5F5", }} type="number" onChange={(e) =>
                                                        props.setVariableCost(prevState => {
                                                            const citiesCopy = [...prevState.cities]
                                                            citiesCopy[index].dataVariables.costOfLiving.costOfFood = e.target.value === "" ? "" : +e.target.value
                                                            return {
                                                                ...prevState,
                                                                cities: citiesCopy
                                                            }
                                                        })
                                                    }
                                                        value={props.variableCost.cities[index].dataVariables.costOfLiving.costOfFood}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="containerSettings" ref={menuSettingsRef} onClick={(e) => e.stopPropagation()}>
                        <div className="titleAndType">
                            <h5>{varRef.title}</h5>
                        </div>
                        <div className="actions payrollActions">
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Close</button>
                            <button className="btnBgBlueTextGradient" onClick={(e) => closeMenus(e)}>Save</button>
                        </div>
                    </div>
                )
        }
    }

    // ARRAY FOR SECTION ICONS
    const icons = [
        imgOperatingCost,
        imgCompetitionLabor,
        imgEnviromentLabor,
        imgLogistics,
        imgInfrastructure,
        imgGovermentIncetives,
        imgSecurity,
        imgQualityLife]

    // VARIABLES TO CONTROL THE CRITERIA MODAL
    const [showPayrollModal, setShowPayrollModal] = useState(false)

    // VARIABLES TO CONTROL THE CRITERIA MODAL
    const [showModal, setShowModal] = useState(false)
    const [modalVariablePosition, setModalVariablePosition] = useState(null)
    const [modalData, setModalData] = useState(null)
    const [modalVarRef, setModalVarRef] = useState(null)

    // VARIABLES TO CONTROL THE MANUAL CRITERIA MODAL
    const [showManualModal, setShowManualModal] = useState(false)
    const [modalManualVariablePosition, setManualModalVariablePosition] = useState(null)
    const [manualModalData, setManualModalData] = useState(null)
    const [manualModalVarRef, setManualModalVarRef] = useState(null)

    const [loading, setLoading] = useState(false)
    const [badCities, setBadCities] = useState(false)

    // VARIABLE DRAFT TO MANAGE SITE SELECTION WANTS
    const [wantsMatrix, setWantsMatrix] = useState(JSON.parse(JSON.stringify(props.draft.siteSelectionReport.want.wantsMatrix)))

    // VARIABLE TO CONTROL WHICH TAB IS ACTIVE 
    const [tabKey, setTabKey] = useState(0)

    // VARIABLE REFERENCE TO CLOSE THE MENU FOR THE VARIABLE INFORMATION 
    const menuInformationRef = useRef()

    // VARIABLE REFERENCE TO CLOSE THE MENU FOR THE VARIABLE SETTINGS 
    const menuSettingsRef = useRef()

    // VARIABLE TO CONTROL THE TOTAL TAB WEIGHT PERCENT
    let totalWantsTabs = 0;

    useEffect(() => {
        if (!loading) {
            Swal.close()
            return
        }

        Swal.fire({
            title: 'Uploading file...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
    }, [loading])

    useEffect(() => {
        if (badCities) {
            Swal.fire({
                icon: "error",
                title: "Check the cities names/number of cities in the file",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            })
            setBadCities(false)
        }
    }, [badCities])

    // EVENT LISTENER TO CLOSE THE INFORMATION MENU
    useEffect(() => {
        document.body.addEventListener("click", closeMenus)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenus)
        }
    }, [])

    // ASSIGMENT OF THE FUNCTION TO BE EXECUTED TO THE FATHERS BUTTON
    useEffect(() => {
        props.setFunctionActionButton(() => next);
    }, [props.draft.activeMenu])

    // FUNCTION TO EXECUTE WHEN FATHER BUTTON WAS CLICKED
    const next = () => {
        window.scrollTo(0, 0)
        if (props.draft.siteSelectionReport.must.cities.length > 0 &&
            props.draft.siteSelectionReport.assumption.exchangeRate !== "" &&
            props.draft.siteSelectionReport.assumption.exchangeRate !== 0) {

            const assumptions = props.draft.siteSelectionReport.assumption
            // check if assumptions inputs are ready
            const assumptionsReady = assumptions.land.sizeValue !== "" && assumptions.utilities.electricityInstalled !== "" &&
                assumptions.utilities.electricityConsumed !== "" && assumptions.utilities.gas !== "" && assumptions.utilities.water !== "" &&
                assumptions.logistics.logisticsInbound.inboundGroups.every(group => {
                    return group.origins.every(origin => { return origin.latitude && origin.longitude && origin.quantity !== "" })
                }) &&
                assumptions.logistics.logisticsOutbound.destinations.every(destiny => {
                    return destiny.latitude && destiny.longitude && destiny.quantity !== ""
                }) && (assumptions.exchangeRate !== "" && assumptions.exchangeRate !== 0)

            // check for weight of categories are 100
            const totalWantsTabs = wantsMatrix.reduce((total, want) => {
                return total + (want.weight === "" ? 0 : want.weight)
            }, 0) === 100

            //check for weight of variables are 100 for each category
            const totalWantsVariables = wantsMatrix.every(want => {
                return want.variables.reduce((total, variable) => {
                    return total + (variable.weight === "" ? 0 : variable.weight)
                }, 0) === 100
            })

            // check if report has the variables that are needed to fill with manual data
            const checkIfVariableExist = (varIdRef) => {
                wantsMatrix.some(want => want.variables.some(variable => variable.varIdRef === varIdRef))
            }
            
            const payrollVariableExist = checkIfVariableExist("65cd417ff955fa725381e7e7")
            const logisticsCostVariableExist = checkIfVariableExist("65cd419ff955fa725381e7e8")
            const waterVariableExist = checkIfVariableExist("65cd41b0f955fa725381e7ea")
            const landVariableExist = checkIfVariableExist("65cd41c4f955fa725381e7ec")
            const turnoverVariableExist = checkIfVariableExist("65cd4263f955fa725381e7f2")
            const absenteeismVariableExist = checkIfVariableExist("65cd42a0f955fa725381e7f6")
            const unionVariableExist = checkIfVariableExist("65cd429af955fa725381e7f5")
            const distanceToClientVariableExist = checkIfVariableExist("65cd42c0f955fa725381e7f9")
            const distanceToSeaportVariableExist = checkIfVariableExist("65cd42baf955fa725381e7f8")
            const distanceToSupplierVariableExist = checkIfVariableExist("65cd42b5f955fa725381e7f7")
            const connectivityVariableExist = checkIfVariableExist("65cd42daf955fa725381e7fc")
            const availabilityPotentialLandVariableExist = checkIfVariableExist("65cd42d4f955fa725381e7fb")
            const governmentIncentivesVariableExist = checkIfVariableExist("65cd42f3f955fa725381e7fe")
            const governmentLandscapeVariableExist = checkIfVariableExist("65cd42ecf955fa725381e7fd")
            const costOfLivingVariableExist = checkIfVariableExist("65cd4355f955fa725381e808")
            
            // check if wants inputs are ready or skip if the variable does not exist in the report
            const wantsReady = props.variableCost?.cities.every(city => {
                return (!waterVariableExist || city.dataVariables.waterCost.costPerCubicMeter !== "") && 
                    (!landVariableExist || (city.dataVariables.landCost.lowCost !== "" && city.dataVariables.landCost.highCost !== "")) &&
                    (!turnoverVariableExist || city.dataVariables.turnoverRate.avgMonthlyTOR !== "") && 
                    (!absenteeismVariableExist || city.dataVariables.absenteeism.absenteeismRate !== "") && 
                    (!unionVariableExist || city.dataVariables.unionEnvironment.total !== 0) && 
                    (!connectivityVariableExist || city.dataVariables.connectivityTravel.points !== "") && 
                    (!availabilityPotentialLandVariableExist || city.dataVariables.availabilityOfPotentialLand.numberOfPotencialBuildings !== "") && 
                    (!governmentIncentivesVariableExist || city.dataVariables.governmentIncentives.points !== "") && 
                    (!governmentLandscapeVariableExist || city.dataVariables.governmentLandscape.points !== "") && 
                    (!costOfLivingVariableExist || city.dataVariables.costOfLiving.costOfHousing !== "") && 
                    (!costOfLivingVariableExist || city.dataVariables.costOfLiving.costOfGas !== "") && 
                    (!costOfLivingVariableExist || city.dataVariables.costOfLiving.costOfUtilities !== "") && 
                    (!costOfLivingVariableExist || city.dataVariables.costOfLiving.costOfFood !== "") && 
                    (!logisticsCostVariableExist || city.inboundGroups.every(group => { return group.origins.every(origin => { return origin.price !== "" }) })) && 
                    (!logisticsCostVariableExist || city.outbound.every(destiny => { return destiny.price !== "" }))
            }) &&
                (!distanceToClientVariableExist || (props.variableCost.distanceToClient[0].destiny.longitude && props.variableCost.distanceToClient[0].destiny.latitude &&
                props.variableCost.distanceToClient[0].quantity !== "")) && 
                (!distanceToSeaportVariableExist || (props.variableCost.distanceToSeaport[0].destiny.longitude && props.variableCost.distanceToSeaport[0].destiny.latitude && 
                props.variableCost.distanceToSeaport[0].quantity !== "")) &&
                (!distanceToSupplierVariableExist || (props.variableCost.distanceToSupplier[0].destiny.longitude && props.variableCost.distanceToSupplier[0].destiny.latitude &&
                props.variableCost.distanceToSupplier[0].quantity !== ""))

            const readyToGenerate = (!payrollVariableExist || props.draft.siteSelectionReport.want.TPCDocument.filename !== "") && assumptionsReady && wantsReady && totalWantsTabs <= 100 && totalWantsVariables <= 100

            if (readyToGenerate) {
                props.setAnimatecontainer(true)
                props.setAnimateColumn(true)
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There is missing information to generate the report",
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "You must select at least one city and add value to exchange rate",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            })
        }
    }

    // FUNCTION TO CLOSE THE MENU VARIABLE INFORMATION
    const closeMenus = (e) => {
        const menusInformation = document.querySelectorAll(".informationContainer")
        const menusSettings = document.querySelectorAll(".containerSettings")

        if (menuInformationRef.current && menuInformationRef.current.contains(e.target)) {
            menusInformation.forEach(element => {
                if (!menuInformationRef.current.nextElementSibling.contains(element)) {
                    element.classList.remove("show")
                }
            });
            menuInformationRef.current.nextElementSibling.classList.toggle("show")
        } else {
            menusInformation.forEach(element => {
                element.classList.remove("show")
            });
        }

        if (menuSettingsRef.current && menuSettingsRef.current.contains(e.target)) {
            menusSettings.forEach(element => {
                if (!menuSettingsRef.current.nextElementSibling.contains(element)) {
                    element.classList.remove("show")
                }
            });
            menuSettingsRef.current.nextElementSibling.classList.toggle("show")
        } else {
            menusSettings.forEach(element => {
                element.classList.remove("show")
            });
        }
    }

    // FUNCTION TO UPDATE THE TAB WEIGHT
    const updateWeightTab = (id, value) => {
        if (value >= 0 && value <= 100) {
            let copyMatrix = [...wantsMatrix]
            copyMatrix.find(want => want.catIdRef === id).weight = value

            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        want: {
                            ...prevState.siteSelectionReport.want,
                            wantsMatrix: copyMatrix
                        }
                    }
                }
            })
        }
    }

    // FUNCTION TO UPDATE THE VARIABLE WEIGHT
    const updateWeightVariable = (idFather, id, value) => {
        if (value >= 0 && value <= 100) {
            let copyMatrix = [...wantsMatrix]
            copyMatrix.find(want => want.catIdRef === idFather).variables
            .find(variable => variable.varIdRef === id).weight = value

            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        want: {
                            ...prevState.siteSelectionReport.want,
                            wantsMatrix: copyMatrix
                        }
                    }
                }
            })
        }
    }

    // FUNCTION TO UPDATE THE VARAIBLE INFORMATION
    const updateVariableInformation = (idFather, id, attr, value) => {
        let copyMatrix = [...wantsMatrix]
        copyMatrix.find(want => want.catIdRef === idFather).variables
        .find(variable => variable.varIdRef === id).information[attr] = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO ADD NEW VARIABLE TO THE SPECIFIC TAB
    const addVariableToCriteria = (positionFather) => {
        let copyMatrix = [...wantsMatrix]
        const cities = props.draft.siteSelectionReport.must.cities.map(city => {
            return {
                name: city.municipality_name,
                value: 0
            }
        })
        copyMatrix[positionFather].variables.push(
            {
                addedVariable: true,
                title: "",
                weight: 0,
                information: {
                    definition: "",
                    metric: "",
                    source: ""
                },
                criterias: {
                    typeOfValue: "Type of value",
                    options: [
                        {
                            criteria: "Select option",
                            value: ""
                        },
                        {
                            firstRange: "",
                            secondRange: ""
                        },
                        {
                            firstRange: "",
                            secondRange: ""
                        },
                        {
                            criteria: "Select option",
                            value: ""
                        }
                    ]
                },
                settings: {
                    typeOfValue: "Type of value",
                    cities: cities
                }
            }
        )

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO REMOVE VARIABLE OF THE SPECIFIC TAB
    const removeVariableToCriteria = (positionFather, position) => {
        let copyMatrix = [...wantsMatrix]
        copyMatrix[positionFather].variables.splice(position, 1)

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO CHANGE VARIABLE TITLE FOR THE NEW VARIABLE OF THE SPECIFIC TAB
    const changeTitleVariable = (positionFather, position, value) => {
        let copyMatrix = [...wantsMatrix]
        copyMatrix[positionFather].variables.find((variable, index) => {
            return index === position
        }).title = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO INITIALIZE THE CRITERIA MODEL FOR THE SPECIFIC TAB AND VARIABLE
    const initializeCriteriaModel = (indexFather, indexVariable, data, varRef) => { //*add varRef to props
        setModalVariablePosition({
            positionFather: indexFather,
            positionVariable: indexVariable
        })
        setModalVarRef(varRef)
        setModalData(data)
        setShowModal(true)
    }

    // FUNCTION TO INITIALIZE THE CRITERIA MODEL FOR THE SPECIFIC TAB AND VARIABLE
    const initializeManualCriteriaModel = (indexFather, indexVariable, data, varRef) => { //*add varRef to props
        setModalVariablePosition({
            positionFather: indexFather,
            positionVariable: indexVariable
        })
        setManualModalVarRef(varRef)
        setManualModalData(data)
        setShowManualModal(true)
    }

    // FUNCTION TO CHANGE THE CRITERIA TYPE OF VALUE FOR THE SPECIFIC TAB AND VARIABLE
    const changeVariableCriteriaTypeOfValue = (value) => {
        let copyMatrix = [...wantsMatrix]

        copyMatrix[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.typeOfValue = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO CHANGE THE CRITERIA TITLE FOR THE SPECIFIC TAB AND VARIABLE
    const changeVariableCriteria = (whichCriteria, value) => {
        let copyMatrix = [...wantsMatrix]

        copyMatrix[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.options[whichCriteria].criteria = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO CHANGE THE CRITERIA VALUE FOR THE SPECIFIC TAB AND VARIABLE
    const changeVariableCriteriaValue = (whichCriteria, attr, value) => {
        let copyMatrix = [...wantsMatrix]

        copyMatrix[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.options[whichCriteria][attr] = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO CHANGE THE SETTINGS TYPE OF VALUE FOR THE SPECIFIC TAB AND VARIABLE
    const changeVariableSettingsTypeOfValue = (indexFather, indexVariable, value) => {
        let copyMatrix = [...wantsMatrix]

        copyMatrix[indexFather].variables[indexVariable].settings.typeOfValue = value

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO CHANGE THE SETTINGS CITY VALUE FOR THE SPECIFIC TAB AND VARIABLE
    const changeVariableSettingsCityvalue = (indexFather, indexVariable, indexCity, value) => {
        let copyMatrix = [...wantsMatrix]

        copyMatrix[indexFather].variables[indexVariable].settings.cities[indexCity].value = value === "" ? "" : value > 0 ? value : 0

        props.setDraft(prevState => {
            return {
                ...prevState,
                siteSelectionReport: {
                    ...prevState.siteSelectionReport,
                    want: {
                        ...prevState.siteSelectionReport.want,
                        wantsMatrix: copyMatrix
                    }
                }
            }
        })
    }

    // FUNCTION TO UPLOAD TO INPUT TYPE FILE THE SELECTED FILE
    const selectFile = async (e) => {
        const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        const selectedFile = e.target.files[0]

        if (selectedFile && allowTypes !== selectedFile?.type) {
            await Swal.fire({
                icon: "error",
                title: "File not supported",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
            return;
        }

        props.setPayrollFile(selectedFile)
    }

    // FUNCTION TO SAVE FILE IN THE SERVER
    const saveFile = () => {
        const data = new FormData()
        data.append('cities', JSON.stringify(props.draft.siteSelectionReport.must.cities))
        data.append('reportId', props.draft._id)
        data.append('payroll', props.payrollFile)
        setLoading(true)
        const updatedDraft = { ...props.draft }
        Axios.post(process.env.REACT_APP_UPDATE_PAYROLL, data).then(response => {
            updatedDraft.siteSelectionReport.want.TPCDocument.filename = response.data.filename
            updatedDraft.siteSelectionReport.want.TPCDocument.payrollFileId = response.data.fileUpdated._id
            Axios.patch(process.env.REACT_APP_UPDATE_REPORT + props.draft._id, updatedDraft).then(draftRes => {
                props.setDraft(prevState => {
                    return {
                        ...prevState,
                        siteSelectionReport: {
                            ...prevState.siteSelectionReport,
                            want: {
                                ...prevState.siteSelectionReport.want,
                                TPCDocument: {
                                    filename: response.data.filename,
                                    payrollFileId: response.data.fileUpdated._id
                                }
                            }
                        }
                    }
                })
                props.setSavePayroll(true)
                setLoading(false)
            }).catch(err => { 
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
                // setLoading(false) 
            })
        }).catch(err => {
            console.log(err)
            if (err.response.status && err.response.status === 400) {
                // setLoading(false)
                setBadCities(true)
            } else {
                customAlert(err.response?.data?.message || err.response?.statusText)
            }
        })
    }

    // JSX FOR THE WANTS VIEW IN SITE SELECTION
    return (
        <section className="wantsSection">
            <WantsMobile
                wantsMatrix={wantsMatrix} 
                wantsMatrixRef={props.wantsMatrixReference}
                updateWeightTab={updateWeightTab}
                updateWeightVariable={updateWeightVariable}
                updateVariableInformation={updateVariableInformation}
                menuInformationRef={menuInformationRef}
                menuSettingsRef={menuSettingsRef}
                initializeManualCriteriaModel={initializeManualCriteriaModel}
                initializeCriteriaModel={initializeCriteriaModel}
                changeTitleVariable={changeTitleVariable}
                changeVariableSettingsTypeOfValue={changeVariableSettingsTypeOfValue}
                changeVariableSettingsCityvalue={changeVariableSettingsCityvalue}
                addVariableToCriteria={addVariableToCriteria}
                removeVariableToCriteria={removeVariableToCriteria}
                settingsModal={settingsModal}
                setShowPayrollModal={setShowPayrollModal}
                setShowLogisticCosts={props.setShowLogisticCosts}
            />
            
            
            <nav className="d-none d-lg-block">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {wantsMatrix
                    .sort((a, b) => {
                        const da = props.wantsMatrixReference.find(want => want._id === a.catIdRef).position
                        const db = props.wantsMatrixReference.find(want => want._id === b.catIdRef).position

                        return da - db
                    })
                    .map((data, index) => {
                        totalWantsTabs += data.weight
                        const catRef = props.wantsMatrixReference.find(want => want._id === data.catIdRef)
                        return (
                            <button key={index} className={`nav-link ${tabKey === index ? "active" : ""}`}
                                id={`nav-home-tab${index}`} data-bs-toggle="tab" data-bs-target={`#tabInfo${index}`}
                                type="button" role="tab" aria-controls="nav-home" aria-selected="true"
                                onClick={() => setTabKey(index)}>
                                <img src={icons[index]} alt="Prodensa Automation" />
                                {catRef.title}
                                <div className="inputContainer">
                                    <input type="number" value={data.weight}
                                        onChange={(e) => updateWeightTab(data.catIdRef, +e.target.value)} />
                                    <div className="numberSelector"
                                        onClick={() => updateWeightTab(data.catIdRef, data.weight + 1)}></div>
                                    <div className="numberSelector"
                                        onClick={() => updateWeightTab(data.catIdRef, data.weight - 1)}></div>
                                </div>
                            </button>
                        )
                    })}
                    <div className="navTotal">
                        <span>Total</span>
                        <span className={totalWantsTabs > 100 ? "danger" : ""}>{totalWantsTabs.toFixed(2)}</span>
                        <div className={`dangerMessage ${totalWantsTabs > 100 ? "show" : ""}`}>
                            <img src={infoIconDanger} alt="Prodensa Automation" />
                            <p>The sum of all categories should't surpass 100%. Please adjust the values of each
                                category.</p>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="tab-content d-none d-lg-block" id="nav-tabContent">
                {wantsMatrix.map((data, indexData) => {
                    let totalPointsInfo = 0;
                    let totalWeightInfo = 0;
                    const catRef = props.wantsMatrixReference.find(want => want._id === data.catIdRef)
                    return (
                        <div key={indexData} className={`tab-pane fade ${tabKey === indexData ? "show active" : ""} `}
                            id={`tabInfo${indexData}`} role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
                            <div className="infoTitle">
                                <h5>{catRef.title}</h5>
                                <h5>Max Points</h5>
                                <h5>Weight</h5>
                            </div>
                            {data.variables
                            .sort((a, b) => {
                                const da = catRef.variables.find(varRef => varRef._id === a.varIdRef)?.position
                                const db = catRef.variables.find(varRef => varRef._id === b.varIdRef)?.position

                                return da - db
                            })
                            .map((variable, index) => {
                                totalWeightInfo += variable.weight
                                totalPointsInfo += (data.weight * variable.weight) / 100
                                const varRef = !variable.addedVariable && catRef.variables.find(varRef => varRef._id === variable.varIdRef)
                                return (
                                    <div className="infoValues" key={index}>
                                        <div className="titleAndMenus">
                                            <img ref={menuInformationRef} src={infoIcon}
                                                onClick={(e) => menuInformationRef.current = e.target}
                                                alt="Prodensa Automation" />
                                            <div className="informationContainer" onClick={(e) => e.stopPropagation()}>
                                                <h5>Information</h5>
                                                <div className="rowInfo">
                                                    <img src={imgDefinition} alt="Prodensa Automation" />
                                                    <div className="w-100">
                                                        <h5 className="title">Definition</h5>
                                                        <textarea rows={3} className="description"
                                                            value={variable.information.definition}
                                                            onChange={(e) => updateVariableInformation(data.catIdRef, variable.varIdRef, "definition", e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="rowInfo">
                                                    <img src={imgMetric} alt="Prodensa Automation" />
                                                    <div className="w-100">
                                                        <h5 className="title">Metric</h5>
                                                        <textarea rows={1.5} className="description"
                                                            value={variable.information.metric}
                                                            onChange={(e) => updateVariableInformation(data.catIdRef, variable.varIdRef, "metric", e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="rowInfo">
                                                    <img src={imgSource} alt="Prodensa Automation" />
                                                    <div className="w-100">
                                                        <h5 className="title">Source</h5>
                                                        <textarea rows={1.5} className="description"
                                                            value={variable.information.source}
                                                            onChange={(e) => updateVariableInformation(data.catIdRef, variable.varIdRef, "source", e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {!variable.addedVariable && <h5>{varRef.title}</h5>}
                                            {variable.addedVariable && <input type="text" value={variable.title}
                                                onChange={(e) => changeTitleVariable(indexData, index, e.target.value)} />}
                                            <button
                                                onClick={() => {
                                                    if (variable.varIdRef.toString() === "65cd42daf955fa725381e7fc" || variable.varIdRef.toString() === "65cd42f3f955fa725381e7fe" || variable.varIdRef.toString() === "65cd42ecf955fa725381e7fd") {
                                                        initializeManualCriteriaModel(indexData, index, variable, varRef)
                                                    } else {
                                                        initializeCriteriaModel(indexData, index, variable, varRef)
                                                    }
                                                }}>Criteria
                                            </button>
                                        </div>
                                        <span
                                            className="infoPoints">{((data.weight * variable.weight) / 100).toFixed(2)}</span>
                                        <div className="inputContainer">
                                            <input type="number" value={variable.weight}
                                                onChange={(e) => updateWeightVariable(data.catIdRef, variable.varIdRef, +e.target.value)} />
                                            <div className="numberSelector"
                                                onClick={() => updateWeightVariable(data.catIdRef, variable.varIdRef, variable.weight + 1)}></div>
                                            <div className="numberSelector"
                                                onClick={() => updateWeightVariable(data.catIdRef, variable.varIdRef, variable.weight - 1)}></div>
                                        </div>

                                        {(variable.addedVariable || variable.moreOptions) &&
                                            <img className="settingsVariable" ref={menuSettingsRef} src={imgSettings} alt="Prodensa Automation"
                                                onClick={(e) => menuSettingsRef.current = e.target}
                                            />
                                        }
                                        {varRef.title === "Total payroll cost" &&
                                            <img className="settingsVariable" src={imgSettings} alt="Prodensa Automation"
                                                onClick={() => setShowPayrollModal(true)}
                                            />
                                        }
                                        {varRef.title === "Logistics cost" &&
                                            <img className="settingsVariable" src={imgSettings} alt="Prodensa Automation"
                                                onClick={() => props.setShowLogisticCosts(true)}
                                            />
                                        }
                                        {
                                            variable.moreOptions && settingsModal(varRef)
                                        }
                                        {variable.addedVariable &&
                                            <div className="containerSettings" onClick={(e) => e.stopPropagation()}>
                                                <div className="titleAndType">
                                                    <h5>Settings</h5>
                                                    <DropdownButton title={`${variable.settings.typeOfValue}`} align="end">
                                                        <Dropdown.Item onClick={(e) => changeVariableSettingsTypeOfValue(indexData, index, e.target.innerText)} as="button">$</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => changeVariableSettingsTypeOfValue(indexData, index, e.target.innerText)} as="button">%</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                                <div className="cities">
                                                    {variable.settings.cities.map((city, cityIndex) => (
                                                        <div className="inputContainer" key={cityIndex}>
                                                            <span className="label">{city.name}</span>
                                                            <input type="number" value={city.value}
                                                                onChange={(e) => changeVariableSettingsCityvalue(indexData, index, cityIndex, e.target.value)}
                                                            />
                                                            <span className="valueSelected">
                                                                {variable.settings.typeOfValue === "Type of value" ? "Select type of value" : variable.settings.typeOfValue}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="actions">
                                                    <button className="btnBgBlueTextGradient" onClick={() => document.body.click()}>Apply</button>
                                                </div>
                                            </div>}
                                        {variable.addedVariable &&
                                            <img className="deleteRow" src={imgDelete}
                                                onClick={() => removeVariableToCriteria(indexData, index)}
                                                alt="Prodensa Automation"
                                            />}
                                    </div>
                                )
                            })}

                            <div className="addAndTotal">
                                <div className="addContainer" >
                                    <button onClick={() => addVariableToCriteria(indexData)}>Variable</button>
                                </div>
                                <span>{totalPointsInfo.toFixed(2)}</span>
                                <span className={totalWeightInfo > 100 ? "danger" : ""}>{totalWeightInfo}%</span>
                                <div className={`dangerMessage ${totalWeightInfo > 100 ? "show" : ""}`}>
                                    <img src={infoIconDanger} alt="Prodensa Automation" />
                                    <p>The sum of all variables shouldn't surpass 100%. Please adjust the values of each
                                        category.</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="tabButtonsContainer">
                    <button className={`btnBgBlueTextGradient ${tabKey === 0 ? "hidden" : ""}`}
                        onClick={() => setTabKey(tabKey > 0 ? tabKey - 1 : tabKey)}>Previous
                    </button>
                    <button className={`btnBgBlueTextGradient ${tabKey === wantsMatrix.length - 1 ? "hidden" : ""}`}
                        onClick={() => setTabKey(tabKey < wantsMatrix.length - 1 ? tabKey + 1 : tabKey)}>Next
                    </button>
                </div>
            </div>

            {modalData && <CriteriaModel
                modalVarRef={modalVarRef}
                show={showModal}
                onHide={() => setShowModal(false)}
                data={modalData}
                changeVariableCriteriaTypeOfValue={changeVariableCriteriaTypeOfValue}
                changeVariableCriteriaValue={changeVariableCriteriaValue}
                changeVariableCriteria={changeVariableCriteria}
            />}

            {manualModalData && <ManualCriteriaModel
                modalVarRef={manualModalVarRef}
                show={showManualModal}
                onHide={() => setShowManualModal(false)}
                data={manualModalData}
                changeVariableCriteriaValue={changeVariableCriteriaValue}
            />}

            {<PayrollFileModel
                showPayrollModal={showPayrollModal}
                onHide={() => setShowPayrollModal(false)}
                payrollFile={props.payrollFile}
                draft={props.draft}
                setDraft={props.setDraft}
                savePayroll={props.savePayroll}
                setSavePayroll={props.setSavePayroll}
                setPayrollFile={props.setPayrollFile}
                selectFile={selectFile}
                saveFile={() => saveFile()}
            />}
        </section>
    )
}

export default Wants;
