import "../../../assets/css/siteSelection/musts.css"
import Axios from "axios"
import Swal from "sweetalert2"
import { Slider } from "@mui/material";
import { useMemo, useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { formatCommas } from "../../../utils/formatCommas.js";
import { formatNumberToShortScale } from "../../../utils/formatNumberToShortScale.js";

import add from "../../../assets/images/add.svg";
import search from "../../../assets/images/search.svg";
import groups from "../../../assets/images/groups.svg";
import PageSelector from "../../reportLog/pageSelector";
import sortDown from "../../../assets/images/sortDown.svg";
import { DataVariables } from "../../../models/dataVariables.ts";
import { customAlert } from "../../../utils/customAlert.js";


const EAP_INITIAL_VALUES = [
    { realValue: 0, value: 0, label: "0" },
    { realValue: 20000, value: 1, label: "20k" },
    { realValue: 100000, value: 2, label: "100k" },
    { realValue: 500000, value: 3, label: "500k" },
    { realValue: 1000000, value: 4, label: "1M" },
    { realValue: 15300000, value: 5, label: "15.3M" },
]

function Musts(props) {
    const NAMES_DIFFERENCE = [
        ['Coahuila', 'Coahuila de Zaragoza'],
        ['México', 'Estado de México'],
        ['Michoacán', 'Michoacán de Ocampo'],
        ['Veracruz', 'Veracruz de Ignacio de la Llave'],
        ['Cd. Juárez', 'Juárez'],
        ['Nueva Rosita', 'San Juan de Sabinas'],
        ['Parral', 'Hidalgo del Parral'],
        ['Pachuca', 'Pachuca de Soto'],
        ['Acapulco', 'Acapulco de Juárez'],
        ['Cancún Q. Roo', 'Benito Juárez'],
        ['Cancún', 'Benito Juárez'],
        ['Cd. Del carmen', 'Carmen'],
        ['Cd. Del Carmen', 'Carmen'],
        ['Chetumal', 'Othón P. Blanco'],
        ['Chilpancingo', 'Chilpancingo de los Bravo'],
        ['Oaxaca', 'Oaxaca de Juárez'],
        ['Poza Rica', 'Poza Rica de Hidalgo'],
        ['Villahermosa', 'Centro'],
        ['Pachuca', 'Pachuca de Soto'],
        ['Queretaro', 'Querétaro'],
        ['Oaxaca', 'Oaxaca de Juárez']
    ]
    const itemsPerPage = 10;
    const [cities, setCities] = useState([])
    const [filteredCities, setFilteredCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState(props.draft.siteSelectionReport.must.cities)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [customRangeToggle, setCustomRangeToggle] = useState([])
    const [isMeasureMiles, setIsMeasureMiles] = useState(false)	
    const [unitOfDistance, setUnitOfDistance] = useState("km")
    const [cityMiles,setCityMiles] = useState([])
    const [milesLabels,setMilesLabels] = useState([])
    const [kilometersLabels,setKilometersLabels] = useState([])
    const [rangeError,setRangeError] = useState("");
    const [selectedBorder, setSelectedBorder] = useState("Tijuana")
    const [borderDistances, setBorderDistances] = useState([])

    const [filters, setFilters] = useState([
        {
            name: "population",
            displayName: "Population",
            menuName: "Population (millions of inhabitants)",
            values: [
                { realValue: 0, value: 0, label: "0" },
                { realValue: 100000, value: 1, label: "100k" },
                { realValue: 300000, value: 2, label: "300k" },
                { realValue: 500000, value: 3, label: "500k" },
                { realValue: 1000000, value: 4, label: "1M" },
                { realValue: 21900000, value: 5, label: "21.9M" },
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0, 5000001],
            active: false,
            show: true,
            defaultShow: true,
        },
        {
            name: "EAP",
            displayName: "Economic active population",
            menuName: "Economic active population (millions of inhabitants)",
            values: [
                { realValue: 0, value: 0, label: "0" },
                { realValue: 20000, value: 1, label: "20k" },
                { realValue: 100000, value: 2, label: "100k" },
                { realValue: 500000, value: 3, label: "500k" },
                { realValue: 1000000, value: 4, label: "1M" },
                { realValue: 15300000, value: 5, label: "15.3M" },
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0,15000000],
            active: false,
            show: true,
            defaultShow: true,
        },
        {
            name: "selectBorder",
            displayName: "Border",
            menuName: "Select a Border",
            values: [
                "Tijuana",
                "Tecate",
                "Mexicali",
                "Nuevo Laredo",
                "Nogales",
                "Ciudad Juárez",
                "Ciudad Acuña",
                "Reynosa",
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0, 5000],
            active: true,
            show: true,
            defaultShow: true,
        },
        {
            name: "distanceToBorder",
            displayName: "Distance to border",
            menuName: "Distance to border - " + unitOfDistance,
            values: [
                { realValue: 0, value: 0, label: "0 km" },
                { realValue: 600, value: 1, label: "600 km" },
                { realValue: 1200, value: 2, label: "1,200 km" },
                { realValue: 1800, value: 3, label: "1,800 km"},
                { realValue: 2400, value: 4, label: "2,400 km"},
                { realValue: 3000, value: 5, label: "3,000 km"},
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0, 5000],
            active: false,
            show: true,
            defaultShow: true,
        },
        {
            name: "manufacturing_industries",
            displayName: "Manufacturing environment",
            menuName: "Manufacturing environment (Number of industries)",
            values: [
                { realValue: 0, value: 0, label: "0" },
                { realValue: 5000, value: 1, label: "5k" },
                { realValue: 10000, value: 2, label: "10k" },
                { realValue: 15000, value: 3, label: "15k" },
                { realValue: 20000, value: 4, label: "20k" },
                { realValue: 20001, value: 5, label: "+20k" },
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0, 20001],
            active: false,
            show: true,
            defaultShow: true,
        },
        {
            name: "waterAvailability",
            displayName: "Water Availability",
            menuName: "Water Availability",
            values: [
                { realValue: 0, value: 0, label: "0" },
                { realValue: 250000, value: 1, label: "250k" },
                { realValue: 500000, value: 2, label: "500k" },
                { realValue: 1000000, value: 3, label: "1M" },
                { realValue: 5000000, value: 4, label: "5M" },
                { realValue: 5000001, value: 5, label: "+5M" },
            ],
            selectedValue: [1, 3],
            defaultValue: [1, 3],
            customRange: [0, 5000001],
            active: false,
            show: false,
            defaultShow: false,
        },
    ])

    const length = filters.length
    const [showFilter, setShowFilter] = useState(Array.from({ length }, () => false))
    const [activateFilter, setActivateFilter] = useState(false) //state just to control whenever a filter.active change
    const [searchFilter, setSearchFilter] = useState("")
    const [sort, setSort] = useState({
        column: "municipality_name",
        order: "ASC"
    })

    useEffect(() => {
        document.body.addEventListener("click", closeFilters)

        return () => {
            document.body.removeEventListener("click", closeFilters)
        }
    }, [])
    
    useEffect(() => {
        setLoading(true)
        const waterAvailable = props.draft.siteSelectionReport.assumption.utilities.waterAvailable
        Axios.post(process.env.REACT_APP_GET_ALL_CITIES_FOR_MUST, { withWater: waterAvailable }).then(res => {
            setBorderDistances(res.data ? res.data.borderDistances : [])
            setCities(res.data ? res.data.cities : [])
            setCityMiles(res.data ? res.data.cityMiles : [])
            setLoading(false)
        }).catch(err => {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }, []);

    
    //useEffect to determine ranges based on data
    const isPopulationFilterActive = useMemo(() => filters.find(filter => filter.name === "population").active, [filteredCities, filters])
    
    useEffect(() => {

        if (!isPopulationFilterActive) {
            const filtersClone = [...filters]
            
            filtersClone.forEach(filter => {
                if (filter.name === "EAP") {
                    filter.values.map((value, index) => {
                        value.label = EAP_INITIAL_VALUES[index].label
                        value.realValue = EAP_INITIAL_VALUES[index].realValue
                    })
                }
            })

            setFilters(filtersClone)

            return
        }

        if (isPopulationFilterActive && filteredCities?.length > 0) {
            const filtersClone = [...filters];
            //Perform this change for every filter
            filtersClone.map((filter, index) => {
                if (filter.name === "EAP") {
                    //Get  min and max values for each filter if the value is undefined, set it as 0 if minimum or as 10000 if maximum(default value)
                    let filterMinValue = Math.min(...filteredCities.map(city => city[filter.name] === undefined ? 0 : city[filter.name])) 
                    let filterMaxValue = Math.max(...filteredCities.map(city => city[filter.name] === undefined ? 0 : city[filter.name]))
                    let filterDifference = filterMaxValue - filterMinValue
                    filter.values.map((value, index) => {
                        if (index === 0) {
                            value.label = formatNumberToShortScale(filterMinValue) 
                            value.realValue = filterMinValue   
                        } else {
                            value.label = (filterMinValue + (filterDifference / 5) * index + 1)
                            value.realValue = filterMinValue + (filterDifference / 5) * index + 1
                            value.label = formatNumberToShortScale(value.label)
        
                        }
                    })
                } else {
                    return filter
                }
            })
            setFilters(filtersClone)
        }
    }, [isPopulationFilterActive, filteredCities])

    useEffect(() => {
        if (cities.length > 0) {
            const filtersClone = [...filters];
            const milesLabelsClone = []
            const kilometersLabelsClone = []
    
            const filterMinValue = Math.min(...cities.map(city => getDistanceToBorder(city)))
    
            const filterMaxValue = Math.max(...cities.map(city => getDistanceToBorder(city)))
    
            const filterDifference = filterMaxValue - filterMinValue
            
            filtersClone.find(x => x.name === "distanceToBorder").values.forEach((value, index) => {
                if (index === 0) {
                    milesLabelsClone.push({label: filterMinValue * 0.621371, realValue: filterMinValue * 0.621371})
                    kilometersLabelsClone.push({label: filterMinValue, realValue: filterMinValue})
                    value.label = filterMinValue + " km"
                    value.realValue = filterMinValue
                } else {
                    value.label = (filterMinValue + (filterDifference / 5) * index + 1)
                    value.realValue = filterMinValue + (filterDifference / 5) * index + 1
                    milesLabelsClone.push({ label: value.label * 0.621371, realValue: value.realValue * 0.621371 })
                    kilometersLabelsClone.push({ label: value.label, realValue: value.realValue })
    
                    value.label = value.label.toFixed(0) + " km"
                }
            })
            setMilesLabels(milesLabelsClone)
            setKilometersLabels(kilometersLabelsClone)
        }
    }, [selectedBorder])

    //useEffect to change filter labels
    useEffect(() => {
        if(milesLabels.length === 0 || kilometersLabels.length === 0) return
        
        const filtersClone = [...filters];
        filtersClone.find(x => x.name === "distanceToBorder").values.map((value, index) => { 
            if(value.label) {
                if (unitOfDistance === "mi") {
                    value.label = milesLabels[index].label.toFixed(0) + " mi"
                    value.realValue = milesLabels[index].realValue
                } else {
                    value.label = kilometersLabels[index]?.label ? kilometersLabels[index].label.toFixed(0) + " km" : value.label ? value.label.replace(" mi"," km") : value.label
                    value.realValue = kilometersLabels[index]?.realValue ? kilometersLabels[index].realValue : value.realValue
                }
            }
        }
        )

        setFilters(filtersClone)
    }, [unitOfDistance, milesLabels, kilometersLabels])

    //Tracks the set of population as active filter
    useEffect(() => {
        if (filters[0].active) {
            const filtersClone = [...filters];
            let maxPopulation, minPopulation, populationDifference;

            if (customRangeToggle.includes(0)) {
                maxPopulation = filtersClone[0].customRange[1];
                minPopulation = filtersClone[0].customRange[0];
                populationDifference = maxPopulation - minPopulation;
            } else {
                let maxIndex = filters[0].selectedValue[1];
                maxPopulation = filters[0].values[maxIndex].realValue;
                let minIndex = filters[0].selectedValue[0];
                minPopulation = filters[0].values[minIndex].realValue;
                populationDifference = maxPopulation - minPopulation;
            }

            let suffixes = ["hundreds", "thousands", "millions"];
            let suffixIndex = 0;
        
            while (maxPopulation >= 1000 && suffixIndex < suffixes.length - 1) {
                maxPopulation /= 1000;
                suffixIndex++;
            }
            filtersClone[1].menuName = "";
            let suffixName = "Economic active population (" + suffixes[suffixIndex] + " of inhabitants)";
            filtersClone[1].menuName = suffixName;

            setFilters(filtersClone);
        }
    }, [activateFilter]);
    
    useEffect(() => {
        if (!loading) {
            Swal.close()
            return
        }

        Swal.fire({
            title: 'Loading...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
    }, [loading])

    useEffect(() => {
        filterTable();
    }, [cities, selectedCities, searchFilter])

    useEffect(() => {
        if (searchFilter === "") {
            orderSelectedCities()
        }
        setCurrentPage(1)
    }, [cities, selectedCities, searchFilter, activateFilter])

    useEffect(() => {
        props.setFunctionActionButton(() => next);
    }, [props.draft.activeMenu])

    useEffect(() => {
        sortTable();
    }, [sort, cities, selectedCities, activateFilter])

    useEffect(() => {
        setStartIndex((currentPage - 1) * itemsPerPage);
        setEndIndex(currentPage * itemsPerPage);
    }, [currentPage]);

    const getDistanceToBorder = (city) => {
        return borderDistances.find(x => x.datamexico_municipality_id === city.datamexico_municipality_id)
            ?.distances.find(d => d.border === selectedBorder).distance
    }
    
    const changeSortOrder = (column) => {
        setSort(prevState => {
            const order = column === prevState.column && prevState.order === "ASC" ? "DESC" : "ASC"

            return {
                column,
                order
            }
        })
    }

    const sortTable = () => {
        const parameter = sort.column
        const order = sort.order
        const sortedCities = filteredCities.length > 0 ? [...filteredCities] : [...cities]

        sortedCities.sort((cityA, cityB) => {
            if (typeof cityA[parameter] === "string") {
                if (order === "ASC")
                    return cityA[parameter].localeCompare(cityB[parameter]);
                else if (order === "DESC")
                    return cityB[parameter].localeCompare(cityA[parameter]);
            } else {
                if (order === "ASC")
                    return parameter === "distanceToBorder" ? getDistanceToBorder(cityA) - getDistanceToBorder(cityB) : 
                    parameter === "manufacturing_industries" ? calculateIndustries(cityA) - calculateIndustries(cityB) : cityA[parameter] - cityB[parameter];
                else if (order === "DESC")
                    return parameter === "distanceToBorder" ? getDistanceToBorder(cityB) - getDistanceToBorder(cityA) : 
                    parameter === "manufacturing_industries" ? calculateIndustries(cityB) - calculateIndustries(cityA) : cityB[parameter] - cityA[parameter];
            }
        });

        if (selectedCities.length > 0) {
            sortedCities.sort((cityA, cityB) => {
                const isSelectedA = selectedCities.some(selectCity => selectCity.datamexico_municipality_id === cityA.datamexico_municipality_id);
                const isSelectedB = selectedCities.some(selectCity => selectCity.datamexico_municipality_id === cityB.datamexico_municipality_id);

                if (isSelectedA && !isSelectedB) {
                    return -1;
                } else if (!isSelectedA && isSelectedB) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        setFilteredCities(sortedCities);
    }

    const changeFilterSelectedValue = (value, index, isReset) => {
        const filtersClone = [...filters];
        filtersClone[index].selectedValue = value;
        filtersClone[index].customRange = [filtersClone[index].values[value[0]].realValue,filtersClone[index].values[value[1]].realValue];
        if (isReset) {
            filtersClone[index].active = false;
            toggleFilter(index);
        }

        setFilters(filtersClone)

        if (isReset) {
            filterTable();
        }
    };

    // const changeFilterShow = (index) => {
    //     const filtersClone = [...filters];
    //     filtersClone[index].show = !filtersClone[index].show;

    //     setFilters(filtersClone)
    // };

    const applyFilter = async (index) => {
        const filtersClone = [...filters];
        if (customRangeToggle.includes(index)) {
            if (filtersClone[index].customRange[0] > filtersClone[index].customRange[1]) {
                setRangeError("Lower limit is greater than upper limit");
                setTimeout(() => {
                    setRangeError("");
                }, 3000); // Timeout duration in milliseconds (3 seconds)
                return;
            }
        }

        filtersClone[index].active = true;
        setFilters(filtersClone);
        filterTable();
        setActivateFilter(true);
        toggleFilter(index);
    };

    const clearAllFilters = () => {
        let filtersClone = [...filters];

        filtersClone.map(filter => {
            filter.selectedValue = filter.defaultValue;
            filter.active = filter.name === "selectBorder"
            filter.show = filter.defaultShow;
        });


        setFilters(filtersClone)
        setSearchFilter("")

        filterTable();
        setActivateFilter(false)
        setShowFilter(Array.from(length).fill(false))
    };

    const filterTable = () => {
        let filteredCitiesClone = [];
        const activeFilters = filters.filter(filter => filter.active && filter.name !== "selectBorder");
        let cityFilterArray = unitOfDistance === "km" ? cities : cityMiles;

        //There are no active filters and there is no search string, SHOW ALL CITIES
        if (activeFilters.length === 0 && searchFilter === "") {
            setFilteredCities(cityFilterArray)
            return
        }

        //There are active filters and there is a search string, FILTER BY ACTIVE FILTERS AND SEARCH STRING
        if (activeFilters.length > 0 && searchFilter !== "") {
            filteredCitiesClone = cityFilterArray.filter(city => {
                return activeFilters.every(activeFilter => {
                    let trueFilterId = filters.indexOf(activeFilter);
                    if (customRangeToggle.includes(trueFilterId)) {
                        return city[activeFilter.name] >= activeFilter.customRange[0] &&
                            city[activeFilter.name] <= activeFilter.customRange[1];
                    } else {
                        if (activeFilter.selectedValue[1] !== activeFilter.values.length - 1) {
                            return city[activeFilter.name] >= activeFilter.values[activeFilter.selectedValue[0]].realValue &&
                            city[activeFilter.name] <= activeFilter.values[activeFilter.selectedValue[1]].realValue;
                        } else {
                            return city[activeFilter.name] >= activeFilter.values[activeFilter.selectedValue[0]].realValue;
                        }
                    }
                });
            });

            filteredCitiesClone = filteredCitiesClone.filter(city => {
                return city.municipality_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchFilter.toLowerCase())
            });

            setFilteredCities(filteredCitiesClone)
            return
        }

        //There are active filters, filter by active filters
        if (activeFilters.length > 0) {
            filteredCitiesClone = cityFilterArray.filter(city => {
                return activeFilters.every(activeFilter => {
                    let trueFilterId = filters.indexOf(activeFilter);
                    if (customRangeToggle.includes(trueFilterId)) {
                        return city[activeFilter.name] >= activeFilter.customRange[0] &&
                            city[activeFilter.name] <= activeFilter.customRange[1];
                    } else {
                        if (activeFilter.selectedValue[1] !== activeFilter.values.length - 1) {
                            return city[activeFilter.name] >= activeFilter.values[activeFilter.selectedValue[0]].realValue &&
                            city[activeFilter.name] <= activeFilter.values[activeFilter.selectedValue[1]].realValue;
                        } else {
                            return city[activeFilter.name] >= activeFilter.values[activeFilter.selectedValue[0]].realValue;
                        }
                    }
                });
            });
        
            setFilteredCities(filteredCitiesClone)
            return
        }

        //There is a search string, filter by search string
        if (searchFilter !== "") {
            filteredCitiesClone = cityFilterArray.filter(city => {
                const normalizedMunicipalityName = city.municipality_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                // const normalizedStateName = city.state_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                // return normalizedMunicipalityName?.toLowerCase().includes(searchFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) ||
                //     normalizedStateName?.toLowerCase().includes(searchFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
                return normalizedMunicipalityName?.toLowerCase().includes(searchFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
            });

            setFilteredCities(filteredCitiesClone)
            return
        }
    };

    const selectCity = (target) => {
        const stateName = NAMES_DIFFERENCE.find(item => item[1] === target.state_name)
        const cityAndState = stateName ? `${target.municipality_name.replace(" Metroplex", "")}, ${stateName[0]}` :
            `${target.municipality_name.replace(" Metroplex", "")}, ${target.state_name}`
        Axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${cityAndState}.json?types=place&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`).then(res => {
            const relevances = res.data.features.map(item => item.relevance)
            const maxRelenvance = Math.max(...relevances)
            const mapboxCity = res.data.features.find(item => item.relevance === maxRelenvance)
            const { _id,
                datamexico_municipality_id,
                municipality_name,
                state_name,
                population,
                security,
                graduates,
                distanceToBorder,
                EAP,
                manufacturing_industries,
                cre_gas_region
            } = target

            const city = {
                _id,
                datamexico_municipality_id,
                municipality_name,
                state_name,
                population,
                EAP,
                distanceToBorder,
                longitude: mapboxCity?.center?.[0], //agregar coordenadas de golfo de mexico
                latitude: mapboxCity?.center?.[1], //agregar coordenadas de golfo de mexico
                manufacturing_industries,
                cre_gas_region,
                security,
                graduates,
            }

            const inboundGroups = props.draft.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map(group => {
                return {
                    groupName: group.groupName,
                    origins: group.origins.map(origin => {
                        return {
                            origin: origin.fullName,
                            price: "",
                        }
                    })
                }
            })

            const outbound = props.draft.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map(destination => {
                return {
                    destination: destination.fullName,
                    price: "",
                }
            })

            const variableCostCity = {
                cityName: city.municipality_name,
                dataVariables: new DataVariables(),
                inboundGroups: inboundGroups,
                outbound: outbound,
            }

            variableCostCity.dataVariables.governmentLandscape.yearStateElection = target.yearElection < new Date().getFullYear() ? target.yearElection + 6 : target.yearElection

            const updateVariableCostCities = [...props.variableCost.cities]
            const updateExtraVariables = [...props.draft.siteSelectionReport.want.wantsMatrix]

            updateExtraVariables.forEach(category => {
                category.variables.forEach(variable => {
                    if (variable.addedVariable) {
                        const cityIndex = variable.settings.cities.findIndex(variableCity => variableCity.name === city.municipality_name)
                        if(cityIndex === -1) {
                            variable.settings.cities.push({
                                name: city.municipality_name,
                                value: 0
                            })
                        } else {
                            variable.settings.cities.splice(cityIndex, 1)
                        }
                    }
                })
            })

            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        want: {
                            ...prevState.siteSelectionReport.want,
                            wantsMatrix: updateExtraVariables
                        }
                    }
                }
            })

            const exist = updateVariableCostCities.find(variableCostCity => variableCostCity.cityName === city.municipality_name)

            if (!exist) {
                updateVariableCostCities.push(variableCostCity)
                props.setVariableCost(prevState => {
                    return {
                        ...prevState,
                        cities: updateVariableCostCities
                    }
                })
            }

            const updateSelectedCities = [...selectedCities]
            const index = updateSelectedCities.findIndex(selectCity => selectCity.datamexico_municipality_id === city.datamexico_municipality_id)
            if (index === -1) {
                updateSelectedCities.push(city)
            } else {
                updateSelectedCities.splice(index, 1)
            }

            setSelectedCities(updateSelectedCities)
            props.setDraft(prevState => {
                return {
                    ...prevState,
                    siteSelectionReport: {
                        ...prevState.siteSelectionReport,
                        must: {
                            ...prevState.siteSelectionReport.musts,
                            cities: updateSelectedCities
                        },
                    }
                }
            });

        }).catch(err => console.log(err))
    }

    const toggleFilter = (index) => {
        const showFilterClone = showFilter.map((item, i) => i === index ? !item : false);
        setShowFilter(showFilterClone);
    }

    const closeFilters = (event) => {
        const isFilterButton = event.target.closest('[name="filter-button"]');
        if (!isFilterButton) {
            const showFilterClone = [...showFilter];
            showFilterClone.fill(false);
            setShowFilter(showFilterClone);
        }
    }

    const orderSelectedCities = () => {
        if (selectedCities.length > 0) {
            const allCities = [...cities]
            allCities.sort((cityA, cityB) => {
                const isSelectedA = selectedCities.some(selectCity => selectCity.datamexico_municipality_id === cityA.datamexico_municipality_id);
                const isSelectedB = selectedCities.some(selectCity => selectCity.datamexico_municipality_id === cityB.datamexico_municipality_id);

                if (isSelectedA && !isSelectedB) {
                    return -1;
                } else if (!isSelectedA && isSelectedB) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setFilteredCities(allCities)
        }
    }

    const calculateIndustries = (city) => {
        if (!city.manufacturing_industries) {
            return 0;
        }

        if (props.draft.siteSelectionReport.assumption.selectedIndustries.length === 0) {
            return city.manufacturing_industries.reduce((acc, industry) => {
                return acc + industry.numberOfCompaniesPerIndustry;
            }, 0);
        } else {
            return city.manufacturing_industries.reduce((acc, industry) => {
                const selectedIndustry = props.draft.siteSelectionReport.assumption.selectedIndustries.find(item => item.nationalIndustryId === industry.nationalIndustryId);
                return selectedIndustry ? acc + industry.numberOfCompaniesPerIndustry : acc;
            }, 0);
        }
    }

    function changeCurrentPage(value) {
        setCurrentPage(value);
    }

    const next = () => {
        props.setActiveMenu(3);
    }

    return (
        <section className="mustsSection">
            <Row>
                <Col className="position-relative px-0" lg={10} xs={12}>
                    <div className="filtersContainer">
                        {filters.map((filter, index) => {
                            if (props.draft.siteSelectionReport.assumption.selectedIndustries.length === 0 && filter.name === "manufacturing_industries"){
                                return null;
                            }
                            if (filter.show) {
                                return (
                                    <Dropdown key={`filter_${index}`} show={showFilter[index]} autoClose={true}>
                                        <Dropdown.Toggle name="filter-button" className={filter.active ? "filterActive" : ""}
                                            onClick={() => toggleFilter(index)}>
                                            <img src={groups} alt="ProdensaAutomation" />
                                            {filter.displayName}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={`${filter.name === "selectBorder" ? "borderCitiesDropdow" : ""}`} onClick={(e) => e.stopPropagation()}>
                                            <div className="filterContentContainer">
                                                {filter.displayName === "Distance to border" ? (
                                                    <div className="filterHeader">
                                                        <span>Distance to border</span>
                                                        <button className={`miles-selector ${isMeasureMiles ? "active" : ""}`}
                                                                onClick={() => setIsMeasureMiles(!isMeasureMiles)}
                                                        >
                                                            {unitOfDistance}
                                                        </button>
                                                        <div className={`distance-options ${isMeasureMiles ? "show" : ""}`}>
                                                            <button className={unitOfDistance=== "km" ? "selected" : ""} onClick={()=> {setUnitOfDistance("km");setIsMeasureMiles(false)}}>km</button>
                                                            <button className={unitOfDistance=== "mi" ? "selected" : ""} onClick={()=> {setUnitOfDistance("mi");setIsMeasureMiles(true)}}>mi</button>
                                                        </div>
                                                        
                                                    </div>
                                                ) : (
                                                    <span className={`${filter.name === "selectBorder" ? "borderFilterName" : ""}`}>
                                                    {filter.menuName}
                                                    </span>
                                                )}
                                        
                                                {filter.name === "selectBorder" && 
                                                    <div className="borderCitiesContainer">
                                                        {filter.values.map((value, index) => {
                                                            return (
                                                                <div key={`${value}-${index}`} className="borderCity" onClick={() => setSelectedBorder(value)}>
                                                                    <input
                                                                        id={`city_${index}`}
                                                                        name="borderCity"
                                                                        type="radio"
                                                                        checked={value === selectedBorder}
                                                                        onChange={() => setSelectedBorder(value)}
                                                                    />
                                                                    <label htmlFor={`city_${index}`}>{value}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                
                                                {filter.name !== "selectBorder" && 
                                                <div className="rangeInputContainer">
                                                    <Slider
                                                        valueLabelFormat={(index) => {
                                                            return filter.values[index].label
                                                        }}
                                                        value={filter.selectedValue}
                                                        onChange={(e, value) => changeFilterSelectedValue(value, index, false)}
                                                        valueLabelDisplay="on"
                                                        marks={filter.values}
                                                        step={1}
                                                        min={0}
                                                        max={5}
                                                        disableSwap
                                                    />
                                                </div>}
                                                {customRangeToggle && customRangeToggle.includes(index) &&
                                                <>
                                                    <div className={`customRangeContainer ${rangeError !== "" ? "error" : ""}`}>
                                                        <input type="text"
                                                            value={formatCommas(filter.customRange[0]).formattedValue}
                                                            placeholder={filter.values[0].realValue}
                                                            onChange={(e) =>
                                                                setFilters(prevState => {
                                                                    const filtersCopy = [...prevState]
                                                                    let parsedValue = Number(e.target.value.replace(/[^0-9.]/g, ''));
                                                                    filtersCopy[index].customRange[0] = parsedValue
                                                                    return [...filtersCopy];
                                                                })} />
                                                        < input type="text"
                                                            value={formatCommas(filter.customRange[1]).formattedValue}
                                                            placeholder={filter.values[4].realValue}
                                                            onChange={(e) =>
                                                                setFilters(prevState => {
                                                                    const filtersCopy = [...prevState]
                                                                    let parsedValue = Number(e.target.value.replace(/[^0-9.]/g, ''));
                                                                    filtersCopy[index].customRange[1] = parsedValue
                                                                    return [...filtersCopy];
                                                                })} />
                                                    </div>
                                                    <h5 className={`error-message ${rangeError !== "" ? "error" : ""}`}>{rangeError}</h5>
                                                </>
                                                }
                                                <div className="filterApplyContainer">
                                                    {filter.name !== "selectBorder" && <button className={`cancelButton ${customRangeToggle === index ? "active" : ""}`}
                                                        onClick={() => setCustomRangeToggle(prevState => {
                                                            if ( prevState && prevState.includes(index)) {
                                                                return prevState.filter(item => item !== index);
                                                            } else {
                                                                return [...prevState, index];
                                                            }
                                                        })}>
                                                        <span>Custom range</span>
                                                    </button>}
                                                    {filter.name !== "selectBorder" && <button className="cancelButton"
                                                        onClick={() => { changeFilterSelectedValue(filter.defaultValue, index, true),
                                                            setCustomRangeToggle(prevState => {
                                                                return prevState.filter(item => item !== index);
                                                        }) }}>
                                                        Reset
                                                    </button>}
                                                    {filter.name !== "selectBorder" && <button className="secondaryButton"
                                                        onClick={() => { applyFilter(index) }}>
                                                        <span>Apply</span>
                                                    </button>}
                                                    {filter.name === "selectBorder" && <button className="secondaryButton" style={{marginRight: "10px" }}
                                                        onClick={() => { toggleFilter(index) }}>
                                                        <span>Close</span>
                                                    </button>}

                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                );
                            }
                        })}
                        <a className={`resetAllFilters ${filters.filter(filter => filter.active && filter.name !== "selectBorder").length !== 0 ? "show" : ""}`} onClick={clearAllFilters}>Remove filters</a>
                    </div>
                </Col>
                <Col className="ms-auto" lg={"auto"}>
                    <div className="filterActionsContainer">
                        <Dropdown className="search">
                            <Dropdown.Toggle>
                                <img src={search} alt="ProdensaAutomation" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="searchContainer">
                                    <input className="search" type="text" placeholder="Search cities"
                                        value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)}
                                    />
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="filter">
                            <Dropdown.Toggle disabled={true}>
                                <img src={add} alt="ProdensaAutomation" />
                                Filter
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <h5 className="filterCityContentContainer">...</h5>
                                {/* {filters.slice(4, filters.length)
                                    .map((filter, index) => {
                                        return (
                                            <div key={`filter_${index}`} className="filterCityContentContainer">
                                                <input id={`checkbox_filter_${index}`} type="checkbox"
                                                    value="" checked={filter.show}
                                                    onChange={() => changeFilterShow(index + 4)} />
                                                <label htmlFor={`checkbox_filter_${index}`}>{filter.displayName}</label>
                                            </div>
                                        )
                                    })} */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <table className="tableCities">
                <thead>
                    <tr>
                        <th>
                            Cities
                            <img className={`sort ${sort.column === "municipality_name" &&
                                sort.order === "DESC" ? "DESC" : "ASC"}`} src={sortDown}
                                alt="ProdensaAutomation"
                                onClick={() => changeSortOrder("municipality_name")} />
                        </th>
                        <th>
                            Population
                            <img className={`sort ${sort.column === "population" &&
                                sort.order === "DESC" ? "DESC" : "ASC"}`}
                                src={sortDown}
                                alt="ProdensaAutomation"
                                onClick={() => changeSortOrder("population")} />
                        </th>
                        <th>
                            EAP
                            <img
                                className={`sort ${sort.column === "EAP" &&
                                    sort.order === "DESC" ? "DESC" : "ASC"}`}
                                src={sortDown}
                                alt="ProdensaAutomation"
                                onClick={() => changeSortOrder("EAP")} />
                        </th>
                        <th>
                            Distance to border
                            <img
                                className={`sort ${sort.column === "distanceToBorder" &&
                                    sort.order === "DESC" ? "DESC" : "ASC"}`}
                                src={sortDown}
                                alt="ProdensaAutomation"
                                onClick={() => changeSortOrder("distanceToBorder")} />
                        </th>
                        <th>
                            Manufacturing environment
                            <img
                                className={`sort ${sort.column === "manufacturing_industries" &&
                                    sort.order === "DESC" ? "DESC" : "ASC"}`}
                                src={sortDown}
                                alt="ProdensaAutomation"
                                onClick={() => changeSortOrder("manufacturing_industries")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {!loading && selectedCities.map((city, index) => {
                        return (
                            <tr key={city._id}>
                                <td>
                                    <div className="filterCityContentContainer">
                                        <input id={`checkbox_table_${index}`} type="checkbox"
                                            value={city._id} onChange={(e) => selectCity(city)}
                                            checked={selectedCities.some(selectCity => selectCity.datamexico_municipality_id === city.datamexico_municipality_id)} />
                                        <label htmlFor={`checkbox_table_${index}`}>{city.municipality_name}, {city.state_name}</label>
                                    </div>
                                </td>
                                <td>{(city.population ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td>{(city.EAP ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td>{(unitOfDistance === "mi" ? (city.distanceToBorder * 0.621371) : city.distanceToBorder ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {unitOfDistance}</td>
                                <td>{(city.manufacturing_industries ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                        )
                    })} */}
                    {!loading && filteredCities.slice(startIndex, endIndex)
                        .map((city, index) => {
                            return (
                                <tr key={city._id}>
                                    <td>
                                        <div className="filterCityContentContainer">
                                            <input id={`checkbox_table_${index}`} type="checkbox"
                                                value={city._id} onChange={(e) => selectCity(city)}
                                                checked={selectedCities.some(selectCity => selectCity.datamexico_municipality_id === city.datamexico_municipality_id)} />
                                            <label htmlFor={`checkbox_table_${index}`}>{city.municipality_name}, {city.state_name}</label>
                                        </div>
                                    </td>
                                    <td>{(city.population ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td>{(city.EAP ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td>{borderDistances.length > 0 && 
                                        getDistanceToBorder(city)?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " km / " +
                                        (getDistanceToBorder(city) * 0.621371)?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " mi"
                                        }
                                    </td>
                                    {/* <td>{(city.distanceToBorder ?? 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {unitOfDistance}</td> */}
                                    <td>{calculateIndustries(city).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                </tr>
                            )
                    })}
                </tbody>
            </table>
            <PageSelector currentPage={currentPage}
                data={filteredCities}
                itemsPerPage={itemsPerPage}
                onChange={changeCurrentPage}
            ></PageSelector>
        </section>
    )
}

export default Musts;
