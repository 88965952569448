import { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { PDF_FORM, NUMBER_OF_INPUTS, NAV_MENU_OPTIONS } from "./utils.js"
import iconBlue from "../../../assets/images/info-blue.svg"
import ExtraOptions from "./extraOptions.jsx"
import CitiesForms from "./citiesForms.jsx"
import WantsForms from "./wantsForms.jsx"

export default function FormsSection(props) {
    const { 
        pdfConfig,
        setPdfConfig,
        activeSection,
        setActiveSection,
        draft,
        handleFile,
        wantsMatrixReference,
        showPdfConfigure,
        savePdfConfig,
        handleActivePdfConfig
    } = props
    
    const [selectedOption, setSelectedOption] = useState(1)
    
    useEffect(() => {
        setSelectedOption(1)
    }, [activeSection, showPdfConfigure])

    useEffect(() => {
        const container = document.querySelector(".inputs-area")

        if (container) {
            container.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [activeSection, selectedOption])
    
    function handleSection(opt) {
        setActiveSection(prev => {
            if (opt === "prev" && prev === 1) return prev
            if (opt === "next" && prev === NAV_MENU_OPTIONS.length) {
                savePdfConfig()

                return prev
            }
            
            if (opt === "prev") {
                return prev - 1
            }

            if (opt === "next") {
                return prev + 1
            }
        })
    }

    function imageTooltip(image) {
        return (
            <Tooltip id="tooltip-img-container">
                <img src={image} alt="Prodensa Automation" className="tooltip-img" />
            </Tooltip>
        )
    }

    function updateInput(section, accessKey, value, elementId = null) {
        if (elementId !== null) {
            const elementExist = pdfConfig?.[section]?.some(pdfElement => pdfElement.id === elementId);

            const updatedElementsData = elementExist
                ? (pdfConfig[section].map(pdfElement =>
                    pdfElement.id === elementId
                        ? { ...pdfElement, [accessKey]: value }
                        : pdfElement
                ))
                : [...pdfConfig[section], { id: elementId, [accessKey]: value }]

            const updatedPdfConfig = {
                ...pdfConfig,
                [section]: updatedElementsData
            };

            setPdfConfig(updatedPdfConfig);

            return
        }
        
        const updatedPdfConfig = {
            ...pdfConfig,
            [section]: {
                ...pdfConfig[section],
                [accessKey]: value
            }
        }

        setPdfConfig(updatedPdfConfig)
    }
    
    const actionsButtonText = activeSection === NAV_MENU_OPTIONS.length ? "Save" : "Next"
    
    return (
        <div className='forms-section'>
            <header className="d-none d-lg-block">
                <div className="title-activate-section">
                    <h1>Results PDF</h1>

                    <div className="activate-container">
                        <span>Ready for client</span>

                        <label className="switch">
                            <span id="label-on" className={`insideLabel ${pdfConfig?.active ? "" : "hidden"}`}>Ready</span>
                            <span id="label-off" className={`insideLabel ${pdfConfig?.active ? "hidden" : ""}`}>Ready</span>
                            <input type="checkbox" checked={pdfConfig?.active} onChange={handleActivePdfConfig} />
                            <span className={`slider round ${pdfConfig?.active ? "isChecked" : ""}`}></span>
                        </label>
                    </div>
                </div>

                <span>
                    Please fill out the information required down below. 
                    This information will be displayed on the final Results PDF.
                </span>
            </header>

            <div className="inputs-area">
                {activeSection > 1 && <h3>{NUMBER_OF_INPUTS[activeSection]?.title}</h3>}

                {NUMBER_OF_INPUTS[activeSection]?.extraOptions && 
                    <ExtraOptions 
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        secondOption={NUMBER_OF_INPUTS[activeSection]?.extraOptions}
                    />
                }

                {activeSection === 5 && selectedOption === 2 &&
                    <CitiesForms 
                        pdfConfig={pdfConfig}
                        handleFile={handleFile}
                        updateInput={updateInput}
                        draft={draft}
                        imageTooltip={imageTooltip}
                    />
                }

                {activeSection === 6 && selectedOption === 2 &&
                    <WantsForms 
                        pdfConfig={pdfConfig}
                        draft={draft}
                        updateInput={updateInput}
                        imageTooltip={imageTooltip}
                        wantsMatrixReference={wantsMatrixReference}
                    />
                }
                    

                {selectedOption === 1 && <div className='forms'>
                    {PDF_FORM.slice(NUMBER_OF_INPUTS[activeSection]?.min, NUMBER_OF_INPUTS[activeSection]?.max).map(option => {
                        const InputType = option.typeOfInput

                        if (option.title) return <h5 key={option.id} className="cityTitle">{option.title}</h5>
                        
                        return (
                            <div key={option.id} className="inputContainer">
                                <div className="input-label-container">
                                    <label htmlFor={option.id}>{option.label}</label>
                                    {option.extraSpan && <span>{option.extraSpan}</span>}
                                    {option.icon && 
                                        <OverlayTrigger placement="top" overlay={imageTooltip(option.icon)}>
                                            <img src={iconBlue} alt="Prodensa Automation"/>
                                        </OverlayTrigger>
                                    }
                                </div>
                                
                                <InputType 
                                    value={pdfConfig?.pdfData?.[option.accessKey] ?? ""}
                                    onChange={(e) => updateInput("pdfData", option.accessKey, e.target.value)}
                                    maxLength={option.maxLength}
                                    placeholder={option.placeholder}
                                    id={option.id}
                                />
                            </div>
                        )
                    })}
                </div>}
            </div>

            <div className="actions-save-container">
                <button className="secondaryButton d-lg-none" onClick={savePdfConfig}>Save</button>

                <div className="actionsContainer">
                    <button className="cancelButton" onClick={() => handleSection("prev")}>Previous</button>
                    <button className="secondaryButton" onClick={() => handleSection("next")}>{actionsButtonText}</button>
                </div>
            </div>
        </div>
    )
}
