import Axios from "axios"
import Swal from "sweetalert2"
import { format } from "date-fns"
import { Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/auth-context";

import { sortTable } from "../../utils/sortTable.js";
import moreDots from "../../assets/images/moreDots.svg"
import sortDown from "../../assets/images/sortDown.svg"
import { customAlert } from "../../utils/customAlert.js";
import checkicon from "../../assets/images/check_green.svg"
import { getClientName } from "../../utils/getClientNameById.js"

function RecentReports(props) {
	const [recentReports, setRecentReports] = useState([])
	const [sortedRecentReports, setSortedRecentReports] = useState([])
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [mailError, setMailError] = useState(false)
	const [customMail, setCustomMail] = useState("")
	const [emailReportId, setEmailReportId] = useState(null)
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 

	// const [cookies] = useCookies(['user'])
	const [orderClient, setOrderClient] = useState("DSC")
	const [orderStatus, setOrderStatus] = useState("DSC")
	const [orderDate, setOrderDate] = useState("DSC")

	const [loading, setLoading] = useState(false)

	const showOptionsRef = useRef(null)

	const { user } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (loading && props.finishAnimation) {
			loadingAnimation()
		} else if (props.startAnimation) {
			Swal.close()
			// return
		}
	}, [loading, props.finishAnimation])

	useEffect(() => {
		if (user && !props.clientsOpened && !props.reportLogOpened && props.showClientId === 0) {
			// setLoading(true)
			if (props.getRecentReports) {
				setRecentReports(props.getRecentReports)
				props.setGetRecentReports(false)
				return
			}
			fetchRecentReports(user._id, user.role)
		}
	}, [user, props.clientsOpened, props.reportLogOpened, props.siteSelectionOpened, props.getRecentReports])

	useEffect(() => {
		handleTable("DSC", "date")
	}, [recentReports])

	useEffect(() => {
		handleTable("DSC", "date")

		document.body.addEventListener("click", closeRowOptions)

		return function cleanUp() {
			document.body.removeEventListener("click", closeRowOptions)
		}
	}, [])

	const loadingAnimation = () => {
		Swal.fire({
			title: 'Loading...',
			html: 'Please wait...',
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading()
			}
		});
	}
	
	const closeRowOptions = (e) => {
		const allShowOptions = Array.prototype.slice.call(document.getElementsByClassName("moreOptions"))
		if (showOptionsRef.current && showOptionsRef.current.contains(e.target)) {
			for (const element of allShowOptions) {
				if (!showOptionsRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			}
			allShowOptions.find(element => showOptionsRef.current.nextElementSibling.contains(element)).classList.toggle("show")
		} else {
			for (const element of allShowOptions) {
				element.classList.remove("show")
			}
		}
	}
	
	const handleShareReport = (reportId) => {
		setCustomMail("")
		setShowEmailModal(true)
		setEmailReportId(reportId)
	}

	const validateMail = (customMail) => {
		Swal.fire({
			title: 'Loading...',
			html: 'Please wait...',
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading()
			}
		});

		if (emailPattern.test(customMail)) {
			sendMail();
		} else {
			Swal.close()
			setMailError(true);
		}
	}

	const sendMail = () => {
		Axios.post(process.env.REACT_APP_GENERATE_MAGIC_LINK + emailReportId, { email: customMail }).then(res => {
			Swal.close();
			if (res.data.message === "Mail sent successfully") {
				Swal.fire({
					position: "center",
					titleText: `Your mail has been sent.`,
					showConfirmButton: false,
					imageUrl: checkicon,
					imageWidth: 50,
					imageHeight: 50,
					timer: 2000,
					timerProgressBar: true,
					backdrop: 'rgba(0, 0, 0, 0)',
					customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
					}
				});
				setShowEmailModal(false);
			}
		}).catch(err => {
			console.log(err);
			Swal.fire({
				position: "center",
				titleText: `Failed to send mail.`,
				text: "Please enter a valid email address.",
				icon: "error",
				showConfirmButton: false,
				customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
				}
			});
		});
	}
	
	const handleOpenResults = async(reportId, typeOfView) => {
		loadingAnimation()
		Axios.get(process.env.REACT_APP_FIND_RESULTS + reportId).then(res => {
			Swal.close()
			if (res.data) {
				navigate(`/reportResults/${reportId}?typeOfResult=${typeOfView}`)
			} else {
				Swal.fire({
					icon: "error",
					title: "Results not found!",
					text: "Need to run the report first to create results.",
					showConfirmButton: false,
					allowEscapeKey: false,
					allowOutsideClick: false,
					timer: 2000,
					timerProgressBar: true,
				})
			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}
	
	const handleDuplicateReport = (reportId) => {
		setLoading(true)
		Axios.post(process.env.REACT_APP_DUPLICATE_REPORT, {
			reportId,
			userId: user._id
		}).then(res => {
			if (!res.data.data) {
				Swal.fire({
					icon: 'error',
					title: res.data.message,
					showConfirmButton: false,
					showCancelButton: false,
					timer: 2500,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading()
					},
					didDestroy: () => {
						fetchRecentReports(user._id, user.role)
					}
				})
				return
			}

			fetchRecentReports(user._id, user.role)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}

	const handleDeleteReport = (reportId) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "swal2-button--confirm",
				cancelButton: "swal2-button--cancel"
			},
			buttonsStyling: false
		});

		swalWithBootstrapButtons.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Delete report",
			cancelButtonText: "Cancel",
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				Axios.patch(process.env.REACT_APP_DELETE_REPORT + reportId).then(res => {
					if (res.data.data) {
						const pinnedReports = [...props.inProgressReports]
						const indexReport = pinnedReports.findIndex(report => report.draftID === reportId)
						if (indexReport !== -1) {
							Axios.delete(process.env.REACT_APP_DELETE_PINNED_REPORT + pinnedReports[indexReport]._id).then(res => {
								console.log("pin removed")
								Axios.post(process.env.REACT_APP_GET_PINNED_REPORT_BY_USER_ID, {
									userId: user._id,
								}).then(pinnedReports => {
									props.setInProgressReports(pinnedReports.data)
								}).catch(err => {
									console.log(err)
									customAlert(err.response?.data?.message || err.response?.statusText)
								})
							}).catch(err => {
								console.log(err)
								customAlert(err.response?.data?.message || err.response?.statusText)
							})
						}

						fetchRecentReports(user._id, user.role)
						swalWithBootstrapButtons.fire({
							title: "Deleted!",
							text: "Your report has been deleted.",
							icon: "success",
							timer: 2000,
							timerProgressBar: true,
						});
					} else {
						swalWithBootstrapButtons.fire({
							title: "Oops..",
							text: res.data.message,
							icon: "error",
							timer: 2000,
							timerProgressBar: true,
							didDestroy: () => {
								setLoading(true)
								fetchRecentReports(user._id, user.role)
							}
						});
					}
				}).catch(err => {
					console.log(err)
					customAlert(err.response?.data?.message || err.response?.statusText)
				})

			}
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		});
	}

	// utils
	const fetchRecentReports = (userId, role) => {
		Axios.post(process.env.REACT_APP_GET_RECENT_REPORTS + userId, { role })
			.then(reportsRes => {
				if (reportsRes.data) {
					setRecentReports(reportsRes.data)
				}
				setLoading(false)
			})
			.catch(err => {
				console.log(err)
				customAlert(err.response?.data?.message || err.response?.statusText)
			})
	}

	const handleTable = (order, type) => {
		if(recentReports.length > 0) {
			setSortedRecentReports(sortTable(recentReports, props.allClients, order, type))
			const resetOrders = { client: "DSC", status: "DSC", date: "DSC" };
			const oppositeOrder = { ASC: "DSC", DSC: "ASC" };
	
			const newOrders = { ...resetOrders, [type]: oppositeOrder[order] };
	
			setOrderClient(newOrders.client);
			setOrderStatus(newOrders.status);
			setOrderDate(newOrders.date);
		}
	}

	return (
		<table className="tableReport">
			<thead>
				<tr>
					<th>Folio</th>
					<th>Report name</th>
					<th>
						<div className="header-icon">
							Client
							<img className={`sort ${orderClient === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderClient, "client")} />
						</div>
					</th>
					<th>Type</th>
					<th>
						<div className="header-icon">
							Status
							<img className={`sort ${orderStatus === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderStatus, "status")} />
						</div>
					</th>
					<th>
						<div className="header-icon">
							Date modified
							<img className={`sort ${orderDate === "DSC" ? "DSC" : ""}`} src={sortDown} alt="ProdensaAutomation" onClick={() => handleTable(orderDate, "date")} />
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				{!loading && sortedRecentReports.map((report) => {
					return (
						<tr key={report._id}>
							<td style={{ opacity: 0.6 }}>{report.folio}</td>
							<td>{report.reportName}</td>
							<td style={{ fontWeight: 700 }}>{getClientName(report.siteSelectionReport.clientId, props.allClients)}</td>
							<td>{report.siteSelectionReport.type}</td>
							<td className={`${report.siteSelectionReport.status ? "finish" : "pending"}`}>{report.siteSelectionReport.percentage}%</td>
							<td className="dateAndMore">
								<span>{format(new Date(report.updatedAt), "dd MMM yyyy")}</span>
								<img ref={showOptionsRef} src={moreDots} onClick={(e) => showOptionsRef.current = e.target} alt="ProdensaAutomation" />
								<div className={`moreOptions`}>
									<span className="option" onClick={() => handleOpenResults(report._id, "preview")}>Preview report</span>
									<span className="option" onClick={() => handleOpenResults(report._id, "generate")}>View report</span>
									<span className="option" onClick={() => props.openDraft(report._id)}>Edit</span>
									<span className="option" onClick={() => handleDuplicateReport(report._id)}>Duplicate</span>
									<span className="option" onClick={() => handleShareReport(report._id)}>Share</span>
									<span className="option" onClick={() => handleDeleteReport(report._id)}>Delete</span>
								</div>
							</td>
						</tr>
					)
				})}
				<Modal className="mail-form-modal" centered show={showEmailModal} onHide={() => setShowEmailModal(false)}>  {/*Modal for adding custom mail */}
					<Modal.Header closeButton>
						<Modal.Title>Send to mail</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<input type="text" className={`mail-capture ${mailError ? "error" : ""}`} placeholder="Enter email address" onChange={(e) => setCustomMail(e.target.value)}></input>
						<p className={`error-msg ${mailError ? "error" : ""}`}>Please enter a valid input</p>
					</Modal.Body>
					<Modal.Footer>
						<button className="send-button" 
							onClick={() => validateMail(customMail)}
						>
							Send
						</button>
					</Modal.Footer>
				</Modal>
			</tbody>
		</table>
	)
}

export default RecentReports