export class DataVariables {
    public gasCost: Object
    public homicides: Object
    public carTheft: Object
    public homeTheft: Object
    public organizedCrimePresence: Object
    public privateSchool: Object
    public privateHospital: Object
    public retailChain: Object
    public manufacturingDensity: Object
    public manufacturingGrowth: Object
    public engineeringGraduates: Object
    public technicalStudies: Object
    public strikesHistory: Object
    public lawsuit: Object
    public electricityCost: Object
    public waterCost: Object = {
        costPerCubicMeter: "",
    }
    public landCost: Object = {
        lowCost: "",
        highCost: "",
    }
    public turnoverRate: Object = {
        avgMonthlyTOR: "",
    }
    public absenteeism: Object = {
        absenteeismRate: "",
    }
    public governmentIncentives: Object = {
        incentives: "",
        description: "",
        points: "",
    }
    public availabilityOfPotentialLand: Object = {
        numberOfPotencialBuildings: "",
    }
    public costOfLiving: Object = {
        costOfHousing: "",
        costOfGas: "",
        costOfUtilities: "",
        costOfFood: "",
    }
    public unionEnvironment: Object = {
        total: 0,
        unionProBusinessCulture: "",
        radicalTakeoverRisks: "",
        laborKnowledgeLaborLaw: "",
        employersLeadershipPerception: "",
        productivityOrientedCulture: "",
        HRExecutivesFaceChallenges: "",
        managementOrientation: "",
        proBusinessSocialEnvironment: "",
        commonUnions: "",
    }
    public connectivityTravel: Object = {
        points: "",
        information: "",
        airport: "",
        milesToAirport: "",
    }
    public governmentLandscape: Object = {
        points: "",
        yearStateElection: "",
        partyPower: "",
        partyElected: "",
        federalGovernment: "",
    }
    public distanceToClient: Object[] = []
    public distanceToSeaport: Object[] = []
    public distanceToSupplier: Object[] = []
}
