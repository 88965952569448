import "../../assets/css/PDF/pdf.css"

import html2pdf from "html2pdf.js"

import { pdfdynamic } from "./pdfDynamic.js"

import frontPageImage from "../../assets/pdfImages/city.png"
import image1 from "../../assets/pdfImages/object-image.png"
import clientImage1 from "../../assets/pdfImages/client.png"
import assumptionImage1 from "../../assets/pdfImages/assumption.png"
import assumptionImage2 from "../../assets/pdfImages/assumtion-main.png"
import mustImage from "../../assets/pdfImages/musts.png"
import mustImage2 from "../../assets/pdfImages/must-image.png"
import wantsImage1 from "../../assets/pdfImages/wants.png"
import wantsImage2 from "../../assets/pdfImages/want-main.png"
import summaryImage from "../../assets/pdfImages/summary.png"
import mapImage from "../../assets/pdfImages/mapa-default.png"
import sourcesImage from "../../assets/pdfImages/sources.png"
import social1 from "../../assets/pdfImages/social1.png"
import social2 from "../../assets/pdfImages/social2.png"
import social3 from "../../assets/pdfImages/social3.png"
import social4 from "../../assets/pdfImages/social4.png"
import logoFramePage from "../../assets/pdfImages/logo_pdf.png"

const pdfTemplate = `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">

        <title>Pdf Work</title>
    </head>

    <body class="bg-[#F5F6F7]">
        <section id="html-pdf-template" class="pdf-area mx-auto w-[793.7007874px] bg-[#F5F6F7]">
        <div
            class="h-[1122.519685px] flex items-center justify-end relative w-full"
        >
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div class="gap-3 flex flex-col w-[70%]">
                <img src=${frontPageImage} alt="Prodensa Automation" />
                <h1
                    class="text-[60px] fontlight text-[#47A0FF] font-light"
                >
                    City and Site <br />
                    Selection Study
                </h1>
                <p
                    class="text-[20px] fontlight text-[#838384] font-light mt-4 pl-4 border-l-[1px] clientName border-l-[#838384] fix-p"
                >
                    Poclain Hydrautics
                </p>
            </div>
        </div>

        <div
            class="h-[1122.519685px] p-10 pt-[140px] pb-[90px] grid grid-cols-2 relative w-full"
        >
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[80px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 02</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="z-10 h-full py-10 pl-16 gap-3 flex flex-col relative">
                <span
                    class="bg-[#000] h-[1px] w-[70%] right-10 -bottom-10 absolute"
                ></span>
                <h1
                    class="text-[100px] uppercase relative z-10 font-thin text-[#171717]"
                >
                    Objective
                </h1>
                <p
                    class="text-[16px] fontlight text-[#838384] font-light mt-4 w-[250px] object-description"
                >
                    <span class="font-medium">Poclain Hydraulics</span> is currently in
                    the search process for the ideal location to expand its
                    manufacturing footprint in Mexico.
                </p>
                <div class="flex flex-col items-end justify-start w-[65%] mt-auto">
                    <p
                    class="text-[11px] text-[#000] leading-[20px] w-[100%] object-long-text pl-4 pr-5 border-l-[1px] border-l-[#838384] fix-p"
                    >
                    As part of this endeavor, Poclain Hydraulics requires to evaluated
                    the business environment in Mexico and has engaged the services of
                    Client, an advisory firm, to assess and gather information from
                    up-to- date operating costs, labor market conditions, industry
                    environment, security and government, infrastructure, and quality
                    of life in each city. 
                    </p>

                    <br />

                    <p class="text-[11px] text-[#000] object-short-text leading-[20px] w-[100%] pl-4 pr-5 fix-p">
                        Using its own City Evaluation Methodology, Client will assist and
                        provide guidance in order for Poclain Hydraulics to make the
                        best-educated decision for the project.
                    </p>
                </div>
            </div>

            <div class="w-[300px] absolute -z-[1px] h-[850px] pr-10 right-10 top-[150px]">
                <img
                    src=${image1}
                    class="w-full h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
        </div>

        <div class="h-[1122.519685px] p-10 pt-[140px] pb-0 relative w-full">
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 03</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="h-full pl-0 flex flex-col pt-4">
                <h1
                    class="text-[80px] w-[65%] pl-14 uppercase font-thin text-[#171717] leading-[80px] break-word"
                >
                    Why Prodensa
                </h1>

                <p
                    class="text-[#AFB0B1] w-[65%] pl-14 why-client-desc font-light text-[18px] w-[450px] mt-5 text-justify"
                >
                    Client is an advisory and project firm with more than 37 years of
                    experience supporting manufacturing start-up projects through
                    strategic advisory and support. in Mexico hands- on support.
                </p>

                <p
                    class="w-[60%] pl-14 mt-20 leading-[30px] text-[#494949]"
                >
                    <span class="pl-5 pb-10 border-l-[#838384] border-l-[1px] block text-[11px] leading-[15px] fix-p why-client-long-text"
                    >From shelter operations to advisory insights, market
                    intelligence, and execution, Client offers the right mix of team
                    experts, know- how, and local skills to provide solutions to all
                    the stages of your project.</span
                    >

                    <span class="pl-5 block text-[11px] leading-[15px] why-client-short-text">
                    Over 1,000 international corporations have trusted their Mexican
                    operations to our local experts.</span
                    >
                </p>

                <div
                    class="flex flex-col pr-10 items-center gap-1 absolute right-10 top-[400px]"
                >
                    <h1
                    class="text-[40px] exp-years text-[#47A0FF] font-light"
                    >
                        +37years
                    </h1>

                    <p class="text-[#AFB0B1] font-light text-[25px]">of experience</p>
                </div>

                <div
                    class="flex flex-col pr-10 items-center gap-1 absolute right-10 top-[550px]"
                >
                    <h1
                    class="text-[40px] client-helped text-[#47A0FF] font-light"
                    >
                    +1000
                    </h1>
                    <p class="text-[#AFB0B1] font-light text-[25px]">clients helped</p>
                </div>

                <div class="flex-1 flex items-end justify-end">
                    <img src=${clientImage1} class="w-full" alt="" />
                </div>
            </div>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[50%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 04</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>

            <div class="h-full flex pr-20 pt-32 flex-col pl-[100px]">
            <h1
                class="text-[40px] mb-10 font-extralight text-[#171717] leading-[80px]"
            >
                Table of <br />
                <span class="text-[100px] uppercase"> contents</span>
            </h1>
            <ul class="flex flex-col gap-8">
                <li class="flex items-center">
                <h1
                    class="text-[50px] leading-[50px] fontlight text-[#47A0FF] font-light"
                >
                    01
                </h1>
                <div class="flex-1 pl-3">
                    <div class="flex items-center justify-between">
                    <h1 class="text-[#000000] text-[18px] font-extralight">
                        Assumptions
                    </h1>
                    <p class="text-[#000000] text-[18px] font-extralight">05</p>
                    </div>
                    <div class="flex items-center gap-2">
                    <p class="text-[#C1C2C3] text-[15px] font-extralight">01</p>
                    <h1 class="text-[#101010] text-[15px] flex-1 font-extralight">
                        Assumptions
                    </h1>
                    <p class="text-[#C1C2C3] text-[15px] font-extralight">06</p>
                    </div>
                </div>
                </li>
                <li class="flex items-start">
                <h1 class="text-[50px] leading-[50px] text-[#47A0FF] font-light">
                    02
                </h1>
                <div class="flex-1 pl-3">
                    <div class="flex mb-2 items-center justify-between">
                        <h1 class="text-[#000000] text-[18px] font-extralight">
                            Musts
                        </h1>
                        <p class="text-[#000000] text-[18px] font-extralight must-front-page">07</p>
                    </div>
                    <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">01</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Musts
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight must-first-page">08</p>
                    </div>
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">02</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Selected cities
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight must-cities-start">09</p>
                    </div>
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">03</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Selected cities summary
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight must-cities-summary">12</p>
                    </div>
                    </div>
                </div>
                </li>
                <li class="flex items-start">
                <h1 class="text-[50px] leading-[50px] text-[#47A0FF] font-light">
                    03
                </h1>
                <div class="flex-1 pl-3">
                    <div class="flex mb-2 items-center justify-between">
                    <h1 class="text-[#000000] text-[18px] font-extralight">
                        Wants
                    </h1>
                    <p class="text-[#000000] text-[18px] font-extralight wants-front-page">13</p>
                    </div>
                    <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">01</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Wants
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight wants-first-page">14</p>
                    </div>
                    <div class="flex items-center gap-2 hidden operating-system-main-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">02</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Operating costs
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">17</p>
                    </div>

                    <div class="flex items-center gap-2 hidden competition-for-labor-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">03</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Competition for labor
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">18</p>
                    </div>

                    <div class="flex items-center gap-2 hidden labor-environment-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">04</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Labor environment
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">19</p>
                    </div>
                    <div class="flex items-center gap-2 hidden logistics-table-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">05</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Logistics
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">20</p>
                    </div>

                    <div class="flex items-center gap-2 hidden infrastructure-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">06</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Infrastructure
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">21</p>
                    </div>
                    <div class="flex items-center gap-2 hidden government-incentives-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">07</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Government incentives
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">22</p>
                    </div>
                    <div class="flex items-center gap-2 hidden security-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">08</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Security
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">23</p>
                    </div>
                    <div class="flex items-center gap-2 hidden quality-of-life-page wantIndexPage">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">09</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Quality of life
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">24</p>
                    </div>
                    </div>
                </div>
                </li>

                <li class="flex items-start">
                <h1 class="text-[50px] leading-[50px] text-[#47A0FF] font-light">
                    04
                </h1>
                <div class="flex-1 pl-3">
                    <div class="flex mb-2 items-center justify-between">
                    <h1 class="text-[#000000] text-[18px] font-extralight">
                        Summary
                    </h1>
                    <p class="text-[#000000] text-[18px] font-extralight summary-front-page">25</p>
                    </div>
                    <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">01</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        City and Site Selection Study Results
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight cities-results-page">26</p>
                    </div>
                    </div>
                </div>
                </li>

                <li class="flex items-start">
                <h1 class="text-[50px] leading-[50px] text-[#47A0FF] font-light">
                    05
                </h1>
                <div class="flex-1 pl-3">
                    <div class="flex mb-2 items-center justify-between">
                    <h1 class="text-[#000000] text-[18px] font-extralight">
                        Sources
                    </h1>
                    <p class="text-[#000000] text-[18px] font-extralight sources-front-page">28</p>
                    </div>
                    <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2">
                        <p class="text-[#C1C2C3] text-[15px] font-extralight">01</p>
                        <h1
                        class="text-[#101010] text-[15px] flex-1 font-extralight"
                        >
                        Sources
                        </h1>
                        <p class="text-[#C1C2C3] text-[15px] font-extralight sources-first-page">29</p>
                    </div>
                    </div>
                </div>
                </li>
            </ul>
            </div>
        </div>

        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[50%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 05</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>

            <img src=${assumptionImage1} class="w-[50%]" alt="Prodensa Automation" />
            <h1 class="text-[#000000] uppercase text-[100px] font-extralight mt-20">
            assumptions
            </h1>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[95px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 06</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>

                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="newAssumptionsPage">
                <div class="assumptions-header">
                    <div class="header-title">
                        <h1 class="text-[40px] uppercase font-light text-[#3D9FFF]">
                            Assumptions
                        </h1>

                        <p
                            class="text-[#AFB0B1] assumptions-desc font-light text-[18px] mt-2 text-justify"
                        >
                            In our City and Site Selection Study, the "assumptions" section
                            includes a crucial list of data provided by the client, offering
                            valuable insights into their specific requirements operational
                            needs.
                        </p>
                    </div>

                    <div>
                        <p
                            class="text-justify text-[#494949]"
                        >
                            <span class="block assumptions-primary text-[10px] leading-[20px] mb-2"
                            >This section typically includes comprehensive information about
                            the client's organization, such as the desired size of the
                            facility needed for their manufacturing operations. Additionally,
                            it involves data regarding the inbound and outbound freight for
                            raw materials and finished products, along with their respective
                            destinations.
                            </span>
        
                            <span class="block assumptions-secondary text-[10px] leading-[20px]">
                            The assumptions section also addresses the client's utilities
                            usage, providing details about the expected consumption of
                            resources like electricity, water, and other necessary utilities.
                            Moreover, a manning table is included, which presents a breakdown
                            of various job positions required for the manufacturing facility,
                            along with the corresponding quantities of personnel needed in
                            each position. This information enables Client to accurately quote
                            salaries and estimate the fully burden labor cost for the client's
                            relocation project.</span
                            >
                        </p>
                    </div>
                </div>

                <img
                    src=${assumptionImage2}
                    class="assumptionImage"
                    alt="Prodensa Automation"
                />

                <div class="assumptionTable1">
                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Assumptions</p>
                        </li>

                        <li class="grid grid-cols-2 py-2 px-2 border-bottom-line">
                            <p class="text-[#191819] font-normal text-[10px] fix-p-2">
                                Locations evaluated
                            </p>

                            <p class="text-[#191819] cities-evaluated font-extralight text-[10px] fix-p-2">
                            </p>
                        </li>
                    </ul>

                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Building</p>
                        </li>
                        
                        <div class="building-assuption-area"></div>
                    </ul>

                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Utilities</p>
                        </li>

                        <div class="utilities-area"></div>
                    </ul>
                </div>
            </div>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[95px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 06</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>

                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="newAssumptionsPage2">
                <div class="assumptionTable1">
                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">
                                Environmental Impact & Certifications
                            </p>
                        </li>

                        <div class="environmental-impacts"></div>
                    </ul>

                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Others</p>
                        </li>

                        <li class="grid grid-cols-2 py-2 px-2 border-bottom-line col-gap-4">
                            <p class="text-[#191819] font-normal text-[12px] fix-p-2">
                                Exchange Rate
                            </p>

                            <p class="text-[#191819] exchange-rate font-extralight text-[10px] fix-p-2"></p>
                        </li>
                    </ul>

                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Logistics</p>
                        </li>

                        <div class="logistics-assumption"></div>
                    </ul>
                </div>
            </div>
        </div>

        <div class="extra-logistics-pages"></div>

        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[50%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination must-front-page-link">Page 07</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>
            <img src=${mustImage} class="w-[50%]" alt="Prodensa Automation" />
            <h1 class="text-[#000000] uppercase text-[100px] font-extralight mt-20">
            musts
            </h1>
        </div>

        <div class="h-[1122.519685px] relative w-full grid grid-cols-2 gap-5">
            <span
            class="bg-[#000] h-[1px] w-[50%] left-24 absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 08</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="py-[100px] pl-24">
                <img src=${mustImage2} class="w-full h-[950px]" alt="Prodensa Automation" />
            </div>

            <div class="flex flex-col py-[100px] pt-20 h-full pl-5 pr-20 flex-1">
                <h1 class="text-[50px] uppercase font-light text-[#3D9FFF]">Musts</h1>
                <p
                    class="text-[#AFB0B1] font-light text-[16px] must-desc mt-4 text-justify"
                >
                    In our City and Site Selection Study, the "musts" section refers to
                    a crucial component that outlines the essential criteria and filters
                    necessary for the client to evaluate potential cities for their
                    manufacturing relocation project in Mexico.
                </p>

                <p
                    class="text-justify mt-5 text-[#494949]"
                >
                    <span class="block must-primary text-[11px] text-start leading-[15px] pb-2 border-l-[#838384] border-l-[1px] pl-4"
                    >These musts incorporate specific characteristics and factors that
                    are indispensable for the client's operations in their new
                    location.
                    </span>
                </p>

                <span class="block must-secondary text-[11px] leading-[15px] pl-4">
                    These criteria typically include the city's population, economic
                    activity, proximity to borders and ports, and the overall
                    manufacturing environment. By defining these musts, Client assists
                    companies in identifying suitable locations to further analyze
                    that align with their operational requirements, ensuring a
                    comprehensive and informed decision-making process for their
                    manufacturing footprint relocation.
                </span>

                <div class="flex flex-col justify-between pb-10 mt-20">
                    <div class="must-table-header">
                        <p class="text-[18px] text-[#239DFF] fix-p">Variable</p>
                        <p class="text-[18px] text-[#239DFF] fix-p">Must</p>
                    </div>
                    <div class="must-filter"></div>
                </div>
            </div>
        </div>

        <div class="cities-pages"></div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[50%] left-24 absolute bottom-12"
            ></span>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination must-cities-summary-link">Page 10</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1
            class="text-[40px] pt-32 mb-5 uppercase font-light leading-[70px] text-[#3D9FFF] pl-24"
            >
                Selected cities summary
            </h1>

            <ul class="pl-24 pr-20">
                <li
                    class="bg-[#DDEDF8] py-1 px-2 grid grid-cols-[1fr_1fr_1fr_1fr_1fr] items-center col-gap-1"
                >
                    <p class="text-[10px] text-[#239DFF] fix-p-2">Selected City</p>
                    <p class="text-[10px] text-[#239DFF] fix-p-2">Population</p>
                    <p class="text-[10px] text-[#239DFF] fix-p-2">Economic Active Population</p>
                    <p class="text-[10px] text-[#239DFF] fix-p-2">Closes Border Time</p>
                    <p class="text-[10px] text-[#239DFF] fix-p-2">Distance To Border</p>
                </li>

                <div class="city-area-must"></div>
            </ul>
        </div>

        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[50%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination wants-front-page-link">Page 11</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>

            <img src=${wantsImage1} class="w-[50%]" alt="Prodensa Automation" />
            <h1 class="text-[#000000] uppercase text-[100px] font-extralight mt-20">
            wants
            </h1>
        </div>

        <div class="h-[1122.519685px] relative w-full grid grid-cols-2 row-gap-2">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>

                <h1 class="-rotate-90 my-5 pagination">Page 12</h1>

                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="col-span-2 pl-28 pr-20 pt-32">
                <img src=${wantsImage2} class="col-span-2" alt="Prodensa Automation" />
            </div>

            <div class="pl-28 pr-10">
                <h1 class="text-[30px] uppercase font-light text-[#3D9FFF]">wants</h1>

                <p
                    class="text-[#AFB0B1] want-desc font-light text-[16px] mt-4 text-justify"
                >
                    In our City and Site Selection Study, the "assumptions" section
                    includes a crucial list of data provided by the client, offering
                    valuable insights into their specific requirements operational
                    needs.
                </p>
            </div>

            <div class="pr-20">
                <p
                    class="text-justify text-[#494949]"
                >
                    <span class="block want-primary leading-[20px] text-[11px] pb-2 border-l-[#838384] border-l-[1px] pl-4 fix-p-2">
                        These “wants” represent specific characteristics or factors that
                        are important to the client’s decision-making process and play a
                        crucial role in evaluating and comparing different cities. Each
                        “wants” is associated with a particular variable or metric, which
                        the client gives a weight for each “wants” within each section in
                        the form of “points” and a weight for each section in general in
                        the form of a percentage, for the overall scoring system in the
                        form. And based on the results obtained for each variable in each
                        city, a score is assigned to facilitate the comparison
                    </span>

                    <span class="block want-secondary leading-[20px] text-[11px] pl-4 pb-2">
                        The variables included in the “wants” section cover a wide range
                        of factors that are relevant to the client’s manufacturing
                        operations. For example, operational costs are often considered,
                        which include factors such as labor costs, real estate expenses,
                        and utility expenses. The security index, another common variable,
                        reflects the overall safety and crime rates in each city. The
                        educational level measures the availability of skilled labor and
                        the quality of educational institutions in the area.
                    </span>

                    <span class="block want-tertiary leading-[20px] text-[11px] pl-4">
                        Other variables may include infrastructure availability, distance
                        to clients and suppliers, tax and government incentives,
                        manufacturing environment, and proximity to key markets.
                    </span>
                </p>
            </div>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 13</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1
            class="text-[30px] pl-24 pt-32 mb-10 uppercase font-light leading-[70px] text-[#3D9FFF]"
            >
                Wants matrix
            </h1>

            <ul class="pl-24 pr-20">
                <li
                    class="bg-[#DDEDF8] py-1 px-2 grid grid-cols-[.3fr_.5fr_.2fr_1fr] col-gap-1"
                >
                    <p class="text-[15px] text-[#239DFF] fix-p">Ref</p>
                    <p class="text-[15px] text-[#239DFF] fix-p">Criterion</p>
                    <p class="text-[15px] text-[#239DFF] fix-p">Weight</p>
                    <p class="text-[15px] text-[#239DFF] fix-p">Definition</p>
                </li>

                <div class="want-matrix-container"></div>

                <li
                    class="bg-[#DDEDF8] py-1 px-2 grid grid-cols-[.3fr_.5fr_.2fr_1fr] col-gap-1"
                >
                    <p class="text-[15px] text-[#239DFF] fix-p"></p>
                    <p class="text-[15px] text-[#239DFF] fix-p">Points</p>
                    <p class="text-[15px] text-[#239DFF] fix-p text-center pr-4 total-wants-points"></p>
                </li>
            </ul>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 14</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>
                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1
            class="text-[30px] pl-24 pt-20 uppercase font-light leading-[50px] text-[#3D9FFF]"
            >
                evaluating system
            </h1>

            <p
            class="text-[#AFB0B1] pl-24 pr-20 font-light text-[18px] mt-3 text-justify"
            >
                In the Client Methodology for City and Site Selection Studies, each
                category includes a series of variables that are evaluated
                individually. For example:
            </p>

            <div class="mt-3 pl-24 pr-20">
                <h1>Category 3) Labor Environment includes:</h1>

                <ul class="mt-3 grid grid-cols-4 gap-2">
                    <li
                    class="flex items-center justify-center bg-[#E2EDF8] h-[60px] p-2"
                    >
                        <p class="text-[#54A1FF] text-[12px] fix-p">&#x2022; Absenteeism</p>
                    </li>

                    <li
                    class="flex items-center justify-center bg-[#E2EDF8] h-[60px]"
                    >
                        <p class="text-[#54A1FF] text-[12px] fix-p">&#x2022; Union Environment</p>
                    </li>

                    <li
                    class="flex items-center justify-center bg-[#E2EDF8] h-[60px]"
                    >
                        <p class="text-[#54A1FF] text-[12px] fix-p">&#x2022; Strike History</p>
                    </li>

                    <li
                    class="flex items-center justify-center bg-[#E2EDF8] h-[60px]"
                    >
                        <p class="text-[#54A1FF] text-[12px] fix-p">&#x2022; Unnotified Collective <br/> &ensp; Conflicts by State</p>
                    </li>
                </ul>

                <p
                    class="text-[12px] flex-1 text-justify mt-2 leading-[20px] text-[#494949]"
                >
                    Each of the variables is assigned a score based on the information
                    Client obtains from each city. This score rates the city on a scale
                    of 0 to 3, with 3 points always representing the "best option."
                </p>

                <h1
                    class="text-[22px] mt-4 uppercase font-light leading-[70px] text-[#3D9FFF]"
                >
                    Scores for each variable
                </h1>

                <p
                    class="text-[12px] leading-[20px] text-[#494949]"
                >
                    For each variable, there will always be a "3" rating, which is the
                    best possible rating. This rating depends on the type of variable
                    and the information obtained, as well as what data range is most
                    suitable when considering a location.

                    <br />
                    <br />

                    There are various scenarios in which a city can receive a "3" rating
                    within a variable:
                </p>

                <div class="mt-3">
                    <ul class="mt-3 grid grid-cols-3 col-gap-2">
                        <li class="text-[#54A1FF] p-4 bg-[#DCEDF8]">
                            <h1 class="mb-2 text-[12px] fix-p">Lower Value</h1>

                            <p class="text-[#54A1FF] text-[10px] fix-p leading-[20px]">
                                A "3" rating is given to the city with the lowest value
                                because a lower value is considered better. For example, costs
                                (electricity, payroll, etc.), turnover rate, absenteeism,
                                strike history, commute times, among others.
                            </p>
                        </li>

                        <li class="text-[#54A1FF] text-[12px] p-4 bg-[#DCEDF8]">
                            <h1 class="mb-2 text-[12px] fix-p">Higher Value</h1>

                            <p class="text-[#54A1FF] text-[10px] fix-p leading-[20px]">
                                A "3" rating is given to the city with the highest value
                                because a higher value is considered better. For example, the
                                number of graduates, land availability, government incentives,
                                etc.
                            </p>
                        </li>

                        <li class="text-[#54A1FF] text-[12px] p-4 bg-[#DCEDF8]">
                            <h1 class="mb-2 text-[12px] fix-p">Tie</h1>

                            <p class="text-[#54A1FF] text-[10px] fix-p leading-[20px]">
                                When two or more cities have the same result in their variable
                                information, they can receive the same score.
                            </p>
                        </li>
                    </ul>

                    <p
                    class="text-[12px] flex-1 text-justify mt-2 leading-[20px] text-[#494949]"
                    >
                        The rest of the cities are rated from 2 to 0, using criteria
                        defined by Client or the client, and these criteria may vary
                        depending on the type of information and category.
                    </p>
                </div>

                <h1
                    class="text-[22px] mt-4 uppercase font-light leading-[50px] text-[#3D9FFF]"
                >
                    Category scores
                </h1>

                <p
                    class="text-[12px] text-justify leading-[20px] text-[#494949]"
                >
                    Finally, to determine the total score for each city in each
                    category, the client-defined weighting and the rating for each
                    variable are taken into account as follows:
                </p>

                <div class="mt-3">
                    <ul class="mt-3 gap-3">
                        <li
                            class="flex justify-center p-2 bg-[#DCEDF8]"
                        >
                            <p class="text-[#54A1FF] text-[16px] fix-p">(Variable Weight / 3 Possible Rating Points) x (Obtained Rating)</p>
                        </li>
                    </ul>

                    <p
                    class="text-[12px] text-justify mt-2 leading-[20px] text-[#494949]"
                    >
                        Each result is added to the results of other variables within each
                        category, and this provides the final score for the city within
                        that category.
                    </p>
                </div>
            </div>
        </div>

        <div class="wants-table-pages"></div>
        
        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[110px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination summary-front-page-link">Page 23</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>

            <img src=${summaryImage} class="w-[50%]" alt="Prodensa Automation" />
            <h1 class="text-[#000000] uppercase text-[100px] font-extralight mt-20">
            summary
            </h1>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 24</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>

                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="h-full pl-24 pr-20 pt-24 flex flex-col gap-4">
                <h1
                    class="text-[30px] summary-title uppercase font-light text-[#3D9FFF]"
                >
                    City and site selection study results
                </h1>

                <div class="flex gap-6">
                    <p
                        class="text-[#AFB0B1] summary-desc font-light text-[20px] text-justify pr-8 fix-p"
                    >
                        The purpose of this report is to provide a concise overview of the
                        findings and recommendations for the client considering relocation
                        of manufacturing footprint.
                    </p>

                    <p
                        class="text-[11px] summary-long text-justify leading-[20px] border-l-[#838384] border-l-[1px] pl-4 fix-p-2 flex-[700px]"
                    >
                        By examining the strengths, weaknesses, and suitability of each
                        city, this summary aims to assist the client in making an informed
                        and strategic decision regarding the optimal location for their
                        manufacturing operations.
                    </p>
                </div>

                <div>
                    <h3 class="uppercase text-[#239DFF]">Results</h3>

                    <div class="cities-cards"></div>
                </div>

                <div class="total-categories-table mt-5"></div>
            </div>
        </div>

        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 25</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>

                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1 class="text-[30px] pl-24 pt-24 uppercase font-light text-[#3D9FFF]">
                City and site selection study results
            </h1>

            <div class="pl-24 pr-20 mt-10">
                <img src=${mapImage} alt="Prodensa Automation" class="w-full object-cover" />
            </div>

            <div class="grid grid-cols-2 pl-24 pr-20 gap-5 mt-10">
                <div class="h-full flex flex-col">
                    <p
                    class="summary-cities-main text-[#AFB0B1] font-light text-[16px] border-l-[1px] border-l-[#000] text-justify pl-2 fix-p"
                    >
                        Based on the comprehensive evaluation of key criteria
                    </p>

                    <p
                    class="summary-cities-primary text-[10px] pl-2 flex-1 text-justify leading-[20px] text-[#494949]"
                    >
                        including operating costs, competition for labor, labor
                        environment, logistics, infrastructure, government incentives,
                        security, and quality of life, the City and Site Selection Report
                        concludes that Aguascalientes, Querétaro, and San Juan del Río
                        each possess unique strengths and considerations for the client’s
                        manufacturing footprint relocation. Querétaro emerged as the
                        frontrunner with a total score of 77.1 out of 100 points,
                        showcasing its competition for labor, security, infrestructure,
                        labor enviroment and goverment incentives.
                    </p>
                </div>

                <div>
                    <p
                    class="summary-cities-secondary text-[10px] flex-1 text-justify leading-[20px] text-[#494949]"
                    >
                        Aguascalientes followed with a score of 67.8, and displayed
                        notable attributes such as good operating cost, logistics low
                        cost, and a higher quality of life. San Juan del Rio with a score
                        of 54.8, benefits from government incentives and aceptable
                        security. Ultimately, the client’s decision should weigh the
                        specific importance they place on each criterion and align it with
                        their operational needs and long-term strategic objectives.
                    </p>
                </div>
            </div>
        </div>

        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination sources-front-page-link">Page 26</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
            <p class="text-[9px] text-[#66B5FE] font-light">
                City and site Selection Study
            </p>
            <p class="text-[9px] text-[#66B5FE] font-light">
                Poclain Hydraulics
            </p>
            <div class="logoFramePage">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>
            </header>
            <img src=${sourcesImage} class="w-[50%]" alt="Prodensa Automation" />
            <h1 class="text-[#000000] uppercase text-[100px] font-extralight mt-20">
            sources
            </h1>
        </div>
        <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 27</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1
            class="text-[30px] pl-24 pt-20 mb-10 uppercase leading-[70px] text-[#3D9FFF]"
            >
                Sources
            </h1>
            
            <ul class="pl-24 pr-20">
                <li class="bg-[#DDEDF8] py-2 px-2 grid grid-cols-2">
                    <p class="text-[12px] text-[#239DFF] fix-p">Variable</p>
                    <p class="text-[12px] text-[#239DFF] fix-p">Source</p>
                </li>

                <div class="sources-area"></div>
            </ul>
        </div>

        <div class="sources-extra-page"></div>

        <div
            class="h-[1122.519685px] relative w-full flex flex-col items-center justify-center pt-10"
        >
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 28</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
            </header>

            <div class="logoFramePageFinal">
                <img
                    src=${logoFramePage}
                    class="w-[100%] h-[100%] object-cover"
                    alt="Prodensa Automation"
                />
            </div>

            <div class="flex flex-col items-end absolute bottom-20 right-10">
                <h1 class="text-[10px] text-[#7B7B7C]">prodensa.com</h1>

                <h1 class="text-[10px] mt-1 text-[#7B7B7C]">consulting@prodensa.com</h1>

                <nav class="flex items-center translate-x-2 mt-4 gap-2">
                    <a href="#">
                    <img src=${social1} class="w-[30px]" alt="Prodensa Automation" />
                    </a>
                    <a href="#">
                    <img src=${social2} class="w-[30px]" alt="Prodensa Automation" />
                    </a>
                    <a href="#">
                    <img src=${social3} class="w-[30px]" alt="Prodensa Automation" />
                    </a>
                    <a href="#">
                    <img src=${social4} class="w-[30px]" alt="Prodensa Automation" />
                    </a>
                </nav>
            </div>
        </div>
        </section>
    </body>
</html>`

export async function createPDF(pdf, pdfConfig, borderDistances) {
    const dateNow = new Date()
    const formattedDate = formatDate(dateNow)
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(pdfTemplate, 'text/html');

    pdfdynamic.addingReport(doc, pdfConfig);
    pdfdynamic.addingObject(doc, pdfConfig);
    pdfdynamic.addingWhyClient(doc, pdf, pdfConfig);
    pdfdynamic.addingAssumptions(doc, pdf, pdfConfig);
    await pdfdynamic.addingMust(doc, pdf, pdfConfig, borderDistances);
    pdfdynamic.addingWant(doc, pdf, pdfConfig);
    pdfdynamic.addingWantTable(doc, pdf, pdfConfig);
    pdfdynamic.addingWantCitiesTable(doc, pdf, pdfConfig);
    pdfdynamic.addingSummary(doc, pdf, pdfConfig);
    pdfdynamic.addingSources(doc, pdf);
    pdfdynamic.updateDate(doc, formattedDate)
    pdfdynamic.updatePagination(doc)

    const element = doc.querySelector(".pdf-area");
    html2pdf(element);
}

function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "2-digit" }

    return new Intl.DateTimeFormat("en-US", options).format(date).replace(",", "")
}

// second paragraph for city in case of change
{/* <span class="block must-secondary text-[11px] leading-[15px] pl-4">
                    These criteria typically include the city's population, economic
                    activity, proximity to borders and ports, and the overall
                    manufacturing environment. By defining these musts, Client assists
                    companies in identifying suitable locations to further analyze
                    that align with their operational requirements, ensuring a
                    comprehensive and informed decision-making process for their
                    manufacturing footprint relocation.
                    </span> */}