import { Container, Row, Col } from "react-bootstrap";
import infodanger from "../../../assets/images/infoDanger.svg";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../utils/formatNumber";



const WaterCost = ({ details, city_names, filtered, reportID, assumptions }) => {

    const [costperM3, setCostperM3] = useState([]);
    const [loading, setLoading] = useState(true);
    //Calculate the water monthly cost 
    const costperMonth = details.results.map((city) => {
        const monthly = city.valueResult.totalCost / 12;
        return monthly
    });

    useEffect(() => {
        //Calculate the cost per m3
        const costM3 = costperMonth.map((city) => {
            const m3 = city / assumptions.utilities.water;
            const formattedNumber = formatNumber(m3);
            return formattedNumber;
        })
        setCostperM3(costM3);
        setLoading(false);
    }, []);

    return (
        <Container className="generic-wrapper" >
            {loading ? (
                <div>Loading</div>
            ) : assumptions && costperM3 ? (
                <>
                    <div className="water-header">
                        <h4 className="table-title">Cubic M3 per month</h4>
                        <h4 className={`${assumptions.utilities.water === "" ? "hide" : ""}`} style={{ border: '2px solid rgba(0, 0, 0, 0.4)', padding: '10px', borderRadius: '20px', marginBottom: "15px" }}>{assumptions.utilities.water}</h4>
                    </div>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Concept</h5>
                            <h5 className="side-text">Average cost per m3/month(USD)</h5>
                            <h5 className="side-text">Monthly</h5>
                            <h5 className="side-text">Annual</h5>
                            <h5 className="side-text">Difference from lower value(%)</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-${index}`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityData = details.results.find(cityFind => cityFind.city === city);
                                    if (cityData) {
                                        let annualCost = cityData.valueResult.totalCost;
                                        if (annualCost !== null) {
                                            let monthlyCost = annualCost / 12;
                                            let costPerM3 = monthlyCost / assumptions.utilities.water;
                                            textToShow = formatNumber(costPerM3);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`costPerCubicWater-${city}-${index}`}>{textToShow}</h5>
                                    )
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityData = details.results.find(cityFind => cityFind.city === city);
                                    if (cityData) {
                                        let annualCost = cityData.valueResult.totalCost;
                                        if (annualCost !== null) {
                                            let monthlyCost = annualCost / 12;
                                            textToShow = formatNumber(monthlyCost);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`monthlyCost-${city}-${index}`}>{textToShow}</h5>
                                    )
                                }
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityData = details.results.find(cityFind => cityFind.city === city);
                                    if (cityData) {
                                        let annualCost = cityData.valueResult.totalCost;
                                        if (annualCost !== null) {
                                            textToShow = formatNumber(annualCost);
                                        }
                                    }
                                    return (filtered.includes(index) &&
                                        <h5 className="number" key={`annualCost-${city}-${index}`}>{textToShow}</h5>
                                    )
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    const cityToShow = details.results.find(cityFind => city === cityFind.city)
                                    if (cityToShow) {
                                        if (cityToShow.valueResult.bestOption) {
                                            textToShow = "Lowest Value"
                                        } else {
                                            textToShow = `${cityToShow.valueResult.difference.toFixed(2)}%`
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number red" key={`difference-${city}-${index}`}>{textToShow}</h5>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-${index}`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Average cost per m3/month(USD)</td>
                                    {costperM3.map((cost, index) =>
                                        filtered.includes(index) &&
                                        <td key={`costPerCubicWater-${index}`}>{cost}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Monthly</td>
                                    {costperMonth.map((cost, index) =>
                                        filtered.includes(index) &&
                                        <td key={`monthlyCost-${index}`}>{formatNumber(cost)}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Annual</td>
                                    {details.results.map((city, index) =>
                                        filtered.includes(index) &&
                                        <td key={`annualCost-${index}`}>{formatNumber(city.valueResult.totalCost)}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Difference from lower value(%)</td>
                                    {details.results.map((city, index) =>
                                        filtered.includes(index) &&
                                        <td key={`difference-${index}`} className="red">{city.valueResult.bestOption ? "Lowest Value" : `${city.valueResult.difference.toFixed(2)}%`}</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            )}
        </Container>
    );
}

export default WaterCost;