import { Row, Col, Container } from "react-bootstrap";
import infodanger from "../assets/images/infoDanger.svg";
import "../assets/css/payrolldetails/payrolldetails.css";
import { useEffect, useState } from "react";
import { formatNumber } from "../utils/formatNumber.js"

const PayrollDetails = ({ setShowExtraDetails, showExtraDetails, city_names, totalPayroll }) => {
  //Contains a static message
  const payroll_warning =
    "(1) The payroll cost calculation may vary if the Mexican regulations change on regards to Taxes, Payroll calculation or any other modifications to IMSS, INFONAVIT and any other Government Authority related.";

  const propertyNames = [
    'Seniority',
    'MonthlySalary',
    'PayrollTax',
    'Retirement',
    'Housing',
    'Fix',
    'Surplus',
    'Hospitals',
    'Disability',
    'WorkRiskInsurance',
    'ForcedRetirement',
    'TotalSocial Security',
    'ChristmasBonus',
    'VacationBonus',
    'SavingFund',
    'FoodCoupons',
    'MedicalAndLifeInsurance',
    'Gasoline',
    'PerformanceBonus',
    'UnionFee',
    'AttendanceAndPunctualityBonus',
    'SchoolBonus',
    'ProgressiveBonus',
    'Overtime',
    'TotalMonthlyPerPerson'
  ];

  //Control variable for view in pesos or dollars
  const [activeCost, setActiveCost] = useState("pesos");
  //Toggle for cost selection menu
  const [costOption, setCostOption] = useState(false);
  //Remove METROPLEX Text from city names
  //Default selected city to the first index
  const [selectedCity, setSelectedCity] = useState(0);
  //Toggle for city selection menu
  const [cityMenu, setCityMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstObject, setFirstObject] = useState(null);
  const [cityRender, setCityRender] = useState(null);
  const exchange_rate = 17.5;


  useEffect(() => {
    if(totalPayroll !== "There is no payroll"){
        let keysOfFirstObject = Object.keys(totalPayroll[0]);
        let firstKey = keysOfFirstObject[0];
        let valueOfFirstKey = totalPayroll[0][firstKey];
        setFirstObject(valueOfFirstKey);
        setLoading(false)
      }
  }, [totalPayroll]);

  useEffect(() => {
    if (totalPayroll !== "There is no payroll") {
      let cityToRender = totalPayroll.find(city => Object.keys(city)[0] === city_names[selectedCity].replace(" Metroplex", "") || Object.keys(city)[0] === city_names[selectedCity]);
      if (cityToRender) {
        setCityRender(cityToRender[city_names[selectedCity]] ? cityToRender[city_names[selectedCity]] : cityToRender[city_names[selectedCity].replace(" Metroplex", "")]);
      } else {
        setCityRender(null);
      }
    }
  }, [selectedCity,totalPayroll])



  return (
    <section className={`payroll-wrapper ${showExtraDetails ? "show" : ""}`}>
      {loading ? (
        <div className="loader">Is loading</div>
      ) : (totalPayroll !== "There is no payroll" && firstObject ? (
        <Container>
          <div className="head-section">
            <div className="title">
              <button className="back" onClick={() => setShowExtraDetails(false)}></button>
              <h4>Total payroll cost</h4>
              {/*Buttons for selecting cost type */}
              <button
                className="change-sub"
                onClick={() =>
                  costOption ? setCostOption(false) : setCostOption(true)
                }
              >
                <span>
                  Monthly cost in {`${activeCost === "pesos" ? "MXP" : " USD"}`}
                </span>
              </button>
              {/*menu for selection of cost type */}
              <div className={`option-selector ${costOption ? "show" : ""}`}>
                <button
                  className={`option ${activeCost === "pesos" ? "selected" : ""}`}
                  onClick={() => {
                    setActiveCost("pesos");
                    setCostOption(false);
                  }}
                >
                  Monthly cost in MXP
                </button>
                <button
                  className={`option ${activeCost === "usd" ? "selected" : ""}`}
                  onClick={() => {
                    setActiveCost("usd");
                    setCostOption(false);
                  }}
                >
                  Monthly cost in USD
                </button>
              </div>
            </div>
            {/*Buttons for selection of city */}
            <button className="change-sub" onClick={() => { cityMenu ? setCityMenu(false) : setCityMenu(true) }}>
              <span>{city_names[selectedCity]}</span>
            </button>
            {/*City selection menu */}
            <div className={`city-menu ${cityMenu ? "show" : ""}`}>
              {city_names.map((city, index) =>
                <button
                  key={`city-${index}`}
                  className={`option ${selectedCity === index ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedCity(city_names.indexOf(city));
                    setCityMenu(false);
                  }}
                >
                  {city}
                </button>
              )}
            </div>
          </div>
          {cityRender ? (
            <Row className="no-gutters">
              <Col lg={3} className="fixed-container">
                <h5 className="first-spacer">Position</h5>
                {firstObject.map((position, index) =>
                  <div className="position-col" key={`object-${index}`}>
                    <h5 style={{ opacity: '0.5' }}>{index + 1}</h5>
                    <h5 className="position-text" style={{ fontWeight: '700' }}>{position.Position}</h5>
                  </div>
                )}
              </Col>
              <Col lg={9} className="table-container">
                <div className="table-heading">
                  <div className="gray-cont">
                    <h5>Seniority</h5><h5 className="gray-txt">(years)</h5>
                  </div>
                  <h5 className="table-title">Monthly Salary</h5>
                  <h5 className="table-title">2% payroll tax</h5>
                  <h5 className="table-title">2% retirement</h5>
                  <h5 className="table-title">5% housing </h5>
                  <h5 className="table-title">Fixed</h5>
                  <h5 className="table-title">Surplus</h5>
                  <h5 className="table-title">Hospitals</h5>
                  <h5 className="table-title">Disability</h5>
                  <h5 className="table-title">Work risk insurance 0.78897</h5>
                  <h5 className="table-title">Forced retirement</h5>
                  <h5 className="table-title blue">Total social security</h5>
                  <div className="gray-cont">
                    <h5>Christmas Bonus</h5><h5 className="gray-txt">(17d/30d)</h5>
                  </div>
                  <div className="gray-cont">
                    <h5>Vacation bonus</h5><h5 className="gray-txt">(30%/35%)</h5>
                  </div>
                  <div className="gray-cont">
                    <h5>Saving funds</h5><h5 className="gray-txt">(8%/13%)</h5>
                  </div>
                  <div className="gray-cont">
                    <h5>Food coupons</h5><h5 className="gray-txt">(8%/12%)</h5>
                  </div>
                  <h5 className="table-title">Medical and Life Insurance</h5>
                  <h5 className="table-title">Gasoline</h5>
                  <h5 className="table-title">Performance Bonus</h5>
                  <h5 className="table-title">Union Fee</h5>
                  <h5 className="table-title">Attendance and Punctuality Bonus</h5>
                  <h5 className="table-title">School Bonus</h5>
                  <h5 className="table-title">Progressive Bonus</h5>
                  <h5 className="table-title">Overtime</h5>
                  <h5 className="table-title blue">{`Total monthly/person in ${activeCost === "pesos" ? "MXP" : "USD"}`}</h5>
                </div>
                {cityRender.map((position, index) =>
                  <div className="table-row" key={`currency-${index}`}>
                    {propertyNames.map((propertyName) => (
                      <h5 className="data" key={propertyName}>
                        {activeCost === "pesos" ?
                          formatNumber(position[propertyName])
                          : propertyName === "Seniority" ? formatNumber(position[propertyName]) : formatNumber((position[propertyName] / exchange_rate))}
                      </h5>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <div className="no-record-wrapper">
              <h3 className="no-record-title">This city has no records, please revise your spreadsheet.</h3>
              <img src={infodanger} width="50px" height="50px" />
            </div>
          )}
          <p>{payroll_warning}</p>
        </Container>
      ) : (
        <div className="no-record-wrapper">
              <h3 className="no-record-title">There is no spreadsheet available with payroll cost information.</h3>
              <img src={infodanger} width="50px" height="50px" />
            </div>
      ))}
    </section>
  );
}
export default PayrollDetails;
