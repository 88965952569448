import React from 'react';

const TokenExpiredPage = () => {
    return (
        <div>
            <h1>Token has expired</h1>
            <p>Please request a new token or contact support.</p>
        </div>
    );
};

export default TokenExpiredPage;
