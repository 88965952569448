import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState, Fragment } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const InboundOutBound = ({ details, city_names, filtered, musts, assumptions, variableCosts, reportID }) => {

    const [loading, setLoading] = useState(true);
    const [inboundGroups, setInboundGroups] = useState(null);
    const [outboundGroups, setOutboundGroups] = useState(null);


    useEffect(() => {
        if (musts && assumptions && variableCosts) {
            let inboundGroupsFromFirst = assumptions.logistics.logisticsInbound.inboundGroups;
            setInboundGroups(inboundGroupsFromFirst);
            let outboundGroupsFromFirst = assumptions.logistics.logisticsOutbound.destinations;
            setOutboundGroups(outboundGroupsFromFirst)
            setLoading(false);
        }
    }, [])


    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <h1>Loading ...</h1>
            ) : (musts && assumptions && variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <div className="first-spacer"></div>

                            <h5 className="side-text">Consolidated inbound & outbound costs</h5>
                            <h5 className="side-text">Difference from lowest value</h5>

                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`header-${city}`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    //Find the result matching the city name 
                                    let resultCity = details.results.find(result => result.city === city);
                                    let textToShow = "N/A";
                                    if (resultCity && resultCity.valueResult.totalCost !== null) {
                                        textToShow = "$ " + formatNumber(resultCity.valueResult.totalCost);
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`row1-${city}`}>{textToShow}</h5>
                                    )
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    //Find the result matching the city name 
                                    let resultCity = details.results.find(result => result.city === city);
                                    let textToShow = "Lowest value";
                                    if (!resultCity.valueResult.bestOption) {
                                        let formattedText = resultCity.valueResult.difference ? formatNumber(resultCity.valueResult.difference) : "N/A";
                                        let addendum = "$ ";
                                        textToShow = addendum + formattedText;
                                        if (resultCity.valueResult.typeOfDifference === "Percentage") {
                                            addendum = "% ";
                                            textToShow = formattedText + addendum;
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number red" key={`row2-${city}`}>{textToShow}</h5>
                                    )
                                })}

                            </div>
                        </Col>
                    </Row>

                    <h4 className="table-title d-none d-lg-block">Inbound Groups</h4>
                    {inboundGroups.map((inboundGroup, inboundIndex) =>
                        <Fragment key={`table1-${inboundIndex}`}>
                            <h4 className="table-title d-none d-lg-flex">{inboundGroup.groupName}</h4>
                            <Row className="no-gutters d-none d-lg-flex">
                                <Col lg={3} className="fixed-container">
                                    <div className="city-title">Origin</div>
                                    {inboundGroup.origins.map((location, index) =>
                                        <Fragment key={`location1-${location._id}`}>
                                            <h5 className="side-text highlighted">{location.fullName} - USD per {location.unit}</h5>
                                            <h5 className="side-text">{location.unit} cost / month</h5>
                                            <h5 className="side-text">Total {location.unit} cost / year</h5>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col lg={9} className="table-container">
                                    <div className="table-header">
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <h5 className="city-title" key={`row-${city}`}>{city}</h5>
                                        )}
                                    </div>

                                    {inboundGroup.origins.map((location, locationIndex) =>
                                        <Fragment key={`location-${location._id}`}>
                                            <div className="row-number">
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price;
                                                        costToShow = price !== "" ? formatNumber(price) : "N/A";
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <h5 className="number" key={`${cityName}-cityRow1`}>$ {costToShow}</h5>
                                                    )

                                                })}
                                            </div>
                                            <div className="row-number">
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price
                                                        let annualCost = resultCity.inboundGroups[inboundIndex].origins[locationIndex].annualCost;
                                                        let monthlyCost = annualCost / 12;
                                                        costToShow = price === "" ? "N/A" : formatNumber(monthlyCost)
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <h5 className="number" key={`${cityName}-cityRow2`}>$ {costToShow}</h5>
                                                    )
                                                })}
                                            </div>
                                            <div className="row-number">
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price
                                                        let annualCost = resultCity.inboundGroups[inboundIndex].origins[locationIndex].annualCost;
                                                        costToShow = price === "" ? "N/A" : formatNumber(annualCost)
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <h5 className="number" key={`${cityName}-cityRow3`}>$ {costToShow}</h5>
                                                    )
                                                })}
                                            </div>
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    )}


                    <h4 className="table-title d-none d-lg-block">Outbound Freight</h4>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <div className="city-title">Destination</div>
                            {outboundGroups.map((location, index) =>
                                <Fragment key={`table2-${location._id}`}>
                                    <h5 className="side-text highlighted">{location.fullName} - USD per {location.unit}</h5>
                                    <h5 className="side-text">Total Cost {location.unit} per month</h5>
                                    <h5 className="side-text">Total {location.unit} cost / year</h5>
                                </Fragment>
                            )}
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`table3-${index}`}>{city}</h5>
                                )}
                            </div>
                            {outboundGroups.map((location, locationIndex) =>
                                <Fragment key={`row-${location._id}`}>
                                    <div className="row-number">
                                        {city_names.map((cityName, index) => {
                                            let resultCity = variableCosts.find(city => city.cityName === cityName);
                                            let costToShow = "N/A";
                                            if (resultCity) {
                                                let price = resultCity.outbound[locationIndex].price;
                                                costToShow = price? formatNumber(price) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <h5 className="number" key={`cityRow3-${cityName}`}>$ {costToShow}</h5>
                                            )
                                        })}
                                    </div>
                                    <div className="row-number">
                                        {city_names.map((cityName, index) => {
                                            let resultCity = variableCosts.find(city => city.cityName === cityName);
                                            let costToShow = "N/A";
                                            if (resultCity) {
                                                let annualCost = resultCity.outbound[locationIndex].annualCost;
                                                let monthlyCost = annualCost / 12;
                                                costToShow = monthlyCost ? formatNumber(monthlyCost) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <h5 className="number" key={`row4-${cityName}`}>$ {costToShow}</h5>
                                            )
                                        })}
                                    </div>
                                    <div className="row-number">
                                        {city_names.map((cityName, index) => {
                                            let resultCity = variableCosts.find(city => city.cityName === cityName);
                                            let costToShow = "N/A";
                                            if (resultCity) {
                                                let annualCost = resultCity.outbound[locationIndex].annualCost;
                                                costToShow = annualCost? formatNumber(annualCost) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <h5 className="number" key={`row5-${cityName}`}>$ {costToShow}</h5>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            )}
                        </Col>
                    </Row>

                    <div className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`mobile-header-${city}`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Consolidated inbound & outbound costs</td>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <td key={`mobile-row1-${city}`}>
                                            {details.results.find(result => result.city === city).valueResult.totalCost !== null ?
                                                "$ " + formatNumber(details.results.find(result => result.city === city).valueResult.totalCost) : "N/A"
                                            }
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Difference from lowest value</td>
                                    {city_names.map((city, index) => {
                                        const cityResult = details.results.find(result => result.city === city);
                                        let textToShow = "Lowest value";
                                        if (!cityResult.valueResult.bestOption) {
                                            let formattedText = cityResult.valueResult.difference ? formatNumber(cityResult.valueResult.difference) : "N/A";
                                            let addendum = "$ ";
                                            textToShow = addendum + formattedText;
                                            if (cityResult.valueResult.typeOfDifference === "Percentage") {
                                                addendum = "% ";
                                                textToShow = formattedText + addendum;
                                            }
                                        }
                                        return (filtered.includes(index) &&
                                        <td key={`mobile-row2-${city}`} className="red">
                                            {textToShow}
                                        </td>
                                    )})}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5 className="mobile-title mobile-main-title d-lg-none">Inbound Groups</h5>
                    {inboundGroups.map((inboundGroup, inboundIndex) =>
                        <div key={`mobile-table1-${inboundIndex}`} className="mobile-details-results-table d-lg-none">
                            <h5 className="mobile-title">{inboundGroup.groupName}</h5>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Destination</th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`mobile-header-${city}`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {inboundGroup.origins.map((location, locationIndex) =>
                                        <Fragment key={`mobile-location-${location._id}`}>
                                            <tr>
                                                <td className="strong">{location.fullName} - USD per {location.unit}</td>
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price;
                                                        costToShow = price !== "" ? formatNumber(price) : "N/A";
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <td key={`mobile-row1-${cityName}`}>$ {costToShow}</td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td>{location.unit} cost / month</td>
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price
                                                        let annualCost = resultCity.inboundGroups[inboundIndex].origins[locationIndex].annualCost;
                                                        let monthlyCost = annualCost / 12;
                                                        costToShow = price === "" ? "N/A" : monthlyCost
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <td key={`mobile-row2-${cityName}`}>$ {costToShow}</td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Total {location.unit} cost / year</td>
                                                {city_names.map((cityName, index) => {
                                                    let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                    let costToShow = "N/A";
                                                    if (resultCity) {
                                                        let price = resultCity.inboundGroups[inboundIndex].origins[locationIndex].price
                                                        let annualCost = resultCity.inboundGroups[inboundIndex].origins[locationIndex].annualCost;
                                                        costToShow = price === "" ? "N/A" : annualCost
                                                    }
                                                    return (
                                                        filtered.includes(index) &&
                                                        <td key={`mobile-row3-${cityName}`}>$ {costToShow}</td>
                                                    )   
                                                })}
                                            </tr>
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <h5 className="mobile-title mobile-main-title d-lg-none">Outbound Freight</h5>
                    <div className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Destination</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`mobile-header-${city}`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {outboundGroups.map((location, locationIndex) =>
                                    <Fragment key={`mobile-row-${location._id}`}>
                                        <tr>
                                            <td className="strong">{location.fullName} - USD per {location.unit}</td>
                                            {city_names.map((cityName, index) => {
                                                let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                let costToShow = "N/A";
                                                if (resultCity) {
                                                    let price = resultCity.outbound[locationIndex].price;
                                                    costToShow = price? formatNumber(price) : "N/A";
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`mobile-row1-${cityName}`}>$ {costToShow}</td>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Total Cost {location.unit} per month</td>
                                            {city_names.map((cityName, index) => {
                                                let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                let costToShow = "N/A";
                                                if (resultCity) {
                                                    let annualCost = resultCity.outbound[locationIndex].annualCost;
                                                    let monthlyCost = annualCost / 12;
                                                    costToShow = monthlyCost ? formatNumber(monthlyCost) : "N/A";
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`mobile-row2-${cityName}`}>$ {costToShow}</td>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>Total {location.unit} cost / year</td>
                                            {city_names.map((cityName, index) => {
                                                let resultCity = variableCosts.find(city => city.cityName === cityName);
                                                let costToShow = "N/A";
                                                if (resultCity) {
                                                    let annualCost = resultCity.outbound[locationIndex].annualCost;
                                                    costToShow = annualCost? formatNumber(annualCost) : "N/A";
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`mobile-row3-${cityName}`}>$ {costToShow}</td>
                                                )
                                            }
                                            )}
                                        </tr>
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            ))
            }
        </Container >
    )
}

export default InboundOutBound;