import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useState, useEffect } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const StrikesHistory = ({ details, city_names, filtered, reportID, variableCosts, musts }) => {

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState(null)

    useEffect(() => {
        if (variableCosts && musts) {
            let yearsRef = []
            variableCosts.forEach(city => {
                const years = Object.keys(city.dataVariables.strikesHistory ?? [])
                if (years.length > yearsRef.length) {
                    yearsRef = years;
                }
            })
            let yearsArray = yearsRef.filter(year => year.startsWith('20'));
            setYears(yearsArray);
            setLoading(false);
        }
    }, []);



    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>The data is still loading</div>
            ) : (
                variableCosts && musts ? (
                    <>
                        <Row className="no-gutters d-none d-lg-flex">
                            <Col lg={3} className="fixed-container">
                                <h5 className="first-spacer">Collective Conflicts without Strike Notice by State</h5>
                                {years.map((year) =>
                                    <h5 className="side-text" key={`${year}-row`}>{year}</h5>
                                )}
                                <h5 className="first-spacer last">Average</h5>
                                <h5 className="first-spacer last">EAP</h5>
                                <h5 className="first-spacer last">Ratio per 100,000</h5>
                                <h5 className="first-spacer last">Ratio per 100,000 EAP</h5>
                            </Col>
                            <Col lg={9} className="table-container">
                                <div className="table-header">
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                    )}
                                </div>
                                {years.map((year) =>
                                    <div className="row-number" key={`${year}-column`}>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                            if (cityData) {
                                                let yearData = cityData.dataVariables.strikesHistory[year];
                                                textToShow = yearData;
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <h5 className="number" key={`${city}-${year}-value`}>{textToShow}</h5>
                                            )
                                        }
                                        )}
                                    </div>
                                )}
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let cityAverage = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.strikesHistory.avgStrikes;
                                        let cityAverageToShow = "N/A";
                                        if (cityAverage) {
                                            cityAverageToShow = formatNumber(cityAverage);
                                        }

                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-average`}><span className={cellClass}>{cityAverageToShow}</span></h5>
                                        )
                                    }
                                    )}
                                </div>
                                <div className="row-number">
                                    {musts.sort((a, b) => a.municipality_name.localeCompare(b.municipality_name)).map((city, index) =>
                                        filtered.includes(index) &&
                                        <h5 className="number" style={{ height: '90px', paddingTop: '35px' }} key={`${city.datamexico_municipality_id}`}>{formatNumber(city.EAP)}</h5>
                                    )}
                                </div>
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                        if (cityData) {
                                            let ratioData = cityData.dataVariables.strikesHistory.EAPRatio;
                                            textToShow = formatNumber(ratioData);
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" style={{ height: '90px', paddingTop: '35px' }} key={`${city}-ratio100k`}>{textToShow}</h5>
                                        )
                                    }
                                    )}
                                </div>
                                <div className="row-number">
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A";
                                        let eapData = details.results.find(cityFind => city === cityFind.city);
                                        if (eapData) {
                                            if (eapData.valueResult.bestOption) {
                                                cellClass += " lowest";
                                            }
                                            textToShow = eapData.valueResult.totalCost !== null ? formatNumber(eapData.valueResult.totalCost) : "N/A";
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <h5 className="number" key={`${city}-ratio100k-eap`}><span className={cellClass}>{textToShow}</span></h5>
                                        )
                                    }
                                    )}
                                </div>
                            </Col>
                        </Row>

                        <section className="mobile-details-results-table d-lg-none">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Collective Conflicts without Strike Notice by State</th>
                                        {city_names.map((city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-mobile-header`}>{city}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {years.map((year) =>
                                        <tr key={`${year}-row-mobile`}>
                                            <td>{year}</td>
                                            {city_names.map((city, index) => {
                                                let textToShow = "N/A";
                                                let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                                if (cityData) {
                                                    let yearData = cityData.dataVariables.strikesHistory[year];
                                                    textToShow = yearData;
                                                }
                                                return (
                                                    filtered.includes(index) &&
                                                    <td key={`mobile-${city}-${year}-value`}>{textToShow}</td>
                                                )
                                            }
                                            )}
                                        </tr>
                                    )}
                                    <tr>
                                        <td className="strong">Average</td>
                                        {city_names.map((city, index) => {
                                            let cityAverage = variableCosts.find(cityFind => city === cityFind.cityName).dataVariables.strikesHistory.avgStrikes;
                                            let cityAverageToShow = "N/A";
                                            if (cityAverage) {
                                                cityAverageToShow = formatNumber(cityAverage);
                                            }

                                            return (
                                                filtered.includes(index) &&
                                                <td key={`mobile-${city}-average`}>{cityAverageToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="strong">EAP</td>
                                        {musts.sort((a, b) => a.municipality_name.localeCompare(b.municipality_name)).map((city, index) =>
                                            filtered.includes(index) &&
                                            <td key={`mobile-${city.datamexico_municipality_id}`}>{formatNumber(city.EAP)}</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="strong">Ratio per 100,000</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityData = variableCosts.find(cityFind => city === cityFind.cityName);
                                            if (cityData) {
                                                let ratioData = cityData.dataVariables.strikesHistory.EAPRatio;
                                                textToShow = formatNumber(ratioData);
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td key={`${city}-mobile-ratio100k`}>{textToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="strong">Ratio per 100,000 EAP</td>
                                        {city_names.map((city, index) => {
                                            let cellClass = "";
                                            let textToShow = "N/A";
                                            let eapData = details.results.find(cityFind => city === cityFind.city);
                                            if (eapData) {
                                                if (eapData.valueResult.bestOption) {
                                                    cellClass += " lowest"
                                                }
                                                textToShow = eapData.valueResult.totalCost !== null ? formatNumber(eapData.valueResult.totalCost) : "N/A";
                                            }
                                            return (
                                                filtered.includes(index) &&
                                                <td className={cellClass} key={`${city}-mobile-ratio100k-eap`}>{textToShow}</td>
                                            )
                                        }
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <div className="no-record-wrapper">
                        <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                        <img src={infodanger} width="50px" height="50px" />
                    </div >
                )
            )}
        </Container>
    )
}

export default StrikesHistory;