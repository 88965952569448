export class LocationData {
  constructor(fullName?, city?, state?, country?, latitude?, longitude?, portAirport?, quantity?, unit?, selectedUnit?, border?) {
    this.fullName = fullName;
    this.city = city;
    this.state = state;
    this.country = country;
    this.latitude = latitude;
    this.longitude = longitude;
    this.portAirport = portAirport;
    this.quantity = quantity;
    this.unit = unit;
    this.selectedUnit = selectedUnit;
    this.border = border;
  }

  public fullName: string = "";
  public city: string = "";
  public state: string = "";
  public country: string = "";
  public latitude: Number;
  public longitude: Number;
  public portAirport: string = ""
  public quantity: String = "";
  public unit: String = "";
  public selectedUnit: Object = {description: "", measures: {}};
  public border: Object = {fullName: ""};
}
