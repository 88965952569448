export class Want {
	public wantsMatrix: any[] = [
		{
			title: "Operating cost",
			weight: 35,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Total payroll cost",
					weight: 20,
					information: {
						definition: "Fully Burdened Payroll Costs (Daily Wage, Payroll Taxes, Social Security, Christmas Bonus, Vacation Bonus, Saving Fund, Food Coupons)",
						metric: "USD / month based on Cost Model",
						source: "Prodensa HR"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 10
							},
							{
								firstRange: 5.01,
								secondRange: 10
							},
							{
								firstRange: 0,
								secondRange: 5
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
					//EL SIGUIENTE OBJETO ES PARTE DEL MODELO PERO COMO SOLO SE AGREGA A LAS VARIABLES NUEVAS POR ESO NO LO TIENEN ESTOS OBJETOS FIJOS
					// settings: {
					// 	typeOfValue: "Type of value",
					// 	cities:[
					// 		{
					// 			name: "Saltillo",
					// 			value: 0
					// 		},
					// 		{
					// 			name: "Torreón",
					// 			value: 0
					// 		},
					// 		{
					// 			name: "Monterrey",
					// 			value: 0
					// 		}
					// 	]
					// }
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Logistics cost",
					weight: 35,
					information: {
						definition: "Inbound and Outbound logistics costs. TBD Service Level",
						metric: "USD / month based on Cost Model",
						source: "Carriers in Prodensa's Database"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 20
							},
							{
								firstRange: 10.01,
								secondRange: 20
							},
							{
								firstRange: 0,
								secondRange: 10
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: false, //?????
					addedVariable: false,
					title: "Total electricity cost",
					weight: 10,
					information: {
						definition: "Electricity costs based on monthly consumption",
						metric: "USD / month based on Cost Model",
						source: "Federal Electricity Commission"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 15
							},
							{
								firstRange: 10.01,
								secondRange: 15
							},
							{
								firstRange: 0,
								secondRange: 10
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Total water cost",
					weight: 5,
					information: {
						definition: "Water costs based on monthly consumption",
						metric: "USD / month based on Cost Model",
						source: "Local supplier"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 40
							},
							{
								firstRange: 20.01,
								secondRange: 40
							},
							{
								firstRange: 0,
								secondRange: 20
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Total gas cost",
					weight: 5,
					information: {
						definition: "Gas LP costs based on monthly consumption",
						metric: "USD / month based on Cost Model",
						source: "Local supplier"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 10
							},
							{
								firstRange: 5.01,
								secondRange: 10
							},
							{
								firstRange: 0,
								secondRange: 5
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Average land cost",
					weight: 25,
					information: {
						definition: "Avg cost per sq ft",
						metric: "Prodensa Select",
						source: ""
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 25
							},
							{
								firstRange: 10.01,
								secondRange: 25
							},
							{
								firstRange: 0,
								secondRange: 10
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				}
			]
		},
		{
			title: "Competition for labor",
			weight: 15,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Turnover rate",
					weight: 20,
					information: {
						definition: "Average monthly Turnover rate of the market",
						metric: "% of Turnover",
						source: "Prodensa HR"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 6.5
							},
							{
								firstRange: 6.01,
								secondRange: 6.49
							},
							{
								firstRange: 5.7,
								secondRange: 6
							},
							{
								criteria: "Lower than",
								value: 5.7
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Manufacturing density",
					weight: 25,
					information: {
						definition: "Density of IMMEX Certified Manufacturing Facilities",
						metric: "Economically Active Population / IMMEX Company",
						source: "National Maquila Association"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 24.99
							},
							{
								firstRange: 25,
								secondRange: 49.99
							},
							{
								criteria: "Higher than",
								value: 49.99
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Manufacturing growth",
					weight: 10,
					information: {
						definition: "IMMEX Certified Manufacturing Facilities Growth in the last 5 years",
						metric: "Δ of IMMEX Establishments in the City",
						source: "National Maquila Association"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 24.99
							},
							{
								firstRange: 25,
								secondRange: 50
							},
							{
								criteria: "Higher than",
								value: 50
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Skilled labor - Similar MFG Companies",
					weight: 20,
					information: {
						definition: "Number of manufacturing companies with the same industrial activity in the city.",
						metric: "Qty",
						source: "INEGI Information"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 10
							},
							{
								firstRange: 10,
								secondRange: 50
							},
							{
								firstRange: 51,
								secondRange: 100
							},
							{
								criteria: "Higher than",
								value: 100
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Engineer Graduates (Manufacturing Activities)",
					weight: 15,
					information: {
						definition: "Number of engineering graduates (and other related careers)",
						metric: "Ratio per 100,000",
						source: "Federal Education Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 5000
							},
							{
								firstRange: 5000,
								secondRange: 10000
							},
							{
								firstRange: 10001,
								secondRange: 20000
							},
							{
								criteria: "Higher than",
								value: 20000
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "TSU Graduates",
					weight: 10,
					information: {
						definition: "Number of TSU Graduates in the last 5 years. TSU:Técnico Superior Universitario (Upper Level Technician)",
						metric: "Ratio per 100,000",
						source: "Federal Education Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 500
							},
							{
								firstRange: 500,
								secondRange: 1000
							},
							{
								firstRange: 1001,
								secondRange: 1400
							},
							{
								criteria: "Higher than",
								value: 1400
							}
						]
					}
				}
			]
		},
		{
			title: "Labor environment",
			weight: 5,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Absenteeism",
					weight: 25,
					information: {
						definition: "Average monthly Absenteeism rate of the market",
						metric: "% of Abstenteeism",
						source: "Prodensa HR"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 3
							},
							{
								firstRange: 2.51,
								secondRange: 3
							},
							{
								firstRange: 1.2,
								secondRange: 2.5
							},
							{
								criteria: "Lower than",
								value: 1.2
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Union Environment",
					weight: 25,
					information: {
						definition: "Qualitative perspective of Union Environment in each city with a points system.",
						metric: "Points based on Union Table",
						source: "Prodensa HR"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 13
							},
							{
								firstRange: 13,
								secondRange: 19
							},
							{
								firstRange: 19.01,
								secondRange: 21.5
							},
							{
								criteria: "Higher than",
								value: 21.5
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Individual Labor Lawsuits",
					weight: 20,
					information: {
						definition: "Ratio of Labor Lawsuits per 100,000 from Economically active population.",
						metric: "Average Number of Individual Lawsuits per Year / Ratio per 100.000 EAP",
						source: "Federal Labor Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 30
							},
							{
								firstRange: 20.01,
								secondRange: 30
							},
							{
								firstRange: 10,
								secondRange: 20
							},
							{
								criteria: "Lower than",
								value: 10
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Strikes History",
					weight: 30,
					information: {
						definition: "Number of Strikes in the last 3 years",
						metric: "Strikes in the last 3 years",
						source: "Federal Labor Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 2
							},
							{
								firstRange: 1.01,
								secondRange: 2
							},
							{
								firstRange: 0.01,
								secondRange: 1
							},
							{
								criteria: "Lower than",
								value: 0.1
							}
						]
					}
				},
			]
		},
		{
			title: "Logistics",
			weight: 15,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Distance to client",
					weight: 60,
					information: {
						definition: "Hours from preliminary city to client",
						metric: "Total Distance in truck hours",
						source: ""
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 40
							},
							{
								firstRange: 20.01,
								secondRange: 40
							},
							{
								firstRange: 0,
								secondRange: 20
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Distance to seaport",
					weight: 10,
					information: {
						definition: "Hours from port to preliminary city",
						metric: "Total Distance in truck hours",
						source: ""
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 18
							},
							{
								firstRange: 16.01,
								secondRange: 18
							},
							{
								firstRange: 14,
								secondRange: 16
							},
							{
								criteria: "Lower than",
								value: 14
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Distance to supplier",
					weight: 30,
					information: {
						definition: "Hours from preliminary city to suppliers",
						metric: "Total Distance in truck hours",
						source: ""
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 15
							},
							{
								firstRange: 12,
								secondRange: 15
							},
							{
								firstRange: 10,
								secondRange: 12
							},
							{
								criteria: "Lower than",
								value: 10
							}
						]
					}
				}
			]
		},
		{
			title: "Infrastructure",
			weight: 5,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Connectivity Travel",
					weight: 30,
					information: {
						definition: "Pressence of International Airport and access to direct international flights.",
						metric: "International Airlines Presence",
						source: "Airports"
					},
					criterias: {
						typeOfValue: "Text",
						options: [
							{
								value: "International Airport more than 100 miles away"
							},
							{
								value: "International Airport within 100 miles and 1 stop international flights"
							},
							{
								value: "International Airport within 100 miles or 1 stop international flights"
							},
							{
								value: "International Airport within 50 miles - Direct International Flights"
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Availability of Potential Land",
					weight: 70,
					information: {
						definition: "Number of Potential Industrial Parks",
						metric: "Qty",
						source: "Prodensa Select"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 2
							},
							{
								firstRange: 3,
								secondRange: 4
							},
							{
								criteria: "Higher than",
								value: 4
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Total Foreign Investment",
					weight: 0,
					information: {
						definition: "",
						metric: "",
						source: ""
					},
					criterias: {
						typeOfValue: "Type of value",
						options: [
							{
								criteria: "Select option",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 3
							},
							{
								firstRange: 4,
								secondRange: 6
							},
							{
								criteria: "Select option",
								value: "6"
							}
						]
					}
				}
			]
		},
		{
			title: "Government incentives",
			weight: 5,
			variables: [
				{
					moreOptions: true,
					addedVariable: false,
					title: "Government Incentives",
					weight: 50,
					information: {
						definition: "Possibility of Obtaining statutory incentives from the government",
						metric: "Based on Past Experiences",
						source: "Prodensa"
					},
					criterias: {
						typeOfValue: "Text",
						options: [
							{
								value: "Not available"
							},
							{
								value: "Less than 10%"
							},
							{
								value: "11% to 60%"
							},
							{
								value: "Greater than 60%"
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Government Landscape",
					weight: 50,
					information: {
						definition: "Qualitative perspective of Government relationship ",
						metric: "Based on Current Political Status",
						source: "News"
					},
					criterias: {
						typeOfValue: "Text",
						options: [
							{
								value: "Close state elections"
							},
							{
								value: "Close state elections and good relationship with state government"
							},
							{
								value: "Distant state elections and neutral relationship with the state government"
							},
							{
								value: "Distant state elections and good relationship with the state government"
							}
						]
					}
				}
			]
		},
		{
			title: "Security",
			weight: 15,
			variables: [
				{
					addedVariable: false,
					title: "Homicides",
					weight: 20,
					information: {
						definition: "Number of Homicides per year per 100,000 people",
						metric: "Number of Homicides per year per 100,000 people",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 15
							},
							{
								firstRange: 10.01,
								secondRange: 15
							},
							{
								firstRange: 4,
								secondRange: 10
							},
							{
								criteria: "Lower than",
								value: 4
							}
						]
					}
				},
				{
					addedVariable: false,
					title: "Car Theft",
					weight: 10,
					information: {
						definition: "Number of Cars Stolen per Month per 100,000 people",
						metric: "Number of Cars Stolen per Month per 100,000 people",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 10
							},
							{
								firstRange: 5.01,
								secondRange: 10
							},
							{
								firstRange: 2.5,
								secondRange: 5
							},
							{
								criteria: "Lower than",
								value: 2.5
							}
						]
					}
				},
				{
					addedVariable: false,
					title: "Home Theft",
					weight: 10,
					information: {
						definition: "House Theft Incidents per Month per 100,000 people",
						metric: "House Theft Incidents per Month per 100,000 people",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 80
							},
							{
								firstRange: 50.01,
								secondRange: 80
							},
							{
								firstRange: 20,
								secondRange: 50
							},
							{
								criteria: "Lower than",
								value: 20
							}
						]
					}
				},
				{
					addedVariable: false,
					title: "Organized Crime Presence",
					weight: 40,
					information: {
						definition: "Organize Crimes Incidents per Month per 100,000 people",
						metric: "Organize Crimes Incidents per Month per 100,000 people",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 300
							},
							{
								firstRange: 200.01,
								secondRange: 300
							},
							{
								firstRange: 100,
								secondRange: 200
							},
							{
								criteria: "Lower than",
								value: 100
							}
						]
					}
				},
				{
					addedVariable: false,
					title: "Insecurity Perception",
					weight: 10,
					information: {
						definition: "Population's perception of public safety (Feeling of insecurity due to fear of crime, witnessing of criminal behaviors, perception of the performance of public safety authorities, government performance and experiences)",
						metric: "Insecurity Perception Index",
						source: "National Urban Public Safety Survey 2022"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 50
							},
							{
								firstRange: 40.01,
								secondRange: 50
							},
							{
								firstRange: 25,
								secondRange: 40
							},
							{
								criteria: "Lower than",
								value: 25
							}
						]
					}
				},
				{
					addedVariable: false,
					title: "Corruption Index",
					weight: 10,
					information: {
						definition: "Percentage of population and companies that were victim of corruption from public employees or institutions (reports)",
						metric: "Rate per State",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Higher than",
								value: 20
							},
							{
								firstRange: 15.01,
								secondRange: 20
							},
							{
								firstRange: 11,
								secondRange: 15
							},
							{
								criteria: "Lower than",
								value: 11
							}
						]
					}
				}
			]
		},
		{
			title: "Quality of life",
			weight: 5,
			variables: [
				{
					moreOptions: false,
					addedVariable: false,
					title: "Private Schools",
					weight: 15,
					information: {
						definition: "Number of Private Schools in the City",
						metric: "Qty",
						source: "Public Education Ministry"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 3
							},
							{
								firstRange: 3,
								secondRange: 3.99
							},
							{
								firstRange: 4,
								secondRange: 6
							},
							{
								criteria: "Higher than",
								value: 6
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Private Hospitals",
					weight: 20,
					information: {
						definition: "Number of Private Hospitals in the City",
						metric: "Qty",
						source: "National Economic Census"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 0.5
							},
							{
								firstRange: 0.5,
								secondRange: 1
							},
							{
								firstRange: 1.01,
								secondRange: 1.6
							},
							{
								criteria: "Higher than",
								value: 1.6
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Recognized Retail Chains in the City",
					weight: 25,
					information: {
						definition: "Number of Recognized Retail Chains in the City with over 250 employees.",
						metric: "Qty",
						source: "Field Research"
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 0.2
							},
							{
								firstRange: 0.2,
								secondRange: 0.4
							},
							{
								firstRange: 0.41,
								secondRange: 0.5
							},
							{
								criteria: "Higher than",
								value: 0.5
							}
						]
					}
				},
				{
					moreOptions: true,
					addedVariable: false,
					title: "Cost of Living",
					weight: 20,
					information: {
						definition: "Cost of Living (Utilities, food, rent, transportation, etc.)",
						metric: "USD",
						source: "National Statistics Ministry"
					},
					criterias: {
						typeOfValue: "Percentage",
						options: [
							{
								criteria: "Higher than",
								value: 200
							},
							{
								firstRange: 100.01,
								secondRange: 200
							},
							{
								firstRange: 0,
								secondRange: 100
							},
							{
								criteria: "Lowest value",
								value: ""
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Higher Temperature Peaks",
					weight: 0,
					information: {
						definition: "",
						metric: "",
						source: ""
					},
					criterias: {
						typeOfValue: "Type of value",
						options: [
							{
								criteria: "Select option",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 3
							},
							{
								firstRange: 4,
								secondRange: 6
							},
							{
								criteria: "Select option",
								value: "6"
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Humidity Days",
					weight: 0,
					information: {
						definition: "",
						metric: "",
						source: ""
					},
					criterias: {
						typeOfValue: "Type of value",
						options: [
							{
								criteria: "Select option",
								value: 1
							},
							{
								firstRange: 1,
								secondRange: 3
							},
							{
								firstRange: 4,
								secondRange: 6
							},
							{
								criteria: "Select option",
								value: "6"
							}
						]
					}
				},
				{
					moreOptions: false,
					addedVariable: false,
					title: "Social Progress Index",
					weight: 20,
					information: {
						definition: "The Social Progress Index (SPI) is a holistic measure of a country's social performance that is independent of economic factors. The Index is based on a range of social and environmental indicators that capture three dimensions of social progress: Basic Human Needs, Foundations of Well-Being and Opportunities, and takes values between 0 and 100, where 0 is the least possible social progress and 100 is the maximum possible social progress.",
						metric: "Social Progress Index",
						source: ""
					},
					criterias: {
						typeOfValue: "Number",
						options: [
							{
								criteria: "Lower than",
								value: 55
							},
							{
								firstRange: 55,
								secondRange: 60
							},
							{
								firstRange: 60.01,
								secondRange: 70
							},
							{
								criteria: "Higher than",
								value: 70.01
							}
						]
					}
				}
			]
		}
	]
	public TPCDocument: {
		filename: String;
		payrollFileId: String;
	} = {
			filename: "",
			payrollFileId: "",
		}
}
