import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const ElectricityCost = ({ details, city_names, filtered, reportID, musts, assumptions, variableCosts }) => {
    const [loading, setLoading] = useState(true)
    const exchangeRate = 17;

    useEffect(() => {
        if (musts && assumptions && variableCosts) {
            setLoading(false)
        }
    }, [])

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>Loading ... </div>
            ) : (musts && assumptions && variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Cost per kwh</h5>
                            <h5 className="first-spacer">Concept</h5>
                            <h5 className="side-text">Total MXP Monthly</h5>
                            <h5 className="side-text">Estimated USD Monthly</h5>
                            <h5 className="side-text">Estimated Annual Cost</h5>
                            <h5 className="side-text">Difference from lower value</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let costToShow = "N/A"
                                    let resultToRender = details.results.find(result => result.city === city);
                                    if (resultToRender) {
                                        let totalCostPesos = resultToRender.valueResult.totalCost;
                                        let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                        let kwUsed = assumptions.utilities.electricityConsumed;
                                        let usdKw = kwUsed === "" ? null : totalMonthlyUSD / kwUsed;
                                        if (kwUsed === 0) {
                                            costToShow = 0;
                                        } else {
                                            costToShow = usdKw ? usdKw : "N/A";
                                        }
                                    }

                                    return filtered.includes(index) && <h5 className="number" key={`row1 ${index}`}>$ {formatNumber(costToShow)}</h5>;
                                })}
                            </div>
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`table1 ${index}`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let costToShow = "N/A";
                                    let resultToRender = details.results.find(result => result.city === city);
                                    if (resultToRender && resultToRender.valueResult.totalCost !== null) {
                                        costToShow = resultToRender.valueResult.totalCost;
                                    }
                                    return filtered.includes(index) && <h5 className="number" key={`row2 ${index}`}>$ {formatNumber(costToShow)}</h5>;
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let costToShow = "N/A"
                                    let resultToRender = details.results.find(result => result.city === city);
                                    if (resultToRender) {
                                        let totalCostPesos = resultToRender.valueResult.totalCost;
                                        let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                        costToShow = totalMonthlyUSD ? totalMonthlyUSD : "N/A";
                                    }
                                    return filtered.includes(index) && <h5 className="number" key={`row3 ${index}`}>$ {formatNumber(costToShow)}</h5>;
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let costToShow = "N/A"
                                    let resultToRender = details.results.find(result => result.city === city);
                                    if (resultToRender) {
                                        let totalCostPesos = resultToRender.valueResult.totalCost;
                                        let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                        let annualUSD = totalMonthlyUSD * 12;
                                        costToShow = annualUSD ? annualUSD : "N/A";
                                    }
                                    return filtered.includes(index) && <h5 className="number" key={`row4 ${index}`}>$ {formatNumber(costToShow)}</h5>;
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let costToShow = "N/A"
                                    let addendum = ""
                                    let resultToRender = details.results.find(result => result.city === city);
                                    if (resultToRender && resultToRender.valueResult.difference) {
                                        costToShow = resultToRender.valueResult.difference.toFixed(2);
                                        if (resultToRender.valueResult.typeOfDifference === "Percentage") {
                                            addendum = " %"
                                            costToShow = costToShow + addendum;
                                        }
                                        if (resultToRender.valueResult.bestOption) {
                                            costToShow = "Lowest value";
                                        }
                                    }
                                    return filtered.includes(index) && <h5 className="number red" key={`row5 ${index}`}>{formatNumber(costToShow)}</h5>;
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={4} className="fixed-container">
                            <h5 className="first-spacer">Input</h5>
                            <h5 className="side-text">kWMax</h5>
                            <h5 className="side-text">Monthly Consumption Total kWh</h5>
                        </Col>
                        <Col lg={8} className="table-container">
                            <h5 className="first-spacer"></h5>
                            <h5 className="number">{formatNumber(+assumptions.utilities.electricityInstalled)}</h5>
                            <h5 className="number">{formatNumber(+assumptions.utilities.electricityConsumed)}</h5>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Cost per kwh</th>
                                    {city_names.map((city, index) => {
                                        let costToShow = "N/A"
                                        let resultToRender = details.results.find(result => result.city === city);
                                        if (resultToRender) {
                                            let totalCostPesos = resultToRender.valueResult.totalCost;
                                            let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                            let kwUsed = assumptions.utilities.electricityConsumed;
                                            let usdKw = kwUsed === "" ? null : totalMonthlyUSD / kwUsed;
                                            if (kwUsed === 0) {
                                                costToShow = 0;
                                            } else {
                                                costToShow = usdKw ? usdKw : "N/A";
                                            }
                                        }
                                        return (filtered.includes(index) &&
                                        <th key={`table1 ${index}`}  style={{textAlign: "center" }}>{formatNumber(costToShow)}</th>)
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map((city, index) => {
                                        return (filtered.includes(index) &&
                                        <th key={`table2 ${index}`}>{city}</th>)
                                    }
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total MXP Monthly</td>
                                    {city_names.map((city, index) => {
                                        let costToShow = "N/A";
                                        let resultToRender = details.results.find(result => result.city === city);
                                        if (resultToRender && resultToRender.valueResult.totalCost !== null) {
                                            costToShow = resultToRender.valueResult.totalCost;
                                        }
                                        return (filtered.includes(index) &&
                                        <td key={`table3 ${index}`}>{formatNumber(costToShow)}</td>)
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Estimated USD Monthly</td>
                                    {city_names.map((city, index) => {
                                        let costToShow = "N/A"
                                        let resultToRender = details.results.find(result => result.city === city);
                                        if (resultToRender) {
                                            let totalCostPesos = resultToRender.valueResult.totalCost;
                                            let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                            costToShow = totalMonthlyUSD ? totalMonthlyUSD : "N/A";
                                        }
                                        return (filtered.includes(index) &&
                                        <td key={`table4 ${index}`}>{formatNumber(costToShow)}</td>)
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Estimated Annual Cost</td>
                                    {city_names.map((city, index) => {
                                        let costToShow = "N/A"
                                        let resultToRender = details.results.find(result => result.city === city);
                                        if (resultToRender) {
                                            let totalCostPesos = resultToRender.valueResult.totalCost;
                                            let totalMonthlyUSD = totalCostPesos / exchangeRate;
                                            let annualUSD = totalMonthlyUSD * 12;
                                            costToShow = annualUSD ? annualUSD : "N/A";
                                        }
                                        return (filtered.includes(index) &&
                                        <td key={`table5 ${index}`}>{formatNumber(costToShow)}</td>)
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>Difference from lower value</td>
                                    {city_names.map((city, index) => {
                                        let costToShow = "N/A"
                                        let addendum = ""
                                        let resultToRender = details.results.find(result => result.city === city);
                                        if (resultToRender && resultToRender.valueResult.difference) {
                                            costToShow = resultToRender.valueResult.difference.toFixed(2);
                                            if (resultToRender.valueResult.typeOfDifference === "Percentage") {
                                                addendum = " %"
                                                costToShow = costToShow + addendum;
                                            }
                                            if (resultToRender.valueResult.bestOption) {
                                                costToShow = "Lowest value";
                                            }
                                        }
                                        return (filtered.includes(index) &&
                                        <td key={`table6 ${index}`} className="red">{formatNumber(costToShow)}</td>)
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{textTransform: "uppercase"}}>Input</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>kWMax</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>{formatNumber(+assumptions.utilities.electricityInstalled)}</td>
                                </tr>
                                <tr>
                                    <td>Monthly Consumption Total kWh</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>{formatNumber(+assumptions.utilities.electricityConsumed)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>

            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            )
            )
            }
        </Container>
    )
}

export default ElectricityCost;