import "../../../assets/css/PDF/configurePdf.css"

import Axios from "axios"
import Swal from "sweetalert2"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import NavSection from "./navSection.jsx"
import FormsSection from "./formsSection.jsx"
import close from "../../../assets/images/close.svg"
import { NAV_MENU_OPTIONS } from "./utils.js"


export default function ConfigurePdf(props) {
    const {
        draft,
        pdfConfig,
        setPdfConfig,
        showPdfConfigure,
        setShowPdfConfigure,
        wantsMatrixReference,
        isOpened
    } = props

    
    const [activeSection, setActiveSection] = useState(NAV_MENU_OPTIONS[0].position)
    
    useEffect(() => {
        if (!isOpened) {
            setActiveSection(NAV_MENU_OPTIONS[0].position)
        }
    }, [isOpened])
    
    useEffect(() => {
        if (showPdfConfigure) {
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [showPdfConfigure])

    function savePdfConfig() {
        Axios.patch(process.env.REACT_APP_GET_PDF_CONFIG + pdfConfig._id, { pdfConfig }).then(res => {
            Swal.fire({
                icon: "success",
                title: "Pdf configuration saved successfully",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
        }).catch(err => {
            console.log(err)
            Swal.fire({
                icon: "error",
                title: err?.response?.data?.message || "Error saving pdf configuration",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
        })
    }

    function handleFile(e, cityId, imageProperty) {
        const selectedFile = e.target.files[0]
        
        if (!selectedFile) return
        
        const data = new FormData()
        data.append("cityId", cityId)
        data.append("reportId", draft._id)
        data.append("pdfConfig", JSON.stringify(pdfConfig))
        data.append("imageProperty", imageProperty)
        data.append("cityImage", selectedFile)
    
        Axios.patch(process.env.REACT_APP_GET_PDF_CONFIG + pdfConfig._id, data).then(res => {
            Swal.fire({
                toast: true,
                icon: 'success',
                text: 'Information saved successfully',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                position: "bottom-end"
            })
            setPdfConfig(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    function closeConfigurePdf() {
        setShowPdfConfigure(false)
    }

    function handleActivePdfConfig() {
        setPdfConfig(prev => {
            return {
                ...prev,
                active: !prev.active
            }
        })
    }

    return (
        <section className={`config-pdf-container ${showPdfConfigure ? "show" : ""}`}>
            <Container>
                <img className="closeImg" src={close} alt="Prodensa Automation" onClick={closeConfigurePdf} />
                
                <div className="main-container">
                    <button className="secondaryButton savePdfConfig d-none d-lg-block" onClick={savePdfConfig}>Save</button>

                    <NavSection 
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        pdfConfig={pdfConfig}
                        handleActivePdfConfig={handleActivePdfConfig}
                    />

                    <FormsSection 
                        pdfConfig={pdfConfig}
                        setPdfConfig={setPdfConfig}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        draft={draft}
                        handleFile={handleFile}
                        wantsMatrixReference={wantsMatrixReference}
                        showPdfConfigure={showPdfConfigure}
                        savePdfConfig={savePdfConfig}
                        handleActivePdfConfig={handleActivePdfConfig}
                    />
                </div>
            </Container>
        </section>
    )
}
