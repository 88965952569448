import { useState } from "react";
import "../../../assets/css/reportResults/wantsView/compare-box.css";

import close from "../../../assets/images/close.svg";

const CompareBox = ({
  origin,
  cityNames,
  setFiltered,
  sliderSettings,
  setSliderSettings,
  comp_variant,
  setShowAllClass,
  filtered
}) => {
  /*The comp variant prop is used to indicate the component which type of role it assumes,when the component is the carousel variant
    sliderSettings is modified so the carousel displays less slides if the filtered array becomes shorter.
    If the variant is table then no sliderSettings are modified since the dynamic generation of columns is done at the table level and this distinction prevents error due to undefined
    items. 
  */
  const [compareactive, setCompareactive] = useState(false);
  //All cities are visible by default
  const initial_checkboxes = cityNames.map((city, index) => index);
  const [checkboxes, setCheckboxes] = useState(initial_checkboxes);
  const [showAllToggle, setShowAllToggle] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(1);


  const handleCheckboxClick = (value) => {
    if (value === "show-all") {
      if (!showAllToggle) {
        setShowAllToggle(true);
      } else {
        setShowAllToggle(false);
      }
    } else {
      if (checkboxes.includes(value)) {
        // If checkbox value is already in the array, remove it
        const updatedCheckboxes = checkboxes.filter((item) => item !== value);
        setCheckboxes(updatedCheckboxes);
      } else {
        // Add checkbox value to the array
        setCheckboxes([...checkboxes, value]);
      }
    }
  };

  //Adds all cities to the filtered array and updates the slides to show to 3 if the number of cities is 3 or more.
  const activateAllCheckboxes = () => {
    if (comp_variant === "carousel-all") {
      setShowAllToggle(false);
      setShowAllClass(false);
    }
    setNumberOfRows(1);
    if (comp_variant === "carousel" || comp_variant === "carousel-all") {        // Make all checkboxes selected
      if (cityNames.length >= 3) {
        const newSlidesToShow = 3;
        const updatedSliderSettings = {
          ...sliderSettings,
          slidesToShow: newSlidesToShow,
          rows: numberOfRows
        };
        setSliderSettings(updatedSliderSettings);
      } else {
        const newSlidesToShow = cityNames.length;
        const updatedSliderSettings = {
          ...sliderSettings,
          slidesToShow: newSlidesToShow,
          rows: numberOfRows
        };
        setSliderSettings(updatedSliderSettings);
      }

    }
    setCheckboxes(initial_checkboxes);
    setFiltered(initial_checkboxes);
    setCompareactive(false);
  };

  //Updates the slides to show depending on the new length of the checkboxes array if its the carousel variant, if not it only assigns checkboxes to filtered
  const applyFilter = () => {
    if (comp_variant === "carousel" || comp_variant === "carousel-all") {
      if (checkboxes.length > 3) {
        if (showAllToggle) {
          const numberOfRows = Math.ceil(checkboxes.length / 3);
          const newSlidesToShow = 3;
          const updatedSliderSettings = {
            ...sliderSettings,
            slidesToShow: newSlidesToShow,
            rows: numberOfRows
          };
          if (comp_variant === "carousel-all") {
            setShowAllClass(true);
          }
          setSliderSettings(updatedSliderSettings);
        } else {
          const numberOfRows = 1;
          const newSlidesToShow = 3;
          const updatedSliderSettings = {
            ...sliderSettings,
            slidesToShow: newSlidesToShow,
            rows: numberOfRows
          };
          if (!showAllToggle && comp_variant === "carousel-all") {
            setShowAllClass(false);
          }
          setSliderSettings(updatedSliderSettings);
        }
      } else {
        const numberOfRows = 1;
        const newSlidesToShow = checkboxes.length;
        const updatedSliderSettings = {
          ...sliderSettings,
          slidesToShow: newSlidesToShow,
          rows: numberOfRows
        };
        setSliderSettings(updatedSliderSettings);
      }
    }
    setCompareactive(false);
    //Sorting checkboxes prevent undesired behaviour
    let sortedcheckboxes = checkboxes.sort((a, b) => a - b);
    setFiltered(sortedcheckboxes);
  };

  return (
    <div className="compare-button">
      {checkboxes &&
        <>
          <button
            className={`compare-button-text ${compareactive ? "active" : ""}`}
            onClick={() => {
              compareactive ? setCompareactive(false) : setCompareactive(true);
            }}
          >
            Compare cities
          </button>
          <div className={`compare-menu ${compareactive ? "active" : ""}`}>
            <img src={close} className="close" alt="Prodensa automation" onClick={() => setCompareactive(false)}/>
            <h5 className="compare-menu-head">Compare cities</h5>
            <div className="compare-menu-body">
              <div className="input-group">
                {cityNames.map((city, index) => (
                  <div className="input-row" key={index}>
                    <input
                      type="checkbox"
                      id={`${origin}-${city}`}
                      value={index}
                      className="city-checkbox"
                      onChange={() => handleCheckboxClick(index)}
                      checked={checkboxes.includes(index)}
                    />
                    <label htmlFor={`${origin}-${city}`}>{city}</label>
                  </div>
                ))}
                {comp_variant === "carousel-all" &&
                  <div className="input-row">
                    <input
                      type="checkbox"
                      id="show-all"
                      value="show-all"
                      className="city-checkbox"
                      onChange={(event) => handleCheckboxClick(event.target.value)}
                      checked={showAllToggle}
                    />
                    <label htmlFor="show-all">Show all</label>
                  </div>
                }
              </div>
              <div className="compare-menu-footer">
                <button
                  className="reset-button"
                  onClick={() => activateAllCheckboxes()}
                >
                  <h5>Reset</h5>
                </button>
                <button className="apply-button" onClick={() => applyFilter()}>
                  <h5>Apply</h5>
                </button>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default CompareBox;
