import { Row, Col, Modal } from "react-bootstrap";
import { useState } from "react";


import "../../assets/css/clients/clients.css";
import EditClient from "./editClient.jsx";
import AllClients from "./allClients.jsx";
import close from "../../assets/images/close.svg";
import minimize from "../../assets/images/minimize.svg";

export default function Clients(props) {
  const [search, setSearch] = useState("");
  const [activeSection, setActiveSection] = useState(1);
  const [idToEdit, setIdToEdit] = useState(null)
  const [isMinimized, setIsMinimized] = useState(false)
  const [clientEdited, setClientEdited] = useState(false)

  const [pathImage, setPathImage] = useState(null)
  const [showImageModal, setShowImageModal] = useState(false)

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  function closeClientsSection() {
    setActiveSection(1)
    props.setClientsOpened(false);
    setIsMinimized(false)
  }
  
  function minimizeClientsSection() {
    props.setClientsOpened(false);
    setIsMinimized(true)
  }

  const handleShowImageModal = (isImage) => {
    setPathImage(isImage)
    setShowImageModal(true)
  }

  return (
    <section className={`containerClientReport ${props.isOpened ? "opened" : ""}`}
    >
      <div className="containerMinimizeClose">
        <img src={minimize} alt="Prodensa automation" onClick={minimizeClientsSection}/>
        <img
          src={close}
          alt="Prodensa automation"
          onClick={closeClientsSection}
        />
      </div>
      <div className="container">
        <Row>
          {activeSection === 1 ? (
            <Col className="containerHeader" lg={{span: 12}} xl={{span: 10, offset: 1}}>
              <div className="clientLogTitle">
                <h1>Clients</h1>
                <p>
                  View, edit, duplicate, and delete clients.
                </p>
              </div>
              <div className="searchContainer">
                <input
                  className="search"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </Col>
          ) : (
            <Col lg={{span: 11, offset: 1 }} xl={{span: 10, offset: 1}}>
              <div className="editClientsHeader">
                <h1>Client</h1>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {activeSection === 1 ? (
            <Col lg={{span: 11, offset: 1 }} xl={{span: 10, offset: 1}}>
              <AllClients 
                isOpened={props.isOpened} 
                search={search} 
                activeSection={activeSection}
                setActiveSection={setActiveSection} 
                setIdToEdit={setIdToEdit} 
                closeClientsSection={closeClientsSection}
                openReportLog={props.openReportLog} 
                setshowClientId={props.setshowClientId} 
                isMinimized={isMinimized} 
                allClients={props.allClients}
                setAllClients={props.setAllClients} 
                clientEdited={clientEdited}
                setClientEdited={setClientEdited}
                setActivateFetch={props.setActivateFetch}
                handleShowImageModal={handleShowImageModal} />

            </Col>
            ) : (
            <Col lg={{ span: 8, offset: 1 }}>
              <EditClient 
                setActiveSection={setActiveSection} 
                idtoEdit={idToEdit} 
                allClients={props.allClients} 
                setClientEdited={setClientEdited}
                handleShowImageModal={handleShowImageModal} />
            </Col>
            )
          }

        <Modal className="modal-client-image" centered show={showImageModal} onHide={() => setShowImageModal(false)}>
          <Modal.Body>
            {pathImage ? 
              (<img src={process.env.REACT_APP_BACKEND_URL + pathImage} alt="Prodensa automation"/>)
              :
              (<span>There is no image available</span>)
            }
          </Modal.Body>
        </Modal>
        </Row>
      </div>
    </section>
  );
}
