import Axios from "axios"
import mapboxgl from 'mapbox-gl';
import { useEffect, useState, useRef } from "react"
import ReactMapGL, { Marker, Layer, Source } from "react-map-gl"

import "../../assets/css/map/map.css"
import CustomPopup from "./customPopup.jsx"
import StreetviewMap from "./streetview.jsx";
import popupImg from "../../assets/images/popup-img.png"
import markerLight from "../../assets/images/markerLightMode.svg"
import markerLightActive from "../../assets/images/markerLightActive.svg"
import nonInteractable from "../../assets/images/nonInteractableMarker.svg"

mapboxgl.accessToken = 'pk.eyJ1Ijoid2Vic2hhdSIsImEiOiJjampmd2E2dzk1M21lM3FtbWRibzZ1dzdyIn0.Uy8XcDO3YrXX6OY5fFY5JA'



export default function Map({ 
    finish, 
    activebutton, 
    flyAnimationInProgress, 
    setFlyAnimationInProgress, 
    streetviewInfo, 
    setStreetviewInfo, 
    assumptions, 
    musts, 
    client, 
    typeOfResult, 
    videoFinish, 
    representationLetter, 
    representationLetterActive,
    allAssumptions,
    setMobilePopupOpen,
    borderDistances
}) {
    const mapRef = useRef(null)
    const mexicoCenter = [-101.5742, 23.54739]
    const [mapclass, setMapclass] = useState("");

    // const [streetviewInfo, setStreetviewInfo] = useState({   //info for streetview window
    //     open: false,
    //     id: 0
    // })
    const [initial, setInitial] = useState(true)            //determine if the first route would be animated or not
    const [distance, setDistance] = useState({
        km: 0,
        miles: 0,
    })             //distance of route
    const [route, setRoute] = useState([])                  //set of coordenates through optimal route
    // const [waypoints, setWaypoints] = useState({            //coordenates for origin, border(mid point) and destination
    //     start: [-100.31847, 25.67507],
    //     border: [-99.51639, 27.47629],
    //     end: [-98.49363, 29.42412],
    // })
    const [waypoints, setWaypoints] = useState({            //coordenates for origin, border(mid point) and destination
        start: [],
        border: [],
        end: [],
    })
    const [routeGeoJSON, setRouteGeoJSON] = useState({      //data for the source
        type: 'FeatureCollection',
        features: [
            { type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }
        ]
    })



    // const [poi, setPoi] = useState([
    //     {
    //         id: 1,
    //         name: "San Antonio",
    //         subName: "Final destination",
    //         lngLat: [-98.49363, 29.42412],
    //         popupOpen: false,
    //     },
    //     {
    //         id: 2,
    //         name: "Laredo",
    //         subName: "Border city",
    //         lngLat: [-99.51639, 27.47629],
    //         popupOpen: false,
    //     },
    // ])
    const [poi, setPoi] = useState([])

    const [hide, setHide] = useState(typeOfResult === "generate")                        //control for title of cities
    const [inProgress, setInProgress] = useState(false)     //control for route line animation
    const [cities, setCities] = useState(null);
    const [loading, setLoading] = useState(true);

    const layerStyle = {                                    //route line style
        id: 'line',
        type: 'line',
        paint: {
            'line-color': ['interpolate', ['linear'], ['line-progress'], 0, '#009AFF', 1, '#8CA5FF'],
            'line-width': 2,
            'line-opacity': 1,
        },
    };

    const getCityPlaces = (city) => {
        return new Promise((resolve, reject) => {
            Axios.get(process.env.REACT_APP_GET_CITY_PLACES + city.datamexico_municipality_id).then(placesRes => {
                resolve(placesRes.data.data)
            }).catch(err => {console.log(err), resolve(null)})
        })
    }

    //useEffect's ⬇️

    useEffect(() => {
        const map = mapRef.current
        if (!map) return

        typeOfResult === "generate" && map.setZoom(11);
    }, [videoFinish])

    useEffect(() => {
        async function getPlaces() {
            if (musts) {
                let cityArray = await Promise.all(musts.map(async (city, index) => {
                    let places = await getCityPlaces(city);

                    if (representationLetter) {
                        representationLetter.forEach((page) => {
                            page[city.municipality_name]?.forEach((place) => {
                                if (places) {
                                    const indexGroup = places.sections.findIndex(section => section.sectionName.toString().toLowerCase() === place.groupName.toString().toLowerCase())

                                    if (indexGroup !== -1) {
                                        places.sections[indexGroup].sectionPois.push({
                                            poiName: place.placeName,
                                            description: place.description,
                                            link: place.link,
                                            longitude: place.longitude,
                                            latitude: place.latitude,
                                            heading: place.heading,
                                            pitch: place.pitch,
                                            zoom: place.zoom,
                                        })
                                    } else {
                                        places.sections.push({
                                            sectionName: place.groupName,
                                            sectionPois: [{
                                                poiName: place.placeName,
                                                description: place.description,
                                                link: place.link,
                                                longitude: place.longitude,
                                                latitude: place.latitude,
                                                heading: place.heading,
                                                pitch: place.pitch,
                                                zoom: place.zoom,
                                            }]
                                        })
                                    }
                                } else {
                                    places = {
                                        sections: [{
                                            sectionName: place.groupName,
                                            sectionPois: [{
                                                poiName: place.placeName,
                                                description: place.description,
                                                link: place.link,
                                                longitude: place.longitude,
                                                latitude: place.latitude,
                                                heading: place.heading,
                                                pitch: place.pitch,
                                                zoom: place.zoom,
                                            }]
                                        }]
                                    }
                                }
                            })
                        })
                    }

                    const cityBorderDistance = borderDistances.find(cityBorder => cityBorder.datamexico_municipality_id === city.datamexico_municipality_id)
                    ?.distances.reduce((acc, curr) => {
                        if (curr.distance < acc) {
                            return curr.distance
                        }

                        return acc
                    }, Infinity)
                    
                    return {
                        id: city._id,
                        name: city.municipality_name,
                        displayName: city.municipality_name,
                        state: city.state_name,
                        img: popupImg,
                        population: city.population,
                        ecoPopu: city.EAP,
                        manuCompany: city.manufacturing_industries,
                        // machCompany: "137",
                        // distPort: "1,015",
                        distBorder: cityBorderDistance,
                        lngLat: [city.longitude, city.latitude],
                        streetviewPlaces: places ? places.sections : [],
                        popupOpen: false,
                        border: city.border,
                        urlPhoto: city.urlPhoto
                    };
                }));
                setCities(cityArray);
                setLoading(false);
            }
        }

        getPlaces();
    }, [musts])


    useEffect(() => {                                       //add event listener for escape key to close popup's
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                closePopup()
            }
        }
        );

        return () => {
            document.removeEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    closePopup()
                }
            }
            );
        };
    }, [])

    useEffect(() => {
        flyToDestination(mexicoCenter)
    }, [finish])

    useEffect(() => {
        const map = mapRef.current
        if (!map) return

        const updateZoom = () => {
            const zoom = map.getZoom()
            if (zoom < 5 || zoom >= 7) {
                setHide(true)
            } else {
                setHide(false)
            }
        }

        map.on("zoom", updateZoom)

        return () => {
            map.off("zoom", updateZoom)
        };
    }, [hide, finish])

    useEffect(() => {                                       //request for route coordenates to the API
        const fetchData = async () => {
            try {
                const { data } = waypoints.border[0] && waypoints.border[1] ? await Axios.get(
                    `https://api.mapbox.com/directions/v5/mapbox/driving/${waypoints.start[0]},${waypoints.start[1]};${waypoints.border[0]},${waypoints.border[1]};${waypoints.end[0]},${waypoints.end[1]}?overview=full&geometries=geojson&access_token=${mapboxgl.accessToken}`
                ) : await Axios.get(
                    `https://api.mapbox.com/directions/v5/mapbox/driving/${waypoints.start[0]},${waypoints.start[1]};${waypoints.end[0]},${waypoints.end[1]}?overview=full&geometries=geojson&access_token=${mapboxgl.accessToken}`
                );
                // console.log(data)
                setDistance({
                    km: Math.ceil((data.routes[0].distance) / 1000),
                    miles: Math.ceil((data.routes[0].distance) * 0.000621371)
                })
                setRoute(data.routes[0].geometry.coordinates)
                setInProgress(initial ? false : true)
                if (initial) {
                    setRouteGeoJSON({
                        type: 'FeatureCollection',
                        features: [
                            { type: 'Feature', geometry: { type: 'LineString', coordinates: data.routes[0].geometry.coordinates } }
                        ]
                    })
                }
            } catch (error) {
                console.log(error)
            }
        };

        if (waypoints.start.length > 0 && waypoints.end.length > 0) {
            fetchData();
        }
    }, [waypoints])

    useEffect(() => {                                       //animate line when new route is set
        if (!initial) {
            animate()
        }
    }, [route])

    useEffect(() => {                                       //set to false when route line animation is over
        if (route.length === routeGeoJSON.features[0].geometry.coordinates.length) {
            setInProgress(false)
        }
    }, [routeGeoJSON.features[0].geometry.coordinates])

    useEffect(() => {
        switch (activebutton) {
            case "wants":
                setMapclass("map hidden-left");
                break;
            case "summary":
                setMapclass("map hidden-right");
                break;
            case "edit":
                setMapclass("map hidden-left");
                break;
            default:
                setMapclass("map");
                break;
        }
    }, [activebutton])

    useEffect(() => {
        if (!cities) return
        
        const citiesCopy = [...cities]

        const isPopupOpen = citiesCopy.some(city => city.popupOpen)

        setMobilePopupOpen(isPopupOpen)
    }, [cities])

    //handlers ⬇️

    function handleClosePopup(event) {                      //close popup when clicking in the map or another marker
        const { className } = event.target
        if (className === "mapboxgl-canvas" || className === "marker-popup") {
            closePopup()
        }
    }

    function handlePopup(id, lngLat) {                      //open marker popup and move to the center of the marker
        setCities(
            cities.map((city) => {
                return {
                    ...city,
                    popupOpen: city.id === id ? !city.popupOpen : false
                };
            })
        )
        
        if (poi.length > 1) {
            handleShowPlaceInfo(id)
        }

        flyToDestination(lngLat, 7, false)
    }

    function setNewRoute(start, border, end) {
        setInitial(false)                                       //set to false so animation will happen
        setInProgress(true)                                     //set to true animation in progress

        setRouteGeoJSON({                                       //clear coordinates from previous route state
            type: 'FeatureCollection',
            features: [
                { type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }
            ]
        })

        setWaypoints({                                          //set new origin coordinate
            ...waypoints,
            start: start,
            border: [border.longitude, border.latitude],
            end: end
        })

        closePopup(start, border, end)                                            //close popup's

        const zoom = calculateZoom(start, end)       //calculte zoom for viewport
        const center = calculateCenter(start, end)   //calculate new center of route
        flyToDestination(center, zoom, false)                   //fly to new center / false in parameters mean for differents values inside function for the fly animation
    }

    function handleStreetView(id) {                         //open streetview window
        setStreetviewInfo({
            open: true,
            id
        })
        closePopup()
    }

    function handleShowPlaceInfo(id) {                      //open info for non interactable markers
        poi.length > 0 && setPoi(poi.map((place) => {
            return {
                ...place,
                popupOpen: place.id === id ? !place.popupOpen : false
            }
        }))
    }

    // utils ⬇️

    function flyToDestination(lngLat, zoom, first = true) { //make transition either the first one or for setting new route
        const map = mapRef.current
        if (!map) return

        if (first) {
            map.once("moveend", () => {
                setFlyAnimationInProgress(false);
                setHide(false)
            })
        }

        setFlyAnimationInProgress(first ? true : false);

        map.flyTo({
            center: lngLat,
            essential: true,
            curve: first ? 1.5 : 3,
            speed: first ? 1.3 : 0.2,
            pitch: 1,
            zoom: first ? 5 : zoom,
            duration: first ? 15000 : 1500,
        })
    }

    function animate() {                                    //animate route line from origin to destination
        for (let index = 0; index < route.length; index++) {
            setTimeout(() => {
                setRouteGeoJSON((prevRouteGeoJSON) => {
                    const newCoordinates = [...prevRouteGeoJSON.features[0].geometry.coordinates];
                    newCoordinates.push(route[index]);
                    return {
                        ...prevRouteGeoJSON,
                        features: [
                            {
                                ...prevRouteGeoJSON.features[0],
                                geometry: {
                                    ...prevRouteGeoJSON.features[0].geometry,
                                    coordinates: newCoordinates,
                                },
                            },
                        ],
                    };
                });
            }, (1000 / route.length) * index);
        }
    }

    function calculateCenter(start, end) {                  //calculate center between to coordenates
        const newLngCenter = (start[0] + end[0]) / 2
        const newLatCenter = (start[1] + end[1]) / 2

        return [newLngCenter, newLatCenter]
    }

    function calculateZoom(start, end) {                    //calculate the appropiate zoom of the map
        const R = 6371; // Earth's radius in kilometers
        const dLat = (end[1] - start[1]) * (Math.PI / 180);
        const dLon = (end[0] - start[0]) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(start[1] * (Math.PI / 180)) * Math.cos(end[1] * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers

        if (distance > 2400.8825180123054) return 3.9
        if (distance > 2000.541144483979 && distance <= 2400.8825180123054) return 4.5
        if (distance > 1600.1472920255646 && distance <= 2000.541144483979) return 5
        if (distance > 1100.9233141416778 && distance <= 1600.1472920255646) return 5.5
        if (distance > 900.0559377080356 && distance <= 1100.9233141416778) return 6
        if (distance > 500.1684068131266 && distance <= 900.0559377080356) return 6.5
        if (distance <= 500.1684068131266) return 7

    }

    async function closePopup(start = [null, null], border = [null, null], end = [null, null]) {  
        cities &&                               //closepopup util function
        setCities(
            cities.map((city) => {
                return {
                    ...city,
                    popupOpen: false
                };
            }))

        poi.length > 0 && !end[0] && !end[1] && setPoi(poi.map((place) => {
            return {
                ...place,
                popupOpen: false
            }
        })
        )

        if (end[0] && end[1]) {
            const destiny = assumptions.logisticsOutbound.destinations.find((destiny) => destiny.longitude === end[0] &&
                destiny.latitude === end[1])
            if (border.longitude && border.latitude) {
                let dataBorderInfo = null
                try {
                    const dataMexicoBorder = await Axios.patch(process.env.REACT_APP_FIND_CITY_BY_NAME, {name: border.city === "Reynosa" ? "Reynosa Metroplex" : border.city})
                    if (dataMexicoBorder.data) {
                        const borderInfo = await Axios.get(process.env.REACT_APP_GET_CITY_BORDER_INFO + dataMexicoBorder.data.datamexico_municipality_id)
                        dataBorderInfo = borderInfo.data
                    }
                } catch (error) {
                    console.error(error)
                }
                const poiArray = [{
                    id: 1,
                    name: destiny?.city,
                    subName: "Final destination",
                    lngLat: [destiny?.longitude, destiny?.latitude],
                    popupOpen: false,
                },
                {
                    id: dataBorderInfo ? dataBorderInfo.datamexico_municipality_id : 101010101,
                    annual_movements: dataBorderInfo ? (dataBorderInfo.annual_movements !== "" ? dataBorderInfo.annual_movements : "No data found") : "No data found",
                    img: dataBorderInfo ? (dataBorderInfo.image?.filename !== "" ? process.env.REACT_APP_BACKEND_URL + dataBorderInfo.image?.path : popupImg) : popupImg,
                    camaras_link: dataBorderInfo ? (dataBorderInfo.camaras_link !== "" ? dataBorderInfo.camaras_link : "No data found") : "No data found",
                    waiting_times_link: dataBorderInfo ? (dataBorderInfo.waiting_times_link !== "" ? dataBorderInfo.waiting_times_link : "No data found") : "No data found",
                    historical_times_link: dataBorderInfo ? (dataBorderInfo.historical_times_link !== "" ? dataBorderInfo.historical_times_link : "No data found") : "No data found",
                    name: border.city,
                    state: border.state,
                    subName: "Border city",
                    lngLat: [border.longitude, border.latitude],
                    popupOpen: false,

                }]
                setPoi(poiArray)
            } else {
                const poiArray = [{
                    id: 1,
                    name: destiny?.city,
                    subName: "Final destination",
                    lngLat: [destiny?.longitude, destiny?.latitude],
                    popupOpen: false,
                }]
                setPoi(poiArray)
            }
        }
    }

    return (
        <main onClick={handleClosePopup} className={mapclass}>
            {loading ? (
                <div style={{ background: '#041A39' }}></div>
            ) : (
                <>
                
                    <ReactMapGL
                        ref={mapRef}
                        initialViewState={{
                            longitude: typeOfResult === "generate" && client ? client.headquarters.longitude : mexicoCenter[0], //longitude of headquarters
                            latitude: typeOfResult === "generate"  && client ? client.headquarters.latitude : mexicoCenter[1], //latitude of headquarters
                            // zoom: typeOfResult === "generate" ? 11 : 5,
                            // pitch: typeOfResult === "generate" ? 60 : 1,
                            zoom: typeOfResult === "generate" ? 1 : 5,
                            pitch: typeOfResult === "generate" ? 60 : 1,
                        }}
                        style={{ width: "100vw", height: "100vh", height: "100dvh" }}
                        mapStyle="mapbox://styles/webshau/clka2yg1r004g01rm2a9va4h4"
                        mapboxApiAccessToken={mapboxgl.accessToken}
                        projection={"globe"}
                        boxZoom={!flyAnimationInProgress || typeOfResult === "preview"}
                        doubleClickZoom={!flyAnimationInProgress || typeOfResult === "preview"}
                        dragRotate={!flyAnimationInProgress || typeOfResult === "preview"}
                        dragPan={!flyAnimationInProgress || typeOfResult === "preview"}
                        keyboard={!flyAnimationInProgress || typeOfResult === "preview"}
                        touchPitch={!flyAnimationInProgress || typeOfResult === "preview"}
                        touchZoomRotate={!flyAnimationInProgress || typeOfResult === "preview"}
                        scrollZoom={!flyAnimationInProgress || typeOfResult === "preview"}
                    >
                        {cities && cities.map((city) => {
                            return (
                                <Marker                                             //marker for each of the studied cities
                                    key={`section-${city.id}`}
                                    longitude={city.lngLat[0]}
                                    latitude={city.lngLat[1]}
                                    anchor="bottom"
                                    style={city.popupOpen ? { zIndex: "9999" } : { zIndex: "1" }}
                                >
                                    <section className="marker-popup" onClick={() => handlePopup(city.id, city.lngLat)}>
                                        <img id={city.id} src={city.popupOpen ? markerLightActive : markerLight}
                                            className={`marker ${city.popupOpen ? "active" : ""}`} />
                                        <CustomPopup
                                            city={city}
                                            handlePopup={handlePopup}
                                            setNewRoute={setNewRoute}
                                            handleStreetView={handleStreetView}
                                            inProgress={inProgress}
                                            assumptions={assumptions}
                                            allAssumptions={allAssumptions}
                                            representationLetterActive={representationLetterActive}
                                        >
                                        </CustomPopup>
                                        <div className={`cityTitle ${hide ? "hide" : ""}`}>
                                            <h5>{city.displayName}</h5>
                                        </div>
                                    </section>
                                </Marker>
                            )
                        })
                        }

                        {poi.length > 0 && poi.map((place) => {
                            if (place.subName === "Border city") {
                                return (
                                    <Marker
                                        key={`place-${place.id}`}
                                        longitude={place.lngLat[0]}
                                        latitude={place.lngLat[1]}
                                        anchor="bottom"
                                        style={{ zIndex: "9999" }}
                                    >
                                        <section className="marker-popup" onClick={() => handlePopup(place.id, place.lngLat)}>
                                            <img id={place.id} src={place.popupOpen ? markerLightActive : markerLight}
                                                className={`marker ${place.popupOpen ? "active" : ""}`}/>
                                            <CustomPopup
                                                city={place}
                                                handlePopup={handlePopup}
                                                // setNewRoute={setNewRoute}
                                                // handleStreetView={handleStreetView}
                                                inProgress={inProgress}
                                                // assumptions={assumptions}
                                            >
                                            </CustomPopup>
                                        </section>
                                    </Marker>
                                )
                            } else {
                                return (
                                    <Marker
                                        key={`place-${place.id}`}
                                        longitude={place.lngLat[0]}
                                        latitude={place.lngLat[1]}
                                        anchor="bottom"
                                        style={{ zIndex: "999" }}
                                    >
                                        <section className="placeMarker" onClick={() => handleShowPlaceInfo(place.id)}>
                                            <img id={place.id} src={nonInteractable} />
                                            <div className={`placeTitleContainer ${place.popupOpen ? "show" : ""}`} >
                                                <span className="placeTitle">{place.name}</span>
                                                <span className="placeSubTitle">{place.subName}</span>
                                            </div>
                                        </section>
                                    </Marker>
                                )
                            }
                        })
                        }

                        {route.length > 0 && <Marker                                                     //Marker to show route distance between origin and destination
                            longitude={route[Math.ceil(route.length / 2)][0] + 0.7}
                            latitude={route[Math.ceil(route.length / 2)][1] - 0.3}
                            anchor="left"
                        >
                            <div className="distance">
                                <span>{distance.km} Km / {distance.miles} Miles</span>
                            </div>
                        </Marker> }
                        <Source id="my-data" type="geojson" data={routeGeoJSON} >
                            <Layer id="my-layer" {...layerStyle} />
                        </Source>
                    </ReactMapGL>
                    {streetviewInfo.open && <StreetviewMap streetviewInfo={streetviewInfo} setStreetviewInfo={setStreetviewInfo} cities={cities} />}
                </>
            )}
        </main>
    )
}