import "../../assets/css/reportLog/reportLog.css"

import { useState } from "react"
import { Row, Col } from "react-bootstrap"

import AllReports from "./allReports.jsx"
import close from "../../assets/images/close.svg"
import minimize from "../../assets/images/minimize.svg"

export default function ReportLog(props) {
    const [search, setSearch] = useState("")                        //value for search input bar
    const [isMinimized, setIsMinimized] = useState(false)           //control for minimized report section
    
    function handleSearch(e) {                                      //control for search input bar value
        const { value } = e.target
        setSearch(value)
    }

    function closeReportLog() {                                     //control for close report section
        props.setActivateFetch(false)
        props.setReportLogOpened(false)
        setIsMinimized(false)
    }
    
    function minimizeReportLog() {                                  //control for minimized report section
        props.setActivateFetch(false)
        props.setReportLogOpened(false)
        setIsMinimized(true)
    }

    return (
        <section className={`containerSectionReport ${props.isOpened ? "opened" : ""}`}>
            <div className="containerMinimizeClose">
                <img src={minimize} alt="Prodensa automation" onClick={minimizeReportLog}/>
                <img src={close} alt="Prodensa automation" onClick={closeReportLog}/>
            </div>
            <div className="container">
                <Row>
                    <Col className="containerHeader" lg={{span: 11, offset: 1 }} xs={{span: 10, offset: 1}}>
                        <div className="reportLogTitle">
                            <h1>Report Log</h1>
                            <p>View, edit, duplicate, and delete reports in draft and generated.</p>
                        </div>
                        <div className="searchContainer">
                            <input className="search" type="text" placeholder="Search" value={search} 
                                onChange={handleSearch}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{span: 11, offset: 1 }} xs={{span: 10, offset: 1}}>
                        <AllReports 
                            isOpened={props.isOpened} 
                            search={search} 
                            showClientId={props.showClientId} 
                            setshowClientId={props.setshowClientId} 
                            isMinimized={isMinimized} 
                            allClients={props.allClients}
                            openDraft={props.openDraft} 
                            closeReportLog={closeReportLog} 
                            inProgressReports={props.inProgressReports} />
                    </Col>
                </Row>
            </div>
        </section>
    ) 
}