import save from "../../assets/images/save.svg"
import { useState, useEffect } from "react"

export default function NavbarSearch(props) {
  const [clients, setClients] = useState(props.allClients)
  const [filteredClients, setFilteredClients] = useState(props.allClients)
  const [search, setSearch] = useState("")
  
  useEffect(() => {
    setClients(props.allClients)
    setFilteredClients(props.allClients)
  }, [props.allClients])
  

  useEffect(() => {
    setFilteredClients(SearchBarFilter(clients, search))
  }, [search])
  
  const handleViewAllReports = () => {
    props.setShowSearchNav(false)
    props.openReportLog()
  }

  const handleHideSearchNav = () => {
    props.setShowSearchNav(false)
    document.body.style.overflow = "auto";
  }

  const handleClientReports = (id) => {
    props.setshowClientId(id)
    props.openReportLog()
    props.setShowSearchNav(false)
  }

  const capitalizedName = (name) => {
		return name.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
	}

  const SearchBarFilter = (clients, searchTerm) => {
    if (searchTerm !== "") {
      const filteredData = clients.filter(client => client.companyName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return filteredData;
    }
    return clients;
  }
  
  return (
    <section className={`navbarSearch ${props.showSearchNav ? "show" : ""}`}>
      <div className="navbarSearch-header">
        <h4 className="navbarSearch-title" onClick={() => handleHideSearchNav()}>Search</h4>
        <button className="primaryButton" onClick={() => handleViewAllReports()}>
          <img src={save} alt="Prodensa Automation" />
          <span>View all reports</span>
        </button>
      </div>
      <div className="navbarSearch-input-container position-relative">
        <input type="text" placeholder="Search client reports" value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="navbar-clients-section">
        {filteredClients.map(client => (
          <div key={client._id} className="navbarSearch-client"
            onClick={() => handleClientReports(client._id)}
          >
            <div>
              <h4 className="navbarSearch-client-title">{capitalizedName(client.companyName)}</h4>
            </div>
            <div className="navbarSearch-client-info">
              <h4 className="navbarSearch-client-title">{capitalizedName(client.contactName)}</h4>
              <span>{client.mail}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}