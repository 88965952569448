import {SiteSelectionReport} from './siteSelectionReport.ts';

export class Draft {
  constructor(_id?, reportName?, activeMenu?, siteSelectionReport?) {
    this._id = _id;
    this.reportName = reportName;
    this.activeMenu = activeMenu;
    this.siteSelectionReport = siteSelectionReport;
  }

  public _id: String;
  public reportName: String;
  public activeMenu: number = 0;
  public siteSelectionReport: SiteSelectionReport = new SiteSelectionReport();
}
