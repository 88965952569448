import "../../assets/css/streetviewconfig/streetviewconfig.css"
import "../../assets/css/borderInfo/borderInfo.css"

import Axios from "axios";
import Swal from "sweetalert2";
import { useEffect, useRef, useState } from "react"
import { Container } from 'react-bootstrap'

import close from "../../assets/images/close.svg"
import checkicon from "../../assets/images/check.svg"
import { customAlert } from "../../utils/customAlert";
// import minimize from "../../assets/images/minimize.svg"
// import deleteIcon from "../../assets/images/delete.svg"

const BorderConfig = (props) => {
	const [borderInfo, setBorderInfo] = useState({
		annual_movements: "",
		image: {
			filename: "",
			path: "",
		},
		camaras_link: "",
		waiting_times_link: "",
		historical_times_link: ""
	})
	const [cities, setCities] = useState([]);

	const [saved, setSaved] = useState(false);
	const [changeCity, setChangeCity] = useState(true);
	const [searchCity, setSearchCity] = useState("");

	/////////////////Choose city logic/////////////////////////////////////////
	const [isCityLookup, setIsCityLookup] = useState(false);
	const [selectedCity, setSelectedCity] = useState({});
	const [cityResults, setCityResults] = useState([]);
	const [selectedBorderFile, setSelectedBorderFile] = useState({});
	const searchCityButton = useRef(null);
	const imageFileRef = useRef(null);

	const citiesLookup = (searchTerm) => {
		setSearchCity(searchTerm); // Set the search term to the state
		const matchingCities = cities.length > 0 ? cities.filter(item => {
			const normalizedMunicipalityName = item.municipality_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			
			return normalizedMunicipalityName?.toLowerCase().includes(searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
		}) : []

		if (matchingCities.length > 0) {
			setCityResults(matchingCities); // Set a message if no matches found
		} else {
			setCityResults([{municipality_name: "No matching cities found"}]); // Set a message if no matches found
		}
	}

	const sendPlaces = async () => {
		if (!selectedCity.municipality_name) {
			Swal.fire({
				icon: "error",
				title: "Select a city",
				showConfirmButton: false,
				showCancelButton: false,
				timer: 2500,
				timerProgressBar: true,
			});
			return;
		}

		let data

		if (!selectedBorderFile.name && borderInfo.image?.filename !== "") {
			const { image, ...restOfBorderInfo } = borderInfo
			restOfBorderInfo.cityId = selectedCity.datamexico_municipality_id
			data = {borderInfo: JSON.stringify(restOfBorderInfo)}
		} else {
			data = new FormData();
			if (selectedBorderFile.name) {
				data.append("cityId", selectedCity.datamexico_municipality_id.toString())
				data.append("image", selectedBorderFile);
			}
			const borderInfoData = {
				...borderInfo,
				cityId: selectedCity.datamexico_municipality_id
			}
			data.append("borderInfo", JSON.stringify(borderInfoData));
		}

		Axios.patch(process.env.REACT_APP_UPDATE_BORDER_INFO, data).then(res => {
			if(!res.data.data) {
				customAlert(res.data.message)
				setSaved(false);
				setChangeCity(false)
				return
			}
			
			Swal.fire({
				position: "center",
				titleText: `Changes saved!`,
				showConfirmButton: false,
				imageUrl: checkicon,
				imageWidth: 50,
				imageHeight: 50,
				backdrop: 'rgba(0, 0, 0, 0)',
				customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
				},
				timer: 2500,
				timerProgressBar: true,
			});
			setSaved(true);
			setChangeCity(true)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
			setSaved(false);
			setChangeCity(false)
		})
	}

	const handleChangeCity = (city) => {
		if (!changeCity) {
			Swal.fire({
				title: "Do you want to continue without save the changes?",
				showCancelButton: true,
				confirmButtonText: "Continue",
				customClass: {
					confirmButton: "swal2-button--confirm",
					cancelButton: "swal2-button--cancel",
				}
			}).then((result) => {
			if (result.isConfirmed) {
					setSelectedCity(city)
					Axios.get(process.env.REACT_APP_GET_CITY_BORDER_INFO + city.datamexico_municipality_id).then(res => {
						setBorderInfo({
							annual_movements: res.data ? res.data.annual_movements : "",
							image: {
								filename: res.data.image ? res.data.image?.filename : "",
								path: res.data.image ? res.data.image?.path : "",
							},
							camaras_link: res.data ? res.data.camaras_link : "",
							waiting_times_link: res.data ? res.data.waiting_times_link : "",
							historical_times_link: res.data ? res.data.historical_times_link : ""
						})
						setSelectedBorderFile({})
						setSearchCity("")
						setSaved(false)
						setChangeCity(true)
						setCityResults(cities)
					}).catch(err => {
						console.log(err)
						customAlert(err.response?.data?.message || err.response?.statusText)
					})
				}
			});
		} else {
			setSelectedCity(city)
			Axios.get(process.env.REACT_APP_GET_CITY_BORDER_INFO + city.datamexico_municipality_id).then(res => {
				setBorderInfo({
					annual_movements: res.data ? res.data.annual_movements : "",
					image: {
						filename: res.data.image ? res.data.image?.filename : "",
						path: res.data.image ? res.data.image?.path : "",
					},
					camaras_link: res.data ? res.data.camaras_link : "",
					waiting_times_link: res.data ? res.data.waiting_times_link : "",
					historical_times_link: res.data ? res.data.historical_times_link :""
				})
				setSelectedBorderFile({})
				setSearchCity("")
				setSaved(false)
				setChangeCity(true)
				setCityResults(cities)
			}).catch(err => {
				console.log(err)
				customAlert(err.response?.data?.message || err.response?.statusText)
			})
		}
	}
	
	const closeMenu = (e) => {
		if (searchCityButton.current && !searchCityButton.current.contains(e.target)) {
			setIsCityLookup(false)
            setSearchCity("")
		}
	}

	const handleCloseBorderConfig = () => {
		setBorderInfo({
			annual_movements: "",
			camaras_link: "",
			image: {
				filename: "",
				path: "",
			},
			waiting_times_link: "",
			historical_times_link: ""
		})
		setSaved(false)
		setSelectedBorderFile({})
		setChangeCity(true)
		setSelectedCity({})
		setIsCityLookup(false)
		setSearchCity("")
		props.setBorderConfOpened(null)
	}

	const handleInputs = (target) => {
		setBorderInfo(prevState => {
			return {
				...prevState,
				[target.name]: target.name.includes("link") ? target.value.replace(/%20/g, "").replace(/\s/g, "") : target.value
			}
		})
		setSaved(false)
		setChangeCity(false)
	}

	const selectBorderImage = async (e) => {
		const allowTypes = ["image/png", "image/jpg", "image/jpeg"];
		const selectedFile = e.target.files[0];

		if (selectedFile && !allowTypes.includes(selectedFile?.type)) {
			await Swal.fire({
				icon: "error",
				title: "File not supported",
				showConfirmButton: false,
				showCancelButton: false,
				timer: 2500,
				timerProgressBar: true,
			});
			return;
		}
		
		setSelectedBorderFile(selectedFile)
	}

	useEffect(() => {
		Axios.get(process.env.REACT_APP_GET_ALL_CITIES).then(citiesRes => {
			setCities(citiesRes.data)
			setCityResults(citiesRes.data)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}, [])

	useEffect(() => {
		document.body.addEventListener('click', closeMenu);

		return function cleanup() {
			document.body.removeEventListener('click', closeMenu);
		}
	}, []);

	return (
		<section className={`street-config-wrapper ${props.isOpened === 3 ? "opened" : ""}`}>
			<div className="containerMinimizeClose">
				{/* <img src={minimize} alt="Prodensa automation" /> */}
				<img src={close} alt="Prodensa automation" onClick={handleCloseBorderConfig} />
			</div>
			<Container>
				<div className="head-wrapper">
					<div className="head-text">
						<h1>Add information to border</h1>
						<h5>It is a long established fact that a reader will be distracted by the readable
							content of a page when looking at its layout
						</h5>
					</div>
					<div className="save-changes-container">
					<button className="blue-bg-button" onClick={() => sendPlaces()}><span className="d-none d-lg-block">Save changes</span></button>
						<p className={saved ? "saved" : ""}>{saved ? "Saved!" : "Changes not saved"}</p>
					</div>
				</div>
				<div className="choose-city">
					<button className="choose" ref={searchCityButton}
						onClick={() => setIsCityLookup(!isCityLookup)}>{selectedCity.municipality_name ? selectedCity.municipality_name : "Select a city"}
                    </button>
					<div className={`city-lookup-wrapper ${isCityLookup ? "active" : ""}`} onClick={(e) => e.stopPropagation()}>
						<div className="lookup-ghost-wrapper">
							<input type="text" className="city-search-input" placeholder="Search cities" value={searchCity} onChange={(e) => citiesLookup(e.target.value)}/>
						</div>
					</div>
					<div className={`city-result-wrapper ${searchCity !== "" ? "active" : ""}`}>
						{
							cityResults.map(city => (
								<div className={`city-result${cityResults.length === 0 ? "-empty" : ""}`} key={`city-${city._id}`} onClick={() => { handleChangeCity(city); setIsCityLookup(false) }}>
									<h5>{`${city.municipality_name}${city.state_name ? `, ${city.state_name}` : ''}`}</h5>
								</div>
							))
						}
					</div>
				</div>

				<section className="container">
						<div className="inputGroupContainer">
								<span>Annual movements</span>
								<input className="border-info-input" type="text" name="annual_movements" value={borderInfo.annual_movements} 
									onChange={(e) => handleInputs(e.target)} 
									disabled={!selectedCity.municipality_name}
								/>
						</div>
						<div className="inputGroupContainer">
								<span>Picture</span>
								<div className="file-container">
									<button className="border-file-input" onClick={() => imageFileRef.current && imageFileRef.current.click()} 
										disabled={!selectedCity.municipality_name}>
											<span>
												Upload border Image
											</span>
									</button>
									<input
										type="file"
										ref={imageFileRef}
										style={{ display: "none" }}
										accept=".png, .jpg, .jpeg"
										name="border_image"
										onChange={(e) => selectBorderImage(e)}
									/>
									<span>{borderInfo.image.filename || (selectedBorderFile?.name || "No file selected")}</span>
								</div>
						</div>
						<div className="inputGroupContainer">
								<span>Link for live camaras</span>
								<input className="border-info-input" type="text" name="camaras_link" value={borderInfo.camaras_link} 
									onChange={(e) => handleInputs(e.target)} 
									disabled={!selectedCity.municipality_name}
								/>
						</div>
						<div className="inputGroupContainer">
								<span>Link for waiting times</span>
								<input className="border-info-input" type="text" name="waiting_times_link" value={borderInfo.waiting_times_link} 
									onChange={(e) => handleInputs(e.target)} 
									disabled={!selectedCity.municipality_name}
								/>
						</div>
						<div className="inputGroupContainer">
								<span>Link for historic waiting times</span>
								<input className="border-info-input" type="text" name="historical_times_link" value={borderInfo.historical_times_link} 
									onChange={(e) => handleInputs(e.target)} 
									disabled={!selectedCity.municipality_name}
								/>
						</div>
				</section>
			</Container>
		</section>
	);
}
export default BorderConfig;