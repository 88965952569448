
import { OverlayTrigger } from "react-bootstrap"

import iconBlue from "../../../assets/images/info-blue.svg"
import wants_pdf from "../../../assets/pdfImages/wants-pdf.jpg"

export default function WantsForms({ pdfConfig, draft, updateInput, imageTooltip, wantsMatrixReference }) {
    const wants = draft?.siteSelectionReport?.want?.wantsMatrix

    return (
        <div>
            {wants?.filter(cat => cat.weight !== 0).map(category => {
                const catId = category.catIdRef
                const catRef = wantsMatrixReference.find(cat => cat._id === catId)
                const wantData = pdfConfig?.wantsData?.find(pdfWant => pdfWant.id === catId)

                const wantDescription = wantData?.description ?? ""
                
                return (
                    <div key={catId}>
                        <div className="want-title-container">
                            <h5>{catRef.title}</h5>

                            <span>Weight {category.weight}%</span>
                        </div>

                        <div className="inputContainer">
                            <div className="input-label-container">
                                <label htmlFor={`${catId}-textarea`}>Definition</label>
                                <span>(Max. 200 characters)</span>
                                <OverlayTrigger placement="top" overlay={imageTooltip(wants_pdf)}>
                                    <img src={iconBlue} alt="Prodensa Automation" />
                                </OverlayTrigger>
                            </div>

                            <textarea 
                                value={wantDescription}
                                onChange={(e) => updateInput("wantsData", "description", e.target.value, catId)}
                                id={`${catId}-textarea`} 
                                placeholder="Want description"
                                maxLength={"200"}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
