import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useMemo, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";
import { v4 as uuidv4 } from "uuid";


const HousingCost = ({ details, city_names, filtered, reportID, variableCosts, assumptions }) => {
    //For test before integration
    // const details = {
    // cost_month:[300,540,445,500,600,500],
    // month_single_cost:[175,205,175,200,250,150],
    // total_usd:[475,745,620,600,500,700],
    // difference_perc:["Lower Value",36,23,20,25,30]
    // };

    // const city_names = ["Saltillo", "Torreon", "Monterrey", "Mexicali", "Queretaro", "Yucatan"];
    const [loading, setLoading] = useState(true);
    const exchangeRate = assumptions.exchangeRate ? assumptions.exchangeRate : 17.5;

    const DETAILS_ROWS = useMemo(() => {
        return [
            {
                title: "Cost of Housing(1 month rent)",
                access: "costOfHousing",
                id: uuidv4()
            },
            {
                title: "Cost of Gas",
                access: "costOfGas",
                id: uuidv4()
            },
            {
                title: "Cost of Utilities",
                access: "costOfUtilities",
                id: uuidv4()
            },
            {
                title: "Cost of Food",
                access: "costOfFood",
                id: uuidv4()
            },
            {
                title: "Total USD",
                id: uuidv4()
            },
            {
                title: "Difference from highest value(%)",
                id: uuidv4()
            }
        ];
    }, []);
    
    useEffect(() => {
        if (variableCosts) {
            setLoading(false);
        }
    }, [])


    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>Loading...</div>
            ) : (variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3}>
                            <h5 className="first-spacer">Concept</h5>
                            <h5 className="side-text">Cost of Housing(1 month rent)</h5>
                            <h5 className="side-text">Cost of Gas</h5>
                            <h5 className="side-text">Cost of Utilities</h5>
                            <h5 className="side-text">Cost of Food</h5>
                            {/* <h5 className="side-text">Estimated monthly costs for a single person</h5> */}
                            <h5 className="side-text">Total USD</h5>
                            <h5 className="side-text">Difference from highest value(%)</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                    if (cityFind) {
                                        if (cityFind.dataVariables.costOfLiving.costOfHousing !== "") {
                                            textToShow = formatNumber(cityFind.dataVariables.costOfLiving.costOfHousing / exchangeRate);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number"  key={`${city}-cost-of-housing`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                    if (cityFind) {
                                        if (cityFind.dataVariables.costOfLiving.costOfGas !== "") {
                                            textToShow = formatNumber(cityFind.dataVariables.costOfLiving.costOfGas / exchangeRate);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`${city}-cost-of-gas`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                    if (cityFind) {
                                        if (cityFind.dataVariables.costOfLiving.costOfUtilities !== "") {
                                            textToShow = formatNumber(cityFind.dataVariables.costOfLiving.costOfUtilities / exchangeRate);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`${city}-cost-of-utilities`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                    if (cityFind) {
                                        if (cityFind.dataVariables.costOfLiving.costOfFood !== "") {
                                            textToShow = formatNumber(cityFind.dataVariables.costOfLiving.costOfFood / exchangeRate);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`${city}-cost-of-food`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            {/* <div className="row-number">
                            {details.month_single_cost.map((singlePerson, index) =>
                                filtered.includes(index) &&
                                <h5 className="number">{singlePerson}</h5>
                            )}
                        </div> */}
                            <div className="row-number">
                                {city_names.map((total, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = details.results.find((cityFind) => cityFind.city === total);
                                    if (cityFind) {
                                        if (cityFind.valueResult.totalCost !== null) {
                                            textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`${total}-total-cost`}>{textToShow}</h5>
                                        )
                                    );
                                }
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((difference, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = details.results.find((cityFind) => cityFind.city === difference);
                                    if (cityFind) {
                                        if (cityFind.valueResult.difference !== null) {
                                            if (cityFind.valueResult.bestOption) {
                                                textToShow = "Lowest Value";
                                            } else {
                                                textToShow = formatNumber(cityFind.valueResult.difference) + "%";
                                            }
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`${difference}-difference`}>{textToShow}</h5>
                                        )
                                    );
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map(
                                        (city, index) =>
                                            filtered.includes(index) &&
                                            <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {DETAILS_ROWS.map((row) => (
                                    <tr key={row.id}>
                                        <td>{row.title}</td>
                                        {city_names.map((city, index) => {
                                            let textToShow = "N/A";
                                            let cityFind = variableCosts.find((cityFind) => cityFind.cityName === city);
                                            if (cityFind) {
                                                if (row.access) {
                                                    if (cityFind.dataVariables.costOfLiving[row.access] !== "") {
                                                        textToShow = formatNumber(cityFind.dataVariables.costOfLiving[row.access] / exchangeRate);
                                                    }
                                                } else if (row.title === "Total USD") {
                                                    let cityFind = details.results.find((cityFind) => cityFind.city === city);
                                                    if (cityFind) {
                                                        if (cityFind.valueResult.totalCost !== null) {
                                                            textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost) : "N/A";
                                                        }
                                                    }
                                                } else if (row.title === "Difference from highest value(%)") {
                                                    let cityFind = details.results.find((cityFind) => cityFind.city === city);
                                                    if (cityFind) {
                                                        if (cityFind.valueResult.difference !== null) {
                                                            if (cityFind.valueResult.bestOption) {
                                                                textToShow = "Lowest Value";
                                                            } else {
                                                                textToShow = formatNumber(cityFind.valueResult.difference) + "%";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            return (
                                                filtered.includes(index) && (
                                                    <td className={row.title === "Difference from highest value(%)" ? "red" : ""} key={`${city}-${row.id}`}>{textToShow}</td>
                                                )
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            ))
            }

        </Container >
    )
}

export default HousingCost;