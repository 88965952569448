import "../../assets/css/siteSelection/siteSelection.css"

import Axios from "axios"
import moment from "moment";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Musts from "./musts/musts.jsx";
import Wants from "./wants/wants.jsx";
import { useAuth } from "../../context/auth-context";
import save from "../../assets/images/save.svg"
import edit from "../../assets/images/edit.svg"
import { Client } from "../../models/client.ts";
import close from "../../assets/images/close.svg"
import LogisticCosts from "./wants/logisticsCost.jsx";
import ConfigurePdf from "./pdfConfigure/configurePdf.jsx"
import ClientSection from "./client/clientSection.jsx";
import minimize from "../../assets/images/minimize.svg"
import Assumptions from "./assumptions/assumptions.jsx";
import loadingGif from "../../assets/images/loading.gif";
import { VariableCost } from "../../models/variableCost.ts";
import checkGreen from "../../assets/images/check_green.svg";
import previewReport from "../../assets/images/previewReport.svg";
import generateReport from "../../assets/images/generateReport.svg";
import { customAlert } from "../../utils/customAlert.js";
import MobileFooter from "./mobileFooter.jsx";
import filePicture from "../../assets/images/description.svg"

function MenuButton(props) {
    return (
        <button className={`btnNavigation ${props.active ? "active" : ""}`}
            onClick={props.setActiveMenu}
            disabled={!props.hasClient && !props.active ? "disabled" : ""}>{props.menuTitle}</button>
    );
}

function SiteSelection(props) {
    // VARIABLES TO CONTROL ANIMATION
    // const [cookies] = useCookies(['user'])
    const { user } = useAuth()

    const [animateContainer, setAnimatecontainer] = useState(false);
    const [animateColumn, setAnimateColumn] = useState(false);
    const [savingDraft, setSavingDraft] = useState(false)
    const [savedDraft, setSavedDraft] = useState(false);
    const [draftStatus, setDraftStatus] = useState("Draft not saved");
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [showLogisticCosts, setShowLogisticCosts] = useState(false)
    const [showPdfConfigure, setShowPdfConfigure] = useState(false)
    const [pdfConfig, setPdfConfig] = useState(null)
    const [draft, setDraft] = useState();
    const [variableCost, setVariableCost] = useState()
    const [payrollFile, setPayrollFile] = useState({})
    const [savePayroll, setSavePayroll] = useState(false)
    const [client, setClient] = useState(new Client());
    const [textActionButton, setTextActionButton] = useState("");
    const [functionActionButton, setFunctionActionButton] = useState();
    const [activeClientSection, setActiveClientSection] = useState(0)
    const [textBackButton, setTextBackButton] = useState(0)
    const [representationLetter, setRepresentationLetter] = useState({});
    const [savedRepresentationLetter, setSavedRepresentationLetter] = useState(false)
    const wizardMenus = ["Client", "Assumptions", "Musts", "Wants"];
    const wizardMenuDescriptions = [
        "",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
    ];

    const navigate = useNavigate()

    useEffect(() => {
        if (props.selectedDraft && props.wantsMatrixReference) {
            setSavingDraft(false);
            setSavedDraft(false);
            setDraftStatus("Draft not saved");
            const filteredWantsMatrixReference = props.wantsMatrixReference.filter(item => !item.deletedRegister).map(item => {
                if (!item.deletedRegister) {
                    const { position, title, deletedRegister, _id, ...rest } = item;
                    const variables = rest.variables.filter(varItem => !varItem.deletedRegister).map(varItem => {
                        const { position, title, deletedRegister, _id, ...variableRest } = varItem;
                        variableRest.varIdRef = _id
                        return variableRest
                    })
                    rest.variables = variables
                    rest.catIdRef = _id
                    return rest
                }
            })
            if (props.selectedDraft._id === 0) {               
                const selectedDraftCopy = JSON.parse(JSON.stringify(props.selectedDraft))
                selectedDraftCopy.siteSelectionReport.want.wantsMatrix = filteredWantsMatrixReference
                setDraft(selectedDraftCopy);
            } else {
                setDraft(props.selectedDraft);
            }
            setPayrollFile(props.selectedDraft.siteSelectionReport.want.TPCDocument);
            setSavePayroll(props.selectedDraft.siteSelectionReport.want.TPCDocument.filename !== "");
            setRepresentationLetter(props.selectedDraft.siteSelectionReport.assumption.representationDocument);
            setSavedRepresentationLetter(props.selectedDraft.siteSelectionReport.assumption.representationDocument.filename !== "");

            if (props.selectedDraft._id === 0) {
                setClient(new Client());
                setVariableCost(new VariableCost());
                setActiveClientSection(0);
            } else {
                const fetchVariableCost = Axios.post(process.env.REACT_APP_FIND_VARIABLE_COST, {reportId: props.selectedDraft._id});
                const fetchClient = Axios.get(process.env.REACT_APP_FIND_CLIENT + props.selectedDraft.siteSelectionReport.clientId);
                const fetchPdfConfig = Axios.get(process.env.REACT_APP_GET_PDF_CONFIG + props.selectedDraft._id)

                Promise.all([fetchVariableCost, fetchClient, fetchPdfConfig])
                    .then(([variableCostRes, clientRes, pdfConfigRes]) => {
                        setVariableCost(variableCostRes.data.data);
                        setClient(clientRes.data);
                        setPdfConfig(pdfConfigRes.data)
                    })
                    .catch(err => {
                        console.log(err)
                        customAlert(err.response?.data?.message || err.response?.statusText)
                    });

                setActiveClientSection(1);
            }
        }
    }, [props.selectedDraft, props.wantsMatrixReference]);

    useEffect(() => {
        if (!loadingModal) {
            Swal.close()
            return
        }

        Swal.fire({
            title: 'Calculating results...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
    }, [loadingModal])

    useEffect(() => {
        if (props.isReportId && props.selectedDraft) {
            setDraft(prevState => {
                return {
                    ...prevState,
                    activeMenu: 3
                }
            })
            props.setIsReportId(false)
        }
    }, [variableCost])

    useEffect(() => {
        window.scrollTo(0, 0);

        switch (draft && draft.activeMenu) {
            case 0:
                setTextBackButton("Cancel");
                setTextActionButton("Submit");
                break;
            case 1:
                setTextBackButton("Cancel");
                setTextActionButton("Next");
                break;
            case 2:
                setTextBackButton("Previous");
                setTextActionButton("Next");
                break
            case 3:
                setTextBackButton("Previous");
                setTextActionButton("Generate report");
                break
        }
    }, [draft && draft.activeMenu])

    useEffect(() => {
        if (!props.isOpened) {
            setDraft()
            setPdfConfig(null)
        }
    }, [props.isOpened])

    const closeBackButton = () => {
        switch (draft.activeMenu) {
            case 0:
            case 1:
                closeSiteSelection();
            break;
            case 2:
            case 3:
                setActiveMenu(draft.activeMenu - 1);
        }
    }

    function openPdfConfig() {
        setShowPdfConfigure(true)
    } 

    const saveDraft = () => {
        return new Promise((resolve, reject) => {
            if(draft._id !== 0) {
                setSavedDraft(false);
                setSavingDraft(true);
                setIsDisable(true);
                if(payrollFile.name) {
                    const data = new FormData()
                    data.append('reportId', draft._id)
                    data.append('cities', JSON.stringify(draft.siteSelectionReport.must.cities))
                    data.append('payroll', payrollFile)
                    const updatedDraft = {...draft}
                    Axios.post(process.env.REACT_APP_UPDATE_PAYROLL, data).then(response => {
                        updatedDraft.siteSelectionReport.want.TPCDocument.filename = response.data.filename
                        updatedDraft.siteSelectionReport.want.TPCDocument.payrollFileId = response.data.fileUpdated._id
                        Axios.patch(process.env.REACT_APP_UPDATE_REPORT + draft._id, draft).then(reportRes => {
                            const selectedCities = reportRes.data.siteSelectionReport.must.cities.map(city => city.municipality_name);
                            const inboundGroupsSelected = reportRes.data.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map(inboundGroup => inboundGroup.groupName)
                            const inboundGroups = reportRes.data.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups
                            
                            const destinationsSelected = reportRes.data.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map(destination => destination.fullName)
                            const filterSelectedCities = variableCost.cities.filter(city => selectedCities.includes(city.cityName))
                            
                            const filterGroups = filterSelectedCities.map(city => {
                                return {
                                    ...city,
                                    inboundGroups: city.inboundGroups.filter(group => inboundGroupsSelected.includes(group.groupName)),
                                    outbound: city.outbound.filter(outbound => destinationsSelected.includes(outbound.destination))
                                }
                            })
                            
                            const finalFilter = filterGroups.map(city => {
                                const newCity = {
                                    ...city,
                                    inboundGroups: city.inboundGroups.map(variableGroup => {
                                        const newGroup = {
                                            ...variableGroup,
                                            origins: variableGroup.origins.filter(origin =>
                                                inboundGroups.some(group =>
                                                    group.groupName === variableGroup.groupName &&
                                                    group.origins.some(testOrigin =>
                                                        testOrigin.fullName === origin.origin
                                                    )
                                                )
                                            )
                                        };
                                        return newGroup;
                                    })
                                };
                                return newCity;
                            });
    
                            const saveVariableCost = {
                                ...variableCost,
                                cities: finalFilter,
                            }
                                Axios.patch(process.env.REACT_APP_UPDATE_VARIABLE_COST + variableCost._id, saveVariableCost).then(variableCostRes => {
                                    setVariableCost(variableCostRes.data)
                                    setSavedDraft(true);
                                    setDraftStatus("Draft saved: " + moment(new Date()).format("LLL"));
                                    setSavingDraft(false);
                                    setIsDisable(false);
                                    setSavePayroll(true)
                                    resolve(true)
                                }).catch(err => {
                                    console.log(err)
                                    setSavingDraft(false);
                                    setIsDisable(false);
                                    resolve(false)
                                })
                            }).catch(err => {
                                console.log(err)
                                setSavingDraft(false);
                                setIsDisable(false);
                                resolve(false)
                            })
                    }).catch(err => {
                        console.log(err)
                        setSavingDraft(false);
                        setIsDisable(false);
                        resolve(false)
                    })
                } else {
                    Axios.patch(process.env.REACT_APP_UPDATE_REPORT + draft._id, draft).then(reportRes => {
                        const selectedCities = reportRes.data.siteSelectionReport.must.cities.map(city => city.municipality_name);
                        const inboundGroupsSelected = reportRes.data.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups.map(inboundGroup => inboundGroup.groupName)
                        const inboundGroups = reportRes.data.siteSelectionReport.assumption.logistics.logisticsInbound.inboundGroups
                        
                        const destinationsSelected = reportRes.data.siteSelectionReport.assumption.logistics.logisticsOutbound.destinations.map(destination => destination.fullName)
                        const filterSelectedCities = variableCost.cities.filter(city => selectedCities.includes(city.cityName))
                        
                        const filterGroups = filterSelectedCities.map(city => {
                            return {
                                ...city,
                                inboundGroups: city.inboundGroups.filter(group => inboundGroupsSelected.includes(group.groupName)),
                                outbound: city.outbound.filter(outbound => destinationsSelected.includes(outbound.destination))
                            }
                        })
                        
                        const finalFilter = filterGroups.map(city => {
                            const newCity = {
                                ...city,
                                inboundGroups: city.inboundGroups.map(variableGroup => {
                                    const newGroup = {
                                        ...variableGroup,
                                        origins: variableGroup.origins.filter(origin =>
                                            inboundGroups.some(group =>
                                                group.groupName === variableGroup.groupName &&
                                                group.origins.some(testOrigin =>
                                                    testOrigin.fullName === origin.origin
                                                )
                                            )
                                        )
                                    };
                                    return newGroup;
                                })
                            };
                            return newCity;
                        });
    
                        const saveVariableCost = {
                            ...variableCost,
                            cities: finalFilter,
                        }
                        Axios.patch(process.env.REACT_APP_UPDATE_VARIABLE_COST + variableCost._id, saveVariableCost).then(variableCostRes => {
                            setVariableCost(variableCostRes.data)
                            setSavedDraft(true);
                            setDraftStatus("Draft saved: " + moment(new Date()).format("LLL"));
                            setSavingDraft(false);
                            setIsDisable(false);
                            resolve(true);
                        }).catch(err => {
                            console.log(err)
                            setSavingDraft(false);
                            setIsDisable(false);
                            resolve(false)
                        });
                    }).catch(err => {
                        console.log(err)
                        setSavingDraft(false);
                        setIsDisable(false);
                        resolve(false)
                    })
                }
            }
        })
    }

    const mapBoxRouteToMustCity = (city, destinyCity, parameter) => {
        return new Promise((resolve, reject) => {
            // must city coordenates
            const mustCityLongitude = city?.longitude;
            const mustCityLatitude = city?.latitude;
            
            // destiny coordenates
            const destinyLongitude = destinyCity?.destiny?.longitude;
            const destinyLatitude = destinyCity?.destiny?.latitude;
            
            if (destinyLongitude && destinyLatitude && mustCityLongitude && mustCityLatitude) {
                Axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${mustCityLongitude},${mustCityLatitude};${destinyLongitude},${destinyLatitude}?overview=full&geometries=geojson&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`).then(res => {
                    const newCities = [...variableCost?.cities];
                    const index = newCities.findIndex(item => city.municipality_name === item.cityName);
                    const newDestiny = {
                        destiny: destinyCity.destiny.fullName,
                        duration: res.data.routes[0].duration,
                        quantity: destinyCity.quantity
                    }

                    const existingIndex = newCities[index].dataVariables[parameter].findIndex(item => item.destiny === newDestiny.destiny);
                    if (existingIndex !== -1) {
                        newCities[index].dataVariables[parameter][existingIndex] = newDestiny;
                    } else {
                        newCities[index].dataVariables[parameter].push(newDestiny);
                    }

                    setVariableCost(prevState => {
                        return {
                            ...prevState,
                            cities: newCities
                        }
                    })
                    resolve(true)
                }).catch(err => {
                    console.log(err)
                    resolve(false)
                })
            } else {
                const newCities = [...variableCost?.cities];
                const index = newCities.findIndex(item => city.municipality_name === item.cityName);
                const newDestiny = {
                    destiny: destinyCity.destiny.fullName,
                    duration: null,
                    quantity: destinyCity.quantity
                }

                const existingIndex = newCities[index].dataVariables[parameter].findIndex(item => item.destiny === newDestiny.destiny);
                if (existingIndex !== -1) {
                    newCities[index].dataVariables[parameter][existingIndex] = newDestiny;
                } else if(destinyCity.destiny.fullName && destinyCity.destiny.fullName !== "") {
                    newCities[index].dataVariables[parameter].push(newDestiny);
                }

                setVariableCost(prevState => {
                    return {
                        ...prevState,
                        cities: newCities
                    }
                })
                resolve(false)
            }
        })
    }
    
    const mapboxRoute = async (destinyCity, parameter) => {
        return new Promise((resolve, reject) => {
            draft.siteSelectionReport.must.cities.forEach(async (city, index) => {
                await mapBoxRouteToMustCity(city, destinyCity, parameter)
            })
            resolve(true)
        })
    }
    
    const generateResults = async (typeOfResult) => {
        setLoadingModal(true)
            
        try {
            // check if report has the variables needed to calculate the distances
            draft.siteSelectionReport.want.wantsMatrix.some(want => 
                want.variables.some(variable => variable.varIdRef === "65cd42c0f955fa725381e7f9")
            ) && 
            variableCost.distanceToClient.forEach(async (destiny) => {
                await mapboxRoute(destiny, "distanceToClient");
            });

            draft.siteSelectionReport.want.wantsMatrix.some(want => 
                want.variables.some(variable => variable.varIdRef === "65cd42baf955fa725381e7f8")
            ) && 
            variableCost.distanceToSeaport.forEach(async (destiny) => {
                await mapboxRoute(destiny, "distanceToSeaport");
            });

            draft.siteSelectionReport.want.wantsMatrix.some(want => 
                want.variables.some(variable => variable.varIdRef === "65cd42b5f955fa725381e7f7")
            ) && 
            variableCost.distanceToSupplier.forEach(async (destiny) => {
                await mapboxRoute(destiny, "distanceToSupplier");
            });
            
            const result = await saveDraft();
            if (result) {
                Axios.get(process.env.REACT_APP_CALCULATE_RESULTS + draft._id)
                    .then((res) => {
                        // console.log(res.data)
                        setLoadingModal(false)
                        Swal.close()
                        navigate("/reportResults/" + draft._id + "?typeOfResult=" + typeOfResult)
                    })
                    .catch((err) => {
                        console.log(err) 
                        customAlert(err.response?.data?.message || err.response?.statusText)
                        // setLoadingModal(false)
                    });
            }
        } catch (error) {
            console.log(error);
            customAlert(error.response?.data?.message || err.response?.statusText)
        }
    }

    const minimizeSiteSelection = () => {
        const reports = [...props.inProgressReports];

        const indexReport = reports.findIndex(report => report.draftID === draft._id);
        if (indexReport === -1) {
            Axios.post(process.env.REACT_APP_CREATE_PINNED_REPORT, {
                reportName: draft.reportName,
                activeMenu: draft.activeMenu,
                lastSave: moment(new Date()).format("LLL"),
                draftID: draft._id,
                profileUserId: user._id,
            }).then(res => {
                // console.log("pin created")
            }).catch(err => {
                console.log(err);
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        } else {
            Axios.patch(process.env.REACT_APP_UPDATE_PINNED_REPORT + reports[indexReport]._id, {
                reportName: draft.reportName,
                activeMenu: draft.activeMenu,
                lastSave: moment(new Date()).format("LLL"),
            }).then(res => {
                // console.log("pin updated")
            }).catch(err => {
                console.log(err);
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        }

        saveDraft();

        props.setSiteSelectionOpened(false);
    }

    const closeSiteSelection = () => {
        const reports = [...props.inProgressReports]

        const indexReport = reports.findIndex(report => report.draftID === draft._id);
        if (indexReport !== -1) {
            Axios.delete(process.env.REACT_APP_DELETE_PINNED_REPORT + reports[indexReport]._id).then(res => {
                // console.log("pin removed")
            }).catch(err => {
                console.log(err);
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
        }
        props.setSiteSelectionOpened(false);
    }

    const setActiveMenu = (index) => {
        setDraft(prevState => {
            return {
                ...prevState,
                activeMenu: index,
            }
        });
    }

    return (
        <section className={`containerSection ${props.isOpened ? "opened" : ""}`}>
            {draft &&
                <>
                    <div className="containerMinimizeClose">
                        {draft?.siteSelectionReport?.clientId &&
                            <img src={minimize} alt="Prodensa automation" onClick={minimizeSiteSelection} />
                        }
                        <img src={close} alt="Prodensa automation" onClick={closeSiteSelection} />
                    </div>
                    <div className="container-xl">
                        <Row>
                            <Col lg={{ span: 11, offset: 1 }} xs={{ span: 12 }}>
                                <div className="siteSelection-header">
                                    <div className="activeMenu">
                                        <h1>{wizardMenus[draft.activeMenu]}</h1>
                                        {draft.activeMenu !== 0 && <p className="sectionDescription">
                                            {wizardMenuDescriptions[draft.activeMenu]}
                                        </p>}
                                    </div>
                                    {draft.activeMenu !== 0 && 
                                        <div className="containerDraftStatus">
                                            <div className="draftName-button-container">
                                                <div className="containerDraftName">
                                                    <input className="draftName"
                                                        value={draft.reportName}
                                                        type="text"
                                                        placeholder="Draft name"
                                                        onChange={(e) =>
                                                            setDraft(prevState => {
                                                                return { ...prevState, reportName: e.target.value }
                                                            })} />
                                                    <img src={edit} alt="Prodensa automation" />
                                                </div>
                                                <button className="secondaryButton" type="submit" onClick={() => saveDraft()}>
                                                    <img src={save} alt="Prodensa automation" />
                                                    <span className="d-none d-lg-block" style={{whiteSpace: "nowrap"}}>Save draft</span>
                                                </button>
                                                <div className="savingDraftContainer my-auto ms-2 d-none d-lg-block">
                                                    {savedDraft &&
                                                        <img className="loading show"
                                                            src={checkGreen}
                                                            alt="ProdensaAutomation" />
                                                        || !savedDraft &&
                                                        <img className={`loading ${savingDraft ? "show" : ""}`}
                                                            src={loadingGif}
                                                            alt="ProdensaAutomation" />
                                                    }
                                                </div>
                                            </div>
                                            <span className="wizardStatus">{draftStatus}</span>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col className="activeMenu-title" lg={{ span: "auto", offset: 1 }} xs={{ span: 11, offset: 1 }}>
                                <h1>{wizardMenus[draft.activeMenu]}</h1>
                            </Col>
                            {draft.activeMenu !== 0 &&
                                <Col className="ms-auto my-auto d-flex justify-content-end" lg={5}>
                                    <div className="containerDraftName">
                                        <input className="draftName"
                                            value={draft.reportName}
                                            type="text"
                                            placeholder="Draft name"
                                            onChange={(e) =>
                                                setDraft(prevState => {
                                                    return { ...prevState, reportName: e.target.value }
                                                })} />
                                        <img src={edit} alt="Prodensa automation" />
                                    </div>
                                    <button className="secondaryButton" type="submit" onClick={() => saveDraft()}>
                                        <img src={save} alt="Prodensa automation" />
                                        <span style={{whiteSpace: "nowrap"}}>Save draft</span>
                                    </button>
                                    <div className="savingDraftContainer my-auto ms-2">
                                        {savedDraft &&
                                            <img className="loading show"
                                                src={checkGreen}
                                                alt="ProdensaAutomation" />
                                            || !savedDraft &&
                                            <img className={`loading ${savingDraft ? "show" : ""}`}
                                                src={loadingGif}
                                                alt="ProdensaAutomation" />
                                        }
                                    </div>

                                </Col>
                            }
                        </Row>
                        {draft.activeMenu !== 0 &&
                            <Row className="wizardStatusContainer">
                                <Col lg={{ span: 5, offset: 1 }}>
                                    <span
                                        className="sectionDescription">{wizardMenuDescriptions[draft.activeMenu]}</span>
                                </Col>
                                <Col className="ms-auto pe-0" lg={"auto"}>
                                    <span className="wizardStatus">{draftStatus}</span>
                                </Col>
                            </Row>
                        } */}
                        {
                            draft.activeMenu === 0 &&
                            <ClientSection draft={draft} setDraft={setDraft} client={client} setClient={setClient}
                                variableCost={variableCost} setVariableCost={setVariableCost}
                                setLoading={setLoading} setIsDisable={setIsDisable}
                                setTextActionButton={setTextActionButton}
                                setFunctionActionButton={setFunctionActionButton}
                                activeClientSection={activeClientSection}
                                setActiveClientSection={setActiveClientSection}
                                setActiveMenu={setActiveMenu}
                                allClients={props.allClients} setPdfConfig={setPdfConfig} />
                            || draft.activeMenu === 1 &&
                            <Assumptions draft={draft} setDraft={setDraft}
                                variableCost={variableCost} setVariableCost={setVariableCost}
                                setTextActionButton={setTextActionButton}
                                setFunctionActionButton={setFunctionActionButton}
                                setActiveMenu={setActiveMenu}
                                representationLetter={representationLetter} 
                                setRepresentationLetter={setRepresentationLetter}
                                savedRepresentationLetter={savedRepresentationLetter}
                                setSavedRepresentationLetter={setSavedRepresentationLetter}
                                />
                            || draft.activeMenu === 2 &&
                            <Musts draft={draft} setDraft={setDraft}
                                variableCost={variableCost} setVariableCost={setVariableCost}
                                setTextActionButton={setTextActionButton}
                                setFunctionActionButton={setFunctionActionButton}
                                setActiveMenu={setActiveMenu} />
                            || draft.activeMenu === 3 &&
                            <Wants draft={draft} setDraft={setDraft}
                                wantsMatrixReference={props.wantsMatrixReference}
                                variableCost={variableCost} setVariableCost={setVariableCost}
                                setTextActionButton={setTextActionButton}
                                setFunctionActionButton={setFunctionActionButton}
                                setAnimatecontainer={setAnimatecontainer}
                                setAnimateColumn={setAnimateColumn}
                                payrollFile={payrollFile}
                                setPayrollFile={setPayrollFile}
                                savePayroll={savePayroll}
                                setSavePayroll={setSavePayroll}
                                setShowLogisticCosts={setShowLogisticCosts} />
                        }
                        <Row>
                            <Col xs={{ span: 12 }}>
                                <div className={`containerActions`}>
                                    <img className={`loading ${loading ? "show" : ""}`} src={loadingGif}
                                        alt="ProdensaAutomation" />
                                    <button className="cancelButton" onClick={closeBackButton}>{textBackButton}</button>
                                    {draft.activeMenu === 3 && <button className="secondaryButton" onClick={openPdfConfig}><img src={filePicture} alt="Prodensa Automation" /> PDF Configuration</button>}
                                    {draft.activeMenu === 3 && <button className="previewReport" disabled={isDisable} onClick={() => {
                                            if(draft.siteSelectionReport.must.cities.length > 0 && 
                                                draft.siteSelectionReport.assumption.exchangeRate !== "" && 
                                                draft.siteSelectionReport.assumption.exchangeRate !== 0) {
                                                generateResults("preview")
                                            } else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "You must select at least one city and add value to exchange rate",
                                                    showConfirmButton: false,
                                                    showCancelButton: false,
                                                    timer: 2500,
                                                    timerProgressBar: true,
                                                })
                                            }
                                        }}>
                                        <img src={previewReport} alt="Prodensa Automation" />
                                        Preview report
                                    </button>}
                                    <button className="secondaryButton" onClick={() => {saveDraft(); functionActionButton()}} disabled={isDisable}>
                                        {draft.activeMenu === 3 && <img src={generateReport} alt="Prodensa Automation" />}
                                        {textActionButton}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="containerWizardNavigation bg-white d-none d-lg-flex">
                        {
                            wizardMenus.map((menu, index) => (
                                <MenuButton key={index} active={draft.activeMenu === index} menuTitle={menu}
                                    hasClient={draft?.siteSelectionReport?.clientId !== null && draft?.siteSelectionReport?.clientId !== undefined}
                                    setActiveMenu={() => setActiveMenu(index)} />
                            ))
                        }
                    </div>

                    <MobileFooter draft={draft} wizardMenus={wizardMenus} setActiveMenu={setActiveMenu}/>

                    <div className={`animationContainer ${animateContainer ? "show" : ""}`}
                        onAnimationEnd={() => generateResults("generate")}>
                        <div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
                        <div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
                        <div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
                        <div className={`columnAnimation ${animateColumn ? "animate" : ""}`}></div>
                    </div>
                </>
            }
            <LogisticCosts 
                draft={draft}
                setDraft={setDraft}
                savedDraft={savedDraft}
                savingDraft={savingDraft}
                variableCost={variableCost}
                setVariableCost={setVariableCost}
                showLogisticCosts={showLogisticCosts}
                setShowLogisticCosts={setShowLogisticCosts}
                saveDraft={saveDraft}
            />

            <ConfigurePdf 
                draft={draft}
                pdfConfig={pdfConfig}
                setPdfConfig={setPdfConfig}
                showPdfConfigure={showPdfConfigure}
                setShowPdfConfigure={setShowPdfConfigure}
                wantsMatrixReference={props.wantsMatrixReference}
                isOpened={props.isOpened}
            />
        </section>
    )
}

export default SiteSelection;
