import "../../assets/css/map/map.css"
// import arrow from "../../assets/images/arrow_forward.svg"
// import arrowDisabled from "../../assets/images/arrow_forward-disabled.svg"
import close from "../../assets/images/white-close.svg"
import { Dropdown, Modal } from "react-bootstrap"
import Axios from "axios"

import React, { useEffect, useState } from "react"

function SelectBorderModal(props) {
  return (
    <Modal
      className="select-border-modal"
      size="md"
      centered
      show={props.showBorderModal}
      onHide={props.onHide}
    >
      <Modal.Body>
        <section className="select-border-section">
          <h4>Select border to {props.city.city}</h4>
          <div className="input-citySearch-container">
            <input value={props.border.fullName} 
              onChange={(e) => {
                if (e.target.value !== "") {
                  props.setShowCitiesContainer(true)
                } else {
                  props.setShowCitiesContainer(false)
                }
                
                props.setCitySearch(e.target.value)
                props.setSelectedBorder({
                  // datamexico_municipality_id: undefined,
                  fullName: e.target.value,
                  city: "",
                  state: "",
                  country: "",
                  longitude: undefined,
                  latitude: undefined,
                })
              }}
            />
            <div className={`cities-dropdown ${props.showCitiesContainer ? "show" : ""}`}>
              {props.placesFound.map((place, index) => {
                return (
                  <span key={`${place}-${index}`} className="cityFound" 
                    onClick={async () => {
                      const state = place.context?.find(x => x.id.includes("region"))?.text
                      const country = place.context?.find(x => x.id.includes("country"))?.text

                      props.setSelectedBorder({
                        fullName: place.place_name,
                        city: place.text,
                        state: state ?? "",
                        country: country ?? "",
                        latitude: place.geometry.coordinates[1],
                        longitude: place.geometry.coordinates[0],
                      })
                        
                      props.setShowCitiesContainer(false)
                    }
                  }>
                    {place.place_name}
                  </span>
                )
              })}
            </div>
          </div>
          <button 
            onClick={() => {
              props.handleRouteToDestination(props.origin.lngLat, props.border, [props.city.longitude, props.city.latitude])
              props.onHide()
            }}
          >
              Create route
          </button>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default function CustomPopup(props) {
  const [showBorderModal, setShowBorderModal] = useState(false)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedBorder, setSelectedBorder] = useState(null)
  const [citySearch, setCitySearch] = useState("")
  const [placesFound, setPlacesFound] = useState([])
  const [showCitiesContainer, setShowCitiesContainer] = useState(false)
  
  function closePopup(id) {
    props.handlePopup(id)
  }

  function handleRouteToDestination(start, border, end) {
    props.setNewRoute(start, border, end)
  }

  function handleStreetView(id) {
    props.handleStreetView(id)
  }
  
  function handleShowModal(city) {
    setShowBorderModal(true)
    setSelectedCity(city)
  }

  const fetchCoordenates = async () => {
    const { data } = await Axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${citySearch}.json?types=place&country=MX&access_token=${process.env.REACT_APP_MAPBOXTOKEN}`
    );

    setPlacesFound(data.features)
  };

  function calculateIndustries(city) {
    const selectedIndustries = props.allAssumptions.selectedIndustries

    if (!city.manuCompany) {
      return 0;
    }

    if (selectedIndustries.length === 0) {
        return city.manuCompany.reduce((acc, industry) => {
            return acc + industry.numberOfCompaniesPerIndustry;
        }, 0);
    } else {
        return city.manuCompany.reduce((acc, industry) => {
            const selectedIndustry = selectedIndustries.find(item => item.nationalIndustryId === industry.nationalIndustryId);
            return selectedIndustry ? acc + industry.numberOfCompaniesPerIndustry : acc;
        }, 0);
    }
  }

  useEffect(() => {
    fetchCoordenates()
  }, [citySearch])

  
  return (
    <main className={`custom-popup ${props.city.popupOpen ? "open" : ""}`} onClick={(e) => e.stopPropagation()}>
      <div className="close-popup">
        <img src={close} onClick={() => closePopup(props.city.id)} />
      </div>
      <img className="img" src={props.city.urlPhoto ?? props.city.img} />
      <div className="header">
        <h4>{props.city.name}</h4>
        <p>{props.city.state}</p>
      </div>
      <section className="infoContainer">
        {props.city.subName && <div className="info">
          <h4>Annual movements</h4>
          <p>{props.city.annual_movements}</p>
        </div>}
        {props.city.subName && <div className="info">
          <h4>Live camaras link</h4>
          {props.city.camaras_link === "No data found" ? 
            <p>{props.city.camaras_link}</p> 
          : 
            <a href={props.city.camaras_link} target="_blank">{props.city.camaras_link}</a>}
        </div>}
        {props.city.subName && <div className="info">
          <h4>Waiting times link</h4>
          {props.city.waiting_times_link === "No data found" ? 
            <p>{props.city.waiting_times_link}</p> 
          : 
            <a href={props.city.waiting_times_link} target="_blank">{props.city.waiting_times_link}</a>}
        </div>}
        {props.city.subName && <div className="info">
          <h4>Historical waiting times link</h4>
          {props.city.historical_times_link === "No data found" ? 
            <p>{props.city.historical_times_link}</p> 
          : 
            <a href={props.city.historical_times_link} target="_blank">{props.city.historical_times_link}</a>}
        </div>}
        {!props.city.subName && <div className="info">
          <h4>{(props.city.population?? 0)?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h4>
          <p>Population</p>
        </div>}
        {!props.city.subName && <div className="info">
          <h4>{(props.city.ecoPopu?? 0)?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h4>
          <p>Economically Active population</p>
        </div>}
        {!props.city.subName && <div className="info">
          <h4>{(calculateIndustries(props.city))?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h4>
          <p>Manufacturing companies</p>
        </div>}
        {/* <div className="info">
          <h4>{props.city.machCompany}</h4>
          <p>Machining companies</p>
        </div> */}
        {/* <div className="info">
          <h4>{props.city.distPort}</h4>
          <p>Distance from port</p>
        </div> */}
        {!props.city.subName && <div className="info">
          <h4>{(props.city.distBorder?? 0)?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} km</h4>
          <p>Distance from border</p>
        </div>}
      </section>
      {!props.city.subName && <section className="btnContainer">
        <button className="button primary" disabled={props.city.streetviewPlaces?.length === 0 || !props.representationLetterActive} onClick={() => handleStreetView(props.city.id)}>
          <span>Street view</span>
        </button>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className="button secondary" 
            disabled={props.inProgress || props.assumptions?.logisticsOutbound?.destinations?.filter(city => city.fullName !== "").length === 0}>
            <span>{props.inProgress ? "Route in process" : "Route to destination"}</span>
            {/* <img src={props.inProgress ? arrowDisabled : arrow} alt="Prodensa Automation" /> */}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {props.assumptions.logisticsOutbound.destinations.map((city, index) => (
              <React.Fragment key={`${city}-${index}`}>
                <Dropdown.Item onClick={() => {
                    handleShowModal(city.city)
                    setCitySearch("")
                    city.border ? 
                      setSelectedBorder(city.border) 
                    : 
                      setSelectedBorder({
                        fullName: "",
                        city: "",
                        state: "",
                        country: "",
                        longitude: undefined,
                        latitude: undefined,
                      })
                }}>
                  {city.city}
                </Dropdown.Item>
                {selectedCity === city.city && (
                  <SelectBorderModal 
                    showBorderModal={showBorderModal} 
                    onHide={() => setShowBorderModal(false)} 
                    city={city}
                    border={selectedBorder}
                    setSelectedBorder={setSelectedBorder}
                    origin={props.city}
                    setCitySearch={setCitySearch}
                    placesFound={placesFound}
                    handleRouteToDestination={handleRouteToDestination}
                    showCitiesContainer={showCitiesContainer}
                    setShowCitiesContainer={setShowCitiesContainer}
                  />
                )}
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </section>}

    </main>
  )
}
