import "../../assets/css/reportResults/summary.css";
import "../../assets/css/detailsViews/tableStyles.css";

import { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

import CompareBox from "./wantsView/compare-box.jsx";

const Summary = (props) => {
    const [filtered,setFiltered] = useState(null);
    
    useEffect(() => {
        if(props.cityNames){
            let initialFiltered = props.cityNames.map((_city,index) => index);
            setFiltered(initialFiltered);
        }
    },[props.cityNames])

    const calculateCategoryScore = (category, city) => {
        return !category.variables ? 0 : category.variables.reduce((acc, variable) => {
            const result = variable.results && variable.results.find(result => result.city === city)
            return acc + (result ? result.valueResult.wantsPoints : 0)
        }, 0);
    }

    const calculateMaxCategoryScore = (category, cities, type) => {
        return type === "category" ? 
        Math.max(...cities.map(city => calculateCategoryScore(category, city))) :
        Math.max(...cities.map(city => calculateTotalPoints(city)))
    }

    const calculateTotalPoints = (city) => {
        return props.wantsResults.reduce((total, category) => {
            return total + (!category.variables ? 0 : category.variables.reduce((total, variable) => {
            const result = variable.results && variable.results.find(result => result.city === city);
            return total + (result ? result.valueResult.wantsPoints : 0);
            }, 0))
        }, 0);
    }

    return (
        <section
            className={`summary-wrapper ${props.activebutton === "summary" ? "show" : ""}`}
        >
            {props.wantsResults && props.cityNames && props.wantsMatrix && filtered &&
                <Container>
                    <div className="summary-header">
                        {/*Title and arrow button to return*/}
                        <div className="summary-title-container">
                            <button onClick={() => props.setActivebutton("map")} className="return-summary"></button>
                            <h4>Summary</h4>
                        </div>
                        <CompareBox
                            origin={"summary"}
                            cityNames={props.cityNames}
                            setFiltered={setFiltered}
                            comp_variant={"table"}
                            filtered={filtered}
                        />
                    </div>
                    <Row className="d-none d-lg-flex no-gutters summaryDataTable">
                        <Col lg={3}>
                            <h5 className="table-head-fixed">Criterion</h5>
                            {props.wantsResults
                            .sort((a, b) => {
                                const da = props.wantsMatrixRef.find(item => item._id === a.categoryIdRef).position
                                const db = props.wantsMatrixRef.find(item => item._id === b.categoryIdRef).position
                            
                                return da - db
                            })
                            .map(category => {
                                const catRef = props.wantsMatrixRef.find(item => item._id === category.categoryIdRef)
                                return <h5 className="table-row-fixed" key={category.categoryIdRef}>{catRef.title}</h5>
                            })}
                            <h5 className="table-row-fixed">Total</h5>
                        </Col>
                        <Col lg={2}>
                            <h5 className="table-head-fixed">Points</h5>
                            {props.wantsMatrix
                            .sort((a, b) => {
                                const da = props.wantsMatrixRef.find(item => item._id === a.catIdRef).position
                                const db = props.wantsMatrixRef.find(item => item._id === b.catIdRef).position
                            
                                return da - db
                            })
                            .map(category => 
                                    <h5 className="table-row-fixed points" key={category.catIdRef}>{category.weight}</h5>   
                            )}
                            <h5 className="table-row-fixed">
                                {props.wantsMatrix.reduce((acc, want) => acc + want.weight, 0)}
                            </h5>
                        </Col>
                        <Col lg={7} className="table-container">
                            <div className="table-header">
                                {props.cityNames.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            {props.wantsResults
                            .sort((a, b) => {
                                const da = props.wantsMatrixRef.find(item => item._id === a.categoryIdRef).position
                                const db = props.wantsMatrixRef.find(item => item._id === b.categoryIdRef).position
                            
                                return da - db
                            })
                            .map((category, index) =>
                                <div className="table-row" key={`row2-${category.categoryIdRef}`}>
                                    {props.cityNames.map((city, cityIndex) =>
                                        filtered.includes(cityIndex) &&
                                        <h5 className="number" key={`${city}-row1`}>
                                            <span
                                                className={
                                                    calculateCategoryScore(category, city) ===
                                                    calculateMaxCategoryScore(category, props.cityNames, "category")
                                                        ? "highest"
                                                        : "regular"
                                                }
                                            >
                                                {calculateCategoryScore(category, city).toFixed(2)}
                                            </span>
                                        </h5>
                                    )}
                                </div>
                            )}
                            <div className="table-row">
                                {props.cityNames.map((city, cityIndex) =>
                                    filtered.includes(cityIndex) &&
                                    <h5 className="number" key={`${city}-row2`}>
                                        <span
                                            className={
                                                calculateTotalPoints(city) === calculateMaxCategoryScore(null, props.cityNames, "total")
                                                    ? "highest-total"
                                                    : ""
                                            }
                                        >
                                            {calculateTotalPoints(city).toFixed(2)}
                                        </span>
                                    </h5>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-lg-none">
                        <Col xs={12}>
                            <div className="summary-start">
                                <h5>Criterion</h5>
                                <h5>Points</h5>
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-lg-none mobile">
                        <Col xs={12}>
                            {props.wantsResults
                            .sort((a, b) => {
                                const da = props.wantsMatrixRef.find(item => item._id === a.categoryIdRef).position
                                const db = props.wantsMatrixRef.find(item => item._id === b.categoryIdRef).position
                            
                                return da - db
                            })
                            .map((category, index) => {
                                const catRef = props.wantsMatrixRef.find(item => item._id === category.categoryIdRef)
                                const catInMatrix = props.wantsMatrix.find(item => item.catIdRef === category.categoryIdRef)

                                return (
                                    <div className="summary-cat-section" key={`row3-${category.categoryIdRef}`}>
                                        <div className="summary-cat-header">
                                            <h5>{catRef.title}</h5>
                                            <span>{catInMatrix.weight}</span>
                                        </div>
                                        <div className="summary-scores">
                                            {props.cityNames.map((city, cityIndex) =>
                                                filtered.includes(cityIndex) &&
                                                <div className="city-score" key={`${city}-summary`}>
                                                    <h5 key={city}>
                                                        {city}
                                                    </h5>
                                                    <span
                                                        className={
                                                            calculateCategoryScore(category, city) ===
                                                            calculateMaxCategoryScore(category, props.cityNames, "category")
                                                                ? "highest"
                                                                : "regular"
                                                        }
                                                    >
                                                        {calculateCategoryScore(category, city).toFixed(2)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </Col>
                    </Row>

                    <Row className="d-lg-none mobile">
                        <Col xs={12}>
                        <div className="summary-totals">
                            <h5>Total</h5>
                        </div>
                        <div className="summary-total-scores">
                            {props.cityNames.map((city, cityIndex) =>
                                filtered.includes(cityIndex) &&
                                <div className="city-total-score" key={`${city}-${cityIndex}`}>
                                    <h5 key={city}>
                                        {city}
                                    </h5>
                                    <span
                                        className={
                                            calculateTotalPoints(city) === calculateMaxCategoryScore(null, props.cityNames, "total")
                                                ? "highest"
                                                : ""
                                        }
                                    >
                                        {calculateTotalPoints(city).toFixed(2)}
                                    </span>
                                </div>
                            )
                            }
                        </div>
                        </Col>
                    </Row>
                </Container>
            }
        </section>
    );
};

export default Summary;
