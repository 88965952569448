import "../../assets/css/streetviewconfig/streetviewconfig.css"

import Axios from "axios";
import Swal from "sweetalert2";
import { Accordion } from 'react-bootstrap'
import { useEffect, useRef, useState } from "react"
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap'

import close from "../../assets/images/close.svg"
import { customAlert } from "../../utils/customAlert";
import checkicon from "../../assets/images/check.svg"
import deleteIcon from "../../assets/images/delete.svg"
import infoDanger from "../../assets/images/infoDanger.svg"

const Streetviewconfig = (props) => {
	//Defines placeholders for default place, group
	const default_place = {
		poiName: "",
		link: "",
		description: ""
	}
	const default_group = {
		sectionName: "",
		sectionPois: [default_place]
	}
	//Defines the initial default group to be rendered
	const default_places_report = [default_group]

	const street_link_tooltip = (
		<Tooltip id="street_link_tooltip">
			<div className="tooltip-text-street-link">Position the camera on street view as you wish it to be and copy the link here.</div>
		</Tooltip>
	);

	const [cities, setCities] = useState([]);

	const [places_report, setPlaces_report] = useState([]);
	const [saved, setSaved] = useState(false);
	const [changeCity, setChangeCity] = useState(true);
	const [searchCity, setSearchCity] = useState("");

	/////////////////Choose city logic/////////////////////////////////////////
	const [isCityLookup, setIsCityLookup] = useState(false);
	const [selectedCity, setSelectedCity] = useState({});
	const [cityResults, setCityResults] = useState([]);
	const [activeItems, setActiveItems] = useState([]);
	const searchCityButton = useRef(null);

	const citiesLookup = (searchTerm) => {
		setSearchCity(searchTerm); // Set the search term to the state
		const matchingCities = cities.length > 0 ? cities.filter(item => {
			const normalizedMunicipalityName = item.municipality_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			
			return normalizedMunicipalityName?.toLowerCase().includes(searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
		}) : []

		if (matchingCities.length > 0) {
			setCityResults(matchingCities); // Set a message if no matches found
		} else {
			setCityResults([{municipality_name: "No matching cities found"}]); // Set a message if no matches found
		}
	}

	const addPlaceToGroup = (groupIndex) => {
		// Clone the current state to avoid mutating the original state directly
		const updatedPlacesReport = [...places_report];

		const newPlace = {
			...default_place,
		};

		// Add the new place to the specified group at groupIndex
		updatedPlacesReport[groupIndex].sectionPois.push(newPlace);

		// Update the state with the modified array
		setPlaces_report(updatedPlacesReport);
		setSaved(false)
		setChangeCity(false)
	};

	const addGroupToReport = () => {
		// Clone the current state to avoid mutating the original state directly
		const updatedPlacesReport = [...places_report];

		const newGroup = {
			...default_group,
		};

		// Add the new group to the array
		updatedPlacesReport.push(newGroup);

		// Update the state with the modified array
		setPlaces_report(updatedPlacesReport);
		setSaved(false)
		setChangeCity(false)
	}

	const modifyingElement = (origin, value) => {
		const updatedPlacesReport = [...places_report]; // Clone the original array

		switch (origin) {
			case "sectionName":
				updatedPlacesReport[value.groupIndex].sectionName = value.value;
				break;
			case "poiName":
				updatedPlacesReport[value.groupIndex].sectionPois[value.placeIndex].poiName = value.value;
				break;
			case "link_for_street":
				updatedPlacesReport[value.groupIndex].sectionPois[value.placeIndex].link = value.value.replace(/%20/g, "").replace(/\s/g, "");
				break;
			case "description":
				updatedPlacesReport[value.groupIndex].sectionPois[value.placeIndex].description = value.value;
				break;
			default:
				break;
		}

		setPlaces_report(updatedPlacesReport); // Update the state with the modified array
		setSaved(false)
		setChangeCity(false)
	};

	const sendPlaces = () => {
		//Add validation  for empty spaces in the report and if so add the indexes to an empty space array which controls the triggering of red 
		//error classes 

		// Check for empty sectionName
		const isGroupTitleEmpty = places_report.some(group => group.sectionName.trim() === '');

		// Check for empty keys in places
		const arePlaceTitlesEmpty = places_report.some(group =>
			group.sectionPois.some(place => place.poiName.trim() === '')
		);

		const arePlaceLinksEmpty = places_report.some(group =>
			group.sectionPois.some(place => place.link.trim() === '')
		);

		// Return true if both sectionName and place titles are not empty
		const places = {
			sections: places_report,
			cityId: selectedCity.datamexico_municipality_id
		}

		
		const isDataValid = !isGroupTitleEmpty && !arePlaceTitlesEmpty && !arePlaceLinksEmpty;
		if (isDataValid) {
			Axios.patch(process.env.REACT_APP_UPDATE_CITY_PLACES, places).then(res => {
				if(!res.data.data) {
					customAlert(res.data.message)
					setSaved(false);
					return
				}

				Swal.fire({
					position: "center",
					titleText: `Changes saved!`,
					showConfirmButton: false,
					imageUrl: checkicon,
					imageWidth: 50,
					imageHeight: 50,
					backdrop: 'rgba(0, 0, 0, 0)',
					customClass: {
						container: 'confirm-swal-container',
						popup: 'confirm-swal-popup',
						header: 'confirm-swal-header',
						title: 'confirm-swal-title',
						content: 'confirm-swal-content',
						confirmButton: 'confirm-swal-confirm-button',
						cancelButton: 'confirm-swal-cancel-button',
						footer: 'confirm-swal-footer'
					},
					timer: 2500,
					timerProgressBar: true,
				});
				setSaved(true);
				setChangeCity(true)
			}).catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
		} else {
			let swalMessage = "";
			let localCounter = 0;
			if (isGroupTitleEmpty) {
				swalMessage = "One or more group titles are empty";
				localCounter++;
			}
			if (arePlaceTitlesEmpty) {
				swalMessage = "One or more place titles are empty";
				localCounter++;
			}
			if (arePlaceLinksEmpty) {
				swalMessage = "One or more links are empty";
				localCounter++;
			}
			if (localCounter > 1) {
				swalMessage = "One or more fields are empty";
			}

			Swal.fire({
				position: "center",
				titleText: `Error: ${swalMessage}`,
				showConfirmButton: false,
				imageUrl: infoDanger,
				imageWidth: 50,
				imageHeight: 50,
				backdrop: 'rgba(0, 0, 0, 0)',
				customClass: {
					container: 'confirm-swal-container',
					popup: 'confirm-swal-popup',
					header: 'confirm-swal-header',
					title: 'confirm-swal-title',
					content: 'confirm-swal-content',
					confirmButton: 'confirm-swal-confirm-button',
					cancelButton: 'confirm-swal-cancel-button',
					footer: 'confirm-swal-footer'
				},
				timer: 2500,
				timerProgressBar: true,
			});
			setSaved(false);
			setChangeCity(false)
		}
	}

	const toggleItem = (index) => {               //Append an accordion id to the active items array so that its mapped in accordion element and open
        const isItemInArray = activeItems.includes(index)
        if (!isItemInArray) {
            // Append newItem to activeItems array
            const updatedactiveItems = [...activeItems, index];
            setActiveItems(updatedactiveItems);

        } else {
            const updatedactiveItems = activeItems.filter(item => item !== index);
            // Set the state to update activeItems
            setActiveItems(updatedactiveItems);
        }

    }

	const handleChangeCity = (city) => {
		if (!changeCity) {
			Swal.fire({
				title: "Do you want to continue without save the changes?",
				showCancelButton: true,
				confirmButtonText: "Continue",
				customClass: {
					confirmButton: "swal2-button--confirm",
					cancelButton: "swal2-button--cancel",
				}
			}).then((result) => {
			if (result.isConfirmed) {
					setSelectedCity(city)
					Axios.get(process.env.REACT_APP_GET_CITY_PLACES + city.datamexico_municipality_id).then(res => {
						setPlaces_report(res.data.data ? res.data.data.sections : default_places_report)
						setSearchCity("")
						setSaved(false)
						setChangeCity(true)
						setCityResults(cities)
					}).catch(err => {
						console.log(err)
						customAlert(err.response?.data?.message || err.response?.statusText)
					})
				}
			});
		} else {
			setSelectedCity(city)
			Axios.get(process.env.REACT_APP_GET_CITY_PLACES + city.datamexico_municipality_id).then(res => {
				setPlaces_report(res.data.data ? res.data.data.sections : default_places_report)
				setSearchCity("")
				setSaved(false)
				setChangeCity(true)
				setCityResults(cities)
			}).catch(err => {
                console.log(err)
                customAlert(err.response?.data?.message || err.response?.statusText)
            })
		}
	}
	
	const closeMenu = (e) => {
		if (searchCityButton.current && !searchCityButton.current.contains(e.target)) {
			setIsCityLookup(false)
		}
	}

	const handleCloseStreetView = () => {
		setSaved(false)
		setChangeCity(true)
		setSelectedCity({})
		setIsCityLookup(false)
		setPlaces_report([])
		setActiveItems([])
		setSearchCity("")
		props.setStreetConfOpened(null)
	}

	useEffect(() => {
		Axios.get(process.env.REACT_APP_GET_ALL_CITIES).then(citiesRes => {
			setCities(citiesRes.data)
			setCityResults(citiesRes.data)
		}).catch(err => {
			console.log(err)
			customAlert(err.response?.data?.message || err.response?.statusText)
		})
	}, [])

	useEffect(() => {
		document.body.addEventListener('click', closeMenu);

		return function cleanup() {
			document.body.removeEventListener('click', closeMenu);
		}
	}, []);

	

	return (
		<section className={`street-config-wrapper ${props.isOpened === 2 ? "opened" : ""}`}>
			<div className="containerMinimizeClose">
				{/* <img src={minimize} alt="Prodensa automation" /> */}
				<img src={close} alt="Prodensa automation" onClick={handleCloseStreetView} />
			</div>
			<Container>
				<div className="head-wrapper">
					<div className="head-text">
						<h1>Add places</h1>
						<h5>It is a long established fact that a reader will be distracted by the readable
							content of a page when looking at its layout
						</h5>
					</div>
					<div className="save-changes-container">
						<button className="blue-bg-button" onClick={() => sendPlaces()}><span className="d-none d-lg-block">Save changes</span></button>
						<p className={saved ? "saved" : ""}>{saved ? "Saved!" : "Changes not saved"}</p>
					</div>
				</div>
				<div className="choose-city">
					<button className="choose" ref={searchCityButton}
						onClick={() => setIsCityLookup(!isCityLookup)}>{selectedCity.municipality_name ? selectedCity.municipality_name : "Select a city"}</button>
						<button className="blue-bg-button group" disabled={!selectedCity.municipality_name}
							onClick={() => addGroupToReport()}
						>
							Add group
						</button>
					<div className={`city-lookup-wrapper ${isCityLookup ? "active" : ""}`} onClick={(e) => e.stopPropagation()}>
						<div className="lookup-ghost-wrapper">
							<input type="text" className="city-search-input" placeholder="Search cities" value={searchCity} onChange={(e) => citiesLookup(e.target.value)}/>
						</div>
					</div>
					<div className={`city-result-wrapper ${isCityLookup ? "active" : ""}`}>
						{
							cityResults.map(city => (
								<div className={`city-result${cityResults.length === 0 ? "-empty" : ""}`} key={city._id} onClick={() => { handleChangeCity(city); setIsCityLookup(false) }}>
									<h5>{`${city.municipality_name}${city.state_name ? `, ${city.state_name}` : ''}`}</h5>
								</div>
							))
						}
					</div>

				</div>
				<Accordion activeKey={activeItems.map(item => item)} flush alwaysOpen className="body-wrapper">
				
						{places_report.map((group, groupIndex) => (
							<Accordion.Item eventKey={groupIndex} key={groupIndex} className="group-wrapper">
								
									<Accordion.Header className="group-head" onClick={() => toggleItem(groupIndex)}>
									
										
												<input
													type="text"
													className="group-title-input"
													placeholder="Enter your title..."
													onChange={(event) => {
															modifyingElement("sectionName", {
															groupIndex: groupIndex,
															value: event.target.value
														})
													}}
													onClick={(event)=>event.stopPropagation()}
													value={group.sectionName}
												/>

												{places_report.length > 1 && <img src={deleteIcon} alt="Prodensa automation" 
													onClick={(e) => {
														e.stopPropagation();
														setActiveItems(prevState => {
															const updatedActiveItems = [...prevState];
															const index = updatedActiveItems.indexOf(groupIndex);

															updatedActiveItems.splice(index, 1);

															return updatedActiveItems;
														
														})
														setPlaces_report(prevState => {
															const updatedPlacesReport = [...prevState];
															updatedPlacesReport.splice(groupIndex, 1);

															return updatedPlacesReport;
														})
													}}
												/>}
										
									
									</Accordion.Header>
									<Accordion.Body className="group-body">
										
											{group.sectionPois.map((place, placeIndex) => (
												<div key={placeIndex} className="group-place">
													<h5>{placeIndex + 1}</h5>
													<div className="place-data-row">
														<div className="first-row">
															<div className="input-container">
																<input
																	type="text"
																	className="place-input"
																	placeholder="Enter the place title"
																	onChange={(event) => modifyingElement("poiName", {
																		groupIndex: groupIndex,
																		placeIndex: placeIndex,
																		value: event.target.value
																	})}
																	value={place.poiName}
																/>
															</div>
															<div className="input-container">
																<input
																	type="text"
																	className="place-input"
																	placeholder="Enter the link for street view"
																	onChange={(event) => modifyingElement("link_for_street", {
																		groupIndex: groupIndex,
																		placeIndex: placeIndex,
																		value: event.target.value
																	})}
																	value={place.link}
																/>
																<OverlayTrigger placement="right" overlay={street_link_tooltip}>
																	<button className="info"></button>
																</OverlayTrigger>
															</div>
														</div>
														<textarea
															name="description"
															id="1"
															cols="30"
															rows="5"
															className="description"
															placeholder="Add description..."
															onChange={(event) => modifyingElement("description", {
																groupIndex: groupIndex,
																placeIndex: placeIndex,
																value: event.target.value
															})}
															value={place.description}
														></textarea>
													</div>
													{group.sectionPois.length > 1 && <img src={deleteIcon} alt="Prodensa automation"
														onClick={(e) => {
															e.stopPropagation();
															setPlaces_report(prevState => {
																const updatedPlacesReport = [...prevState];
																updatedPlacesReport[groupIndex].sectionPois.splice(placeIndex, 1);

																return updatedPlacesReport;
															})
														}}
													/>}
												</div>
											))}

										
										<button
											className="blue-bg-button place"
											onClick={() => addPlaceToGroup(groupIndex)}
										>
											<span>Add place</span>
										</button>
										
								
									</Accordion.Body>
									
								
							</Accordion.Item>
						))}

				
				</Accordion>
			</Container>
		</section>
	);
}
export default Streetviewconfig;