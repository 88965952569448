import Axios from "axios"
import Swal from 'sweetalert2'
import { useEffect, useState, useRef } from "react";

import "../../assets/css/clients/clients.css";
import close from "../../assets/images/close.svg";
import PageSelector from "../reportLog/pageSelector.jsx";
import listAlt from "../../assets/images/list_alt.svg";
import moreDots from "../../assets/images/moreDots.svg";
// import logo from "../../assets/images/profileExample.jpg";
import arrowDown from "../../assets/images/arrowDown.svg";
import alphabet from "../../assets/jsonData/abecedario.json";
import ClientsMobileFilter from "./clientsMobileFilter.jsx";


export default function AllClients(props) {
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(false)
  const [activeToolTip, setActiveTooltip] = useState({ id: "", h4: "" })

  const [activeFilter, setActiveFilter] = useState(0);
  const [activeLetter, setActiveLetter] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(6);

  const pageClientFilterRef = useRef(null)
  const showOptionsClientRef = useRef(null)

  // -------------- states ⬆, useEffect's ⬇ -------------  
  useEffect(() => { 
        document.body.addEventListener("click", handleCloseDropdowns)

        return () => {
            document.body.removeEventListener("click", handleCloseDropdowns)
        }
  }, [])

  useEffect(() => {
    if (!props.isOpened && !props.isMinimized) {
      setActiveFilter(0);
      setItemsPerPage(6);
      setCurrentPage(1);
      setActiveLetter("")
    }
  }, [props.isOpened]);

  useEffect(() => {
    setFilteredClients(
      props.allClients.map((client) => {
        return {
          ...client,
          showNotes: false,
        };
      })
    );
  }, []);

  useEffect(() => {
      if(!loading) {
        Swal.close()
        return
      }

      Swal.fire({
        title: 'Loading...',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
  }, [loading])

  useEffect(() => {
    if(props.clientEdited) {
      setLoading(true)
      Axios.get(process.env.REACT_APP_FIND_ALL_CLIENTS).then(clients => {
        props.setAllClients(clients.data)
        setLoading(false)
        props.setClientEdited(false)
      }).catch(err => console.log(err))
    }
  }, [props.clientEdited])

  useEffect(() => {
    setStartIndex((currentPage - 1) * itemsPerPage);
    setEndIndex(currentPage * itemsPerPage);
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    const filteredByLetter = Filters(props.allClients, activeLetter);
    setFilteredClients(SearchBarFilter(filteredByLetter, props.search));

    if (currentPage > Math.ceil(props.allClients.length / itemsPerPage)) {
      setCurrentPage(currentPage - 1);
    }
  }, [props.allClients]);

  useEffect(() => {
    setCurrentPage(1);

    const filteredByLetter = Filters(props.allClients, activeLetter);
    setFilteredClients(SearchBarFilter(filteredByLetter, props.search));
  }, [props.search, activeLetter]);

  // ---------------- useEffect's ⬆, handle Functions ⬇ ------

  function handleCloseDropdowns(e) {
    if (pageClientFilterRef.current && !pageClientFilterRef.current.contains(e.target)) {
      setActiveFilter(0)
    }

    const allShowOptions = Array.prototype.slice.call(document.getElementsByClassName("moreOptionsClient"))
    if (showOptionsClientRef.current && showOptionsClientRef.current.contains(e.target)) {
      for (const element of allShowOptions) {
        if(!showOptionsClientRef.current.nextElementSibling.contains(element)) {
          element.classList.remove("show")
        }
      }
      allShowOptions.find(element => showOptionsClientRef.current && showOptionsClientRef.current.nextElementSibling.contains(element)).classList.toggle("show")
    } else {
      for(const element of allShowOptions) {
        element.classList.remove("show")
      }
    }
  }
  
  function handleCurrentPage(value) {
    setCurrentPage(value);
  }

  function handleItemsPerPage(e) {
    setItemsPerPage(+e.target.innerText);

    setCurrentPage(1);
  }

  function handleCloseNote() {
    setFilteredClients(
      filteredClients.map((client) => {
        return {
          ...client,
          showNotes: false,
        };
      })
    );
  }

  function handleClickAlphabet(letter) {
    setActiveLetter(letter);
  }

  function handleShowNotes(e) {
    const { id } = e.target
    
    setFilteredClients(
      filteredClients.map((client) => {
        return {
          ...client,
          showNotes: client._id === id,
        };
      })
    );
  }

  function handleShowReport(e) {
    const { id } = e.target

    setFilteredClients(
      filteredClients.map((client) => {
        return {
          ...client,
          showNotes: false,
        };
      }))

    props.setshowClientId(id)
    props.setActivateFetch(true)
    props.closeClientsSection()
    setTimeout(() => {
      props.openReportLog()
    }, 100);
  }

  function handleReset() {
    setActiveLetter("");
  }

  function handleEdit(e) {
    const { id } = e.target;
    props.setIdToEdit(id)
    props.setActiveSection(2)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // function handleDelete(e) {
  //   const { id } = e.target;
  //   console.log(id)
    // const newAllClients = filteredClients.filter((client) => client._id !== id);
    // props.setAllClients(newAllClients);
    // setFilteredClients(
    //   filteredClients.map((client) => {
    //     return {
    //       ...client,
    //       showNotes: false,
    //     };
    //   })
    // );
  // }

  // utils ⬇

  function SearchBarFilter(data, searchTerm) {
    if (searchTerm !== "" && data.length > 0) {
      const filteredData = data.filter((item) =>
        item.companyName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return filteredData;
    }
    return data;
  }

  function Filters(data, letter) {
    if (letter && data.length > 0) {
      const filteredClients = data.filter(
        (client) => client.companyName.charAt(0).toLowerCase() === letter.toLowerCase()
      );
      return filteredClients;
    }

    return data;
  }

  return (
    <main className="clientDirectory">
      <section className="filtersContainer">
        <ClientsMobileFilter 
          activeLetter={activeLetter}
          setActiveLetter={setActiveLetter}
          handleClickAlphabet={handleClickAlphabet}
        />
        <div className="alphabetContainer d-none d-lg-flex">
          {alphabet.alphabet.map((letter) => {
            return (
              <button
                className="buttonLetter"
                key={letter}
                onClick={() => handleClickAlphabet(letter)}
                style={{ opacity: activeLetter === letter ? "1" : "0.5" }}
              >
                {letter}
              </button>
            );
          })}
          <button
            className="buttonLetter"
            name="reset"
            onClick={handleReset}
            style={{
              visibility: alphabet.alphabet.includes(activeLetter)
                ? "visible"
                : "hidden",
            }}
          >
            Reset
          </button>
        </div>
        <div ref={pageClientFilterRef} className="pagesFilterContainer">
          <div
            className={`itemsPerPageFilter ${
              activeFilter === 1 ? "active" : ""
            }`}
            onClick={() =>
              activeFilter === 1 ? setActiveFilter(0) : setActiveFilter(1)
            }
          >
            <img src={listAlt} alt="Prodensa automation" />
            <span>Show per page</span>
            <img src={arrowDown} alt="Prodensa automation" />
            <div
              className={`filterOptions ${activeFilter === 1 ? "show" : ""}`}
            >
              <span className="option" onClick={handleItemsPerPage}>
                6
              </span>
              <span className="option" onClick={handleItemsPerPage}>
                24
              </span>
              <span className="option" onClick={handleItemsPerPage}>
                48
              </span>
              <span className="option" onClick={handleItemsPerPage}>
                100
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="clientsCardsSection">
        {filteredClients.slice(startIndex, endIndex).map((client) => {
          return (
            <div key={client._id} className="clientCard">
              <div className="clientCard-title-section">
                <div className="logo" onClick={() => props.handleShowImageModal(client.logo && client.logo.filename !== "" ? client.logo.path : false)}>
                  {client.logo && client.logo.filename !== "" ? (
                    <img src={process.env.REACT_APP_BACKEND_URL + client.logo.path} alt="Prodensa automation" />
                  ) : (
                    <span>{client.companyName.charAt(0)}</span>
                  )}
                </div>
                <h3>{client.companyName}</h3>
              </div>
              <div className="cardOptions">
                <img
                  ref={showOptionsClientRef}
                  src={moreDots}
                  onClick={(e) => showOptionsClientRef.current = e.target}
                  alt="ProdensaAutomation"
                />
                <div
                  className={`moreOptionsClient`}
                >
                  <span className="option" id={client._id} onClick={handleShowNotes}>
                    Show notes
                  </span>
                  <span className="option" id={client._id} onClick={handleEdit}>
                    Edit
                  </span>
                  <span className="option" id={client._id} onClick={handleShowReport}>
                    Show Reports
                  </span>
                  {/* <span
                    className="option"
                    id={client._id}
                    onClick={handleDelete}
                  >
                    Delete
                  </span> */}
                </div>
              </div>
              
              <div className="clientInfo">
                <div className="tooltipContainer">
                  <div className="info">
                    <h4
                      onMouseOver={() => setActiveTooltip({ id: client._id, h4: "industry" })}
                      onMouseOut={() => setActiveTooltip({ id: "", h4: "" })}
                    >
                      {client.industry}
                    </h4>
                    {activeToolTip.id === client._id && activeToolTip.h4 === "industry" && <span className="tooltipClients">{client.industry}</span>}
                    <span className="subtitle">Industry</span>
                  </div>
                </div>
                <div className="tooltipContainer">
                  <div className="info">
                    <h4
                      onMouseOver={() => setActiveTooltip({ id: client._id, h4: "headquarters" })}
                      onMouseOut={() => setActiveTooltip({ id: "", h4: "" })}
                    >
                      {client.headquarters.fullName}
                    </h4>
                    {activeToolTip.id === client._id && activeToolTip.h4 === "headquarters" && <span className="tooltipClients">{client.headquarters.fullName}</span>}
                    <span className="subtitle">Headquarters</span>
                  </div>
                </div>
                <div className="tooltipContainer">
                  <div className="info">
                    <h4
                      onMouseOver={() => setActiveTooltip({ id: client._id, h4: "contactName" })}
                      onMouseOut={() => setActiveTooltip({ id: "", h4: "" })}
                    >
                      {client.contactName}
                    </h4>
                    {activeToolTip.id === client._id && activeToolTip.h4 === "contactName" && <span className="tooltipClients">{client.contactName}</span>}
                    <span className="subtitle">Contact Name</span>
                  </div>
                </div>
                <div className="tooltipContainer">
                  <div className="info">
                    <h4
                      className="clientMail"
                      onMouseOver={() => setActiveTooltip({ id: client._id, h4: "contactPosition" })}
                      onMouseOut={() => setActiveTooltip({ id: "", h4: "" })}
                    >
                      {client.mail}
                    </h4>
                    {activeToolTip.id === client._id && activeToolTip.h4 === "contactPosition" && <span className="tooltipClients">{client.mail}</span>}
                    <span className="subtitle">Mail</span>
                </div>
                </div>
                <div className="tooltipContainer">
                  <div className="info">
                    <h4
                      onMouseOver={() => setActiveTooltip({ id: client._id, h4: "phone" })}
                      onMouseOut={() => setActiveTooltip({ id: "", h4: "" })}
                    >
                      {client.phone}
                    </h4>
                    {activeToolTip.id === client._id && activeToolTip.h4 === "phone" && <span className="tooltipClients">{client.phone}</span>}
                    <span className="subtitle">Phone</span>
                  </div>
                </div>
              </div>
              <div className={`noteModal ${client.showNotes ? "show" : ""}`}>
                <div className="modalInfo">
                    <img src={close} alt="Prodensa automation" onClick={handleCloseNote}/>
                    <h5>Notes</h5>
                    <p>{client.notes}</p>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <PageSelector
        currentPage={currentPage}
        data={filteredClients}
        itemsPerPage={itemsPerPage}
        onChange={handleCurrentPage}
      ></PageSelector>
    </main>
  );
}
