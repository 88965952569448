import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const ManufacturingDensity = ({ details, city_names, filtered, reportID, musts, variableCosts }) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (musts && variableCosts) {
            setLoading(false);
        }
    }, []);

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {loading ? (
                <div>Loading</div>
            ) : musts && variableCosts ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Concept</h5>
                            <h5 className="side-text">Economically Active Population</h5>
                            <h5 className="side-text">IMMEX plants</h5>
                            <h5 className="side-text">IMMEX density per 100,000</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`row-1-${city}-${index}`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = musts.find(cityFind => cityFind.municipality_name === city);
                                    if (cityFind) {
                                        let eap = cityFind.EAP;
                                        if (eap) {
                                            textToShow = formatNumber(eap);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`row-2-${city}-${index}`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = variableCosts.find(cityFind => cityFind.cityName === city);
                                    if (cityFind) {
                                        let immexPlantsPrevYear = cityFind.dataVariables.manufacturingDensity?.immexPlantsPrevYear;
                                        if (immexPlantsPrevYear !== undefined) {
                                            textToShow = formatNumber(immexPlantsPrevYear);
                                        }
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number" key={`row-3-${city}-${index}`}>{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let textToShow = "N/A";
                                    let cityFind = details.results.find(cityFind => cityFind.city === city);
                                    if (cityFind && cityFind.valueResult.totalCost !== null) {
                                        textToShow = formatNumber(cityFind.valueResult.totalCost);
                                    }
                                    return (
                                        filtered.includes(index) && (
                                            <h5 className="number">{textToShow}</h5>
                                        )
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Concept</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={index}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Economically Active Population</td>
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = musts.find(cityFind => cityFind.municipality_name === city);
                                        if (cityFind) {
                                            let eap = cityFind.EAP;
                                            if (eap) {
                                                textToShow = formatNumber(eap);
                                            }
                                        }
                                        return filtered.includes(index) &&
                                            <td>{textToShow}</td>
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>IMMEX plants</td>
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = variableCosts.find(cityFind => cityFind.cityName === city);
                                        if (cityFind) {
                                            let immexPlantsPrevYear = cityFind.dataVariables.manufacturingDensity?.immexPlantsPrevYear;
                                            if (immexPlantsPrevYear !== undefined) {
                                                textToShow = formatNumber(immexPlantsPrevYear);
                                            }
                                        }
                                        return filtered.includes(index) &&
                                            <td>{textToShow}</td>
                                    }
                                    )}
                                </tr>
                                <tr>
                                    <td>IMMEX density per 100,000</td>
                                    {city_names.map((city, index) => {
                                        let textToShow = "N/A";
                                        let cityFind = details.results.find(cityFind => city === cityFind.city);
                                        if (cityFind && cityFind.valueResult.totalCost !== null) {
                                            textToShow = formatNumber(cityFind.valueResult.totalCost);
                                        }
                                        return filtered.includes(index) &&
                                            <td>{textToShow}</td>
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            )
            }

        </Container>
    )
}

export default ManufacturingDensity;