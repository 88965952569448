import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import { useEffect, useState } from "react";
import infodanger from "../../../assets/images/infoDanger.svg";

const Airports = ({
  details,
  city_names,
  filtered,
  musts,
  assumptions,
  variableCosts,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (musts && assumptions && variableCosts) {
      setLoading(false);
    }
  }, []);

  return (
    <Container className="generic-wrapper">
      {/*First table*/}
      {loading ? (
        <div>Loading...</div>
      ) : (musts && assumptions && variableCosts) ? (
        <>
          <Row className="no-gutters d-none d-lg-flex">
            <Col lg={3}>
              <h5 className="first-spacer">Strategic Driver</h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>
                Airport
              </h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>
                Information
              </h5>
              <h5 className="side-text" style={{ minHeight: "90px" }}>
                Miles to nearby International Airport
              </h5>
            </Col>
            <Col lg={9} className="table-container">
              <div className="table-header">
                {city_names.map(
                  (city, index) =>
                    filtered.includes(index) && (
                      <h5 className="city-title" key={`${city}-${index}-title`}>{city}</h5>
                    )
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (city, index) => {
                    let infoToShow = "N/A"
                    let airportToRender = variableCosts.find(obj => obj.cityName == city);
                    if (airportToRender.dataVariables.connectivityTravel.airport !== "") {
                      infoToShow = airportToRender.dataVariables.connectivityTravel.airport;
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (city, index) => {
                    let infoToShow = "N/A"
                    let airportToRender = variableCosts.find(obj => obj.cityName == city);
                    if (airportToRender.dataVariables.connectivityTravel.information !== "") {
                      infoToShow = airportToRender.dataVariables.connectivityTravel.information;
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
              <div className="row-number">
                {city_names.map(
                  (city, index) => {
                    let infoToShow = "N/A"
                    let airportToRender = variableCosts.find(obj => obj.cityName == city);
                    if (airportToRender.dataVariables.connectivityTravel.milesToAirport !== "") {
                      infoToShow = airportToRender.dataVariables.connectivityTravel.milesToAirport;
                    }
                    return filtered.includes(index) && (
                      <h5 className="number" style={{ minHeight: "90px" }}>{infoToShow}</h5>
                    )
                  }
                )}
              </div>
            </Col>
          </Row>

          <section className="mobile-details-results-table d-lg-none">
            <table>
              <thead>
                <tr>
                  <th>Strategic driver</th>
                  {city_names.map(
                    (city, index) =>
                      filtered.includes(index) && (
                        <th key={`${city}-mobile-header`} style={{textAlign: 'center'}}>{city}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airport</td>
                  {city_names.map(
                    (city, index) => {
                      let infoToShow = "N/A"
                      let airportToRender = variableCosts.find(obj => obj.cityName == city);
                      if (airportToRender.dataVariables.connectivityTravel.airport !== "") {
                        infoToShow = airportToRender.dataVariables.connectivityTravel.airport;
                      }
                      return filtered.includes(index) && (
                        <td key={`${city}-airport`}>{infoToShow}</td>
                      )
                    }
                  )}
                </tr>
                <tr>
                  <td>Information</td>
                  {city_names.map(
                    (city, index) => {
                      let infoToShow = "N/A"
                      let airportToRender = variableCosts.find(obj => obj.cityName == city);
                      if (airportToRender.dataVariables.connectivityTravel.information !== "") {
                        infoToShow = airportToRender.dataVariables.connectivityTravel.information;
                      }
                      return filtered.includes(index) && (
                        <td key={`${city}-info`}>{infoToShow}</td>
                      )
                    }
                  )}
                </tr>
                <tr>
                  <td>Miles to nearby International Airport</td>
                  {city_names.map(
                    (city, index) => {
                      let infoToShow = "N/A"
                      let airportToRender = variableCosts.find(obj => obj.cityName == city);
                      if (airportToRender.dataVariables.connectivityTravel.milesToAirport !== "") {
                        infoToShow = airportToRender.dataVariables.connectivityTravel.milesToAirport;
                      }
                      return filtered.includes(index) && (
                        <td key={`${city}-miles`}>{infoToShow}</td>
                      )
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div className="no-record-wrapper">
          <h3 className="no-record-title">There has been an issue retrieving data.</h3>
          <img src={infodanger} width="50px" height="50px" />
        </div >
      )
      }

    </Container>
  );
};

export default Airports;
