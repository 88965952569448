import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";
import { formatNumber } from "../../../utils/formatNumber";


const NumberBuildings = ({ details, city_names, filtered }) => {

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {details ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3}>
                            <h5 className="first-spacer">Indicator Driver</h5>
                            <h5 className="side-text last">Number of Potential Buildings for Q12024</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let cellClass = "";
                                    let textToShow = "N/A";
                                    let cityFind = details.results.find((cityFind) => cityFind.city === city);
                                    if (cityFind) {
                                        if (cityFind.valueResult) {
                                            if (cityFind.valueResult.bestOption) {
                                                cellClass += " highest";
                                            }
                                            textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost)
                                            : "N/A"
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-buildings`}><span className={cellClass}>{textToShow}</span></h5>
                                    )
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Indicator</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Number of Potential Buildings for Q12024</td>
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A";
                                        let cityFind = details.results.find((cityFind) => cityFind.city === city);
                                        if (cityFind) {
                                            if (cityFind.valueResult) {
                                                if (cityFind.valueResult.bestOption) {
                                                    cellClass += " highest";
                                                }
                                                textToShow = cityFind.valueResult.totalCost !== null ? formatNumber(cityFind.valueResult.totalCost)
                                                : "N/A"
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td className={cellClass} key={`${city}-mobile-buildings`}>{textToShow}</td>
                                        )
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            )}

        </Container>
    )
}

export default NumberBuildings;