import "../../../assets/css/reportResults/wantsView/wantsView.css"

import { useState, useRef, useEffect } from "react";
import { Accordion, Container, Modal, Dropdown, DropdownToggle, DropdownMenu } from "react-bootstrap";

import PrevNext from "../prevNext.jsx";
import CompareBox from "./compare-box.jsx";
import info from "../../../assets/images/info-blue.svg";
import { formatCommas } from "../../../utils/formatCommas";
import infoIconDanger from "../../../assets/images/infoDanger.svg";
import arrowforward from "../../../assets/images/arrow_forward.svg";

const VAR_NO_DETAILS = [
    "65cd42caf955fa725381e7fa", 
    "65cd434ef955fa725381e807", 
    "65cd4347f955fa725381e806"
];


//  JSX FOR THE CRITERIA MODAL FOR EVERY VARIABLE
function CriteriaModel(props) {
    return (
        <Modal
            className="wantsCriteriaModal wantsViewCriteriaModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="titleAndType">
                    <span>{props.data.title}</span>
                    <Dropdown className={props.activebutton !== "edit" || props.token ? "disabled" : ""}>
                        <DropdownToggle disabled={props.activebutton !== "edit" || props.token}>
                            {props.data.criterias.typeOfValue}
                        </DropdownToggle>
                        <DropdownMenu>
                            <Dropdown.Item as="button"
                                onClick={(e) => props.changeVariableCriteriaTypeOfValue(e.target.innerText)}>Percentage</Dropdown.Item>
                            <Dropdown.Item as="button"
                                onClick={(e) => props.changeVariableCriteriaTypeOfValue(e.target.innerText)}>Number</Dropdown.Item>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="criteriaPoints">
                    <div className="criterias">
                        <span>Criteria</span>
                        <div className="inputContainer">
                            <span className="label">Option</span>
                            <Dropdown className={props.activebutton !== "edit" || props.token ? "disabled" : ""}>
                                <DropdownToggle disabled={props.activebutton !== "edit" || props.token}>
                                    {props.data.criterias.options[0].criteria}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Lowest value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Highest value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Higher than</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(0, e.target.innerText)}>Lower than</Dropdown.Item>
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className={`inputContainer ${props.data.criterias.options[0].criteria === "Lowest value" || props.data.criterias.options[0].criteria === "Highest value" ? "hide" : ""}`}>
                            <span className="label">Value</span>
                            <input type="text" value={formatCommas(props.data.criterias.options[0].value).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(0, "value", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>

                        <div className="inputContainer">
                            <span className="label">From</span>
                            <input type="text" placeholder="From" value={formatCommas(props.data.criterias.options[1].firstRange).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "firstRange", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">To</span>
                            <input type="text" placeholder="To" value={formatCommas(props.data.criterias.options[1].secondRange).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "secondRange", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">From</span>
                            <input type="text" placeholder="From" value={formatCommas(props.data.criterias.options[2].firstRange).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "firstRange", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                        <div className="inputContainer">
                            <span className="label">To</span>
                            <input type="text" placeholder="To" value={formatCommas(props.data.criterias.options[2].secondRange).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "secondRange", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>

                        <div className="inputContainer">
                            <span className="label">Option</span>
                            <Dropdown className={props.activebutton !== "edit" || props.token ? "disabled" : ""}>
                                <DropdownToggle disabled={props.activebutton !== "edit" || props.token}>
                                    {props.data.criterias.options[3].criteria}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Lowest
                                        value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Highest
                                        value</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Higher
                                        than</Dropdown.Item>
                                    <Dropdown.Item as="button"
                                        onClick={(e) => props.changeVariableCriteria(3, e.target.innerText)}>Lower
                                        than</Dropdown.Item>
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className={`inputContainer ${props.data.criterias.options[3].criteria === "Lowest value" || props.data.criterias.options[3].criteria === "Highest value" ? "hide" : ""}`}>
                            <span className="label">Value</span>
                            <input type="text" value={formatCommas(props.data.criterias.options[3].value).formattedValue} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(3, "value", formatCommas(e.target.value).numericValue)} />
                            <span className="absoluteIcon">{props.data.criterias.typeOfValue === "Percentage" ? "%" : "#"}</span>
                        </div>
                    </div>
                    <div className="points">
                        <span className="title text-center">Points</span>
                        <span className="value">0</span>
                        <span className="value">1</span>
                        <span className="value">2</span>
                        <span className="value">3</span>
                    </div>
                </div>
                <button className="applyChanges" onClick={props.onHide}>{props.activebutton === "edit" && !props.token ? "Apply" : "Close"}</button>
            </Modal.Body>
        </Modal>
    );
}

//  JSX FOR THE MANUAL CRITERIA MODAL FOR VARIABLE 5.1, 6.1 and 6.2
function ManualCriteriaModel(props) {
    return (
        <Modal
            className="wantsCriteriaModal wantsViewCriteriaModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="titleAndType">
                    <span>{props.data.title}</span>
                </div>
                <div className="criteriaPoints">
                    <div className="criterias">
                        <span>Criteria</span>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data.criterias.options[0].value} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(0, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data.criterias.options[1].value} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(1, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data.criterias.options[2].value} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(2, "value", e.target.value)} />
                        </div>
                        <div className="inputContainer manual">
                            <span className="label">Information</span>
                            <input type="text" value={props.data.criterias.options[3].value} disabled={props.activebutton !== "edit" || props.token}
                                onChange={(e) => props.changeVariableCriteriaValue(3, "value", e.target.value)} />
                        </div>
                    </div>
                    <div className="points">
                        <span className="title text-center">Points</span>
                        <span className="value">0</span>
                        <span className="value">1</span>
                        <span className="value">2</span>
                        <span className="value">3</span>
                    </div>
                </div>
                <button className="applyChanges" onClick={props.onHide}>{props.activebutton === "edit" && !props.token ? "Apply" : "Close"}</button>
            </Modal.Body>
        </Modal>
    );
}

const WantsView = (props) => {
    const infotooltipref = useRef(null); // refs for tooltip handling
    const [activeItems, setActiveItems] = useState([]); //array of active accordion items
    const [lowerVisible, setLowerVisible] = useState(0);   //Sets the lower visible limit for displaying cities in the table
    const [upperVisible, setUpperVisible] = useState(2);  //Sets the initial upper visible limit that allows visualization
    const [filtered, setFiltered] = useState(null);      //Array of filtered cities
    const [showModal, setShowModal] = useState(false);   //State for showing the criteria modal
    const [showManualModal, setShowManualModal] = useState(false); //State for showing the manual criteria modal
    const [modalData, setModalData] = useState(null);    //State for storing the data of the variable to be edited
    const [manualModalData, setManualModalData] = useState(null); //State for storing the data of the variable to be edited
    const [modalVariablePosition, setModalVariablePosition] = useState({ positionFather: null, positionVariable: null }); //State for storing the position of the variable to be edited

    
    useEffect(() => {
        if (props.cityNames) {
            let initialFiltered = props.cityNames.map((city, index) => index);
            setFiltered(initialFiltered);
        }
    }, [props.cityNames])

    useEffect(() => {
        document.body.addEventListener("touchmove", handleOutsideClick);
        document.body.addEventListener("click", handleOutsideClick);  //Event listeners for verifying click outside of tooltip
        return () => {
            document.body.removeEventListener("click", handleOutsideClick);
            document.body.removeEventListener("touchmove", handleOutsideClick);
        };
    });

    useEffect(() => {    //If the array is smaller than three elements it assigns the value of the last element and the first, if its greater than 3 it assigns the first and third position automatically
        // Determine new upperVisible based on the length of filtered array
        if (filtered) {
            let newUpperVisible;
            if (filtered.length <= 3) {
                newUpperVisible = filtered[filtered.length - 1];
            } else {
                newUpperVisible = filtered.length - (filtered.length - 2);
            }
            // Adjust lowerVisible and upperVisible based on the newUpperVisible
            setLowerVisible(0);
            setUpperVisible(newUpperVisible);
        }

    }, [filtered]);

    const initializeCriteriaModel = (indexFather, indexVariable, data, title) => {
        setModalVariablePosition({
            positionFather: indexFather,
            positionVariable: indexVariable
        })
        if (title === "Connectivity Travel" || title === "Government Incentives" || title === "Government Landscape") {
            data.title = title
            setManualModalData(data)
            setShowManualModal(true)
        } else {
            data.title = title
            setModalData(data)
            setShowModal(true)
        }
    }

    const changeVariableCriteriaTypeOfValue = (value) => {
        props.setWantsMatrixCopy(prevWantsMatrixCopy => {
            const newWantsMatrixCopy = [...prevWantsMatrixCopy];
            newWantsMatrixCopy[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.typeOfValue = value;
            return newWantsMatrixCopy;
        });
    }

    const changeVariableCriteria = (position, value) => {
        props.setWantsMatrixCopy(prevWantsMatrixCopy => {
            const newWantsMatrixCopy = [...prevWantsMatrixCopy];
            newWantsMatrixCopy[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.options[position].criteria = value;
            return newWantsMatrixCopy;
        });
    }

    const changeVariableCriteriaValue = (position, value, newValue) => {
        props.setWantsMatrixCopy(prevWantsMatrixCopy => {
            const newWantsMatrixCopy = [...prevWantsMatrixCopy];
            newWantsMatrixCopy[modalVariablePosition.positionFather].variables[modalVariablePosition.positionVariable].criterias.options[position][value] = newValue;
            return newWantsMatrixCopy;
        });
    }


    const handleOutsideClick = (e) => {             //Function for handling the visibility of tooltips
        const infoArray = Array.prototype.slice.call(document.getElementsByClassName("info-tooltip"))
        if (infotooltipref.current && infotooltipref.current.contains(e.target)) {
            for (const element of infoArray) {
                if (infotooltipref.current && !infotooltipref.current.nextElementSibling.contains(element)) {
                    element.classList.remove("activa")
                }
            }
            infoArray.find(element => infotooltipref.current && infotooltipref.current.nextElementSibling.contains(element)).classList.toggle("activa")
        } else {
            for (const element of infoArray) {
                element.classList.remove("activa")
            }
        }
    }

    const toggleItem = (item_id) => {               //Append an accordion id to the active items array so that its mapped in accordion element and open
        const newItem = { id: item_id };
        // Check if the newItem is already in activeItems
        const isItemInArray = activeItems.some(item => item.id === newItem.id);
        if (!isItemInArray) {
            // Append newItem to activeItems array
            const updatedactiveItems = [...activeItems, newItem];
            setActiveItems(updatedactiveItems);

        } else {
            const updatedactiveItems = activeItems.filter(item => item.id !== newItem.id);
            // Set the state to update activeItems
            setActiveItems(updatedactiveItems);
        }

    }

    const openAllItems = () => {                    //Adding a number of items depending on the criteria, i.e operations costs
        let idCounter = 0; // Start with 1 or any initial value you prefer
        const updatedActiveItems = props.wantsMatrix.map(() => ({
            id: idCounter++,
        }));
        updatedActiveItems.push({ id: -1 });
        setActiveItems(updatedActiveItems);
    }

    const closeAllItems = () => {                   //Flushes the array of active items so that none are mapped, thus all are closed
        setActiveItems([]);
    }

    const calculateCategoryScore = (category, city) => {
        return !category.variables ? 0 : category.variables.reduce((acc, variable) => {
            const result = variable.results && variable.results.find(result => result.city === city)
            return acc + (result ? result.valueResult.wantsPoints : 0)
        }, 0);
    }

    const calculateTotalPoints = (city) => {
        return props.wantsResults.reduce((total, category) => {
            return total + (!category.variables ? 0 : category.variables.reduce((total, variable) => {
            const result = variable.results && variable.results.find(result => result.city === city);
            return total + (result ? result.valueResult.wantsPoints : 0);
            }, 0))
        }, 0);
    }

    const calculateTotalWeight = () => {
        return props.wantsMatrixCopy.reduce((total, category) => {
            return total + (category.weight === "" ? 0 : category.weight);
        }, 0);
    }

    return (
        <section className={`wants-view ${props.activebutton === "wants" || props.activebutton === "edit" ? "show" : ""}`}>
            {props.wantsMatrix && props.wantsResults && props.cityNames && filtered &&
                <Container>
                    <div className={`header-sticky`}>
                        <div className="openClose-comparebox-container">
                            <div className="d-lg-none openClose-mobile">
                                <button onClick={openAllItems}></button>
                                <button onClick={closeAllItems}></button>
                            </div>
                            <CompareBox 
                                origin={"wantsView"}
                                cityNames={props.cityNames} 
                                comp_variant={"table"} 
                                setFiltered={setFiltered} 
                                filtered={filtered} 
                            />
                        </div>
                        {/*Collapse all, open all, and other titles, cities are dynamically added*/}
                        <div className="header-section">
                            <div className="criterion-wrapper">
                                <span>Criterion</span>
                                <button className="d-none d-lg-flex" onClick={openAllItems}>Open all</button>
                                <button className="d-none d-lg-flex" onClick={closeAllItems}>Close all</button>
                            </div>
                            <button className="btn-header">Points</button>
                            <button className="btn-header">Weight %</button>
                            {props.cityNames.map((cityName, index) => (
                                filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible && (
                                    <button className="btn-header d-none d-lg-block" key={index}>{cityName}</button>
                                )
                            ))}
                            <PrevNext filtered={filtered} upperVisible={upperVisible} lowerVisible={lowerVisible}
                                setUpperVisible={setUpperVisible} setLowerVisible={setLowerVisible}
                            />
                        </div>
                    </div>
                    {/*Map through active items array as current active keys*/}
                    <Accordion activeKey={activeItems.map(item => item.id)}>
                        {props.wantsMatrixCopy
                        .sort((a, b) => {
                            const da = props.wantsMatrixRef.find(item => item._id === a.catIdRef).position
                            const db = props.wantsMatrixRef.find(item => item._id === b.catIdRef).position
                        
                            return da - db
                        })
                        .filter(category => {
                            const catRes = props.wantsResults.find(item => item.categoryIdRef === category.catIdRef)
                            
                            return catRes.variables && (props.activebutton === "edit" || category.weight !== 0);
                        })
                        .map((category, index) => {
                            const catRes = props.wantsResults.find(item => item.categoryIdRef === category.catIdRef)
                            const catRef = props.wantsMatrixRef.find(item => item._id === category.catIdRef)
                            return (
                                <Accordion.Item eventKey={index} key={index} style={{position: "relative"}}>
                                    <Accordion.Header onClick={() => toggleItem(index)} className={`${activeItems.some(item => item.id === index) ? "active" : ""}`}>
                                        <h5 className={`button-title ${props.activebutton === "edit" ? "edit-mode" : ""}`}>{catRef.title}</h5>
                                        <h5 className="points-weight">{category.weight === "" ? 0 : category.weight}</h5>
                                        {props.activebutton === "edit" ?
                                            <div className="inputContainer">
                                                <input
                                                    className="edit-mode-input"
                                                    type="number"
                                                    value={category.weight}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onWheel={(e) => e.currentTarget.blur()}
                                                    onChange={(e) => {
                                                        props.setWantsMatrixCopy(prevState => {
                                                            let newWantsMatrixCopy = [...prevState];
                                                            const categoryFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                            categoryFound.weight = e.target.value === "" ? "" :
                                                                +e.target.value < 0 ? 0 :
                                                                    +e.target.value > 100 ? 100 :
                                                                        +e.target.value;
                                                            return newWantsMatrixCopy;
                                                        })
                                                    }}
                                                /> 
                                                <div className="numberSelector" onClick={(e) => {
                                                    e.stopPropagation()
                                                    props.setWantsMatrixCopy(prevState => {
                                                        let newWantsMatrixCopy = [...prevState];
                                                        const categoryFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                        categoryFound.weight += 1;
                                                        categoryFound.weight = categoryFound.weight === "" ? "" :
                                                            +categoryFound.weight < 0 ? 0 :
                                                                +categoryFound.weight > 100 ? 100 :
                                                                    +categoryFound.weight;
                                                        return newWantsMatrixCopy;
                                                    })
                                                }}></div>
                                                <div className="numberSelector" onClick={(e) => {
                                                    e.stopPropagation()
                                                    props.setWantsMatrixCopy(prevState => {
                                                        let newWantsMatrixCopy = [...prevState];
                                                        const categoryFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                        categoryFound.weight -= 1;
                                                        categoryFound.weight = categoryFound.weight === "" ? "" :
                                                            +categoryFound.weight < 0 ? 0 :
                                                                +categoryFound.weight > 100 ? 100 :
                                                                    +categoryFound.weight;
                                                        return newWantsMatrixCopy;
                                                    })
                                                }}></div>
                                            </div>
                                            :
                                            <h5 className="points-weight">{category.weight}%</h5>
                                        }
                                        {props.cityNames.map((cityName, index) => {
                                            const cityScore = calculateCategoryScore(catRes, cityName)
                                            return (
                                                filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible &&
                                                <h5 className="city-score-highlight d-none d-lg-block" key={index}>{cityScore.toFixed(2)}</h5>
                                            )
                                        })}
                                    </Accordion.Header>
                                    <Accordion.Body className="d-none d-lg-block">
                                        {category.variables
                                        .sort((a, b) => {
                                            const da = catRef.variables.find(item => item._id === a.varIdRef)?.position
                                            const db = catRef.variables.find(item => item._id === b.varIdRef)?.position
                                        
                                            return da - db
                                        })
                                        .filter(variable => {
                                            const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                            return varRes.results && (props.activebutton === "edit" || variable.weight !== 0)
                                        })
                                        .map((variable, subindex) => {
                                            const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                            catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                            const varRef = variable.addedVariable ? variable : 
                                                catRef.variables.find(item => item._id === variable.varIdRef)
                                            return (
                                                <div key={subindex} className="subcriteria-row">
                                                    <div className={`criteria-description ${props.activebutton === "edit" ? "edit-mode" : ""}`}>
                                                        <img src={info} className="info-icon" onClick={(e) => infotooltipref.current = e.target} ref={infotooltipref} />
                                                        <div className="info-tooltip">
                                                            <h5>Information</h5>
                                                            <div className="tooltip-row">
                                                                <button className="definition-icon"></button>
                                                                <div>
                                                                    <h5 className="tooltip-title">Definition</h5>
                                                                    <span className="text-column">{variable.information.definition}</span>
                                                                </div>
                                                            </div>
                                                            <div className="tooltip-row">
                                                                <button className="metric-icon"></button>
                                                                <div>
                                                                    <h5 className="tooltip-title">Metric</h5>
                                                                    <span className="text-column">{variable.information.metric}</span>
                                                                </div>
                                                            </div>
                                                            <div className="tooltip-row">
                                                                <button className="source-icon"></button>
                                                                <div>
                                                                    <h5 className="tooltip-title">Source</h5>
                                                                    <span className="text-column">{variable.information.source}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h5 className="button-title">{variable.varIdRef?.toString() === "65cd41c4f955fa725381e7ec" ? 
                                                            "Average " + props.land.landContract.toLowerCase() + " cost" : 
                                                            varRef.title}
                                                        </h5>
                                                        <button className="criteria-button" onClick={() => initializeCriteriaModel(index, subindex, variable, varRef.title)}>Criteria</button>
                                                    </div>
                                                    <h5 className="criteria-row-data">{(category.weight * variable.weight) / 100}</h5>
                                                    {props.activebutton === "edit" ?
                                                        <div className="inputContainer">
                                                            <input
                                                                className="edit-mode-input"
                                                                type="number"
                                                                value={variable.weight}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onWheel={(e) => e.currentTarget.blur()}
                                                                onChange={(e) => {
                                                                    props.setWantsMatrixCopy(prevState => {
                                                                        let newWantsMatrixCopy = [...prevState];
                                                                        const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                                            .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                                        variableFound.weight = e.target.value === "" ? "" :
                                                                            +e.target.value < 0 ? 0 :
                                                                                +e.target.value > 100 ? 100 :
                                                                                    +e.target.value;
                                                                        return newWantsMatrixCopy;
                                                                    })
                                                                }}
                                                            />
                                                            <div className="numberSelector" onClick={(e) => {
                                                                e.stopPropagation()
                                                                props.setWantsMatrixCopy(prevState => {
                                                                    let newWantsMatrixCopy = [...prevState];
                                                                    const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                                        .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                                    variableFound.weight += 1;
                                                                    variableFound.weight = variableFound.weight === "" ? "" :
                                                                        +variableFound.weight < 0 ? 0 :
                                                                            +variableFound.weight > 100 ? 100 :
                                                                                +variableFound.weight;
                                                                    return newWantsMatrixCopy;
                                                                })
                                                            }}></div>
                                                            <div className="numberSelector" onClick={(e) => {
                                                                e.stopPropagation()
                                                                props.setWantsMatrixCopy(prevState => {
                                                                    let newWantsMatrixCopy = [...prevState];
                                                                    const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                                        .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                                    variableFound.weight -= 1;
                                                                    variableFound.weight = variableFound.weight === "" ? "" :
                                                                        +variableFound.weight < 0 ? 0 :
                                                                            +variableFound.weight > 100 ? 100 :
                                                                                +variableFound.weight;
                                                                    return newWantsMatrixCopy;
                                                                })
                                                            }}></div>
                                                        </div>
                                                        :
                                                        <h5 className="criteria-row-data">{variable.weight}%</h5>
                                                    }
                                                    {props.cityNames.map((cityName, index) => {
                                                        const cityScoreValue = varRes.results && varRes.results.find(result => result.city === cityName)
                                                        const cityScoreToShow = cityScoreValue ? +cityScoreValue.valueResult.points : "N/A"
                                                        return (
                                                            filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible &&
                                                            <h5 className="criteria-row-data d-none d-lg-block" key={index}>{cityScoreToShow}</h5>
                                                        )
                                                    })}
                                                    {<div className={`details-button ${(props.activebutton === "edit" || 
                                                        variable.varIdRef?.toString() === "65cd42caf955fa725381e7fa" || //foreign investment
                                                        variable.varIdRef?.toString() === "65cd434ef955fa725381e807" || //high temperature peaks
                                                        variable.varIdRef?.toString() === "65cd4347f955fa725381e806") //humidity days
                                                        ? "hide" : ""}`} 
                                                        onClick={() => {
                                                        props.setIsDetailsActive(true); props.setDetails(varRes); props.setTableComponentLoaded(false);
                                                        props.setWantsDetails(variable)
                                                    }}> 
                                                        <h5>Details</h5>
                                                        <img src={arrowforward} />
                                                    </div>}
                                                </div>
                                            )
                                        })}
                                        <div className={`totals-row ${activeItems.some(item => item.id === index) ? "show" : ""} ${props.activebutton === "edit" ? "edit-mode" : ""}`}>
                                            <h5>{category.weight === "" ? 0 : category.weight}</h5>
                                            <h5 className={props.activebutton === "edit" &&
                                                category.variables.reduce((total, variable) => total + (variable.weight === "" ? 0 : variable.weight), 0) > 100 ?
                                                "danger" : ""}
                                            >
                                                {`${(category.variables.reduce((total, variable) => total + (variable.weight === "" ? 0 : variable.weight), 0)).toFixed(2)}`}
                                            </h5>
                                            <div className={`dangerMessage ${category.variables.reduce((total, variable) => total + (variable.weight === "" ? 0 : variable.weight), 0) > 100 ?
                                                "show" : ""}`}>
                                                <img src={infoIconDanger} alt="Prodensa Automation" />
                                                <p>The sum of all variables should't surpass 100%. Please adjust the values of each
                                                    category.
                                                </p>    
                                            </div>
                                            {props.cityNames.map((cityName, index) => {
                                                const cityScore = calculateCategoryScore(catRes, cityName)
                                                return (
                                                    filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible &&
                                                    <h5 className="city-score-highlight" key={index}>{cityScore.toFixed(2)}</h5>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Body>
                                        
                                    <Accordion.Body className="d-lg-none">
                                        <table className="mobile-results-table">
                                            <thead>
                                                <tr>
                                                    <th>Cities</th>
                                                    {category.variables
                                                    .sort((a, b) => {
                                                        const da = catRef.variables.find(item => item._id === a.varIdRef)?.position
                                                        const db = catRef.variables.find(item => item._id === b.varIdRef)?.position
                                                    
                                                        return da - db
                                                    })
                                                    .filter(variable => {
                                                        const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                            catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                        return varRes.results && (props.activebutton === "edit" || variable.weight !== 0)
                                                    })
                                                    .map((variable, subindex) => {
                                                        const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                        catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                        const varRef = variable.addedVariable ? variable : 
                                                            catRef.variables.find(item => item._id === variable.varIdRef)

                                                            return (
                                                                <th key={varRef._id}>
                                                                    <div>
                                                                        <img src={info} className="info-icon" alt="Prodensa Automation" 
                                                                            onClick={(e) => infotooltipref.current = e.target} ref={infotooltipref}
                                                                        />
                                                                        <div className="info-tooltip">
                                                                            <h5>Information</h5>
                                                                            <div className="tooltip-row">
                                                                                <button className="definition-icon"></button>
                                                                                <div>
                                                                                    <h5 className="tooltip-title">Definition</h5>
                                                                                    <span className="text-column">{variable.information.definition}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tooltip-row">
                                                                                <button className="metric-icon"></button>
                                                                                <div>
                                                                                    <h5 className="tooltip-title">Metric</h5>
                                                                                    <span className="text-column">{variable.information.metric}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tooltip-row">
                                                                                <button className="source-icon"></button>
                                                                                <div>
                                                                                    <h5 className="tooltip-title">Source</h5>
                                                                                    <span className="text-column">{variable.information.source}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tooltip-row">
                                                                                <button className="source-icon"></button>
                                                                                <div>
                                                                                    <h5 className="tooltip-title">Points</h5>
                                                                                    <span className="text-column">{(category.weight * variable.weight) / 100}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tooltip-row">
                                                                                <button className="source-icon"></button>
                                                                                <div>
                                                                                    <h5 className="tooltip-title">Weight %</h5>
                                                                                    <span className="text-column">{variable.weight}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <h5 className="var-title">{varRef.title}</h5>
                                                                        <div className="title-criteria-container">
                                                                            <button className="criteria-button" 
                                                                                onClick={() => initializeCriteriaModel(index, subindex, variable, varRef.title)}>
                                                                                Criteria
                                                                            </button>
                                                                            {<div className={`details-button mobile-details-button ${(props.activebutton === "edit" || VAR_NO_DETAILS.includes(variable.varIdRef?.toString()))
                                                                                ? "hide" : ""}`} 
                                                                                onClick={() => {
                                                                                    props.setIsDetailsActive(true);
                                                                                    props.setDetails(varRes);
                                                                                    props.setTableComponentLoaded(false);
                                                                                    props.setWantsDetails(variable)
                                                                                }}> 
                                                                                <img src={arrowforward} />
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.cityNames.map((cityName, index) => {
                                                    const cityScore = calculateCategoryScore(catRes, cityName)
                                                    return (
                                                        filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible &&
                                                        <tr key={cityName}>
                                                            <td>{cityName}</td>
                                                            {category.variables
                                                            .sort((a, b) => {
                                                                const da = catRef.variables.find(item => item._id === a.varIdRef)?.position
                                                                const db = catRef.variables.find(item => item._id === b.varIdRef)?.position
                                                            
                                                                return da - db
                                                            })
                                                            .filter(variable => {
                                                                const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                                    catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                                return varRes.results && (props.activebutton === "edit" || variable.weight !== 0)
                                                            })
                                                            .map((variable, subindex) => {
                                                                const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                                catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                                return (
                                                                    <td key={variable.varIdRef}>{varRes.results.find(result => result.city === cityName).valueResult.points}</td>
                                                                )
                                                            })}
                                                            <td>{cityScore.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                        
                                        {category.variables
                                            .sort((a, b) => {
                                                const da = catRef.variables.find(item => item._id === a.varIdRef)?.position
                                                const db = catRef.variables.find(item => item._id === b.varIdRef)?.position
                                            
                                                return da - db
                                            })
                                            .filter(variable => {
                                                const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                    catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                return varRes.results && (props.activebutton === "edit" || variable.weight !== 0)
                                            })
                                            .map((variable, subindex) => {
                                                const varRes = variable.addedVariable ? catRes.variables.find(item => item.variableIdRef === variable._id) :
                                                catRes.variables.find(item => item.variableIdRef === variable.varIdRef)

                                                const varRef = variable.addedVariable ? variable : 
                                                    catRef.variables.find(item => item._id === variable.varIdRef)

                                                    return (null)

                                                    // return (
                                                    //     <section key={varRef._id} className="mobile-variable-row">
                                                    //         <div className="mobile-variable-header">
                                                    //             <div className="mobile-variable-title-info">
                                                    //                 <img src={info} className="info-icon" onClick={(e) => infotooltipref.current = e.target} ref={infotooltipref} />
                                                    //                 <div className="mobile-variable-titlse">
                                                    //                     <h5>{varRef.title}</h5>
                                                    //                     <button className="criteria-button" onClick={() => initializeCriteriaModel(index, subindex, variable, varRef.title)}>Criteria</button>
                                                    //                 </div>
                                                    //             </div>
                                                    //             <div className="mobile-variable-weight-details">
                                                    //                 <div className="mobile-variable-weight">
                                                    //                     <h5>{(category.weight * variable.weight) / 100}</h5>
                                                    //                     {props.activebutton === "edit" ?
                                                    //                         <div className="inputContainer">
                                                    //                             <input
                                                    //                                 className="edit-mode-input"
                                                    //                                 type="number"
                                                    //                                 value={variable.weight}
                                                    //                                 onClick={(e) => e.stopPropagation()}
                                                    //                                 onWheel={(e) => e.currentTarget.blur()}
                                                    //                                 onChange={(e) => {
                                                    //                                     props.setWantsMatrixCopy(prevState => {
                                                    //                                         let newWantsMatrixCopy = [...prevState];
                                                    //                                         const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                    //                                             .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                    //                                         variableFound.weight = e.target.value === "" ? "" :
                                                    //                                             +e.target.value < 0 ? 0 :
                                                    //                                                 +e.target.value > 100 ? 100 :
                                                    //                                                     +e.target.value;
                                                    //                                         return newWantsMatrixCopy;
                                                    //                                     })
                                                    //                                 }}
                                                    //                             />
                                                    //                             <div className="numberSelector" onClick={(e) => {
                                                    //                                 e.stopPropagation()
                                                    //                                 props.setWantsMatrixCopy(prevState => {
                                                    //                                     let newWantsMatrixCopy = [...prevState];
                                                    //                                     const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                    //                                         .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                    //                                     variableFound.weight += 1;
                                                    //                                     variableFound.weight = variableFound.weight === "" ? "" :
                                                    //                                         +variableFound.weight < 0 ? 0 :
                                                    //                                             +variableFound.weight > 100 ? 100 :
                                                    //                                                 +variableFound.weight;
                                                    //                                     return newWantsMatrixCopy;
                                                    //                                 })
                                                    //                             }}></div>
                                                    //                             <div className="numberSelector" onClick={(e) => {
                                                    //                                 e.stopPropagation()
                                                    //                                 props.setWantsMatrixCopy(prevState => {
                                                    //                                     let newWantsMatrixCopy = [...prevState];
                                                    //                                     const variableFound = newWantsMatrixCopy.find(item => item.catIdRef === category.catIdRef)
                                                    //                                         .variables.find(item => item.addedVariable ? item.title === variable.title : item.varIdRef === variable.varIdRef)
                                                    //                                     variableFound.weight -= 1;
                                                    //                                     variableFound.weight = variableFound.weight === "" ? "" :
                                                    //                                         +variableFound.weight < 0 ? 0 :
                                                    //                                             +variableFound.weight > 100 ? 100 :
                                                    //                                                 +variableFound.weight;
                                                    //                                     return newWantsMatrixCopy;
                                                    //                                 })
                                                    //                             }}></div>
                                                    //                         </div>
                                                    //                         :
                                                    //                         <h5 className="criteria-row-data">{variable.weight}%</h5>
                                                    //                     }
                                                    //                 </div>
                                                    //                 {<div className={`details-button ${(props.activebutton === "edit" || 
                                                    //                     variable.varIdRef?.toString() === "65cd42caf955fa725381e7fa" || //foreign investment
                                                    //                     variable.varIdRef?.toString() === "65cd434ef955fa725381e807" || //high temperature peaks
                                                    //                     variable.varIdRef?.toString() === "65cd4347f955fa725381e806") //humidity days
                                                    //                     ? "hide" : ""}`} 
                                                    //                     onClick={() => {
                                                    //                     props.setIsDetailsActive(true); props.setDetails(varRes); props.setTableComponentLoaded(false);
                                                    //                     props.setWantsDetails(variable)
                                                    //                 }}> 
                                                    //                     <h5>Details</h5>
                                                    //                     <img src={arrowforward} />
                                                    //                 </div>}
                                                    //             </div>
                                                    //         </div>
                                                    //     </section>
                                                    // )
                                            })
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                        )}
                        <Accordion.Item className="d-lg-none" eventKey={-1}>
                            <Accordion.Header onClick={() => toggleItem(-1)}>
                                <h5 className="total-title">Total</h5>
                                <h5 className="total-points">{calculateTotalWeight()}</h5>
                                <h5 className={`total-points ${calculateTotalWeight() > 100 ? "danger" : ""}`}
                                >
                                    {`${calculateTotalWeight()}%`}
                                </h5>
                                {/* <div className={`dangerMessage ${calculateTotalWeight() > 100 ? "show" : ""}`}>
                                    <img src={infoIconDanger} alt="Prodensa Automation" />
                                    <p>The sum of all categories should't surpass 100%. Please adjust the values of each
                                        category.
                                    </p>
                                </div> */}
                            </Accordion.Header>
                            <Accordion.Body>
                            <section className="total-table-container" >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Cities</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.cityNames.map((cityName, index) => (
                                            filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible && (
                                                <tr key={index}>
                                                    <td>{cityName}</td>
                                                    <td>{calculateTotalPoints(cityName)?.toFixed(2)}</td>
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                </table>
                            </section>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className="totals-row d-none d-lg-flex">
                        <div></div>
                        <h5>Totals</h5>
                        <h5 className={props.activebutton === "edit" && 
                            calculateTotalWeight() > 100 ?
                            "danger" : ""}
                        >
                            {`${(calculateTotalWeight())?.toFixed(2)}%`}
                        </h5>
                        <div className={`dangerMessage ${calculateTotalWeight() > 100 ? 
                            "show" : ""}`}
                        >
                            <img src={infoIconDanger} alt="Prodensa Automation" />
                            <p>The sum of all categories should't surpass 100%. Please adjust the values of each
                                category.</p>
                        </div>
                        {props.cityNames.map((cityName, index) => 
                                filtered.includes(index) && filtered.indexOf(index) < upperVisible + 1 && filtered.indexOf(index) >= lowerVisible &&
                                <h5 className="city-score-highlight" key={index}>{calculateTotalPoints(cityName)?.toFixed(2)}</h5>
                        )}
                    </div>

                    

                    
                </Container >
            }

            {modalData && <CriteriaModel
                show={showModal}
                onHide={() => setShowModal(false)}
                data={modalData}
                changeVariableCriteriaTypeOfValue={changeVariableCriteriaTypeOfValue}
                changeVariableCriteria={changeVariableCriteria}
                changeVariableCriteriaValue={changeVariableCriteriaValue}
                activebutton={props.activebutton}
                token={props.token}
            />}

            {manualModalData && <ManualCriteriaModel
                show={showManualModal}
                onHide={() => setShowManualModal(false)}
                data={manualModalData}
                changeVariableCriteriaValue={changeVariableCriteriaValue}
                activebutton={props.activebutton}
                token={props.token}
            />}
        </section >

    )

}
export default WantsView;