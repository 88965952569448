import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";


const MachineryEquipment = ({ details, city_names, filtered, reportID }) => {
    //For test before integration
    // const details = [13, 39, 13, 20, 26, 20];

    // const city_names = ["Saltillo", "Torreon", "Monterrey", "Mexicali", "Queretaro", "Yucatan"];


    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {details ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Indicator</h5>
                            <h5 className="side-text last">Machinery & Equipment Fabrication(+100)</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let cellClass = "";
                                    let textToShow = "N/A"
                                    let cityFind = details.results.find(cityFind => city === cityFind.city)
                                    if (cityFind) {
                                        if (cityFind.valueResult.totalCost !== null) {
                                            if (cityFind.valueResult.bestOption) {
                                                cellClass = "highest";
                                            }
                                            textToShow = cityFind.valueResult.totalCost
                                        }
                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className="number" key={`${city}-row1`}><span className={cellClass}>{textToShow}</span></h5>
                                    );
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Indicator</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Machinery & Equipment Fabrication(+100)</td>
                                    {city_names.map((city, index) => {
                                        let cellClass = "";
                                        let textToShow = "N/A"
                                        let cityFind = details.results.find(cityFind => city === cityFind.city)
                                        if (cityFind) {
                                            if (cityFind.valueResult.totalCost !== null) {
                                                if (cityFind.valueResult.bestOption) {
                                                    cellClass = "highest";
                                                }
                                                textToShow = cityFind.valueResult.totalCost
                                            }
                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td key={`${city}-row1`}><span className={cellClass}>{textToShow}</span></td>
                                        );
                                    })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            )}

        </Container >
    )
}

export default MachineryEquipment;