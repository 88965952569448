import "../../assets/css/reportResults/reportResults.css";

import axios from "axios";
import Swal from 'sweetalert2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

import { createPDF } from "./generatePdfFile.js"

import Summary from "./summary.jsx";
import VideoAndMap from "./videoAndMap.jsx";
import { createPdfObject } from "./utils.js"
import Details from "./wantsView/details.jsx";
import WantsView from "./wantsView/wantsView.jsx";
import PayrollDetails from "../payrolldetails.jsx";
import { useAuth } from "../../context/auth-context";
import { customAlert } from "../../utils/customAlert.js";
import arrowDown from "../../assets/images/arrowDown.svg";
import CompareSlider from "./wantsView/compareSlider.jsx";
import ReportResultsFooter from "./reportResultsFooter.jsx";
import checkicon from '../../assets/images/check_green.svg';
import infodanger from "../../assets/images/infoDanger.svg";
import arrowforward from "../../assets/images/arrow_forward.svg";


export default function ReportResults() {
    const { id } = useParams();
    const { user } = useAuth();
    const [cookies] = useCookies(['user'])
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const typeOfResult = searchParams.get("typeOfResult");

    const [mobilePopupOpen, setMobilePopupOpen] = useState(false); //Controller for mobile popup

    const [activebutton, setActivebutton] = useState("map");
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //Regex for mail validation
    const [activeMail, setActiveMail] = useState(false);                              //Control for email menu dropdown
    const [showEmail, setShowEmail] = useState(false);                                //email modal variable
    const [customMail, setCustomMail] = useState("");                                 //Mail holder
    const [isDetailsActive, setIsDetailsActive] = useState(false);                  //Controller of details view show
    const [details, setDetails] = useState(null);                                     //variable for passing down details depending on clicked variable
    const [mailError, setMailError] = useState(false);                              //Control for mail error display on input
    const [flyAnimationInProgress, setFlyAnimationInProgress] = useState(typeOfResult === "generate");    //control for initial flyover animation
    const [showExtraDetails, setShowExtraDetails] = useState(false);
    const [toggleSummary, setToggleSummary] = useState(true);
    const [streetviewInfo, setStreetviewInfo] = useState({   //info for streetview window
        open: false,
        id: 0
    })
    const [tableComponentLoaded, setTableComponentLoaded] = useState(false);
    const [wantsDetails, setWantsDetails] = useState(null); //array of wants 


    //*Misc initializing variables

    {
        /*calculation of the highest city score here instead of evaluating for every item mapped*/
    }
    const [sliderSettings, setSliderSettings] = useState(null);     //Control variable for carousel settings

    //*Use effects for initialization of component
    //token validation for retrieval of report object


    const [report, setReport] = useState(null)
    const [client, setClient] = useState(null)
    const [borderDistances, setBorderDistances] = useState(null)
    const [pdfConfig, setPdfConfig] = useState(null)
    const [wantsMatrix, setWantsMatrix] = useState(null); //array of user defined matrix values
    const [variableCosts, setVariableCosts] = useState(null); //array of variable costs
    const [musts, setMusts] = useState(null); //array of musts
    const [assumptions, setAssumptions] = useState(null); //array of assumptions
    const [wantsResults, setWantsResults] = useState(null); //array of results from wants matrix  
    const [cityNames, setCityNames] = useState(null);
    const [loading, setLoading] = useState(true);       //Controller for loading state
    const [sumBoxFiltered, setSumBoxFiltered] = useState([]);
    const [totalPayroll, setTotalPayroll] = useState(null);
    const [representationLetter, setRepresentationLetter] = useState(null);
    const [wantsMatrixCopy, setWantsMatrixCopy] = useState(null)
    const [isSaved, setIsSaved] = useState(false);
    const [wantsMatrixRef, setWantsMatrixRef] = useState(null);
    const reportID = id;

    const navigate = useNavigate()

    //Retrieval and propagation of report
    useEffect(() => {
        Promise.all([
            //Get the wants categories
            axios.get(process.env.REACT_APP_FIND_REPORT + reportID),
            //Get the calculated results for each category
            axios.get(process.env.REACT_APP_FIND_RESULTS + reportID),
            //Get certain extra data from each variable
            axios.post(process.env.REACT_APP_FIND_VARIABLE_COST, { reportId: reportID }),
            // Get wantsMatrixReference 
            axios.get(process.env.REACT_APP_GET_WANTSMATRIXREFERENCE),
            // Get pdf configuration
            axios.get(process.env.REACT_APP_GET_PDF_CONFIG + reportID),
            // Get border distances
            axios.get(process.env.REACT_APP_FIND_BORDER_DISTANCE),

        ]).then(([reportResponse, resultsResponse, variableCostResponse, wantsMatrixRefRes, pdfConfigRes, borderDistancesRes]) => {
            if (!reportResponse.data.data || !variableCostResponse.data.data || !wantsMatrixRefRes.data.data) {
                Swal.fire({
                    icon: 'error',
                    title: reportResponse.data.message,
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didDestroy: () => {
                        navigate('/dashboard')
                    }
                })
            }

            setBorderDistances(borderDistancesRes.data)

            setReport({
                report: reportResponse.data.data,
                results: resultsResponse.data
            })

            setPdfConfig(pdfConfigRes.data)

            const clientId = reportResponse.data.data.siteSelectionReport.clientId

            axios.get(process.env.REACT_APP_FIND_CLIENT + clientId).then((clientResponse) => {
                setClient(clientResponse.data)
                setWantsMatrixRef(wantsMatrixRefRes.data.data)

                //define the array of city Names
                const cityArray = reportResponse.data.data.siteSelectionReport.must.cities.map(city => city.municipality_name);
                cityArray.sort((a, b) => a.localeCompare(b));
                setCityNames(cityArray);

                //Define the initial filtered cities as all by default
                const initial_filtered = cityArray.map(city => cityArray.indexOf(city));
                setSumBoxFiltered(initial_filtered);


                setAssumptions(reportResponse.data.data.siteSelectionReport.assumption)
                setWantsMatrix(reportResponse.data.data.siteSelectionReport.want.wantsMatrix)
                setWantsMatrixCopy(JSON.parse(JSON.stringify(reportResponse.data.data.siteSelectionReport.want.wantsMatrix)))
                setWantsResults(resultsResponse.data.wantsCategories);
                setVariableCosts(variableCostResponse.data.data.cities);
                const init_scoreSliderSettings = {                                                  //Initial settings for the carousel slider
                    slidesToShow: cityArray.length > 3 ? 3 : cityArray.length,
                    slidesToScroll: 1,
                    infinite: false,
                };
                setSliderSettings(init_scoreSliderSettings);

                const promises = []
                if (reportResponse.data.data.siteSelectionReport.assumption.representationDocument.filename !== "") {
                    const getPayrollPromise = axios.post(process.env.REACT_APP_GET_REPRESENTATION, { reportId: reportID }).then((representationResponse) => {
                        setRepresentationLetter(representationResponse.data.representationDocumentInfo);
                    }).catch((error) => {
                        console.log(error);
                    });

                    promises.push(getPayrollPromise);
                }

                if (reportResponse.data.data.siteSelectionReport.want.TPCDocument.filename !== "") {
                    const getPayrollPromise = axios.post(process.env.REACT_APP_GET_PAYROLL, { reportId: reportID }).then((payrollResponse) => {
                        setTotalPayroll(payrollResponse.data.totalPayrollCostInfo);
                    }).catch((error) => {
                        console.log(error);
                        setTotalPayroll("There is no payroll");
                    });

                    promises.push(getPayrollPromise);
                } else {
                    setTotalPayroll("There is no payroll");
                }




                const fetchPhoto = async (city) => {
                    return new Promise((resolve, reject) => {
                        const request = {
                            query: city,
                            fields: ['name', 'geometry', 'photos'],
                        };

                        const service = new google.maps.places.PlacesService(document.createElement('div'));

                        service.findPlaceFromQuery(request, (results, status) => {
                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                resolve(results[0].photos[0].getUrl({ 'maxWidth': results[0].photos[0].height, 'maxHeight': results[0].photos[0].width }))
                            }
                        });
                    })
                }

                const getImages = async () => {
                    const cities = reportResponse.data.data.siteSelectionReport.must.cities;

                    const photoPromises = cities.map(async city => {
                        const cityName = city.municipality_name.replace(" Metroplex", "")
                        const photo = await fetchPhoto(cityName + ", " + city.state_name);
                        city.urlPhoto = photo
                        return city;
                    });

                    const citiesWithPhoto = await Promise.all(photoPromises);
                    setMusts(citiesWithPhoto)
                }







                Promise.all(promises)
                    .then(async () => {
                        const scriptId = 'google-maps-script';
                        const existingScript = document.getElementById(scriptId);

                        if (!existingScript) {
                            const script = document.createElement('script');
                            script.id = scriptId;
                            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1CwP1-nRCz9lOb_wRIhBn810G8sj-5ic&libraries=places`;
                            script.async = true;
                            await new Promise((resolve, reject) => {
                                script.onload = resolve;
                                script.onerror = reject;
                                document.body.appendChild(script);
                            });
                        }

                        await getImages();
                    })
                    .finally(() => {
                        if (!clientResponse.data) {
                            Swal.fire({
                                icon: 'error',
                                title: "There was an error retrieving the client data",
                                showConfirmButton: false,
                                showCancelButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: () => {
                                    Swal.hideLoading()
                                },
                                didDestroy: () => {
                                    setLoading(false)
                                }
                            })
                        }

                        clientResponse.data && setLoading(false);
                    }).catch((error) => {
                        console.log(error);
                        setLoading(false);
                    })
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }).catch((error) => {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: reportResponse.data.message,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.hideLoading()
                },
                didDestroy: () => {
                    navigate('/dashboard')
                }
            })
            // setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!isSaved && activebutton === "wants") {
            setWantsMatrixCopy(JSON.parse(JSON.stringify(wantsMatrix)))
        }
    }, [activebutton])

    useEffect(() => {
        if (!user) {
            navigate('/')
        }
    }, [])

    const recalculateResults = async () => {
        const data = {
            reportId: reportID,
            wantsMatrixCopy: wantsMatrixCopy,
            saveChanges: typeOfResult === "preview"
        }

        const readyToCalculate = wantsMatrixCopy.reduce((acc, category) => acc + (category.weight === "" ? 0 : category.weight), 0) <= 100 &&
            wantsMatrixCopy.every(category => category.variables.reduce((acc, variable) => acc + (variable.weight === "" ? 0 : variable.weight), 0) <= 100)

        if (!readyToCalculate) {
            Swal.fire({
                position: "center",
                title: "Error",
                text: `The sum of the weights for each category and variable must be less than or equal to 100.`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });

            return;
        }


        axios.post(process.env.REACT_APP_EDIT_MODE_RESULTS, data).then((editModeRes) => {
            if (!editModeRes.data) {
                Swal.fire({
                    position: "center",
                    title: "Error",
                    text: `There was an error recalculating the results.`,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
                return;
            }

            setWantsMatrixCopy(editModeRes.data.wantsMatrixCopy)

            setWantsMatrix(JSON.parse(JSON.stringify(editModeRes.data.wantsMatrixCopy)))
            setWantsResults(editModeRes.data.wantsCategories);

            setIsSaved(true)
            setActivebutton("wants")
        }).catch(err => {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }

    const validateMail = (customMail) => {
        Swal.fire({
            title: 'Loading...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        if (emailPattern.test(customMail)) {
            sendMail(customMail);
        } else {
            Swal.close()
            setMailError(true);
        }
    }

    //Sends the mail and if succesful, triggers handleMailSuccessMsg
    const sendMail = (email) => {
        if (email) {
            axios.post(process.env.REACT_APP_GENERATE_MAGIC_LINK + reportID, { email: email }).then(res => {
                Swal.close();
                if (res.data.message === "Mail sent successfully") {
                    Swal.fire({
                        position: "center",
                        titleText: `Your mail has been sent.`,
                        showConfirmButton: false,
                        imageUrl: checkicon,
                        imageWidth: 50,
                        imageHeight: 50,
                        timer: 2000,
                        timerProgressBar: true,
                        backdrop: 'rgba(0, 0, 0, 0)',
                        customClass: {
                            container: 'confirm-swal-container',
                            popup: 'confirm-swal-popup',
                            header: 'confirm-swal-header',
                            title: 'confirm-swal-title',
                            content: 'confirm-swal-content',
                            confirmButton: 'confirm-swal-confirm-button',
                            cancelButton: 'confirm-swal-cancel-button',
                            footer: 'confirm-swal-footer'
                        }
                    });
                    setShowEmail(false);
                    setActiveMail(false);
                }

            }).catch(err => {
                console.log(err);
                Swal.fire({
                    position: "center",
                    titleText: `Failed to send mail.`,
                    text: "Please enter a valid email address.",
                    icon: "error",
                    showConfirmButton: false,
                    customClass: {
                        container: 'confirm-swal-container',
                        popup: 'confirm-swal-popup',
                        header: 'confirm-swal-header',
                        title: 'confirm-swal-title',
                        content: 'confirm-swal-content',
                        confirmButton: 'confirm-swal-confirm-button',
                        cancelButton: 'confirm-swal-cancel-button',
                        footer: 'confirm-swal-footer'
                    }
                });
            });
        } else {
            //render new mail
            setCustomMail("")
            setShowEmail(true);
            setActiveMail(false)
        }
    }

    const calculateTotalPoints = (city) => {
        return wantsResults.reduce((total, category) => {
            return total + (!category.variables ? 0 : category.variables.reduce((total, variable) => {
                const result = variable.results && variable.results.find(result => result.city === city);
                return total + (result ? result.valueResult.wantsPoints : 0);
            }, 0))
        }, 0);
    }

    const getMaxScore = (cities) => {
        return Math.max(...cities.map(city => calculateTotalPoints(city)))
    }

    const generatePDF = () => {
        const PDF = createPdfObject(report, wantsMatrixRef, totalPayroll, borderDistances)

        try {
            createPDF(PDF, pdfConfig, borderDistances)
        } catch (err) {
            console.log("Error creating PDF")
            console.log(err)
        }
    }

    const handleGoBackClick = () => {
        navigate(`/dashboard?reportId=${id}`)
    }

    const handleEditSaveClick = () => {
        if (activebutton === "edit") {
            recalculateResults()
        } else {
            setIsSaved(false)
            setActivebutton("edit");
        }
    }

    const handleMapClick = () => {
        setActivebutton("map")
        setIsDetailsActive(false)
    }

    const handleWantsClick = () => {
        setActivebutton("wants")
    }


    return (
        <section className="report-results-wrapper" style={{ background: loading ? '#041A39' : '', transition: "background ease 0.2s" }}>
            {loading ? (
                <div style={{ background: '#041A39', width: "100vw", height: "100vh", height: "100dvh" }}></div>
            ) : (wantsMatrix && wantsResults && cityNames && musts && totalPayroll ? (
                <>
                    <div className={`button-wrapper 
                        ${!flyAnimationInProgress && !streetviewInfo.open ? "show" : ""}
                        ${activebutton === "wants" ? "rowOrder" : ""}
                        `}
                    >
                        <button
                            className="button-item" onClick={generatePDF} disabled={!pdfConfig}
                        >
                            <span className="d-none d-lg-block">Download PDF</span>
                        </button>

                        {typeOfResult === "generate" && <button
                            id="send-mail"
                            className={`button-item ${activeMail ? "active" : ""}`}
                            onClick={() => activeMail ? setActiveMail(false) : setActiveMail(true)}  //Makes the email selection button active, changing the class to the hover state
                        >
                            <span className="d-none d-lg-block">Send to mail</span>
                            <div className={`email-menu ${activeMail ? "show" : ""}`}>          {/*Makes the email selection menu active*/}
                                {client && <h5
                                    className="option-mail"
                                    onClick={() => { sendMail(client.mail); }}             //First option of the menu, logged in email. Sends email to the function in charge of dispatching to mailer
                                >
                                    {client.mail}
                                </h5>}
                                <h5 className="option-mail" onClick={() => { sendMail(); }}>          {/*Opens custom mail window so it can be then sent as parameter to the send Mail function */}
                                    Other...
                                </h5>
                            </div>
                        </button>}
                        <Modal className="mail-form-modal" centered show={showEmail} onHide={() => setShowEmail(false)}>  {/*Modal for adding custom mail */}
                            <Modal.Header closeButton>
                                <Modal.Title>Send to mail</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <input type="text" className={`mail-capture ${mailError ? "error" : ""}`} placeholder="Enter email address" onChange={(e) => setCustomMail(e.target.value)}></input>
                                <p className={`error-msg ${mailError ? "error" : ""}`}>Please enter a valid input</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="send-button" onClick={() => validateMail(customMail)}>
                                    Send
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className={`d-none d-lg-flex button-wrapper ${!flyAnimationInProgress && !streetviewInfo.open ? "show" : ""}`}>
                        <button
                            id="go-back"
                            className={`button-item ${activebutton === "goBack" ? "active" : ""}`}
                            onClick={handleGoBackClick}
                        >
                            Go back
                        </button>
                        <button
                            id="edit-save-button"
                            className={`button-item ${activebutton === "edit" ? "save" : ""}`}
                            onClick={handleEditSaveClick}
                        >
                            {`${activebutton === "edit" ? (typeOfResult === "generate" ? "Preview changes" : "Save changes") : "Edit mode"}`}
                        </button>
                        {/*Verify the current active button and toggle hover class on it*/}
                        <button
                            id="map"
                            className={`button-item ${activebutton === "map" ? "active" : ""}`}
                            onClick={handleMapClick}
                        >
                            Map view
                        </button>
                        <button
                            className={`button-item ${activebutton === "wants" ? "active" : ""}`}
                            onClick={handleWantsClick}
                        >
                            Wants view
                        </button>
                    </div>

                    <div className={`summary-button 
                        ${activebutton === "map" && !flyAnimationInProgress && !streetviewInfo.open ? "" : "show"}  
                        ${mobilePopupOpen ? "mobileHide" : ""}
                        ${toggleSummary ? "" : "toggle"}`}
                    >
                        <div className="d-lg-none hide-show-summary" onClick={() => setToggleSummary(!toggleSummary)}>
                            <img src={arrowDown} alt="Prodensa Automation" />
                        </div>
                        <div className="summary-box-head">
                            <h4>Summary</h4>
                            <CompareSlider
                                cityNames={cityNames}
                                setSumBoxFiltered={setSumBoxFiltered}
                                sliderSettings={sliderSettings}
                                setSliderSettings={setSliderSettings}
                            />
                        </div>
                        <div className="slider-detailsButton-container">
                            <div className="scores-row">
                                <Slider  {...sliderSettings}>
                                    {/*Shows the summary icon, mapping through cities and assigning a special class to the highest so its highlighted*/}
                                    {cityNames.map((cityName, cityIndex) => {

                                        return (
                                            sumBoxFiltered.includes(cityIndex) &&
                                            <div key={cityIndex} className="score-container">
                                                <h5>{cityName}</h5>
                                                <h2
                                                    className={
                                                        calculateTotalPoints(cityName) === getMaxScore(cityNames)
                                                            ? "highest"
                                                            : "std"
                                                    }
                                                >
                                                    {calculateTotalPoints(cityName).toFixed(0)}
                                                </h2>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                            <button onClick={() => setActivebutton("summary")}>
                                <span>See details</span>
                                <img src={arrowforward} />{" "}
                            </button>
                        </div>
                    </div>

                    <VideoAndMap
                        activebutton={activebutton}
                        typeOfResult={typeOfResult}
                        flyAnimationInProgress={flyAnimationInProgress}
                        setFlyAnimationInProgress={setFlyAnimationInProgress}
                        streetviewInfo={streetviewInfo}
                        setStreetviewInfo={setStreetviewInfo}
                        assumptions={assumptions.logistics}
                        allAssumptions={assumptions}
                        representationLetter={representationLetter}
                        representationLetterActive={assumptions.representationLetter}
                        musts={musts}
                        client={client}
                        setMobilePopupOpen={setMobilePopupOpen}
                        borderDistances={borderDistances}
                    />

                    <WantsView
                        wantsMatrixRef={wantsMatrixRef}
                        activebutton={activebutton}
                        setIsDetailsActive={setIsDetailsActive}
                        setDetails={setDetails}
                        setTableComponentLoaded={setTableComponentLoaded}
                        wantsResults={wantsResults}
                        wantsMatrix={wantsMatrix}
                        wantsMatrixCopy={wantsMatrixCopy}
                        setWantsMatrixCopy={setWantsMatrixCopy}
                        cityNames={cityNames}
                        reportID={reportID}
                        setWantsDetails={setWantsDetails}
                        land={assumptions.land}
                        token={null}
                    />

                    <Details
                        wantsMatrixRef={wantsMatrixRef}
                        setIsDetailsActive={setIsDetailsActive}
                        isDetailsActive={isDetailsActive}
                        details={details} setDetails={setDetails}
                        city_names={cityNames}
                        setShowExtraDetails={setShowExtraDetails}
                        tableComponentLoaded={tableComponentLoaded}
                        setTableComponentLoaded={setTableComponentLoaded}
                        variableCosts={variableCosts}
                        musts={musts}
                        assumptions={assumptions}
                        wantsDetails={wantsDetails}
                        totalPayroll={totalPayroll}
                    />

                    <Summary
                        activebutton={activebutton}
                        setActivebutton={setActivebutton}
                        wantsResults={wantsResults}
                        cityNames={cityNames}
                        wantsMatrix={wantsMatrix}
                        wantsMatrixRef={wantsMatrixRef}
                    />

                    {details && details.variableIdRef.toString() === "65cd417ff955fa725381e7e7" &&
                        <PayrollDetails
                            setShowExtraDetails={setShowExtraDetails}
                            city_names={cityNames}
                            showExtraDetails={showExtraDetails}
                            totalPayroll={totalPayroll} />
                    }

                    <ReportResultsFooter
                        activebutton={activebutton}
                        typeOfResult={typeOfResult}
                        handleGoBackClick={handleGoBackClick}
                        handleEditSaveClick={handleEditSaveClick}
                        handleMapClick={handleMapClick}
                        handleWantsClick={handleWantsClick}
                        token={null}
                    />
                </>
            ) : (
                <div className="no-record-wrapper" style={{ background: '#041A39', width: "100vw", height: "100vh", height: "100dvh" }}>
                    <h3 className="no-record-title" style={{ color: "white" }}>Data retrieval has failed</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div>
            )
            )
            }
        </section>
    );
}
