import checked from "../../../assets/images/checked.svg"

export default function ExtraOptions({ selectedOption, setSelectedOption, secondOption }) {
    function handleSelect(opt) {
        setSelectedOption(opt)
    }
    
    const Icon = selectedOption > 1 ?
                    <img src={checked} alt="Prodensa Automation" />
                    :
                    <span>1</span>

    
    return (
        <div className="extra-options-container">
            <div onClick={() => handleSelect(1)} className={`option-container ${selectedOption === 1 ? "selected" : ""}`}>
                <div className={`option-number ${selectedOption === 2 ? "checked" : ""}`}>
                    {Icon}
                </div>

                <span className="option-title">General Information</span>
            </div>

            <div onClick={() => handleSelect(2)} className={`option-container ${selectedOption === 2 ? "selected" : ""}`}>
                <div className="option-number">
                    <span>2</span>
                </div>

                <span className="option-title">{secondOption}</span>
            </div>
        </div>
    )
}
