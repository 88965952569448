import { Container, Row, Col } from "react-bootstrap";
import "../../../assets/css/detailsViews/tableStyles.css";
import infodanger from "../../../assets/images/infoDanger.svg";


const AbsenteeismRate = ({ details, city_names, filtered, reportID }) => {

    return (
        <Container className="generic-wrapper" >
            {/*First table*/}
            {details ? (
                <>
                    <Row className="no-gutters d-none d-lg-flex">
                        <Col lg={3} className="fixed-container">
                            <h5 className="first-spacer">Indicator</h5>
                            <h5 className="side-text last">Absenteeism Rate</h5>
                        </Col>
                        <Col lg={9} className="table-container">
                            <div className="table-header">
                                {city_names.map((city, index) =>
                                    filtered.includes(index) &&
                                    <h5 className="city-title" key={`${city}-header`}>{city}</h5>
                                )}
                            </div>
                            <div className="row-number">
                                {city_names.map((city, index) => {
                                    let cellClass = ""
                                    let textToShow = "N/A"
                                    const cityToShow = details.results.find(cityFind => city === cityFind.city)
                                    if (cityToShow) {
                                        if (cityToShow.valueResult.totalCost !== null) {
                                            if (cityToShow.valueResult.bestOption) {
                                                cellClass = "lowest";
                                            }
                                            textToShow = cityToShow.valueResult.totalCost
                                        }

                                    }
                                    return (
                                        filtered.includes(index) &&
                                        <h5 className={`number ${cellClass}`} key={`${city}-result`}>{`${textToShow} ${textToShow === "N/A" ? "" : "%"}`}</h5>
                                    )
                                }
                                )}
                            </div>
                        </Col>
                    </Row>

                    <section className="mobile-details-results-table d-lg-none">
                        <table>
                            <thead>
                                <tr>
                                    <th>Incicator</th>
                                    {city_names.map((city, index) =>
                                        filtered.includes(index) &&
                                        <th key={`${city}-mobile-header`}>{city}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Absenteeism Rate</td>
                                    {city_names.map((city, index) => {
                                        let cellClass = ""
                                        let textToShow = "N/A"
                                        const cityToShow = details.results.find(cityFind => city === cityFind.city)
                                        if (cityToShow) {
                                            if (cityToShow.valueResult.totalCost !== null) {
                                                if (cityToShow.valueResult.bestOption) {
                                                    cellClass = "lowest";
                                                }
                                                textToShow = cityToShow.valueResult.totalCost
                                            }

                                        }
                                        return (
                                            filtered.includes(index) &&
                                            <td className={`number ${cellClass}`} key={`${city}-mobile-result`}>{`${textToShow} ${textToShow === "N/A" ? "" : "%"}`}</td>
                                        )
                                    }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <div className="no-record-wrapper">
                    <h3 className="no-record-title">There has been an issue retrieving data.</h3>
                    <img src={infodanger} width="50px" height="50px" />
                </div >
            )}
        </Container>
    )
}

export default AbsenteeismRate;