import Swal from 'sweetalert2'

export const customAlert = (message, icon = "error") => {
    Swal.fire({
        icon: icon,
        title: message,
        showConfirmButton: false,
        showCancelButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: () => {
            Swal.hideLoading()
        }
    })
}

export const loadingAlert = () => {
    Swal.fire({
        title: 'Loading...',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
        }
    });
}