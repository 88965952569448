import "../../assets/css/reportLog/mobileFilters.css";
import groups from "../../assets/images/groups.svg";
import calendar from "../../assets/images/calendar_month.svg";
import list from "../../assets/images/format_list_bulleted.svg";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import close from "../../assets/images/close.svg";

export default function MobileFilters(props) {
    const FILTERS = [
        {
            _id: 1,
            img: groups,
            title: "Client",
        },
        {
            _id: 2,
            img: list,
            title: "Type",
            options: ["Teaser", "Site Selection", "Feasibility"]
        },
        {
            _id: 3,
            img: calendar,
            title: "Date",
        }
    ]
    
    const [showMobileFilters, setShowMobileFilters] = useState(false)
    // const [selectedDates, setSelectedDates] = useState([null, new Date("2023 03 14")])
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: new Date(), 
            endDate: new Date(), 
            key: 'selection'
        }
    ])

    const [showFilterInfo, setShowFilterInfo] = useState({
        _id: 0,
        isOpen: false,
        filterName: "",
    })

    const capitalizedName = (name) => {
		return name.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ');
	}

    const handleMobileSubmitReset = (e) => {
        setShowMobileFilters(false)
        setShowFilterInfo({
            ...showFilterInfo,
            isOpen: false,
        })

        if (e.target.id === "resetFilter") {
            props.handleReset(e)
        }

        if (e.target.id === "applyFilter") {
            props.handleSubmit(e)
        }

    }


    const handleDateChange = (item) => {
        props.setDataType("date")
        setSelectedDates([item.selection])
        props.setFilters({
            ...props.filters,
            startDate: item.selection.startDate.toISOString().split('T')[0],
            endDate: item.selection.endDate.toISOString().split('T')[0],
        })
    }

    return (
        <section className="mobile-filters-section d-lg-none">
            <button className="primaryButton" onClick={() => setShowMobileFilters(true)}><span>Sort and Filter</span></button>
            <div className={`mobile-filters-container ${showMobileFilters ? "opened" : ""}`}>
                <img className="close-image" src={close} alt="Prodensa Automation" onClick={() => setShowMobileFilters(false)}/>
                <div className="container">
                    <Row>
                        <Col className="filtersContainer" lg={{span: 12, offset: 0}} xs={{span: 10, offset: 1}}>
                            <h4 style={{fontWeight: 700}}>Filter</h4>
                            {FILTERS.map((filter, index) => {
                                return (
                                    <div key={index} className="filter" onClick={() => setShowFilterInfo({_id: filter._id, isOpen: true, filterName: filter.title})}>
                                        <img src={filter.img} alt="Prodensa Automation" />
                                        <h5>{filter.title}</h5>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={`mobile-filters-container ${showFilterInfo.isOpen ? "opened" : ""}`}>
                <img className="close-image" src={close} alt="Prodensa Automation" onClick={() => setShowFilterInfo({
                    ...showFilterInfo,
                    isOpen: false,
                })}/>
                <div className="container filterInfo">
                    <Row>
                        <Col className="filterInfoContainer" lg={{span: 12, offset: 0}} xs={{span: 10, offset: 1}}>
                            <button data-type={`${showFilterInfo.filterName.toLocaleLowerCase()}`} 
                                className="btnFilter" 
                                id="resetFilter"
                                name={`${showFilterInfo.filterName.toLocaleLowerCase()}`}
                                onClick={handleMobileSubmitReset}
                            >
                                    Reset
                            </button>
                            <h5 style={{fontWeight: 700}}>{showFilterInfo.filterName}</h5>
                            <button data-type={`${showFilterInfo.filterName.toLocaleLowerCase()}`} 
                                className="btnFilter"
                                id="applyFilter"
                                disabled={props.enable[showFilterInfo.filterName.toLocaleLowerCase()]}
                                name={`${showFilterInfo.filterName.toLocaleLowerCase()}`}
                                onClick={handleMobileSubmitReset}
                            >
                                    Apply
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{span: 12, offset: 0}} xs={{span: 10, offset: 1}}>
                            {showFilterInfo._id === 1 &&
                                <section className="filter-client-section">
                                    <div className="mobile-filter-search-input-container position-relative">
                                        <input type="text" placeholder="Search clients" value={props.searchClient} onChange={props.handleSearchClient}/>
                                    </div>
                                    <div className="mobile-clients-container">
                                        {props.clients.filtered.map((client, index) => {
                                            const fullClient = props.allClients.find(c => c.companyName.toLowerCase() === client.toLowerCase())

                                            return (
                                                <div key={index} className="mobile-client" onClick={() => {
                                                    const checkbox = document.getElementById(`mobile-${client}`);
                                                    props.handleFilters(checkbox);
                                                }}>
                                                    <div className="mobile-client-title">
                                                        <input data-type={`${showFilterInfo.filterName.toLowerCase()}`} type="checkbox" 
                                                            id={`mobile-${client}`} value={`${client}`} 
                                                            checked={props.filters.client.includes(client)} />
                                                        <label htmlFor={`mobile-${client}`}>{capitalizedName(fullClient.companyName)}</label>
                                                    </div>
                                                    <div className="mobile-client-info">
                                                        <h5>{capitalizedName(fullClient.contactName)}</h5>
                                                        <span>{fullClient.mail}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </section>
                            }

                            {showFilterInfo._id === 2 &&
                                <section className="filter-client-section filter-type-section">
                                    {FILTERS[1].options.map((option, index) => {
                                        return (
                                            <div key={index} className="mobile-type" onClick={() => {
                                                const checkbox = document.getElementById(`mobile-${option}`);
                                                props.handleFilters(checkbox);
                                            }}>
                                                <input data-type={`${showFilterInfo.filterName.toLocaleLowerCase()}`} type="checkbox" 
                                                    id={`mobile-${option}`} value={`${option}`} checked={props.filters[option]} defaultChecked={false}/>
                                                <h5 htmlFor={`mobile${option}`}>{option}</h5>
                                            </div>
                                        )
                                    })}
                                </section>
                            }

                            {showFilterInfo._id === 3 &&
                                <section className="filter-date-section">
                                    <DateRangePicker
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        ranges={selectedDates}
                                        onChange={handleDateChange}
                                        direction="horizontal"
                                    />
                                    <div className="inputs-date-container">
                                        <div className="date-container">
                                            <label htmlFor="startDate">From</label>
                                            <input data-type="date" type="date" name="startDate" id="startDate" value={props.filters.startDate} onChange={(e) => props.handleFilters(e.target)}
                                                onClick={(e) => e.preventDefault()} disabled={true}
                                                />
                                        </div>
                                        <div className="date-container">
                                            <label htmlFor="endDate">To</label>
                                            <input data-type="date" type="date" name="endDate" id="endDate" value={props.filters.endDate} onChange={(e) => props.handleFilters(e.target)}
                                                onClick={(e) => e.preventDefault()} disabled={true}
                                            />
                                        </div>
                                    </div>
                                </section>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}