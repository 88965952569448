import mapImage from "../../assets/pdfImages/mapa-default.png"
import mustImage from "../../assets/pdfImages/must-image-1.png"
import logoFramePage from "../../assets/pdfImages/logo_pdf.png"
import { imageToBase64 } from "./utils.js"

class HTMLtemplates {
    buildingTemplate(key, value) {
        return `
            <li class="grid grid-cols-2 py-2 px-2 border-bottom-line col-gap-4">
                <p class="text-[#191819] font-normal text-[10px] fix-p-2">${key}</p>
                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">
                    ${value}
                </p>
            </li>
        `;
    }

    logisticsTemplate(city, qty, unit, header = false) {
        return `
            <li class="grid grid-cols-logistic py-2 px-2 border-bottom-line">
                <p class="text-[#191819] font-normal text-[10px] fix-p-2">${city}</p>
                <p class="text-[#191819 text-[10px] fix-p-2 ${header ? "font-normal" : "font-extralight"}">
                    ${qty}
                </p>
                <p class="text-[#191819] text-[10px] fix-p-2 ${header ? "font-normal" : "font-extralight"}">
                    ${unit}
                </p>
            </li>
        `;
    }

    sourcesTemplate(key, value) {
        return `
            <div>
                <li class="grid grid-cols-2 py-2 border-bottom-line">
                    <p class="text-[#191819] font-extralight text-[10px] px-3 fix-p-2">
                        ${key}
                    </p>

                    <p class="text-[#191819] font-extralight text-[10px] px-2 text-[#AFB0B1] fix-p-2">
                        ${value}
                    </p>
                </li>
            </div>
        `;
    }

    sourceExtraPage() {
        return `
            <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
            ></span>

            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
                Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                <h1 class="-rotate-90 my-5 pagination">Page 27</h1>
                <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>

            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
                Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>
                
                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <h1
            class="text-[30px] pl-24 pt-20 mb-10 uppercase leading-[70px] text-[#3D9FFF]"
            >
                Sources
            </h1>
            
            <ul class="pl-24 pr-20">
                <li class="bg-[#DDEDF8] py-2 px-2 grid grid-cols-2">
                    <p class="text-[12px] text-[#239DFF] fix-p">Variable</p>
                    <p class="text-[12px] text-[#239DFF] fix-p">Source</p>
                </li>

                <div class="sources-extra-area"></div>
            </ul>
        </div>
        `
    }
    
    async cityPage(city, filters, pdfConfig, borderDistances) {
        const cityPdf = pdfConfig?.citiesData?.find(pdfCity => pdfCity.id === city.datamexico_municipality_id)
        const cityPrimaryText = cityPdf?.cityPrimary ?? ""
        // const citySecondaryText = cityPdf?.citySecondary ?? ""
        const cityImage = cityPdf?.document
        const mapImageFile = cityPdf?.mapImage

        const cityClosestBorder = borderDistances.find(cityBorder => cityBorder.datamexico_municipality_id === city.datamexico_municipality_id)
                                ?.distances.reduce((acc, curr) => {
                                    if (curr.distance < acc.distance) {
                                        return curr
                                    }

                                    return acc
                                }, { distance: Infinity })
        
        let mustCityImage;
        let cityMapImage;

        try {
            // Prepare an array of promises for image processing
            const imagePromises = [
                cityImage ? imageToBase64(process.env.REACT_APP_BACKEND_URL + cityImage.path) : Promise.resolve(mustImage),
                mapImageFile ? imageToBase64(process.env.REACT_APP_BACKEND_URL + mapImageFile.path) : Promise.resolve(mapImage)
            ];

            // Use Promise.all to execute all promises in parallel
            const [processedMustCityImage, processedCityMapImage] = await Promise.all(imagePromises);

            // Assign the results to variables
            mustCityImage = processedMustCityImage;
            cityMapImage = processedCityMapImage;
        } catch (error) {
            console.log(error);
        }

        return `
            <div class="h-[1122.519685px] relative w-full grid grid-cols-2 gap-7">
                <span
                    class="bg-[#000] h-[1px] w-[50%] left-24 absolute bottom-12"
                ></span>
                <h1
                    class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
                >
                    Feb 22 2024
                </h1>

                <div
                    class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
                >
                    <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                    <h1 class="-rotate-90 my-5 pagination">Page 09</h1>
                    <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                </div>
                <h1
                    class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
                >
                    Prodensa
                </h1>

                <header class="absolute top-8 right-[80px] flex items-center gap-3">
                    <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                    </p>
                    <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                    </p>
                    <div class="logoFramePage">
                        <img
                            src=${logoFramePage}
                            class="w-[100%] h-[100%] object-cover"
                            alt="Prodensa Automation"
                        />
                    </div>
                </header>

                <div class="flex flex-col pl-24 py-32 h-full flex-1">
                    <span>Selected cities</span>

                    <h1
                    class="text-[32px] uppercase font-light leading-[40px] text-[#3D9FFF]"
                    >
                        ${city?.municipality_name}
                    </h1>

                    <p
                    class="text-justify mt-10 text-[#494949] h-[160px]"
                    >
                        <span class="block leading-[20px] text-[11px] pl-4 border-l-[#838384] border-l-[1px] pb-4">
                            ${cityPrimaryText}
                        </span>
                    </p>

                    <img
                        src="${mustCityImage}"
                        class="h-[550px] w-[300px] mt-15 object-cover"
                        alt="Prodensa Automation"
                    />
                </div>

                <div class="flex pr-20 py-32 flex-col h-full">
                    <img src=${cityMapImage} alt="Prodensa Automation" class="h-[240px]" />

                    <div class="flex items-center mt-30">
                        <div class="grid grid-cols-2 gap-3 pb-10">
                            <div class="flex flex-col items-start">
                                <h1
                                    class="text-[30px] population-must text-[#47A0FF] font-thin pb-2"
                                >
                                    ${addCommas(city?.population)}
                                </h1>
                                <p class="text-[#AFB0B1] font-light text-[11px]">
                                    Population
                                </p>
                            </div>

                            <div class="flex flex-col items-start">
                                <h1
                                    class="text-[30px] time-to-closest-border-must text-[#47A0FF] font-thin pb-2"
                                >
                                    ${addCommas(+cityClosestBorder?.distance?.toFixed(0))} Km 
                                </h1>
                                <p class="text-[#AFB0B1] font-light text-[11px]">
                                    Distance To Closest Border
                                </p>
                            </div>

                            <div class="flex flex-col items-start">
                                <h1
                                    class="text-[30px] economicActivePopulation-must text-[#47A0FF] font-thin pb-2"
                                >
                                    ${addCommas(city?.EAP)}
                                </h1>
                                <p class="text-[#AFB0B1] font-light text-[11px]">
                                    Economically Active Population
                                </p>
                            </div>

                            <div class="flex flex-col items-start">
                                <h1
                                    class="text-[30px] manufacturingEnvironmentMust text-[#47A0FF] font-thin pb-2"
                                >
                                    ${city?.manufacturing_industries?.[0]}
                                </h1>
                                <p class="text-[#AFB0B1] font-light text-[11px]">
                                    Manufacturing Environment
                                </p>
                            </div>
                        </div>
                    </div>

                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[18px] text-[#239DFF]  fix-p">State - ${city.state_name}</p>
                        </li>

                        <div class="must-filter">
                            <li class="grid grid-cols-2 py-1 px-2 border-bottom-line">
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">Population</p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">
                                    ${addCommas(city.population)}
                                </p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${filters["Population"]}</p>
                            </li>

                            <li class="grid grid-cols-2 py-1 px-2 border-bottom-line">
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">Economic Active Population</p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">
                                    ${addCommas(city.EAP)}
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${filters["Economic Active Population"]}</p>
                                </p>
                            </li>

                            <li class="grid grid-cols-2 py-1 px-2 border-bottom-line">
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">Time to closest border</p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">
                                    ${addCommas(cityClosestBorder?.time)} Hours
                                </p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${filters["Time to closest border"]}</p>
                            </li>

                            <li class="grid grid-cols-2 py-1 px-2 border-bottom-line">
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">Distance to closest border</p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">
                                    ${addCommas(+cityClosestBorder?.distance?.toFixed(0))} Km
                                </p>
                                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${filters["Distance to closest border"]} Km</p>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        `
    }

    logisticsExtraPage() {
        return `
            <div class="h-[1122.519685px] relative w-full">
            <span
            class="bg-[#000] h-[1px] w-[35%] left-[95px] absolute bottom-12"
            ></span>
            <h1
            class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
            >
            Feb 22 2024
            </h1>

            <div
            class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
            >
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            <h1 class="-rotate-90 my-5 pagination">Page 06</h1>
            <span class="bg-[#000] h-[240px] w-[1px] block"></span>
            </div>
            <h1
            class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg] client-page-frame"
            >
            Prodensa
            </h1>

            <header class="absolute top-8 right-[80px] flex items-center gap-3">
                <p class="text-[9px] text-[#66B5FE] font-light">
                    City and site Selection Study
                </p>

                <p class="text-[9px] text-[#66B5FE] font-light">
                    Poclain Hydraulics
                </p>

                <div class="logoFramePage">
                    <img
                        src=${logoFramePage}
                        class="w-[100%] h-[100%] object-cover"
                        alt="Prodensa Automation"
                    />
                </div>
            </header>

            <div class="newAssumptionsPage2">
                <div class="assumptionTable1">
                    <ul>
                        <li class="bg-[#DDEDF8] py-1 px-2">
                            <p class="text-[16px] text-[#239DFF] fix-p">Logistics</p>
                        </li>

                        <div class="logistics-assumption-extra"></div>
                    </ul>
                </div>
            </div>
        </div>
        `
    }

    htmlCities(data, borderDistances) {
        const cityClosestBorder = borderDistances.find(cityBorder => cityBorder.datamexico_municipality_id === data.datamexico_municipality_id)
                                ?.distances.reduce((acc, curr) => {
                                    if (curr.distance < acc.distance) {
                                        return curr
                                    }

                                    return acc
                                }, { distance: Infinity })
        
        return `
        <li class="grid grid-cols-[1fr_1fr_1fr_1fr_1fr] p-3 px-2 border-bottom-line col-gap-1">
            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${data.municipality_name}</p>
            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${addCommas(data.population)}</p>
            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${addCommas(data.EAP)}</p>
            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${addCommas(cityClosestBorder?.time)} Hrs</p>
            <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${addCommas(+cityClosestBorder?.distance?.toFixed(0))} Km</p>
        </li>
    `;
    }

    htmlWantMatrix(data, count, wantDescription) {
        return `
            <li class="grid grid-cols-[.3fr_.5fr_.2fr_1fr] py-3 px-2 border-bottom-line col-gap-1">
                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${count}</p>
                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${data.title}</p>
                <p class="text-[#191819] font-extralight text-[10px] fix-p-2 text-center pr-4">${data.weight}%</p>
                <p class="text-[#191819] font-extralight text-[10px] fix-p-2">${wantDescription}</p>
            </li>
        `;
    }

    htmlWantMatrixCat(category, element, index) {
        const number_of_variables = category.variables.length
        const wantStartClass = index === 0 ? "initial-want" : ""
        
        return `
            <div class="h-[1122.519685px] relative w-full ${element}">
                <div>
                    <span
                        class="bg-[#000] h-[1px] w-[35%] left-24 absolute bottom-12"
                    ></span>
                    <h1
                        class="text-[#7B7C7C] date-now left-[15px] top-[100px] absolute -rotate-[90deg]"
                    >
                        Feb 22 2024
                    </h1>

                    <div
                        class="flex items-center flex-col absolute gap-3 right-0 top-20 translate-x-0"
                    >
                        <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                        <h2 class="-rotate-90 my-5 pagination ${wantStartClass} ${element}-link">Page 15</h2>
                        <span class="bg-[#000] h-[240px] w-[1px] block"></span>
                    </div>
                    <h1
                        class="text-[#7B7C7C] left-[30px] bottom-[100px] absolute -rotate-[90deg]"
                    >
                        Prodensa
                    </h1>

                    <header class="absolute top-8 right-[80px] flex items-center gap-3">
                        <p class="text-[9px] text-[#66B5FE] font-light">
                            City and site Selection Study
                        </p>

                        <p class="text-[9px] text-[#66B5FE] font-light">
                            Poclain Hydraulics
                        </p>

                        <div class="logoFramePage">
                            <img
                                src=${logoFramePage}
                                class="w-[100%] h-[100%] object-cover"
                                alt="Prodensa Automation"
                            />
                        </div>
                    </header>
                </div>

                <h5
                class="text-[350px] text-[#EAF1F8] absolute font-extrabold left-[40px] -top-[250px] -z-[1]"
                >
                    ${index + 1}
                </h5>

                <div class="flex items-start justify-between pl-24 pr-20 pt-24">
                    <div class="flex flex-col gap-2">
                        <h1 class="text-[#3D9FFF] text-[30px] uppercase">${index + 1} ${category.title}</h1>
                        
                        <div class="flex gap-3">
                            <span class="text-[10px] text-[#3D9FFF]">Ref</span>
                            <span class="text-[10px] text-[#3D9FFF]">Criterion</span>
                        </div>
                    </div>

                    <div class="flex items-center gap-3">
                        <div class="flex items-end justify-end flex-col gap-1">
                            <h1 class="text-[25px] text-extralight text-[#3D9FFF]">${category.weight?.toFixed(2)}</h1>
                            <p class="text-[#3D9FFF] font-thin text-[11px]">Points</p>
                        </div>

                        <div class="flex items-end justify-end flex-col gap-1">
                            <h1 class="text-[25px] text-extralight text-[#3D9FFF]">${category.weight}%</h1>
                            <p class="text-[#3D9FFF] font-thin text-[11px]">Weight</p>
                        </div>
                    </div>
                </div>

                <ul class="pl-24 pr-20 pt-5">
                    <li
                        class="bg-[#DDEDF8] grid grid-cols-wants-table py-2"
                    >
                        <p
                        class="text-[#239DFF] text-[11px] font-extralight text-center flex flex-col items-center fix-p"
                        >
                            <span class="block text-[9px] font-extralight">Ref</span>
                            
                            ${index + 1}
                        </p>

                        <p
                        class="text-[11px] text-[#239DFF] px-2 flex flex-col fix-p"
                        >
                            <span class="block text-[9px] font-extralight">Criterion</span>
                            
                            ${category.title}
                        </p>

                        <p
                        class="text-[11px] text-[#239DFF] items-center px-2 flex flex-col fix-p"
                        >
                            <span class="block text-[9px] font-extralight">Points</span>
                            
                            ${category.weight?.toFixed(2)}
                        </p>

                        <p
                        class="text-[11px] text-[#239DFF] px-2 flex flex-col fix-p items-center"
                        >
                            <span class="block text-[9px] font-extralight">Weight</span>

                            ${category.weight}%
                        </p>

                        <p
                        class="text-[11px] text-[#239DFF] px-2 flex flex-col"
                        >
                            Definition
                        </p>

                        <p
                        class="text-[11px] text-[#239DFF] px-2 flex flex-col"
                        >
                            Metric
                        </p>
                    </li>
                </ul>

                <div class="pl-24 pr-20 mt-2 cities-table-${element}">
                    <div class="bg-[#DDEDF8] want-cities-cols want-cities-cols-${number_of_variables} py-2 px-2">
                        
                    </div>
                </div>
            </div>
        `
    }

    htmlWantMatrixVar(data, catIndex, varIndex) {
        return `
            <li
            class="grid grid-cols-wants-table border-bottom-line py-3"
            >
                <p
                    class="text-[#191819] text-[9px] flex items-center justify-center fix-p-2"
                >
                    ${catIndex + 1}.${varIndex}
                </p>
                <p
                    class="text-[#191819] flex items-center justify-start pl-2 text-[9px] fix-p-2"
                >
                    ${data.title}
                </p>
                <p
                    class="text-[#191819] font-extralight text-[9px] flex items-center justify-center fix-p-2"
                >
                ${data.points}
                </p>
                <p
                    class="text-[#191819] font-extralight text-[9px] flex items-center justify-center fix-p-2"
                >
                ${data.weight}%
                </p>
                <p
                    class="text-[#191819] font-extralight text-[9px] flex items-center  pl-3 fix-p-2"
                >
                ${data.information["definition"].substr(0, 140)}
                </p>
                <p
                    class="text-[#191819] font-extralight text-[9px] flex items-center  pl-3 fix-p-2"
                >
                ${data.information["metric"]}
                </p>
            </li>
        `
    }

    htmlCitiesCards(city) {
        const pointsClass = city.bestOption ? "text-[green] font-extralight" : "text-[#239DFF] font-thin"
        
        return `
            <div class="cityCard">
                <div class="card-section-container">
                    <span class="text-[11px] fix-p-2">${city.cityName}</span>
                    <span class="text-[9px] text-[#239DFF] font-extralight fix-p-2">City</span>
                </div>

                <div class="card-section-container">
                    <span class="text-[22px] ${pointsClass} fix-p align-self-end">${city.points?.toFixed(1)}</span>
                    <span class="text-[9px] text-[#239DFF] font-extralight fix-p align-self-end">Points</span>
                </div>
            </div>
        `
    }

    htmlTotalCategoriesTable(report) {
        const number_of_categories = report.want.wantMatrix.length
        const totalPoints = report.want.wantMatrix.reduce((acc, curr) => acc + curr.weight, 0)
        const wantsTitles = report.want.wantMatrix.sort((a, b) => a.position - b.position).map((category, index) => {
            return `
                <span class="text-[10px] font-extralight text-[#239DFF] fix-p">${index + 1} ${category.title}</span>
            `
        }).toString().replaceAll(",", "")

        const wantsWeights = report.want.wantMatrix.sort((a, b) => a.position - b.position).map(category => {
            return `
                <span class="text-[10px] text-[#239DFF] text-center fix-p-2">${category.weight}</span>
            `
        }).toString().replaceAll(",", "")

        const citiesRows = report.must.cities.map((city, index) => {
            const cityPoints = report.want.wantMatrix.sort((a, b) => a.position - b.position).map(category => {
                const points = category.totals?.find(total => total.cityId === city.datamexico_municipality_id)?.points
                
                return `
                    <span class="text-[10px] text-[#AFB0B1] text-center fix-p-2">${points}</span>
                `
            })

            const totalCity = report.total.find(finalTotal => finalTotal.cityId === city.datamexico_municipality_id)
            const totalClass = totalCity.bestOption ? "text-[green] font-bold" : "text-[#239DFF] font-bold"
            
            return `
                <div class="total-wants-table  total-wants-table-${number_of_categories} py-2 border-bottom-line">
                    <span class="text-[10px] fix-p-2 flex gap-2"> <span class="text-[10px] text-[#AFB0B1]">${index + 1}</span> ${city.municipality_name}</span>
                    ${cityPoints}
                    <span class="text-[10px] ${totalClass} text-center fix-p-2">${totalCity.points?.toFixed(1)}</span>
                </div>
            `
        }).toString().replaceAll(",", "")


        return `
            <div class="bg-[#DDEDF8] total-wants-table  total-wants-table-${number_of_categories} py-6">
                <span class="text-[10px] font-extralight text-[#239DFF] fix-p-2">Selected city</span>
                ${wantsTitles}
                <span class="text-[10px] font-extralight text-[#239DFF] text-center fix-p-2">Total</span>
            </div>

            <div class="bg-[#000] total-wants-table total-wants-table-${number_of_categories} py-3">
                <span class="text-[10px] text-[#239DFF] text-center fix-p-2">Points</span>
                ${wantsWeights}
                <span class="text-[10px] text-[#3D9FFF] font-bold text-center fix-p-2">${totalPoints}</span>
            </div>

            ${citiesRows}
        `
    }
}

export const html_templates = new HTMLtemplates();


function addCommas(value) {
    if (value?.toString()?.includes(".")) return value?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

