import { HEADERS, ACTIONS } from "./userConfigUtils";

import UserRow from "./UserRow";
import sortDown from "../../assets/images/sortDown.svg";

export default function UsersTable( { state, dispatch, handleEditAddUser }) {
    // change sort type
    function handleSort(sortType) {
        dispatch({ type: ACTIONS.sortUsers, payload: sortType })
    }
    
    const sortActive = state.sort === "DSC" ? "up" : ""
    
    return (
        <section className="user-table">
            <div className="table-head">
                {HEADERS.map((header, index) => {
                    const arrowClass = state.sortType === header.sortRef ? "active" : ""
                    const haveSort = header.hasOwnProperty("sortRef")
                
                    return (
                        <div key={`${header.name}-${index}`} className="table-head-item">
                        <span>{header.name}</span>
                            {haveSort && <img 
                                src={sortDown} 
                                alt="Prodensa Automation" 
                                className={`${arrowClass} ${sortActive}`}
                                onClick={() => handleSort(header.activateSort)}
                            />}
                        </div>
                    )
                }
                )}
            </div>
            <div className="table-body">
                {state.filteredUsers?.map(user => {
                    return (
                        <UserRow
                            key={user._id} 
                            user={user}
                            state={state}
                            dispatch={dispatch}
                            handleEditAddUser={handleEditAddUser}
                        />
                    )
                })
                }
            </div>
        </section>
    )
}