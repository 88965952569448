import { getClientName } from "./getClientNameById"

export function Filters(data, activeFilters, clientFilter, typeFilter, dateFilter, allClients) {
    // filtrado cuando los 3 filtros estan activos 
    if (activeFilters.client && activeFilters.type && activeFilters.date) {
        const filterReportsbyClient = data.filter((report) => clientFilter.some((option) => getClientName(report.siteSelectionReport.clientId, allClients).includes(option)))
        const filterReportsbyType = filterReportsbyClient.filter((report) => typeFilter.some((option) => option.includes(report.reportType || report.siteSelectionReport.type)))
        const filteredReportsbyDate = filterReportsbyType.filter((report) => {
            const reportDate = new Date(report.updatedAt)
            const startDate = new Date(dateFilter[0])
            const endDate = new Date(dateFilter[1])

            return reportDate >= startDate && reportDate <= endDate
        })
        
        return filteredReportsbyDate
    }
    
    // filtrado cuando filtros de client y tipo estan activos 
    if (activeFilters.client && activeFilters.type) {
        const filterReportsbyClient = data.filter((report) => clientFilter.some((option) => getClientName(report.siteSelectionReport.clientId, allClients).includes(option)))
        const filterReportsbyType = filterReportsbyClient.filter((report) => typeFilter.some((option) => option.includes(report.reportType || report.siteSelectionReport.type)))
        
        return filterReportsbyType
    }
    
    // filtrado cuando filtros de client y fecha estan activos 
    if (activeFilters.client && activeFilters.date) {
        const filterReportsbyClient = data.filter((report) => clientFilter.some((option) => getClientName(report.siteSelectionReport.clientId, allClients).includes(option)))
        const filteredReportsbyDate = filterReportsbyClient.filter((report) => {
            const reportDate = new Date(report.updatedAt)
            const startDate = new Date(dateFilter[0])
            const endDate = new Date(dateFilter[1])

            return reportDate >= startDate && reportDate <= endDate
        })

        return filteredReportsbyDate
    }
    
    
    // filtrado cuando filtros de tipo y fecha estan activos 
    if (activeFilters.type && activeFilters.date) {
        const filterReportsbyType = data.filter((report) => typeFilter.some((option) => option.includes(report.reportType || report.siteSelectionReport.type)))
        const filteredReportsbyDate = filterReportsbyType.filter((report) => {
            const reportDate = new Date(report.updatedAt)
            const startDate = new Date(dateFilter[0])
            const endDate = new Date(dateFilter[1])

            return reportDate >= startDate && reportDate <= endDate
        })

        return filteredReportsbyDate
    }

    // filtrado solo solo por cliente
    if (activeFilters.client) {
        const filterReportsbyClient = data.filter((report) => clientFilter.some((option) => getClientName(report.siteSelectionReport.clientId, allClients).includes(option)))
        return filterReportsbyClient
    }
    
    // filtrado solo por tipo
    if(activeFilters.type) {
        const filterReportsbyType = data.filter((report) => typeFilter.some((option) => option.includes(report.reportType || report.siteSelectionReport.type)))
        return filterReportsbyType
    }
    
    // filtrado solo por fecha
    if (activeFilters.date) {
        const filteredReportsbyDate = data.filter((report) => {
            const reportDate  = new Date(report.updatedAt)
            const hours = reportDate.getUTCHours()
            const minutes = reportDate.getUTCMinutes()
            const seconds = reportDate.getUTCSeconds()
            const miliSeconds = reportDate.getUTCMilliseconds()
            reportDate.setHours(hours, minutes, seconds, miliSeconds)
            
            const startDate = new Date(dateFilter[0].replace("-", " "));
            const endDate = new Date(dateFilter[1].replace("-", " "));
            endDate.setHours(23,59,59,999)

            return reportDate >= startDate && reportDate <= endDate
        })
        return filteredReportsbyDate
    }
        // regresa data original si ningun filtro esta activo
        return data
}