import Swal from "sweetalert2";

export const ACTIVE_SECTION = {
    users: 1,
    editAddUser: 2
}

export const USER_INPUTS = {
    name: {
        label: "Name",
        accessKey: "fullName",
    },
    mail: {
        label: "Mail",
        accessKey: "mail",
    },
    role: {
        label: "Role",
        accessKey: "role",
        options: ["admin", "consultory", "BD"]
    },
    status: {
        label: "Status",
        accessKey: "deletedRegister",
        options: ["active", "inactive"]
    },
}

export const ACTIONS = {
    setInitial: "SET_INITIAL",
    toggleMenu: "TOGGLE_MENU",
    searchFilter: "SEARCH_FILTER",
    createUpdateUser: "CREATE_UPDATE_USER",
    toggleUserStatus: "TOGGLE_USER_STATUS",
    toggleSection: "TOGGLE_SECTION",
    closeUserConfig: "CLOSE_USER_CONFIG",
    sortUsers: "SORT_USERS"
}

export const SORT_TYPES = {
    name: "fullName",
    mail: "mail",
    role: "role",
    status: "deletedRegister"
}

export const HEADERS = [
    { name: "Picture" },
    { name: "Name", sortRef: "fullName", activateSort: "name" },
    { name: "Email", sortRef: "mail", activateSort: "mail"},
    { name: "Role", sortRef: "role", activateSort: "role"},
    { name: "Status", sortRef: "deletedRegister", activateSort: "status"}
]

export const capitalizedName = (name) => {
    return name?.charAt(0)?.toUpperCase() + name?.slice(1);
}

export function filterUsers(users, search) {
    return users.filter(user => {
        return user.fullName?.toLowerCase()?.includes(search.toLowerCase()) || user.mail?.toLowerCase()?.includes(search.toLowerCase())
    })
}

export function sortUsers(data, sortType, sort = "ASC") {
    let sortedData
    
    switch (sortType) {
        case SORT_TYPES.name:
            if (sort === "ASC") {
                sortedData = data?.sort((a, b) => a.fullName.localeCompare(b.fullName))
            } else {
                sortedData = data?.sort((a, b) => b.fullName.localeCompare(a.fullName))
            }
            break;
        case SORT_TYPES.mail:
            if (sort === "ASC") {
                sortedData = data?.sort((a, b) => a.mail.localeCompare(b.mail))
            } else {
                sortedData = data?.sort((a, b) => b.mail.localeCompare(a.mail))
            }
            break;
        case SORT_TYPES.role:
            if (sort === "ASC") {
                sortedData = data?.sort((a, b) => a.role.localeCompare(b.role))
            } else {
                sortedData = data?.sort((a, b) => b.role.localeCompare(a.role))
            }
            break;
        case SORT_TYPES.status:
            if (sort === "ASC") {
                sortedData = data?.sort((a, b) => a.deletedRegister - b.deletedRegister)
            } else {
                sortedData = data?.sort((a, b) => b.deletedRegister - a.deletedRegister)
            }
            break;
        default:
            break;
    }

    return sortedData
}

export const createUpdateUserAlert = async(message, dispatch) => {
    Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: false,
        showCancelButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: () => {
            Swal.hideLoading()
        },
        willClose: () => {
            dispatch({ type: ACTIONS.toggleSection, payload: null })
        }
    })
}
