import React from 'react'

import { NAV_MENU_OPTIONS } from "./utils.js"
import checked from "../../../assets/images/checked.svg"

export default function NavSection({ activeSection, setActiveSection, pdfConfig, handleActivePdfConfig }) {
    function selectOption(opt) {
        setActiveSection(opt)
    }
    
    return (
        <div className='nav-section'>
            <header className="d-lg-none">
                <div className="title-activate-section">
                    <h4>Results PDF</h4>

                    <div className="activate-container">
                        <span>Ready for client</span>

                        <label className="switch">
                            <span id="label-on" className={`insideLabel ${pdfConfig?.active ? "" : "hidden"}`}>Ready</span>
                            <span id="label-off" className={`insideLabel ${pdfConfig?.active ? "hidden" : ""}`}>Ready</span>
                            <input type="checkbox" checked={pdfConfig?.active} onChange={handleActivePdfConfig} />
                            <span className={`slider round ${pdfConfig?.active ? "isChecked" : ""}`}></span>
                        </label>
                    </div>
                </div>

                <span>
                    Please fill out the information required down below. 
                    This information will be displayed on the final Results PDF.
                </span>
            </header>
            
            <div className='nav-options-container'>
                {NAV_MENU_OPTIONS.map(option => {
                    const Icon = activeSection > option.position ? 
                                <img src={checked} alt="Prodensa Automation" />
                                : 
                                <span>{option.position}</span>
                    const checkedClass = activeSection > option.position ? "checked" : ""
                    const selectedClass = activeSection === option.position ? "selected" : ""

                    return (
                        <div key={option.id} className={`option-container ${selectedClass}`} onClick={() => selectOption(option.position)}>
                            <div className={`option-number ${checkedClass}`}>
                                {Icon}
                            </div>

                            <span className='option-title'>{option.title}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
