import { useState } from "react";
import "../../../assets/css/reportResults/wantsView/compare-box.css";

import close from "../../../assets/images/close.svg";

const CompareSlider = ({
    cityNames,
    setSumBoxFiltered,
    sliderSettings,
    setSliderSettings,
}) => {
    /*The comp variant prop is used to indicate the component which type of role it assumes,when the component is the carousel variant
        sliderSettings is modified so the carousel displays less slides if the filtered array becomes shorter.
        If the variant is table then no sliderSettings are modified since the dynamic generation of columns is done at the table level and this distinction prevents error due to undefined
        items. 
    */
    const [compareactive, setCompareactive] = useState(false);
    //All cities are visible by default
    const  initial_checkboxes = cityNames.map((_city, index) => index);
    const [checkboxes, setCheckboxes] = useState(initial_checkboxes);


    const handleCheckboxClick = (value) => {
            if (checkboxes.includes(value)) {
                // If checkbox value is already in the array, remove it
                const updatedCheckboxes = checkboxes.filter((item) => item !== value);
                setCheckboxes(updatedCheckboxes);
            } else {
                // Add checkbox value to the array
                setCheckboxes([...checkboxes, value]);
            }
    };

    //Adds all cities to the filtered array and updates the slides to show to 3 if the number of cities is 3 or more.
    const activateAllCheckboxes = () => { 
        setCheckboxes(initial_checkboxes)       // Make all checkboxes selected
        setSumBoxFiltered(initial_checkboxes)
            if (cityNames.length >= 3) {
                const newSlidesToShow = 3;
                const updatedSliderSettings = {
                    ...sliderSettings,
                    slidesToShow: newSlidesToShow,
                };
                setSliderSettings(updatedSliderSettings);
            } else {
                const newSlidesToShow = cityNames.length;
                const updatedSliderSettings = {
                    ...sliderSettings,
                    slidesToShow: newSlidesToShow,
                };
                setSliderSettings(updatedSliderSettings);
            }
            setCompareactive(false);
    };

    //Updates the slides to show depending on the new length of the checkboxes array if its the carousel variant, if not it only assigns checkboxes to filtered
    const applyFilter = () => {
            if (checkboxes.length > 3) {
                    const newSlidesToShow = 3;
                    const updatedSliderSettings = {
                        ...sliderSettings,
                        slidesToShow: newSlidesToShow,
                    };
                    setSliderSettings(updatedSliderSettings);  
            } else {
                const newSlidesToShow = checkboxes.length;
                const updatedSliderSettings = {
                    ...sliderSettings,
                    slidesToShow: newSlidesToShow,
                };
                setSliderSettings(updatedSliderSettings);
            }
        setCompareactive(false);
        //Sorting checkboxes prevent undesired behaviour
        let sortedcheckboxes = checkboxes.sort((a, b) => a - b);
        setSumBoxFiltered(sortedcheckboxes);
    };

    return (
        <div className="compare-button">
            {checkboxes && 
                <>
                    <button
                        className={`compare-button-text ${compareactive ? "active" : ""}`}
                        onClick={() => {
                            compareactive ? setCompareactive(false) : setCompareactive(true);
                        }}
                    >
                        Compare cities
                    </button>
                    <div className={`compare-menu ${compareactive ? "active" : ""}`}>
                        <img src={close} className="close" alt="Prodensa Automation" onClick={() => setCompareactive(false)}/>
                        <h5 className="compare-menu-head">Compare cities</h5>
                        <div className="compare-menu-body">
                            <div className="input-group">
                                {cityNames.map((city, index) => (
                                    <div className="input-row" key={index}>
                                        <input
                                            id={city}
                                            type="checkbox"
                                            value={index}
                                            className="city-checkbox"
                                            onChange={() => handleCheckboxClick(index)}
                                            checked={checkboxes.includes(index)}
                                        />
                                        <label htmlFor={city}>{city}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="compare-menu-footer">
                                <button
                                    className="reset-button"
                                    onClick={() => activateAllCheckboxes()}
                                >
                                    <h5>Reset</h5>
                                </button>
                                <button className="apply-button" onClick={() => applyFilter()}>
                                    <h5>Apply</h5>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default CompareSlider;
