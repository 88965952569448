import { useEffect, useState } from "react"
import Streetview from "react-google-streetview"
import Accordion from 'react-bootstrap/Accordion';

import "../../assets/css/map/streetview.css"
import arrow from "../../assets/images/arrow_forward.svg"
import refresh from "../../assets/images/refresh.svg"
import dataTable from "../../assets/images/data_table.svg"
import bank from "../../assets/images/account_balance.svg"
// import attractions from "../../assets/images/attractions.svg"
// import factory from "../../assets/images/factory.svg"

export default function StreetviewMap(props) {
    const googleMapsKey = "AIzaSyB7fW-S741fkiRIQHRgQPXj6WlesdFIu28"
    
    const [show, setShow] = useState(false)
    const [markers, setMarkers] = useState([])
    const [currentLocation, setCurrentLocation] = useState(0)
    
    const [city, setCity] = useState(null)

    const StreetMapOptions = {
        position: { lat: markers.length > 0 ? markers[currentLocation].latitude : 0, lng: markers.length > 0 ? markers[currentLocation].longitude : 0 },
        pov: { heading: markers.length > 0 ? markers[currentLocation].heading : 0, pitch: markers.length > 0 ? markers[currentLocation].pitch : 0 },
        zoom: markers.length > 0 ? markers[currentLocation].zoom : 0,
    };

    useEffect(() => {
        const gmIvAddressElements = document.querySelectorAll('.gm-iv-address');
        gmIvAddressElements.forEach(element => {
            element.style.opacity = false ? '1' : '0';
            element.style.visibility = false ? 'visible' : 'hidden';
        });
    }, []); //todo: agregar state para control de direccion
    
    useEffect(() => {
        if(props.streetviewInfo.id !== 0) {
            setCity(props.cities.find((city) => city.id === props.streetviewInfo.id))
        }
    }, [props.streetviewInfo])

    useEffect(() => {
        if(city) {
            const coordinates = city.streetviewPlaces.reduce((acc, category) => {
                category.sectionPois.forEach(place => {
                    acc.push(place)
                })
                return acc
            }, [])
            setMarkers(coordinates)
        }
    }, [city])

    // function handleShowMenu() {
    //     setShow(!show)
    // }

    function handleCurrentLocation(e) {
        const { id } = e.target
        e.stopPropagation()
        setShow(false)

        if(currentLocation === 0 && id === "prev") return
        if(currentLocation === markers.length - 1 && id === "next") return

        if(id === "prev") {
            setCurrentLocation(currentLocation - 1)
        } else {
            setCurrentLocation(currentLocation + 1)
        }
    }

    function handleCloseStreetView() {
        props.setStreetviewInfo({
            open: false,
            id: 0
        })
        setCurrentLocation(0)
        setShow(false)
    }

    function handleRefresh() {
        setCurrentLocation(0)
        setShow(false)
    }

    function handleLocation(coordinates) {
        const index = markers.findIndex(marker => marker.longitude === coordinates[0] && marker.latitude === coordinates[1])
        setCurrentLocation(index)
        setShow(false)
    }

    return (
        <div className={`streetviewContainer ${props.streetviewInfo.open ? "show" : ""}`}>
            <div className="streetviewHeader">
                <div className="streetviewHeaderTitle">
                    <img src={arrow} alt="ProdensaAutomation" onClick={handleCloseStreetView}/>
                    <h5 className="tourTexts">{city ? city.name : "to be determined"}</h5>
                </div>
                <img className="refresh" src={refresh} alt="ProdensaAutomation" onClick={handleRefresh}/>
            </div>
            <Streetview
                apiKey={googleMapsKey}
                streetViewPanoramaOptions={StreetMapOptions}
            />
            <div className="tourLocationsMenu">
                <div className="tourLocationsHeader" onClick={() => setShow(!show)}>
                    <div className="headerContainer">
                        <img src={dataTable} alt="ProdensaAutomation"/>
                        <h5 className="tourTexts">Tour Locations</h5>
                    </div>
                    <div className="headerContainer">
                        <img id="prev" style={{transform: "rotate(180deg)"}} src={arrow} alt="ProdensaAutomation" onClick={handleCurrentLocation}/>
                        <p className="tourTexts">{currentLocation + 1}/{markers.length}</p>
                        <img id="next" src={arrow} alt="ProdensaAutomation" onClick={handleCurrentLocation}/>
                    </div>
                </div>
                <div className={`tourLocationsList ${show ? "show" : ""}`}>
                    <Accordion>
                        {city && city.streetviewPlaces.map((category, index) => {
                            return(
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{<img src={bank} alt="Prodensa Automation" />}{category.sectionName}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="ul">
                                            {category.sectionPois.map((place, placeIndex) => (
                                                <li key={placeIndex} onClick={() => handleLocation([place.longitude, place.latitude])}>{place.poiName}</li>
                                            ))}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            </div>
        </div>
    )
}