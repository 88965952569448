// import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from './components/login/login.jsx';
import Dashboard from './components/dashboard/dashboard.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ReportResults from "./components/reportResults/reportResults.jsx";
import MagicReport from './components/magicLinkReport/magicReport.jsx';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './context/auth-context.jsx';
// import LogisticCosts from './components/siteSelection/wants/logisticsCost.jsx';
import TokenExpiredPage from './components/magicLinkReport/expired.jsx';
// import FormatTest from './components/formatTest.jsx';
// import TestImagePlaces from './components/testImagePlaces.jsx';
// import SecretUpdates from './components/utils/secretUpdates.js'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <CookiesProvider path={"/"}>
            <AuthProvider>
                <BrowserRouter>
                    <Routes path="/">
                        <Route index element={<Login />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/reportResults/:id" element={<ReportResults />} />
                        {/* <Route path='/testMemo' element={<LogisticCosts />} /> */}
                        <Route path='/reportClient/:token' element={<MagicReport/>} />
                        <Route path='/expired' element={<TokenExpiredPage/>} />
                        {/* <Route path='/testFormat' element={<FormatTest/>}/> */}
                        {/* <Route path='/TestImagePlaces' element={<TestImagePlaces/>}/> */}
                        {/* <Route path='/$SUFA-001$' element={<SecretUpdates />}/> */}
                        <Route path='*' element={<Navigate to="/" replace/>} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </CookiesProvider>
    </>
);
