import { useEffect, useState } from "react"

import save from "../../assets/images/save.svg"

function SearchClients(props) {
	const [clients, setClients] = useState(props.allClients)
	const [filteredClients, setFilteredClients] = useState(props.allClients)
	const [search, setSearch] = useState("")

	const [showClients, setShowClients] = useState(false)

	useEffect(() => {
		setClients(props.allClients)
		setFilteredClients(props.allClients)
	}, [props.allClients])

	useEffect(() => {
		setFilteredClients(SearchBarFilter(clients, search))
	}, [search])

	const handleClientReports = (e) => {
		const { id } = e.target.closest(".client")
		props.setshowClientId(id)
		props.openReportLog()
	}

	const capitalizeString = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	const SearchBarFilter = (clients, searchTerm) => {
		if (searchTerm !== "") {
			const filteredData = clients.filter(client => client.companyName.toLowerCase().includes(searchTerm.toLowerCase())
			);
			return filteredData;
		}
		return clients;
	}

	return (
		<div className="searchContainer d-none d-lg-block">
			<input className="search" type="text" placeholder="Search client reports" value={search}
				onFocus={() => setShowClients(!showClients)} onBlur={() => setShowClients(!showClients)}
				onChange={(e) => setSearch(e.target.value)} />
			<div className={`searchClients ${showClients ? "show" : ""}`}>
				<div className="clients">
					{filteredClients.map(client => (
						<div key={client._id} className="client" id={client._id} onClick={(e) => handleClientReports(e)}>
							<span className="company">{capitalizeString(client.companyName)}</span>
							<span className="name">{capitalizeString(client.contactName)}</span>
							<span className="email">{client.mail}</span>
						</div>
					))}
				</div>
				<div className="viewAll">
					<img src={save} alt="ProdensaAutomation" />
					<span onClick={() => props.openReportLog()}>View all reports</span>
				</div>
			</div>
		</div>
	)
}

export default SearchClients