import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import { ACTIONS, USER_INPUTS, createUpdateUserAlert } from "./userConfigUtils";
import { customAlert, loadingAlert } from "../../utils/customAlert";

export default function EditAddUser({ state, dispatch }) {
    const [user, setUser] = useState(state.user)
    const [showMenu, setShowMenu] = useState({
        role: false,
        status: false
    })

    useEffect(() => {
        function handleShowMenu(menu, oppositeMenu) {
            setShowMenu(prevState => {
                return {
                    ...prevState,
                    [oppositeMenu]: false,
                    [menu]: !prevState[menu]
                }
            })
        }
    
        function closeDropdowns(e) {
            const { classList } = e.target
            if (!classList.contains("dropdown-button")) {
                setShowMenu({ role: false, status: false })
            } else {
                const menu = e.target.getAttribute("id") === USER_INPUTS.role.accessKey ? "role" : "status"
                const oppositeMenu = e.target.getAttribute("id") === USER_INPUTS.role.accessKey ? "status" : "role"

                handleShowMenu(menu, oppositeMenu)
            }
        }

        window.addEventListener("click", closeDropdowns)

        return () => window.removeEventListener("click", closeDropdowns)
    }, [])
    
    function handleConfirmAction() {
        loadingAlert()

        const typeOfFetch = state.user?._id ? "patch" : "post"
        const endpoint = state.user?._id ? (process.env.REACT_APP_UPDATE_PROFILE_USER + state.user._id) : process.env.REACT_APP_CREATE_USER

        Axios[typeOfFetch](endpoint, user).then(res => {
            Swal.close()
            createUpdateUserAlert(res.data.message, dispatch)
        }).catch(err => {
            Swal.close()
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }

    function handleCancel() {
        dispatch({ type: ACTIONS.toggleSection, payload: null })
    }

    function handleInputOnChange(e) {
        const { value, id } = e.target
        const spanValue = e.target.dataset.value

        setUser(prevState => {
            return {
                ...prevState,
                [id]: spanValue || value 
            }
        })
    }


    // text for confirm button
    const confirmAction = state.user?._id ? "Save Changes" : "Create User"

    // text for dropdown buttons
    const roleButtonText = user?.[USER_INPUTS.role.accessKey] === "" ? "Select Role" : user?.[USER_INPUTS.role.accessKey]
    const statusButtonText = user?.[USER_INPUTS.status.accessKey] ? "inactive" : "active"

    // state to know if dropdowns are active
    const buttonActive = {
        role: showMenu.role ? "active" : "",
        status: showMenu.status ? "active" : ""
    }

    // show dropdowns
    const showRoleMenu = showMenu.role ? "show" : ""
    const showStatusMenu = showMenu.status ? "show" : ""

    // verification for email
    const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

    // check if inputs are filled to enable confirm button
    const actionButtonDisabled = user?.fullName !== "" && user?.mail !== "" && mailRegex.test(user?.mail) && user?.role !== "" ? false : true

    return (
        <Row>
            <Col lg={{ span: 8 }}>
                <section className="new-edit-user-section">
                    <div className="inputContainer" key={USER_INPUTS.name.accessKey}>
                        <label htmlFor={USER_INPUTS.name.accessKey}>{USER_INPUTS.name.label}</label>
                        <input 
                            id={USER_INPUTS.name.accessKey} 
                            placeholder="User Name"
                            type="text" 
                            value={user?.[USER_INPUTS.name.accessKey]} 
                            onChange={handleInputOnChange}
                        />
                    </div>
                    <div className="inputContainer" key={USER_INPUTS.mail.accessKey}>
                        <label htmlFor={USER_INPUTS.mail.accessKey}>{USER_INPUTS.mail.label}</label>
                        <input 
                            id={USER_INPUTS.mail.accessKey} 
                            placeholder="User Mail / Username"
                            type="text" 
                            value={user?.[USER_INPUTS.mail.accessKey]} 
                            disabled={state.user?._id}
                            onChange={handleInputOnChange}
                        />
                    </div>
                    <div className="dropdowns-container">
                        <div className="inputContainer" key={USER_INPUTS.role.accessKey}>
                            <label>{USER_INPUTS.role.label}</label>
                            <button id={USER_INPUTS.role.accessKey} className={`dropdown-button ${buttonActive.role}`} >
                                {roleButtonText}
                            </button>
                            <div className={`dropdown-list ${showRoleMenu}`}>
                                {USER_INPUTS.role.options.map(option => {
                                    return (
                                        <span 
                                        id={USER_INPUTS.role.accessKey}
                                        data-value={option}
                                        key={option}
                                        onClick={handleInputOnChange}
                                        >
                                        {option}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="inputContainer" key={USER_INPUTS.status.accessKey}>
                            <label>{USER_INPUTS.status.label}</label>
                            <button id={USER_INPUTS.status.accessKey} className={`dropdown-button ${buttonActive.status}`} >
                                {statusButtonText}
                            </button>
                            <div className={`dropdown-list ${showStatusMenu}`}>
                                {USER_INPUTS.status.options.map(option => {
                                    const statusValue = !(option === "active")
                                
                                    return (
                                        <span 
                                        id={USER_INPUTS.status.accessKey}
                                        data-value={statusValue}
                                        key={option} 
                                        onClick={handleInputOnChange}
                                        >
                                        {option}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="actions-container">
                        <button className="button confirmButton" disabled={actionButtonDisabled} onClick={handleConfirmAction}>
                            <span>
                                {confirmAction}
                            </span>
                        </button>
                        <button className="btn" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </section>
            </Col>
        </Row>
    )
}