import "../../../assets/css/reportResults/wantsView/details-heading.css";

import Slider from "react-slick";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal, Row, Col } from "react-bootstrap";

import CompareBox from "./compare-box.jsx";
import comment from "../../../assets/images/3p.svg";
import detail from "../../../assets/images/analytics.svg";
import barchart from "../../../assets/images/bar_chart_4_bars.svg";
import expandless from "../../../assets/images/expand_less_dark.svg";

const DetailsHeading = ({
  wantsMatrixRef,
  setIsDetailsActive,
  details,
  city_names,
  setShowExtraDetails,
  wantsDetails,
  land
}) => {
  const [showComments, setShowComments] = useState(false); //Control variable for comments modal
  const [showCriteria, setShowCriteria] = useState(false); //Control variable for criteria modal
  const initial_filtered = city_names.map((city) => city_names.indexOf(city));
  const [filtered, setFiltered] = useState(initial_filtered);
  const [showAllClass, setShowAllClass] = useState(false);
  const commentSample =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis nulla vel ultrices fermentum. Etiam vitae eros tellus. Sed vitae maximus felis. Duis ornare velit in sapien condimentum, consequat convallis dolor vulputate. Morbi dolor enim, egestas ac massa non, venenatis tincidunt nunc. Ut ut viverra nulla, vel porta magna. Aliquam bibendum, eros eu rhoncus maximus, orci nunc tristique est, ac finibus quam augue a leo.Sed tempor nulla et turpis consequat vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse vestibulum ligula feugiat cursus mollis. Ut in lobortis dui. Nunc hendrerit ante sapien, vel eleifend.";

  //Initializes sliderSettings
  const init_scoreSliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
  };

  const addendum = wantsDetails.criterias.typeOfValue === "Percentage" ? "%" : "";
  const varRef = wantsDetails.addedVariable ? { title: wantsDetails.title } :
    wantsMatrixRef.flatMap(category => category.variables).find(variable => variable._id === details.variableIdRef);

  const [sliderSettings, setSliderSettings] = useState(
    init_scoreSliderSettings
  );

  const handleResize = (event) => {
    //Handles the resize of the textarea depending on the scrollheight added.
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };
  //Previously calculates the max city score so the generated elements can be compared and highest class thus be assigned

  return (
    <>
      <Row>
        <Row className="title-section">
          <Col lg={4} className="title">
            <img
              src={expandless}
              className="expand-icon"
              onClick={() => setIsDetailsActive(false)}
            />
            <h4>{details.variableIdRef.toString() === "65cd41c4f955fa725381e7ec" ? "Average "+ land.landContract.toLowerCase() + " cost" : varRef.title}</h4>
          </Col>
          <Col lg={7} className="extra-buttons">
            {details.variableIdRef.toString() === "65cd417ff955fa725381e7e7" && (
              <button
                onClick={() => {
                  setShowExtraDetails(true);
                }}
              >
                <img src={detail} />
                <span>Details</span>
              </button>
            )}
            <CompareBox
              origin={`details-heading-${details.variableIdRef}`}
              cityNames={city_names}
              setFiltered={setFiltered}
              sliderSettings={sliderSettings}
              setSliderSettings={setSliderSettings}
              comp_variant={"carousel-all"}
              setShowAllClass={setShowAllClass}
            />
            <button onClick={() => setShowCriteria(true)}>
              <img src={barchart} />
              <span>Criteria</span>
            </button>
            <button onClick={() => setShowComments(true)}>
              <img src={comment} />
              <span>Comments</span>
            </button>
          </Col>
        </Row>

        <Row className={`d-none d-lg-flex city-score-section ${showAllClass ? "horizontal" : ""}`}>
          <Slider {...sliderSettings}>
            {details.results.map(
              (cityScore, index) =>
                filtered.includes(index) && (
                  <Col lg={4} key={index} className="item-score-wrapper">
                    <div
                      className={`city-score-item ${
                        showAllClass ? "horizontal" : ""
                      }`}
                    >
                      <div>
                        <h4>
                          {city_names.find(
                            (obj) =>
                              obj == cityScore.city ||
                              obj.replace(" Metroplex", "") == cityScore.city
                          )}
                        </h4>
                        <p>City</p>
                      </div>
                      <div>
                        <h1
                          className={`score ${
                            cityScore.valueResult.bestOption ? "highest" : ""
                          }`}
                        >
                          {+cityScore.valueResult.points ?? "N/A"}
                        </h1>
                        <p>Rate</p>
                      </div>
                    </div>
                  </Col>
                )
            )}
          </Slider>
        </Row>

        <Row className="px-0">
          <Col xs={12}>
            <section className="mobile-cities-score-section d-lg-none">
              {details.results.map(
                (cityScore, index) =>
                  filtered.includes(index) && (
                    <div key={index} className={`city-section ${cityScore.valueResult.bestOption ? "highest" : ""}`}>
                      <div>
                        <h5>
                          {city_names.find(
                            (obj) =>
                              obj == cityScore.city ||
                              obj.replace(" Metroplex", "") == cityScore.city
                          )}
                        </h5>
                        <span>City</span>
                      </div>
                      <div className="score-section">
                        <h4>
                          {+cityScore.valueResult.points ?? "N/A"}
                        </h4>
                        <span>Rate</span>
                      </div>
                    </div>
                  )
              )}
            </section>
          </Col>
        </Row>

        <Modal
          className="comments-modal"
          centered
          show={showComments}
          onHide={() => setShowComments(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Comments</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="comment-save"
              rows="6"
              value={commentSample}
              readOnly={true}
            ></textarea>
          </Modal.Body>
          {/* <Modal.Footer>
                <button>
                  <span>Save</span>
                </button>
              </Modal.Footer> */}
        </Modal>

        <Modal
          className="criteria-modal"
          centered
          show={showCriteria}
          onHide={() => setShowCriteria(false)}
        >
          {" "}
          {/*Modal for adding custom mail */}
          <Modal.Header closeButton>
            <Modal.Title>Criteria</Modal.Title>{" "}
            {/*Modal fro criteria visualization, pending to add dynamic generation based on criteria */}
          </Modal.Header>
          <Modal.Body>
            <div className="criteria-modal-content">
              <div className="head-section">
                <h5>{details.variableName}</h5>
                <h5>Type of value: {wantsDetails.criterias.typeOfValue}</h5>
              </div>
              <div className="body-section">
                <div className="criteria-sub">
                  <h5>Criteria</h5>
                  {wantsDetails.criterias.typeOfValue == "Text" ? (
                    <>
                      {wantsDetails.criterias.options.map((option, index) => (
                        <div className="text-crit" style={{ justifyContent: "left" }} key={index}>
                          {option.value}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-crit">
                        {wantsDetails.criterias.options[0].criteria}{" "}
                        {wantsDetails.criterias.options[0].value !== null
                          ? wantsDetails.criterias.options[0].value
                          : "N/A"} {addendum}
                      </div>
                      <div className="from-row">
                        <div className="from-to">
                          <h5>From</h5>
                          <h5>
                            {wantsDetails.criterias.options[1].firstRange} {addendum}
                          </h5>
                        </div>
                        <div className="from-to">
                          <h5>To</h5>
                          <h5>
                            {wantsDetails.criterias.options[1].secondRange} {addendum}
                          </h5>
                        </div>
                      </div>
                      <div className="from-row">
                        <div className="from-to">
                          <h5>From</h5>
                          <h5>
                            {wantsDetails.criterias.options[2].firstRange} {addendum}
                          </h5>
                        </div>
                        <div className="from-to">
                          <h5>To</h5>
                          <h5>
                            {wantsDetails.criterias.options[2].secondRange} {addendum}
                          </h5>
                        </div>
                      </div>
                      <div className="text-crit">
                        {wantsDetails.criterias.options[3].criteria}{" "}
                        {wantsDetails.criterias.options[3].value !== null
                          ? wantsDetails.criterias.options[3].value 
                          : "N/A"} {addendum}
                      </div>
                    </>
                  )}
                </div>
                <div className="points-sub">
                        <h5>Points</h5>
                        <div className="point">0</div>
                        <div className="point">1</div>
                        <div className="point">2</div>
                        <div className="point">3</div>
                      </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Row>
    </>
  );
};
export default DetailsHeading;
