export function formatNumberToShortScale(value) {
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixIndex = 0;

    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
        value /= 1000;
        suffixIndex++;
    }

    // Use toFixed to limit the number of decimal places

    const formattedValue = Number(value).toFixed(suffixIndex);

    return `${formattedValue}${suffixes[suffixIndex]}`;
}