import { v4 as uuidv4 } from "uuid";
import img_2_1 from "../../../assets/pdfImages/2-1.jpg";
import img_2_2 from "../../../assets/pdfImages/2-2.png";
import img_2_3 from "../../../assets/pdfImages/2-3.png";
import img_3_1 from "../../../assets/pdfImages/3-1.jpg";
import img_3_2 from "../../../assets/pdfImages/3-2.jpg";
import img_3_3 from "../../../assets/pdfImages/3-3.jpg";
import img_4_1 from "../../../assets/pdfImages/4-1.jpg";
import img_4_2 from "../../../assets/pdfImages/4-2.jpg";
import img_4_3 from "../../../assets/pdfImages/4-3.jpg";
import img_5_1 from "../../../assets/pdfImages/5-1.jpg";
import img_5_2 from "../../../assets/pdfImages/5-2.jpg";
import img_5_3 from "../../../assets/pdfImages/5-3.jpg";
import img_6_1 from "../../../assets/pdfImages/6-1.jpg";
import img_6_2 from "../../../assets/pdfImages/6-2.jpg";
import img_6_3 from "../../../assets/pdfImages/6-3.jpg";
import img_6_4 from "../../../assets/pdfImages/6-4.jpg";
import img_7_1 from "../../../assets/pdfImages/7-1.jpg";
import img_7_2 from "../../../assets/pdfImages/7-2.jpg";
import img_7_3 from "../../../assets/pdfImages/7-3.jpg";
import img_7_4 from "../../../assets/pdfImages/7-4.jpg";
import img_7_5 from "../../../assets/pdfImages/7-5.jpg";


export const NAV_MENU_OPTIONS = [
    {
        id: uuidv4(),
        title: "Client info",
        position: 1,
    },
    {
        id: uuidv4(),
        title: "Objective",
        position: 2,
    },
    {
        id: uuidv4(),
        title: "Why prodensa",
        position: 3,
    },
    {
        id: uuidv4(),
        title: "Assumptions",
        position: 4,
    },
    {
        id: uuidv4(),
        title: "Musts",
        position: 5,
    },
    {
        id: uuidv4(),
        title: "Wants",
        position: 6,
    },
    // {
    //     id: uuidv4(),
    //     title: "Evaluating System",
    //     position: 7,
    // },
    {
        id: uuidv4(),
        title: "Summary",
        position: 7,
    },
    // {
    //     id: uuidv4(),
    //     title: "Sources",
    //     position: 9,
    // },
]

export const PDF_FORM = [
    // section 1
    {
        id: uuidv4(),
        label: "Client name",
        typeOfInput: "input",
        placeholder: "Client name",
        maxLength: "1000",
        accessKey: "clientName"
    },
    // section 2
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 150 characters)",
        icon: img_2_1,
        typeOfInput: "textarea",
        placeholder: "Highlighted text",
        maxLength: "150",
        accessKey: "objectiveHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 350 characters)",
        icon: img_2_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "350",
        accessKey: "objectivePrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 200 characters)",
        icon: img_2_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "200",
        accessKey: "objectiveSecondary"
    },
    // section 3
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 200 characters)",
        icon: img_3_1,
        typeOfInput: "textarea",
        placeholder: "Highlighted text",
        maxLength: "200",
        accessKey: "whyProdensaHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 250 characters)",
        icon: img_3_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "250",
        accessKey: "whyProdensaPrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 200 characters)",
        icon: img_3_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "200",
        accessKey: "whyProdensaSecondary"
    },
    // section 4
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 220 characters)",
        icon: img_4_1,
        typeOfInput: "textarea",
        placeholder: "Highlighted text",
        maxLength: "220",
        accessKey: "assumptionsHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 350 characters)",
        icon: img_4_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "350",
        accessKey: "assumptionsPrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 550 characters)",
        icon: img_4_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "550",
        accessKey: "assumptionsSecondary"
    },
    // section 5
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 250 characters)",
        icon: img_5_1,
        typeOfInput: "textarea",
        placeholder: "Highlighted text",
        maxLength: "250",
        accessKey: "mustsHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 150 characters)",
        icon: img_5_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "150",
        accessKey: "mustsPrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 420 characters)",
        icon: img_5_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "420",
        accessKey: "mustsSecondary"
    },
    // section 6
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 200 characters)",
        icon: img_6_1,
        typeOfInput: "textarea",
        placeholder: "Highlighted text",
        maxLength: "200",
        accessKey: "wantsHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 580 characters)",
        icon: img_6_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "580",
        accessKey: "wantsPrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 500 characters)",
        icon: img_6_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "500",
        accessKey: "wantsSecondary"
    },
    {
        id: uuidv4(),
        label: "Tertiary text",
        extraSpan: "(Max. 200 characters)",
        icon: img_6_4,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "200",
        accessKey: "wantsTertiary"
    },
    // section 7
    // {
    //     id: uuidv4(),
    //     label: "Highlighted text",
    //     extraSpan: "(Max. 200 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Highlighted text",
    //     maxLength: "200"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Title",
    //     extraSpan: "(Max. 50 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "50"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Definition",
    //     extraSpan: "(Max. 500 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "500"
    // },
    // {
    //     id: uuidv4(),
    //     title: "Scores for each variable",
    // },
    // {
    //     id: uuidv4(),
    //     label: "Regular text",
    //     extraSpan: "(Max. 500 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "500"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Lower value",
    //     extraSpan: "(Max. 200 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "200"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Higher value",
    //     extraSpan: "(Max. 200 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "200"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Tie",
    //     extraSpan: "(Max. 200 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "200"
    // },
    // {
    //     id: uuidv4(),
    //     title: "Category scores",
    // },
    // {
    //     id: uuidv4(),
    //     label: "Regular text",
    //     extraSpan: "(Max. 500 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "500"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Highlighted text",
    //     extraSpan: "(Max. 200 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "500"
    // },
    // {
    //     id: uuidv4(),
    //     label: "Regular text",
    //     extraSpan: "(Max. 500 characters)",
    //     icon: "hola",
    //     typeOfInput: "textarea",
    //     placeholder: "Regular text",
    //     maxLength: "500"
    // },
    // section 8
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 200 characters)",
        icon: img_7_1,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "200",
        accessKey: "summaryHighlighted"
    },
    {
        id: uuidv4(),
        label: "Regular text",
        extraSpan: "(Max. 250 characters)",
        icon: img_7_2,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "250",
        accessKey: "summaryRegular"
    },
    {
        id: uuidv4(),
        title: "City and Site Selection Study Results",
    },
    {
        id: uuidv4(),
        label: "Highlighted text",
        extraSpan: "(Max. 70 characters)",
        icon: img_7_3,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "70",
        accessKey: "summaryCitiesHighlighted"
    },
    {
        id: uuidv4(),
        label: "Primary text",
        extraSpan: "(Max. 750 characters)",
        icon: img_7_4,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "750",
        accessKey: "summaryCitiesPrimary"
    },
    {
        id: uuidv4(),
        label: "Secondary text",
        extraSpan: "(Max. 750 characters)",
        icon: img_7_5,
        typeOfInput: "textarea",
        placeholder: "Regular text",
        maxLength: "750",
        accessKey: "summaryCitiesSecondary"
    },
]

export const NUMBER_OF_INPUTS = {
    1: {
        min: 0,
        max: 1,
    },
    2: {
        min: 1,
        max: 4,
        title: "Objective"
    },
    3: {
        min: 4,
        max: 7,
        title: "Why Prodensa"
    },
    4: {
        min: 7,
        max: 10,
        title: "Assumptions"
    },
    5: {
        min: 10,
        max: 13,
        title: "Musts",
        extraOptions: "Cities"
    },
    6: {
        min: 13,
        max: 17,
        title: "Wants",
        extraOptions: "Wants matrix"
    },
    7: {
        min: 17,
        max: 23,
        title: "Summary",
    },
    // 7: {
    //     min: 17,
    //     max: 29,
    //     title: "Evaluating System",
    // },
    // 8: {
    //     min: 29,
    //     max: 35,
    //     title: "Summary",
    // },
}
