import { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import moreDots from "../../assets/images/moreDots.svg"
import prodensaIcon from "../../assets/images/iconProdensa.svg"
import { ACTIONS, capitalizedName } from "./userConfigUtils";
import { customAlert, loadingAlert } from "../../utils/customAlert"

export default function UserRow({ user, state, dispatch, handleEditAddUser }){
    useEffect(() => {
        function closeMenus(event) {
            const targetClassList = event.target.classList;
            if (!targetClassList.contains("moreOptions-dots")) {
                dispatch({ type: ACTIONS.toggleMenu, payload: null });
            }
        }

        document.addEventListener("click", closeMenus)

        return () => document.removeEventListener("click", closeMenus)
    }, [])
    

    function handleShowMenu(id) {
        dispatch({ type: ACTIONS.toggleMenu, payload: id })
    }

    // change user status
    function handleUserStatus(user) {
        loadingAlert()

        Axios.patch(process.env.REACT_APP_TOGGLE_USER_STATUS + user._id, { deletedRegister: !user.deletedRegister }).then(res => {
            Swal.close()
            if (res.status === 202) {
                dispatch({ type: ACTIONS.toggleUserStatus, payload: user._id })
            }
        }).catch(err => {
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }

    // capitalize user name
    const userName = capitalizedName(user.fullName)

    // show is user is active or inactive
    const activeUser = user.deletedRegister ? "inactive" : "active";
    
    // show user image if exists, if not show default image
    const userImage = user && user.hasOwnProperty("photo") ? 
        <img className="profile-picture" src={process.env.REACT_APP_BACKEND_URL + user.photo.path} alt="Prodensa Automation"/> :
        <div className="no-image-container">
            <img className="no-image" src={prodensaIcon} alt="Prodensa Automation"/>
        </div>;

    // show user menu if user is active
    const showMenuClass = state.menuShow === user._id ? "showUserMenu" : "";

    // show user status class
    const userStatusClass = user.deletedRegister ? "inactive" : "";

    // show text for enable or disable user
    const userStatusAction = user.deletedRegister ? "Enable" : "Disable";
    const userStatusActionClass = user.deletedRegister ? "enable" : "disable";
    
    
    return (
        <main className="table-row">
            <section className="table-row-item">{userImage}</section>
            <section className="table-row-item"><span>{userName}</span></section>
            <section className="table-row-item" id="userMail"><span>{user.mail}</span></section>
            <section className="table-row-item"><span>{user.role}</span></section>
            <section className="table-row-item" id="userStatus"><span className={`${userStatusClass}`}>{activeUser}</span></section>
            <section className="table-row-item options">
                <img src={moreDots} className="moreOptions-dots" alt="Prodensa Automation" onClick={() => handleShowMenu(user._id)}/>
                <div className={`moreOptions ${showMenuClass}`}>
                    <span className="moreOptions-item" onClick={() => handleEditAddUser(user)}>Edit</span>
                    <span className={`moreOptions-item ${userStatusActionClass}`} onClick={() => handleUserStatus(user)}>{userStatusAction}</span>
                </div>
            </section>
        </main>
    )
};
