import { getClientName } from "./getClientNameById";

export const sortTable = (data, allClients, order, type) => {
    switch (type) {
        case "client":
            if (order === "ASC") {
                return (data.sort((a, b) => {
                    const fa = getClientName(a.siteSelectionReport.clientId, allClients).toLocaleLowerCase();
                    const fb = getClientName(b.siteSelectionReport.clientId, allClients).toLocaleLowerCase();

                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }

                    return 0
                }))
            } else {
                return (data.sort((a, b) => {
                    const fa = getClientName(a.siteSelectionReport.clientId, allClients).toLocaleLowerCase();
                    const fb = getClientName(b.siteSelectionReport.clientId, allClients).toLocaleLowerCase();

                    if (fa > fb) {
                        return -1
                    }
                    if (fa < fb) {
                        return 1
                    }

                    return 0
                }))
            }

        case "status":
            if (order === "ASC") {
                return (data.sort((a, b) => {
                    return a.siteSelectionReport.percentage - b.siteSelectionReport.percentage
                }))
            } else {
                return (data.sort((a, b) => {
                    return b.siteSelectionReport.percentage - a.siteSelectionReport.percentage
                }))
            }

        case "date":
            if (order === "ASC") {
                return (data.sort((a, b) => {
                    const da = new Date(a.updatedAt)
                    const db = new Date(b.updatedAt)

                    return da - db
                }))
            } else {
                return (data.sort((a, b) => {
                    const da = new Date(a.updatedAt)
                    const db = new Date(b.updatedAt)

                    return db - da
                }))
            }

        default:
            break;
    }
}