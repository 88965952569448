/* FORMAT COMMAS */
export const formatCommas = (value) => {
    const rawValue = value ? value.toString() : '0';
    const numericValue = rawValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    const includeDot = numericValue.includes('.');
    if(includeDot) {
        const splitValue = numericValue.split('.');
        const beforeDot = splitValue[0];
        const afterDot = splitValue[1];
        const formattedBeforeDot = new Intl.NumberFormat('en-US').format(Number(beforeDot));
        const formattedAfterDot = afterDot;
        const formattedValue = `${formattedBeforeDot}.${formattedAfterDot}`;
        /*
            Return values
            formattedValue: Number for display with commas
            numericValue: Raw value for storage
        */
        return {
            formattedValue,numericValue
        }
    } else {
        const formattedValue = new Intl.NumberFormat('en-US').format(Number(numericValue));
        /*
            Return values
            formattedValue: Number for display with commas
            numericValue: Raw value for storage
        */
        return {
            formattedValue,numericValue
        }
    }
}