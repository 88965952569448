import Axios from "axios"
import Swal from "sweetalert2"
import { OverlayTrigger } from "react-bootstrap";
import { useState, useRef } from "react";

import loading from "../../../assets/images/loading.gif"
import iconBlue from "../../../assets/images/info-blue.svg"
import close from "../../../assets/images/close.svg"
// import img_city_1 from "../../../assets/pdfImages/city-1.jpg";
// import img_city_2 from "../../../assets/pdfImages/city-2.jpg";
import img_city_3 from "../../../assets/pdfImages/city-3.jpg";
import img_city_regular from "../../../assets/pdfImages/city-regular.jpg"
import img_city_map from "../../../assets/pdfImages/city-map.jpg"

const openAICityDescriptionUrl = process.env.REACT_APP_GET_PDF_CITY_OPEN_AI

export default function CitiesForms({ pdfConfig, handleFile, updateInput, draft, imageTooltip }) {
    const [isLoading, setIsLoading] = useState(false)
    
    const cities = draft?.siteSelectionReport?.must?.cities
    
    const fileInputRefs = useRef({})
    const mapInputsRefs = useRef({})
    
    function handleFileButtonClick(cityId) {
        const fileInput = fileInputRefs.current[cityId];
        if (fileInput) {
            fileInput.click();
        }
    };
    
    function handleMapButtonClick(cityId) {
        const mapInput = mapInputsRefs.current[cityId];
        if (mapInput) {
            mapInput.click();
        }    
    }
    
    function handleIAPrompt(prompt, callback) {
        setIsLoading(true)
        Axios.post(openAICityDescriptionUrl, { prompt }).then(res => {
            const cityDescription = res.data?.cityDescription ?? prompt
            
            callback(cityDescription)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.log(err)

            Swal.fire({
                icon: "error",
                title: err?.response?.data?.message || "Error getting openai response",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500,
                timerProgressBar: true,
            });
        })
    }
    
    return (
        <div>
            {cities?.map(city => {
                const cityId = city.datamexico_municipality_id
                const cityData = pdfConfig?.citiesData?.find(pdfCity => pdfCity.id === cityId)
                const cityName = city.municipality_name.replace(" Metroplex", "")

                const cityTextPrimary = cityData?.cityPrimary ?? `Give me a brief description of ${cityName}, ${city.state_name} of maximum 450 characters`
                // const cityTextSecondary = cityData?.citySecondary ?? `Give me a brief description of ${cityName}, ${city.state_name} of maximum 250 words`
                const cityImage = cityData?.document
                const mapImage = cityData?.mapImage

                return (
                    <div key={cityId} className="city-forms-container">
                        <h5 className="cityTitle">{city.municipality_name}</h5>
                        
                        <div className="inputContainer">
                            <div className="input-label-container">
                                <label htmlFor={`${cityId}-textarea`}>Regular Text</label>
                                <span>(Max. 450 characters)</span>
                                <OverlayTrigger placement="top" overlay={imageTooltip(img_city_regular)}>
                                    <img src={iconBlue} alt="Prodensa Automation" />
                                </OverlayTrigger>
                            </div>

                            <textarea 
                                value={cityTextPrimary}
                                onChange={(e) => updateInput("citiesData", "cityPrimary", e.target.value, cityId)}
                                id={`${cityId}-textarea`} 
                                placeholder="City description"
                                maxLength={"450"}
                            />

                            <div className="openAIButtonContainer">
                                {isLoading && <img src={loading} alt="Prodensa Automation" />}

                                <button 
                                    className="secondaryButton chatgptButton" 
                                    onClick={() => handleIAPrompt(cityTextPrimary, (cityDescription) => updateInput("citiesData", "cityPrimary", cityDescription, cityId))}
                                    disabled={isLoading}
                                >
                                    Generate text with Chat GPT
                                </button>
                            </div>
                        </div>

                        {/* <div className="inputContainer">
                            <div className="input-label-container">
                                <label htmlFor={`${cityId}-textarea`}>Secondary Text</label>
                                <span>(Max. 250 characters)</span>
                                <OverlayTrigger placement="top" overlay={imageTooltip(img_city_2)}>
                                    <img src={iconBlue} alt="Prodensa Automation" />
                                </OverlayTrigger>
                            </div>

                            <textarea 
                                value={cityTextSecondary}
                                onChange={(e) => updateInput("citiesData", "citySecondary", e.target.value, cityId)}
                                id={`${cityId}-textarea`} 
                                placeholder="City description"
                                maxLength={"250"}
                            />
                        </div> */}

                        <div className="inputContainer">
                            <div className="input-label-container">
                                <strong>City Picture</strong>
                                <span>(Max. 5 MB)</span>
                                <OverlayTrigger placement="top" overlay={imageTooltip(img_city_3)}>
                                    <img src={iconBlue} alt="Prodensa Automation" />
                                </OverlayTrigger>
                            </div>

                            <input 
                                type="file" 
                                style={{display: "none"}} 
                                ref={el => (fileInputRefs.current[cityId] = el)} 
                                onChange={(e) => handleFile(e, cityId, "document")}
                            />

                            {!cityImage && <button 
                                className="secondaryButton imgButton" 
                                onClick={() => handleFileButtonClick(cityId)}
                            >
                                Select image
                            </button>}

                            
                            {cityImage && 
                                <div className="image-name-container">
                                    <a 
                                        className="secondaryButton"
                                        href={process.env.REACT_APP_BACKEND_URL + cityImage.path} 
                                        target="_blank"
                                    >
                                        View attached file
                                    </a>
                                    
                                    <span>{cityImage.filename}</span>

                                    <img 
                                        src={close} 
                                        alt="Prodensa Automation" 
                                        style={{ cursor: "pointer" }}
                                        onClick={() => updateInput("citiesData", "document", null, cityId)}
                                    />
                                </div>
                            }
                        </div>

                        <div className="inputContainer">
                            <div className="input-label-container">
                                <strong>Map Picture</strong>
                                <span>(Max. 5 MB)</span>
                                <OverlayTrigger placement="top" overlay={imageTooltip(img_city_map)}>
                                    <img src={iconBlue} alt="Prodensa Automation" />
                                </OverlayTrigger>
                            </div>

                            <input 
                                type="file" 
                                style={{display: "none"}} 
                                ref={el => (mapInputsRefs.current[cityId] = el)} 
                                onChange={(e) => handleFile(e, cityId, "mapImage")}
                            />

                            {!mapImage && <button 
                                className="secondaryButton imgButton" 
                                onClick={() => handleMapButtonClick(cityId)}
                            >
                                Select image
                            </button>}

                            
                            {mapImage && 
                                <div className="image-name-container">
                                    <a 
                                        className="secondaryButton"
                                        href={process.env.REACT_APP_BACKEND_URL + mapImage.path} 
                                        target="_blank"
                                    >
                                        View attached file
                                    </a>
                                    
                                    <span>{mapImage.filename}</span>

                                    <img 
                                        src={close} 
                                        alt="Prodensa Automation" 
                                        style={{ cursor: "pointer" }}
                                        onClick={() => updateInput("citiesData", "mapImage", null, cityId)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
