import "../../assets/css/streetviewconfig/streetviewconfig.css"
import "../../assets/css/usersConfig/userConfig.css"

import Axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";

import UsersTable from "./UsersTable";
import EditAddUser from "./EditAddUser";
import { useAuth } from "../../context/auth-context.jsx";
import close from "../../assets/images/close.svg"
import { customAlert, loadingAlert } from "../../utils/customAlert.js"
import { ACTIONS, SORT_TYPES, ACTIVE_SECTION, filterUsers, sortUsers } from "./userConfigUtils";

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.setInitial:
            return {
                ...state,
                users: action.payload,
                filteredUsers: sortUsers(action.payload, SORT_TYPES.status)
            }
        case ACTIONS.toggleMenu:
            return {
                ...state,
                menuShow: state.menuShow === action.payload ? null : action.payload
            }
        case ACTIONS.searchFilter: {
            const filteredUsers = filterUsers(state.users, action.payload)
            const sortedUsers = sortUsers(filteredUsers, state.sortType, state.sort)

            return {
                ...state,
                filteredUsers: sortedUsers
            }
        }
        case ACTIONS.toggleUserStatus: {
            const updatedUsers = state.users.map(user => {
                if (user._id === action.payload) {
                    user.deletedRegister = !user.deletedRegister
                }
                return user
            })

            const sortedUsers = sortUsers(updatedUsers, state.sortType, state.sort)

            return {
                ...state,
                users: updatedUsers,
                filteredUsers: sortedUsers
            }
        }
        case ACTIONS.toggleSection: 
            return {
                ...state,
                activeSection: state.activeSection === ACTIVE_SECTION.users ? ACTIVE_SECTION.editAddUser : ACTIVE_SECTION.users,
                user: action.payload
            }
        case ACTIONS.closeUserConfig: 
            return {
                ...state,
                activeSection: ACTIVE_SECTION.users,
                user: action.payload
            }
        case ACTIONS.sortUsers: {
            let sort
            if (SORT_TYPES[action.payload] === state.sortType) {
                sort = state.sort === "ASC" ? "DSC" : "ASC"
            } else {
                sort = "DSC"
            }
            const sortedUsers = sortUsers(state.filteredUsers, SORT_TYPES[action.payload], sort)

            return {
                ...state,
                sortType: SORT_TYPES[action.payload],
                sort,
                filteredUsers: sortedUsers
            }
        }
        default:
            return state
    }
}

export default function UserConfig(props) {
    const [state, dispatch] = useReducer(reducer, {
        users: [],
        filteredUsers: [],
        sortType: SORT_TYPES.status,
        sort: "ASC",
        menuShow: null,
        activeSection: ACTIVE_SECTION.users,
        user: null
    })

    const [search, setSearch] = useState("")

    const { user } = useAuth()
    
    useEffect(() => {
        if (props.isOpened !== 1 || state.activeSection !== ACTIVE_SECTION.users) return

        loadingAlert()

        Axios.get(process.env.REACT_APP_GET_ALL_PROFILE_USERS).then(res => {
            Swal.close()
            if (res.status === 200) {
                // remove active user from list
                const removeActiveUser = res.data.filter(resUser => resUser._id !== user._id)
                
                dispatch({ type: ACTIONS.setInitial, payload: removeActiveUser })
            }
        }).catch(err => {
            Swal.close()
            console.log(err)
            customAlert(err.response?.data?.message || err.response?.statusText)
        })
    }, [props.isOpened, state.activeSection])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [state.activeSection])

    function handleCloseUserConfig() {
        props.setUserConfigOpened(null);
        setTimeout(() => {
            dispatch({ type: ACTIONS.closeUserConfig, payload: null });
        }, 1000);
    }
    
    function handleSearch(e) {
        setSearch(e.target.value)
        dispatch({ type: ACTIONS.searchFilter, payload: e.target.value })
    }

    function handleEditAddUser(user = null) {
        const selectedUser = {
            _id: user ? user._id : null,
            fullName: user ? user.fullName : "",
            mail: user ? user.mail : "",
            role: user ? user.role : "",
            deletedRegister: user ? user.deletedRegister : false
        }

        dispatch({ type: ACTIONS.toggleSection, payload: selectedUser })
    }


    // control the visibility of the user configuration
    const showUserConfig = props.isOpened === 1 ? "opened" : "";

    // title and description of the user configuration
    const title = state.activeSection === ACTIVE_SECTION.users ? "Users Configuration" : state.user?._id ? "Edit User" : "Add User"
    const description = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout"

    // check if the active section is the users section
    const activeSection = state.activeSection === ACTIVE_SECTION.users
    
    return (
        <main className={`street-config-wrapper ${showUserConfig}`}>
            <div className="containerMinimizeClose">
				<img src={close} alt="Prodensa automation" onClick={handleCloseUserConfig} />
			</div>
            <Container>
                <header className="head-wrapper">
                    <div className="head-text">
                        <h1>{title}</h1>
                        {activeSection && <h5>{description}</h5>}
                    </div>
                    {activeSection && 
                        <aside className="searchContainer">
                            <input className="search" type="text" placeholder="Search" value={search} onChange={handleSearch}/>
                        </aside>
                    }
                </header>
                {activeSection && 
                    <section className="new-user">
                        <button className="blue-bg-button" onClick={() => handleEditAddUser()}>
                            Add new user
                        </button>
                    </section>
                }
                {activeSection ? 
                    <UsersTable state={state} dispatch={dispatch} handleEditAddUser={handleEditAddUser} /> : 
                    <EditAddUser state={state} dispatch={dispatch} />
                }
            </Container>
        </main>
    )
};
