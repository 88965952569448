import {LocationData} from './locationData.ts';
import {DocumentData} from './documentData.ts';

export class Client {
  public _id: string = "";
  public companyName: string = "";
  public phone: string = "";
  public industry: string = "";
  public contactName: string = "";
  public mail: string = "";
  public headquarters: LocationData = new LocationData();
  public notes: string = "";
  public logo: DocumentData = new DocumentData("");
}
