import alphabet from "../../assets/jsonData/abecedario.json";

import { Col, Row } from "react-bootstrap"
import { useState } from "react"

import close from "../../assets/images/close.svg"

export default function ClientsMobileFilter(props) {
    const [showFilter, setShowFilter] = useState(false)
    const [activeLetter, setActiveLetter] = useState("")

    const RESET_ACTION = "reset"
    const APPLY_ACTION = "apply"
    
    const handleButtonAction = (type) => {
        if (type === RESET_ACTION) {
            setActiveLetter("")
            props.setActiveLetter("")
        }

        if (type === APPLY_ACTION) {
            props.handleClickAlphabet(activeLetter)
        }

        setShowFilter(false)
        document.body.style.overflow = "auto"

    }

    const handleOpenCloseAlphabet = () => {
        setShowFilter(!showFilter)

        window.scrollTo(0, 0)
        document.body.style.overflow = showFilter ? "auto" : "hidden"
    }
    
    return (
        <section className="d-lg-none client-mobile-filter-section">
            <button className="primaryButton-mobile" onClick={handleOpenCloseAlphabet}>
                <span>Filter</span>
            </button>
            <div className={`alphabert-mobile-container ${showFilter ? "opened" : ""}`}>
                <img className="close-image" src={close} alt="Prodensa Automation" onClick={handleOpenCloseAlphabet}/>
                <div className="container alphabetInfo">
                    <Row>
                        <Col className="alphabet-actions" xs={{span: 10, offset: 1}}>
                            <button onClick={() => handleButtonAction(RESET_ACTION)}>Reset</button>
                            <h5 style={{fontWeight: 700}}>Filter</h5>
                            <button onClick={() => handleButtonAction(APPLY_ACTION)} disabled={activeLetter === ""}>Apply</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="alphabet-options" xs={{span: 10, offset: 1}}>
                            {alphabet.alphabet.map((letter, index) => {
                                return (
                                    <div key={index} className="letter-container" onClick={() => setActiveLetter(letter)}>
                                        <input type="checkbox" checked={activeLetter === letter} readOnly />
                                        <label>{letter}</label>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

