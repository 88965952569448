import { LocationData } from './locationData.ts';

export class Assumption {
  public building: Building = new Building();
  public land: Land = new Land();
  public utilities: Utilities = new Utilities();
  public logistics: Logistics = new Logistics();
  public environmentalImpacts: {
    options: String[];
    otherChecked: Boolean;
    otherValue: String
  } = {
      options: [],
      otherChecked: false,
      otherValue: "",
    };
  public additionalComments: String = "";
  public representationLetter: Boolean = false
  public representationDocument: {
    filename: String;
    representationFileId: String;
  } = {
      filename: "",
      representationFileId: "",
    };
  public selectedIndustries: any[] = [];
  public exchangeRate: Number = 18;
  // public exchangeRateType: String = "USD";
}


export class Building {
  public buildingContract: String = "";
  public sizeType: String = "sqft";
  public sizeValue: String = "";
  public requirements: {
    options: String[];
    otherChecked: Boolean;
    otherValue: String
  } = {
      options: [],
      otherChecked: false,
      otherValue: "",
    };
}

export class Land {
  public landContract: String = "";
  public sizeType: String = "sqft";
  public sizeValue: String = "";
  public comment: String = "";
}

export class Utilities {
  public electricityInstalled: String = "";
  public electricityInstalledType: String = "monthly"
  public electricityConsumed: String = "";
  public electricityConsumedType: String = "monthly"
  public gas: String = "";
  public gasType: String = "monthly"
  public water: String = "";
  public waterType: String = "monthly"
  public waterAvailable: Boolean = false;
}

export class Logistics {
  public otherLogisticConsiderations: String = "";
  public handlingEquipment: String = "";
  public logisticsInbound: LogisticsInbound = new LogisticsInbound();
  public logisticsOutbound: LogisticsOutbound = new LogisticsOutbound();
  public plLogistics: Boolean = false;
  public logisticsWarehouse: Boolean = false;
}

export class LogisticsInbound {
  // public origins: LocationData[] = [new LocationData("")];
  public inboundGroups: InboundGroups[] = [new InboundGroups()];
  // public quantity: Number = 0;
  // public periodicity: String = "";
  // public unit: String = "";
}

export class LogisticsOutbound {
  public destinations: LocationData[] = [new LocationData("")];
  // public quantity: Number = 0;
  // public periodicity: String = "";
  // public unit: String = "";
}

export class InboundGroups {
  public groupName: String = "";
  public origins: LocationData[] = [new LocationData("")];
}


