import "../../assets/css/siteSelection/mobileFooter.css";

export default function MobileFooter(props) {
    return (
        <footer className="mobile-footer d-lg-none">
            {props.wizardMenus.map((section, index) => {
                const disabled = !(props.draft.siteSelectionReport.clientId !== null &&
                props.draft.siteSelectionReport.clientId !== undefined) && !(props.draft.activeMenu === index);
                
                return (
                    <section key={section} className={`footer-section`} onClick={() => disabled ? null : props.setActiveMenu(index)}>
                        <span id={section} className={`title ${props.draft.activeMenu === index ? "active" : ""}
                        ${disabled ? "disabled" : ""}`}>
                            {section}
                        </span>
                    </section>
                );
            })}
        </footer>
    );
}