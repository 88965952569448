import {Assumption} from './assumption.ts';
import {Must} from './must.ts';
import {Want} from './want.ts';

export class SiteSelectionReport {
  public clientId: String;
  public profileUserId: String;
  public type: String;
  public status: Boolean;
  public percentage: Number;
  public assumption: Assumption = new Assumption();
  public must: {
    cities: Object[]
  } = {
    cities: [],
  };
  public want: Want = new Want();
}
