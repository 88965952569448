const PrevNext = ({ filtered, upperVisible, lowerVisible, setUpperVisible, setLowerVisible }) => {


  const handlePrevClick = () => {  //Decreases the visible index limits so the previous element is shown and the current last element is hidden 
    if (lowerVisible > 0) {
      setLowerVisible(lowerVisible - 1);
      setUpperVisible(upperVisible - 1);
    }

  };

  const handleNextClick = () => {
    if (upperVisible < filtered.length - 1) {  //Increases the visible index limits so the the previous element is hidden and the last element is shown
      setLowerVisible(lowerVisible + 1);
      setUpperVisible(upperVisible + 1);
    }
  };
  return (
    <div className="arrow-button-container d-none d-lg-flex">{/*If the filtered array contains more than three, the buttons are shown*/}
      {filtered.length > 3 && ( 
        <>
          <button className={`prev-button ${lowerVisible > 0 ? "" : "hidden"}`} onClick={handlePrevClick}></button> {/*If the element is not the first one in the array it shows the arrow */}
          <button className={`next-button ${upperVisible < filtered.length - 1 ? "" : "hidden"}`} onClick={handleNextClick}></button> {/*If the element is not the last one in the array it shows the arrow */}
        </>
      )}
    </div>
  );
}

export default PrevNext;